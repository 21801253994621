import { useEffect, useState } from "react";
import { getRatioListByPeriod, LandPrice } from "@api/landprice-api";


export default function useDropdownLandArea(period_plant_txt: string) {
  const [dataLandArea, setDataLandArea] = useState<LandPrice[]>([]);
  const [loadingLandArea, setLoading] = useState(false);
  const [errorLandArea, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownLandArea() {
      setLoading(true);
      let res = await getRatioListByPeriod(period_plant_txt);

      console.log("DD LandArea :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataLandArea(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownLandArea();
  }, [period_plant_txt]);

  return { dataLandArea, errorLandArea, loadingLandArea };
}
