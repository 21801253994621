import axios from "axios";
import { baseURL } from "./common-api";

export interface IFarmerGroup {
  id_seq?: number;
  farmer_group_name_var: string;
  project_code_var: number;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

class FarmerGroupApi {
  static getAll = async () => {
    try {
      const data = await axios.get(baseURL + "/api/farmergroup/get-all", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static createNew = async (payload: IFarmerGroup) => {
    try {
      const data = await axios.post(
        baseURL + "/api/farmergroup/create",
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static update = async (farmerGroupID: number, payload: IFarmerGroup) => {
    try {
      const data = await axios.patch(
        baseURL + "/api/farmergroup/update/" + farmerGroupID,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static delete = async (farmerGroupID: number) => {
    try {
      const data = await axios.delete(
        baseURL + "/api/farmergroup/delete/" + farmerGroupID,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static getFarmers = async (query: any) => {
    try {
      const data = await axios.get(
        baseURL + "/api/farmergroup/get-farmers?" + query,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };
}

export default FarmerGroupApi;
