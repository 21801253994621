import { useEffect, useState } from "react";
import { ILandResearch, getAllLandResearch } from "@api/landResearch-api";

export default function useDropdownLandReserch() {
  const [dataLandResearch, setDataLandResearch] = useState<ILandResearch[]>([]);
  const [loadingLandResearch, setLoading] = useState(false);
  const [errorLandResearch, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownLandResearch() {
      setLoading(true);
      let res = await getAllLandResearch();

      console.log("DD LandResearch :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataLandResearch(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownLandResearch();
  }, []);

  return { dataLandResearch, errorLandResearch, loadingLandResearch };
}
