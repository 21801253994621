import axios from 'axios'
import { baseURL } from './common-api'

export interface ILandArea {
    id_seq: number,
    user_id_int?: number,
    project_code_var?: string,
    project_name_var?: string,
    land_code_var: string,
    land_name_var: string,
    address_land_txt?: string,
    district_var?: number,
    city_var?: number,
    province_var?: number,
    dis_name?: string,
    city_name?: string,
    prov_name?: string,
    postal_code_int?: string,
    area_var?: string,
    land_coordinates_txt?: string,
    managing_farmer_var?: string,
    status_int?: number,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string
}


export const getAllLand = async () => {
    try {
        const data = await axios.get(baseURL + '/api/land-area/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getOneLand = async (landCode: string) => {
    try {
        const data = await axios.get(baseURL + '/api/land-area/get-one?land_code_var=' + landCode, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getDetailLand = async (userID: number, landCode: string) => {
    try {
        const data = await axios.get(baseURL + '/api/land-area/get-one-detail?user_id_int=' + userID + '&land_code_var=' + landCode, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getLandListByUser = async (userID: number) => {
    try {
        const data = await axios.get(baseURL + '/api/land-area/get-list-user?user_id_int=' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getDetailLandListByUser = async (userID: number) => {
    try {
        const data = await axios.get(baseURL + '/api/land-area/list-detail-user?user_id_int=' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewLand = async (payload: ILandArea) => {
    try {
        const data = await axios.post(baseURL + '/api/land-area/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateLand = async (landID: number, payload: ILandArea) => {
    try {
        const data = await axios.patch(baseURL + '/api/land-area/update/' + landID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteLand = async (landID: number) => {
    try {
        const data = await axios.delete(baseURL + '/api/land-area/delete/' + landID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const disableLand = async (landID: number) => {
    try {
        const data = await axios.delete(baseURL + '/api/land-area/disable/' + landID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getAllLandCommodity = async () => {
    try {
        const data = await axios.get(baseURL + '/api/land-commodity/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getOneLandCommodity = async (landCode: string) => {
    try {
        const data = await axios.get(baseURL + '/api/land-commodity/get-one?land_code_var=' + landCode, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createLandCommodity = async (payload: any) => {
    try {
        const data = await axios.post(baseURL + '/api/land-commodity/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateLandCommodity = async (landCode: string, payload: any) => {
    try {
        const data = await axios.post(baseURL + '/api/land-commodity/update?land_code_var=' + landCode, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const disableLandCommodity = async (landCode: string) => {
    try {
        const data = await axios.delete(baseURL + '/api/land-commodity/disable?land_code_var=' + landCode, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


class LandAreaApi {
    static getListLand = async (projectCode: string, landCode: string) => {
        try {
            const data = await axios.get(baseURL + '/api/trxprobe/listland?project_code_var=' + projectCode + '&land_code_var=' + landCode, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static getDropdownFieldAsistant = async () => {
        try {
            const data = await axios.get(baseURL + '/api/land-commodity/dropdownFieldAssistant', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static getDropdownProject = async () => {
        try {
            const data = await axios.get(baseURL + '/api/trxprobe/dropdownProject', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static getDropdownLand = async () => {
        try {
            const data = await axios.get(baseURL + '/api/trxprobe/dropdownLand', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static dropdownLandByUserID = async (userID: number) => {
        try {
            const data = await axios.get(baseURL + '/api/land-commodity/dropdownLand?id_seq'+ userID, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
}


export default LandAreaApi