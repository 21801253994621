import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// import TableUser from "../../components/tables/Table";
import { DropdownLand } from "../../components/dropdown/SimpleDropdown";
import Loader from "../../components/modal/Loader";
import Select from "react-select";
// import { toast } from "react-toastify";
import { ActionType } from "../../reduxs/action/actions";
// import SearchField from "../../components/SearchField";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalProvider";
import { IRecentPage } from "../../context/ContextInterface";
import useDropdownFarmer from "../../hooks/useDropdownFarmer";
import { getLogActivityFarmer } from "../../api/common-api";
import useDropdownLand from "../../hooks/useDropdownLand";
import {
	ILogActivities
} from "@api/logactivity-api";
import dayjs from "dayjs";
import TableMax from "../../components/tables/TableMax";
import ModalDetailObservation from "@components/modal/ModalDetailObservation";
import { SelectCustomStyles } from "./TaskManagement";
import { useDropdownPeriodPlantALL } from "@hooks/useDropdownPeriodPlant";

const TITLE_BAR = "Log Aktivitas FA";

type Props = {
	user?: any;
};

const LogActivityFarmerManagement: React.FC<Props> = ({ user }) => {
	const [isRefreshing, setIsRefreshing] = useState(false);
	// const [data, setData] = useState<any>(null);
	const [loader, showLoader] = useState(false);
	// const [isUpdate, setIsUpdate] = useState(false);
	// const [originData, setOriginData] = useState<ILogActivities[] | []>([]);
	// const [filterData, setFilterData] = useState<ILogActivities[] | []>([]);
	const [selectedData, setSelectedData] = useState<ILogActivities | null>(null,);
	const { dataFarmer, loadingFarmer } = useDropdownFarmer();
	const [selectedFarmer, setSelectedFarmer] = useState<any>(null);
	const [selectedLand, setSelectedLand] = useState<any>(null);
	const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
	const [selectedFilterFarmer, setSelectedFilterFarmer] = useState<any>(null);
	const { DataPeriodPlant, loadingPeriodPlant } = useDropdownPeriodPlantALL(isRefreshing)
	// const { dataPeriod, loadingPeriod } = useDropdownPeriod(selectedLand?.land_code_var || "",);
	const [DataObservation, setDataObservation] = useState<any[]>([]);
	const [activities, setActivities] = useState<any[]>([]);
	const [Filteredactivities, setFilterdActivities] = useState<any[]>([]);
	const [modalDetailObservation, showModalDetailObservation] = useState(false);
	// const [modalDetailObservation, showModalDetailObservation] = useState(false);
	const [selectedObservation, setselectedObservation] = useState<any[]>([]);
	const [monthIndex, setMonthIndex] = useState(dayjs().month());
	const [yearIndex, setYearIndex] = useState(dayjs().year());

	const months = [
		'January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December'
	];
	const years = Array.from({ length: 100 }, (_, i) => dayjs().year() - 50 + i); // A range of 100 years

	const handleMonthChange = (e) => {
		// console.log('type ',typeof(e.target.value));

		setMonthIndex(parseInt(e.target.value));
	};

	const handleYearChange = (e) => {
		setYearIndex(parseInt(e.target.value));
	};

	const dispatch = useDispatch();
	// const navigate = useNavigate();

	const { pathname } = useLocation();
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);
	const { dataLand, loadingLand } = useDropdownLand(selectedFilterFarmer?.id_seq || 0,);

	useEffect(() => {
		const payload: IRecentPage = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	// const fetchData = useCallback(async () => {
	// 	showLoader(true);

	// 	const res = await getAllListFA()

	// 	showLoader(false);
	// 	console.log("LOG ACTIVITIES ==> ", res);
	// 	if (res.data) {
	// 		if (res.data.code === 0) {
	// 			setOriginData(res.data.data);
	// 			setFilterData(res.data.data);
	// 		} else {
	// 			toast.error(res.data.message);
	// 		}
	// 	} else {
	// 		toast.error(res.status + " " + res.message || "Connection timeout");
	// 	}
	// }, []);

	// const handleClik = () => {
	// 	fetchActivities(monthIndex, yearIndex);
	// };

	const handleSelectedFarmer = (val) => {
		if (val != null) {
			setSelectedFilterFarmer(val)
			setFilterdActivities(activities.filter(activity => activity.fullname_var === val.fullname_var))
		} else {
			setFilterdActivities(activities)
		}
	};

	const handleSelectedLand = (val) => {
		if (val != null) {
			setFilterdActivities(activities.filter(activity => activity.land_code_var === val.land_code_var))
		} else {
			setFilterdActivities(activities)
		}
	};


	const fetchActivities = async (monthIndex, yearIndex) => {

		(async function fetchDataDashboard() {
			showLoader(true);
			const res = await getLogActivityFarmer(
				selectedFarmer?.id_seq || "ALL",
				selectedLand?.land_code_var || "ALL",
				selectedPeriod?.period_plant_txt || "ALL",
				monthIndex + 1,
				yearIndex
			);

			console.log("DASHBOARD ACTIVITIES ==> ", res);
			if (res.data && res.data.code === 0) {
				// console.log('activitas ', res.data.data[0].activity);
				// console.log('activity_with_observation ', res.data.data[0].activity_with_observation);
				// console.log('pengamatan ', res.data.data[0].observation);

				setActivities(res.data.data[0].activity_with_observation);
				// setActivities(res.data.data[0].activity);
				setFilterdActivities(res.data.data[0].activity_with_observation);
				setDataObservation(res.data.data[0].observation);

				showLoader(false);
			} else {
				showLoader(false);
				// toast.error(res.data?.message || res.status + ' ' + res.message || 'Connection timeout')
			}
		})();
	};

	useEffect(() => {
		fetchActivities(monthIndex, yearIndex);
		// fetchData();
	}, [dispatch, monthIndex, yearIndex]);

	const handleCloseModal = () => {
		setSelectedData(null);
		showModalDetailObservation(false);
	};

	const resetForm = () => {
		setSelectedData(null);
		// setIsUpdate(false);
		showLoader(false);
	};

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="pl-4 text-left">{row.index + 1}.</div>
			),
			width: 10,
		},
		{
			Header: "Aktivitas",
			accessor: "activity_txt",
			width: 300,
		},
		{
			Header: "Pendamping",
			accessor: "field_assistant_name_var",
			width: 125,
		},
		{
			Header: "Petani",
			accessor: "fullname_var",
			width: 150,
		},
		{
			Header: "Tanggal",
			accessor: "time_calender_dte",
			width: 90,
			Cell: ({ value }: any) => {
				return dayjs(value).format("DD/MM/YYYY");
			},
		},
		{
			Header: "Waktu",
			accessor: "time_txt",
			width: 60,
		},
		{
			Header: "Lahan",
			accessor: "land_code_var",
			width: 125,
			Cell: ({ value, row }: any) => {
				const data = row.original;
				return (
					<span>
						[{value}] {data.land_name_var}
					</span>
				);
			},
		},
		{
			Header: "Pengamatan",
			Footer: "Pengamatan",
			Cell: ({ row }: any) => {
				const data = row.original;

				const filterdataObservation = DataObservation.filter(subArray => {
					return subArray.time_calender_dte === data.time_calender_dte;
				});

				return filterdataObservation.length > 0 ? (
					<span className="px-2 py-1 font-medium text-green-800 bg-green-100 rounded-xl">
						Ada
					</span>
				) : (
					<span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
						Belum
					</span>
				);
			}
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }: any) => {
				const data = row.original;

				const filterdataObservation = DataObservation.filter(subArray => {
					return subArray.time_calender_dte === data.time_calender_dte;
				});
				return (
					<DropdownLand
						data={data}
						onActivity={() => {
							setSelectedData(data);
							showModalDetailObservation(true);
							setselectedObservation(filterdataObservation)
							// setIsUpdate(true);
						}} onEdit={undefined} onDelete={undefined}
					/>
				);
			},
		},
	];

	return (
		<>
			<div className="p-5">
				<div className="p-5 bg-white shadow-lg rounded-2xl">
					<div className="flex flex-col md:flex-row items-center justify-between pb-5 space-y-4 md:space-y-0">
						<h1 className="font-bold uppercase text-xl">Log Aktivitas</h1>
						<div className="calendar-header">
							<div className="flex items-center justify-center">
								<select
									value={monthIndex}
									onChange={handleMonthChange}
									className="text-gray-600 text-base cursor-pointer"
								>
									{months.map((monthName, index) => (
										<option key={index} value={index}>
											{monthName}
										</option>
									))}
								</select>
								<select
									value={yearIndex}
									onChange={handleYearChange}
									className="text-gray-600 text-base cursor-pointer ml-2"
								>
									{years.map((yearValue) => (
										<option key={yearValue} value={yearValue}>
											{yearValue}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-center justify-between w-full mb-5 space-y-3 md:w-max md:flex-row md:justify-center md:space-x-3 md:space-y-0 md:mb-4 2xl:mb-5">
						{/* <Select
							name="period"
							styles={SelectCustomStyles}
							options={DataPeriodPlant}
							onChange={setSelectedPeriod}
							isClearable
							placeholder="- Pilih Periode -"
							className="w-full md:w-[175px] 2xl:md:w-[200px]"
							isLoading={loadingPeriodPlant}
							// isDisabled
							getOptionValue={(option: any) => option["period_plant_txt"]}
							getOptionLabel={(option: any) => option["period_plant_txt"]}
						/> */}
						<Select
							name="farmer"
							styles={SelectCustomStyles}
							options={dataFarmer}
							onChange={handleSelectedFarmer}
							isClearable
							placeholder="- Pilih Petani -"
							className="w-full md:w-[200px]"
							isLoading={loadingFarmer}
							getOptionValue={(option: any) => option["id_seq"]}
							getOptionLabel={(option: any) => option["fullname_var"]}
						/>
						<Select
							name="land"
							styles={SelectCustomStyles}
							options={dataLand}
							onChange={handleSelectedLand}
							isClearable
							placeholder="- Pilih Lahan -"
							className="w-full md:w-[200px] 2xl:md:w-[300px]"
							isLoading={loadingLand}
							getOptionValue={(option: any) => option["land_code_var"]}
							getOptionLabel={(option: any) =>
								`[${option["land_code_var"]}] ${option["land_name_var"]}`
							}
						/>
						{/* <button
							type="submit"
							className="w-full px-6 py-3 text-sm text-white bg-blue-600 rounded-lg md:w-max hover:bg-blue-500"
							onClick={handleClik}>
							Terapkan
						</button> */}
					</div>

					<TableMax dataTable={Filteredactivities} columnTable={columns} />
				</div>
			</div>
			{modalDetailObservation && (
				<ModalDetailObservation
					data={selectedData!}
					onCancel={resetForm}
					onClose={handleCloseModal}
					dataObservation={selectedObservation!}
				/>
			)}

			{loader && <Loader />}
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps, null)(LogActivityFarmerManagement);
