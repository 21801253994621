import { useEffect, useState } from "react";
import {
  dropdownCommodityName,
  dropdownPlantMethodByCommodity,
  dropdownTemplateTask,
  ICommodity,
} from "../api/commodity-api";

export default function useDropdownCommodity(isRefreshing = false) {
  const [dataCommodity, setDataCommodity] = useState<ICommodity[]>([]);
  const [loadingCommodity, setLoading] = useState(false);
  const [errorCommodity, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownCommodity() {
      setLoading(true);
      let res = await dropdownCommodityName();

      console.log("DD COMMODITY :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataCommodity(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownCommodity();
  }, [isRefreshing]);

  return { dataCommodity, errorCommodity, loadingCommodity };
}

export function useDropdownPlantingMethod(commodity_name_var: string) {
  const [dataPlanting, setDataPlantingMethod] = useState<any[]>([]);
  const [loadingPlanting, setLoading] = useState(false);
  const [errorPlanting, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownCommodity() {
      let res = await dropdownPlantMethodByCommodity(commodity_name_var);

      console.log("DD PLANTING METHOD :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setLoading(false);
          setDataPlantingMethod(res.data.data);
        } else {
          setLoading(false);
          setError(res.data.message);
        }
      } else {
        setLoading(false);
        setError(res.message);
      }
    }

    setLoading(true);
    fetchDropdownCommodity();
  }, [commodity_name_var]);

  return { dataPlanting, errorPlanting, loadingPlanting };
}

export function useDropdownTemplateTask(
  commodity_name_var: string,
  planting_method_int: number,
) {
  const [dataTemplate, setData] = useState<any[]>([]);
  const [loadingTemplate, setLoading] = useState(false);
  const [errorTemplate, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownCommodity() {
      let res = await dropdownTemplateTask(
        commodity_name_var,
        planting_method_int,
      );

      console.log("DD TEMPLATE TASK :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setLoading(false);
          setData(res.data.data);
        } else {
          setLoading(false);
          setError(res.data.message);
        }
      } else {
        setLoading(false);
        setError(res.message);
      }
    }

    setLoading(true);
    fetchDropdownCommodity();
  }, [commodity_name_var, planting_method_int]);

  return { dataTemplate, errorTemplate, loadingTemplate };
}
