import { LazyLoadImage } from "react-lazy-load-image-component"
import { IBudgetDetail } from "../../../api/budgetplan-api"
import Helper from "../../../helpers/helper"

type Props = {
    data: IBudgetDetail,
    onCancel: (e: React.MouseEvent) => any
}

const BudgetDetailApprovalModal: React.FC<Props> = ({ data, onCancel }) => {
    const getStatusStyle = () => {
        switch (data.status_int) {
            case -5:
                return 'bg-red-50 text-red-500'
            case 400:
                return 'bg-red-50 text-red-500'
            case 100:
                return 'bg-slate-100 text-slate-500'
            case 150:
                return 'bg-blue-50 text-blue-500'
            default:
                return 'bg-green-50 text-green-500'
        }
    }

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 md:px-8 rounded-t border-b border-slate-300">
                        <div className="text-base md:text-xl font-semibold flex item-center">
                            <h3>Detail Budget Plan</h3>
                        </div>
                        <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <div className={`${getStatusStyle()} font-medium px-2 md:px-8 py-2 md:py-4 text-xs md:text-sms flex items-center justify-between`}>
                        <p>Status Approval</p>
                        <p className="italic font-bold capitalize">{data.status_name_var}</p>
                    </div>
                    <div className="p-5 md:p-8 space-y-6">
                        <div>
                            <div className="flex items-center justify-between">
                                <h2 className='text-base text-lightcayn font-bold'>Informasi Budget Plan</h2>
                            </div>
                            <table className="font-medium text-sms md:text-sm mt-2.5">
                                <tbody>
                                    <tr>
                                        <td className='text-gray-500 pb-1 w-[10rem] md:w-52'>Kegiatan</td>
                                        <td>{data?.activity_txt}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Kategori</td>
                                        <td>{data?.category_var}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Luas</td>
                                        <td>{data.area_var}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Jumlah</td>
                                        <td>{data.quantity_var}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Satuan</td>
                                        <td>{data.satuan_var}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Jumlah</td>
                                        <td>Rp{Helper.amount(data.price_var)}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Total Harga</td>
                                        <td>Rp{Helper.amount(data.total_price_var)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='mt-6'>
                            <h2 className='text-base text-lightcayn font-bold'>Dokumentasi</h2>
                            <div className='grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-4 2xl:gap-5 mt-4'>
                                {data.document_txt && data.document_txt?.split(', ')?.map((url: string, key: number) =>
                                    <LazyLoadImage effect='blur' alt='' src={url} className='rounded-lg shadow-md h-48 object-cover' key={key} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BudgetDetailApprovalModal