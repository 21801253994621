import React, { useContext } from 'react';
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"

import TableUser from "../../../components/tables/Table"
import { ButtonAdd } from "../../../components/button/CustomButton"

import Loader from "../../../components/modal/Loader";

import SearchField from '../../../components/SearchField';
import { ActionType } from '../../../reduxs/action/actions';
import dayjs from 'dayjs';
import ModalFormTaskPreference from '../../../components/modal/operatingparameter/taskreference/ModalFormTaskPreference';
import Dropdown from '../../../components/dropdown/Dropdown';
import { ITaskReference, TaskReferenceApi } from '../../../api/taskreference-api';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalProvider';
import { IRecentPage } from '../../../context/ContextInterface';
import { getPayloadRecentActivity } from '../../../helpers/helper';


const TITLE_BAR = 'Operating Parameter / Task Reference'

type Props = {};

const TaskReference: React.FC<Props> = () => {
	const [loader, showLoader] = useState(false)
	const [modalForm, showModalForm] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false)
	const [originData, setOriginData] = useState<ITaskReference[] | []>([])
	const [filterData, setFilterData] = useState<ITaskReference[] | []>([])
	const [selectedData, setSelectedData] = useState<ITaskReference | null>(null)

	const dispatch = useDispatch()
	const user = useSelector((state: any) => state['user'])
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext)

	useEffect(() => {
		const payload: IRecentPage = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: 'push', payload })
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR })
	}, [dispatch, dispatchCalPage, pathname])


	const fetchData = useCallback(async () => {
		showLoader(true)
		const res = await TaskReferenceApi.getAll()

		showLoader(false)
		console.log("TASK REFERENCE ==>", res)
		if (res.data) {
			if (res.data.code === 0) {
				setOriginData(res.data.data)
				setFilterData(res.data.data)
			} else {
				toast.error(res.data.message)
			}
		} else {
			toast.error(res.status + ' ' + res.message || 'Connection timeout')
		}
	}, [])

	useEffect(() => {
		fetchData()
	}, [fetchData, dispatch])


	const handleReceiveDataForm = async (data: FormData) => {
		showLoader(true)

		let res = null
		if (!isUpdate) {
			data.append('created_by_var', user.fullname_var)
			res = await TaskReferenceApi.createNew(data)

			var payload = getPayloadRecentActivity(1, 'Task Reference', '', user?.fullname_var, pathname)
		} else {
			data.append('updated_by_var', user.fullname_var)
			res = await TaskReferenceApi.update(selectedData?.id_seq!, data)

			payload = getPayloadRecentActivity(2, 'Task Reference', '', user?.fullname_var, pathname)
		}

		console.log('TASK REFERENCE ACTION ==>', res)

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message)

				dispatchCalActivity({ type: 'push', payload })

				fetchData()
				resetForm()
			} else {
				toast.error(res.data.message)
				showLoader(false)
			}
		} else {
			toast.error(res.status + ' ' + res.message || 'Connection timeout')
		}
	}

	const handleDeleteData = async (data: any) => {
		showLoader(true)
		const res = await TaskReferenceApi.delete(data.id_seq!)

		console.log("TASK REFERENCE ACTION ==>", res)
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message)

				const payload = getPayloadRecentActivity(3, 'Task Reference', data.id_seq, user?.fullname_var, pathname)
				dispatchCalActivity({ type: 'push', payload })

				fetchData()
				resetForm()
			} else {
				toast.error(res.data.message)
				showLoader(false)
			}
		} else {
			toast.error(res.status + ' ' + res.message || 'Connection timeout')
			showLoader(false)
		}
	}

	const resetForm = () => {
		setSelectedData(null)
		setIsUpdate(false)
		showModalForm(false)
		showLoader(false)
	}

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()

		const newData = [...originData]
		if (event.target.value) {
			const filtered = newData.filter(item => {
				return (
					item.file_name_txt?.toLowerCase().includes(event.target.value.toLowerCase()) ||
					item.commodity_name_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
					item.name_planting_var?.toString().toLowerCase().includes(event.target.value.toLowerCase())
				)
			});

			setFilterData(filtered)
		} else {
			setFilterData(originData)
		}
	}

	const columns = [
		{
			Header: () => <span className='p-4'>No</span>,
			accessor: 'no',
			Cell: ({ row }: any) => <div className='text-left pl-4'>{row.index + 1}.</div>,
		},
		{
			Header: 'File',
			accessor: 'file_name_txt',
			Cell: ({ value }: any) => {
				return <span className='flex items-center'>
					<i className="fa-solid fa-file-lines text-blue-500 text-base mr-2"></i>
					<p>{value}</p>
				</span>
			}
		},
		{
			Header: 'Komoditas',
			accessor: 'commodity_name_var'
		},
		{
			Header: 'Metode',
			accessor: 'name_planting_var'
		},
		{
			Header: 'Last Modified',
			accessor: 'updated_on_dtm',
			Cell: ({ value, row }: any) => {
				const data = row.original
				return dayjs(value || data.created_on_dtm).format('DD MMMM YYYY')
			}
		},
		{
			Header: 'File Size',
			accessor: 'file_size_int'
		},
		{
			Header: 'Action',
			Footer: 'Action',
			Cell: ({ row }: any) => {
				const data = row.original
				return <Dropdown onDetail={() => navigate(`/dashboard/operating-parameter-taskreference/${data.file_name_txt}`)} onDelete={() => handleDeleteData(data)} />
			}
		}
	]

	return (
		<div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>
			<div className='w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6'>
				<div className='mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0'>
					<h1 className='font-bold uppercase text-xl'>List Data</h1>
					<div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0'>
						<SearchField onChange={handleSearch} placeholder='Cari file...' />
						{user?.user_group_id_int === 1 || user?.user_group_id_int === 2 ? (
							<ButtonAdd onClick={() => showModalForm(true)} />
						) : null}
					</div>
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{modalForm && <ModalFormTaskPreference data={selectedData!} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
			{loader && <Loader />}
		</div>
	)
}


export default TaskReference