import { useCallback, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";

import {
	createNewSaprodiDistribution,
	deleteSaprodiDistribution,
	updateSaprodiDistribution,
	getAllSaprodiDistribution
} from "@api/saprodidistribution-api";

import TableUser from "../../../components/tables/Table";
import { ButtonAdd } from "../../../components/button/CustomButton";
import { DropdownLand } from "../../../components/dropdown/SimpleDropdown";
import Loader from "../../../components/modal/Loader";
import { toast } from "react-toastify";
import SearchField from "../../../components/SearchField";
import { ActionType } from "../../../reduxs/action/actions";
import { GlobalContext } from "../../../context/GlobalProvider";
import Helper, { getPayloadRecentActivity } from "../../../helpers/helper";
import ModalFormSaprodiDistribution from "@components/modal/operatingparameter/ModalFormSaprodiDistribution";

const TITLE_BAR = "Operating Parameter / Saprodi Distribution";

const SaprodiDistribution = ({ user }) => {
	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [dataLandPrice, setDataLandPrice] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [isUpdate, setIsUpdate] = useState(false);
	const [selectedData, setSelectedData] = useState(null);
	const [modalDetail, showModalDetail] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

	useEffect(() => {
		const payload = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	const fetchData = useCallback(async () => {
		showLoader(true);

		const res = await getAllSaprodiDistribution();

		console.log("Get Land Price :", res);
		resetForm();
		if (res.data) {
			if (res.data.code === 0) {
				setDataLandPrice(res.data.data);
				setFilterData(res.data.data);
			} else if (res.data.code === 99) {
				showLoader(true);
				navigate("/auth", { replace: true });
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(`${res.config?.url} ${res.message}`);
		}
	}, [navigate]);

	useEffect(() => {
		fetchData();
	}, [fetchData, dispatch]);

	const handleEdit = (selectedData) => {
		setSelectedData(selectedData);
		setIsUpdate(true);
		showModalForm(true);
	};

	const handleDetail = (selectedData: any) => {
		let path = `/dashboard/operating-parameter-saprodi-distribution/${selectedData.id_seq}`;
		navigate(path);
	};

	const handleReceiveDataForm = async (payload) => {
		showLoader(true);

		let res = null;
		if (!isUpdate) {
			payload.created_by_var = user.fullname_var;
			res = await createNewSaprodiDistribution(payload);

			var payloadRecentActivity = getPayloadRecentActivity(
				1,
				"Land Price",
				payload.id_seq,
				user?.fullname_var,
				pathname
			);
		} else {
			payload.updated_by_var = user.fullname_var;
			res = await updateSaprodiDistribution(selectedData.id_seq, payload);

			payloadRecentActivity = getPayloadRecentActivity(
				2,
				"Land Price",
				payload.id_seq,
				user?.fullname_var,
				pathname
			);
		}

		console.log("Create/Update Land Price :", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				dispatchCalActivity({ type: "push", payload: payloadRecentActivity });

				fetchData();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(`${res.config?.url} ${res.message}`);
		}
	};

	const handleDeleteData = async (id_seq) => {
		showLoader(true);

		const res = await deleteSaprodiDistribution(id_seq);

		console.log("Delete Material :", res);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				const payload = getPayloadRecentActivity(
					3,
					"Land Price",
					id_seq,
					user?.fullname_var,
					pathname
				);
				dispatchCalActivity({ type: "push", payload });

				fetchData();
			} else if (res.data.code === 99) {
				navigate("/auth", { replace: true });
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(`${res.config?.url} ${res.message}`);
		}
	};

	const resetForm = () => {
		showLoader(false);
		setIsUpdate(false);
		showModalForm(false);
		setSelectedData(null);
		showModalDetail(false);
	};

	const handleSearch = (event) => {
		event.preventDefault();

		const newData = [...dataLandPrice];
		if (event.target.value) {
			const filtered = newData.filter((item) => {
				return (
					item.commodity_name_var
						.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.description_txt
						.toLowerCase()
						.includes(event.target.value.toLowerCase())
				);
			});

			setFilterData(filtered);
		} else {
			setFilterData(dataLandPrice);
		}
	};

	const columns = [
		{
			Header: () => <span className="py-4 pl-2">No</span>,
			accessor: "no",
			Cell: ({ row }) => <div className="pl-2 text-left">{row.index + 1}.</div>,
		},
		{
			Header: () => <div className="pl-2 text-center">Periode Tanam</div>,
			accessor: "period_plant_txt",
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<div className="pl-2 text-center">
						{value}
					</div>
				);
			},
		},
		{
			Header: () => <div className="pl-2 text-center">Komoditas</div>,
			accessor: "commodity_name_var",
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<div className="pl-2 text-center">
						{value}
					</div>
				);
			},
		},
		{
			Header: () => <div className="pl-2 text-center">Luas Lahan</div>,
			accessor: "land_area_var",
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<div className="pl-2 text-center">
						{value} Ha
					</div>
				);
			},
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }) => {
				const data = row.original;
				return (
					<DropdownLand
						data={data}
						onEdit={() => handleEdit(data)}
						onDelete={handleDeleteData}
						onDetail={() => handleDetail(data)}
					/>
				)
			},
		},
	];

	return (
		<div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
			<div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
				<div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
					<h1 className="text-xl font-bold uppercase">
						pembagian saprodi
					</h1>
					<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
						<SearchField
							onChange={handleSearch}
							placeholder="Cari data material..."
						/>
						{user?.user_group_id_int === 1 || user?.user_group_id_int === 2 ? (
							<ButtonAdd onClick={() => showModalForm(true)} />
						) : null}
					</div>
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{modalForm && (
				<ModalFormSaprodiDistribution
					data={selectedData}
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
				/>
			)}
			{loader && <Loader />}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps, null)(SaprodiDistribution);
