import React from "react"

interface ICustomButton {
    type?: "button" | "reset" | "submit" | undefined,
    title?: string,
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ButtonAdd: React.FC<ICustomButton> = ({onClick, title}) => {
    return (
        <button onClick={onClick} className='flex items-center font-medium rounded-full px-3 py-2.5 bg-lightcayn text-white hover:opacity-80'>
            <span className='text-lightcayn bg-white hover:bg-darkcayn hover:text-white rounded-full p-1 w-5 h-5 flex items-center justify-center cursor-pointer text-base'>
                <i className="fa-solid fa-plus"></i>
            </span>
            <p className='ml-2'>{title || 'Tambah Data'}</p>
        </button>
    )
}

export const CancelButton: React.FC<ICustomButton> = ({type, onClick, title}) => (
    <button type={type || 'reset'} onClick={onClick} className='bg-gray-500 hover:bg-black text-white px-6 py-3 rounded-full'>
        {title || 'Batal'}
    </button>
)

export const SaveButton: React.FC<ICustomButton> = ({type, onClick, title}) => (
    <button type={type || 'submit'} onClick={onClick} className='bg-lightcayn hover:bg-darkcayn text-white px-4.5 py-3 rounded-full ml-2 sm:ml-3.5'>
        {title || 'Simpan'}
    </button>
)