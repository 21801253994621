import axios from "axios";
import { baseURL } from "./common-api";

export interface ICultivatioLandProcess {
	variety_id?: any;
	file_template_txt?: any;
	id_seq?: number;
	financial_scheme_id_int?: number;
	id_cultivation_int?: number;
	observation_value?: any;
	user_id_int?: number;
	harvest_prediction_int?: number;
	planting_method_int?: number;
	planting_phase_int?: number;
	land_code_var?: string;
	commodity_name_var?: string;
	date_performed_dtm?: string;
	activity_txt?: string;
	document_txt?: any;
	images?: any;
	example_observation_txt?: string;
	created_by_var?: string;
	created_on_dtm?: string;
	updated_by_var?: string;
	updated_on_dtm?: string;
	fullname_var?: string;
	period_plant_txt?: string;
	material_txt?: string;
	dose_txt?: string;
	jumlah_txt?: string;
	satuan_txt?: string;
	source_funding_var?: string;
	land_name_var?: string;
	variety_name?: string;
	start_planting_dtm?: string;
}

export const createNewCultivation = async (payload: FormData) => {
	try {
		const data = await axios.post(baseURL + "/api/cultivation-land-process/create", payload, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
			},
		});
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const updateCultivationLandProcess = async (idSeq: number, payload: FormData) => {
	try {
		const data = await axios.patch(
			baseURL + "/api/cultivation-land-process/update/" + idSeq,
			payload,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			},
		);
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllCultivation = async () => {
	try {
		const data = await axios.get(baseURL + "/api/cultivation/get-all", {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const deleteActivity = async (id: number) => {
    try {
      const data = await axios.delete(
        baseURL + "/api/cultivation-land-process/delete/" + id,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };


export const getByQueryCulltivation = async (query) => {
	try {
		console.log('query ',query);
		
		const data = await axios.get(baseURL + "/api/cultivation-land-process/get?"+query, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllCultivationbyLandProcess = async () => {
	try {
		const data = await axios.get(baseURL + "/api/cultivation-land-process/get-join-cultivation", {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};
//#endregion
