import React, { useContext } from 'react';
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { connect, useDispatch } from "react-redux"

import TableUser from "../../../components/tables/Table"

import Loader from "../../../components/modal/Loader";

import SearchField from '../../../components/SearchField';
import { ActionType } from '../../../reduxs/action/actions';
import { IMonitoringProduct, MonitoringProductApi } from '../../../api/inventory-api';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalProvider';
import { IRecentPage } from '../../../context/ContextInterface';


const TITLE_BAR = 'Inventory / Monitoring Stock'

type Props = { };

const InventoryMonitoringStock: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const [originData, setOriginData] = useState<IMonitoringProduct[]|[]>([])
    const [filterData, setFilterData] = useState<IMonitoringProduct[]|[]>([])

    const dispatch = useDispatch()
    const { pathname } = useLocation()
  const { dispatchCalPage } = useContext(GlobalContext)

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: 'push', payload })
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR })
  }, [dispatch, dispatchCalPage, pathname])

    
    const fetchData = useCallback( async () => {
        showLoader(true)
        const res = await MonitoringProductApi.getAll()

        showLoader(false)
        console.log("MONITORING PRODUCT ==>", res)
        if(res.data){
            if(res.data.code === 0){
                setOriginData(res.data.data)
                setFilterData(res.data.data)
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData, dispatch])


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        const newData = [...originData]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.id_product_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.product_name_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.product_name_type_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.measure_var?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(originData)
        }
    }

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            accessor: 'no',
            Cell: ({ row }: any) =>  <div className='text-left pl-4'>{row.index + 1}.</div>,
        },
        {
            Header: 'ID Produk',
            accessor: 'id_product_var'
        },
        {
            Header: 'Nama Produk',
            accessor: 'product_name_var'
        },
        {
            Header: 'Jenis Produk',
            accessor: 'product_name_type_var'
        },
        {
            Header: 'Stok Produk',
            accessor: 'quantity_int'
        },
        {
            Header: 'Satuan',
            accessor: 'measure_var'
        },
    ]

    return (
        <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>
            <div className='w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6'>
                <div className='mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0'>
                    <h1 className='font-bold uppercase text-xl'>Monitoring Stok</h1>
                    <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0'>
                        <SearchField onChange={handleSearch} placeholder='Cari produk...' />
                    </div>
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, null) (InventoryMonitoringStock)