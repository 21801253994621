import axios from "axios";
import { baseURL } from "./common-api";

export interface IParameterPascaPanen {
  id_seq?: number;
  commodity_id: string;
  variety_id: string;
  activity: string;
  variety_name?: string;
  commodity_name_var?: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
  pasca_panen_details?: any;
}

export const getAll = async () => {
  try {
    const data = await axios.get(
      baseURL + "/api/cultivation-management/parameter-pasca-panen/get-all",
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNew = async (payload: IParameterPascaPanen) => {
  try {
    const data = await axios.post(
      baseURL + "/api/cultivation-management/parameter-pasca-panen/create",
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const update = async (
  productTypeId: number,
  payload: IParameterPascaPanen,
) => {
  try {
    const data = await axios.patch(
      baseURL +
        "/api/cultivation-management/parameter-pasca-panen/update/" +
        productTypeId,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteData = async (productTypeId: number) => {
  try {
    const data = await axios.delete(
      baseURL +
        "/api/cultivation-management/parameter-pasca-panen/delete/" +
        productTypeId,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const getParams = async () => {
  try {
    const data = await axios.get(
      baseURL + "/api/cultivation-management/parameter-pasca-panen/get-params",
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
export const getParamValues = async () => {
  try {
    const data = await axios.get(
      baseURL +
        "/api/cultivation-management/parameter-pasca-panen/get-paramvalues",
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
