import { useEffect, useState } from "react";
import { dropdownForm } from "@api/parameterpengamatanhasilpanen-api";

export function useDropdownParameterHasilPanen(
	commodity_id: number,

) {
	const [dataParams, setDataParams] = useState<any>([]);
	const [loadingParams, setLoading] = useState(false);
	const [errorParams, setError] = useState(null);

	useEffect(() => {
		async function fetchDropdownParams() {
			let res = await dropdownForm(
				commodity_id
			);

			console.log("DD parameter Hasil Panen :", res);
			if (res.data) {
				if (res.data.code === 0) {
					setLoading(false);
					setDataParams(res.data.data);
				} else {
					setLoading(false);
					setError(res.data.message);
				}
			} else {
				setLoading(false);
				setError(res.message);
			}
			// setLoading(false);
		}
		setLoading(true);
		fetchDropdownParams();
	}, [commodity_id]);

	return { dataParams, errorParams, loadingParams };
}
