import React, { useState } from 'react';

type Props = {
    title: string,
    children?: JSX.Element | string,
    defaultOpen?: boolean
};

const Accordion: React.FC<Props> = ({ title, children, defaultOpen }) => {
    const [isActive, setIsActive] = useState(defaultOpen || false);
  
    return (
      <div className="w-full flex flex-col">
        <div className="cursor-pointer flex justify-between items-center px-5 py-4 font-medium rounded-lg rounded-b-none border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 hover:bg-gray-100 bg-gray-100 text-gray-900" onClick={() => setIsActive(!isActive)}>
            <div className='flex items-center space-x-2.5 italic text-sm md:text-base'>
                <h3>{title}</h3>
            </div>
          <div className='text-xl transition transform ease-in-out duration-300'>{isActive ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}</div>
        </div>
        {isActive && 
            <div className="p-5 rounded-b-lg border border-t-0 border-gray-200">
                {children}
            </div>}
      </div>
    );
}

export default Accordion;