import { useCallback, useEffect, useState } from "react";

import L from "leaflet";
import {
  FeatureGroup,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

import logo from "../../../assets/img/map-pandawa.png";
import { SaveButton } from "../../button/CustomButton";

export const icon = new L.Icon({
  iconUrl: logo,
  iconRetinaUrl: logo,
  iconSize: new L.Point(45, 55),
});

const ModalDrawMaps = ({ onCancel, onSubmit, data }) => {
  const [center, setCenter] = useState([-6.175372, 106.827194]);
  const [existKoor, setExistKoor] = useState([]);
  const [mapLayers, setMapLayers] = useState(null);
  const [onDraw, setOnDraw] = useState(false);
  const [map, setMap] = useState(null);

  const ZOOM_LEVEL = 17;

  const getCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (res) => {
          setTimeout(() => {
            console.log("Current Location :", res);
            const { latitude, longitude } = res.coords;
            setCenter([latitude, longitude]);
            map?.flyTo([latitude, longitude], ZOOM_LEVEL, { animate: true });
          }, 500);
        },
        (err) => {
          console.log(err);
          alert("Error get your location ", err.message);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, [map]);

  useEffect(() => {
    if (data) {
      const latLng = data.split(";").map((koor) => {
        return { lat: koor.split(",")[0], lng: koor.split(",")[1] };
      });
      setExistKoor(latLng);
      const centerMap = L.latLngBounds(latLng).getCenter();
      setCenter(centerMap);
      setTimeout(() => {
        map?.flyTo([centerMap.lat, centerMap.lng], 17, { animate: true });
      }, 1000);
    } else {
      getCurrentLocation();
    }
  }, [data, map, getCurrentLocation]);

  const _ondDrawStart = (e) => {
    setOnDraw(true);
  };

  const _onCreate = (e) => {
    // console.log(e);

    const { layerType, layer } = e;
    if (layerType === "polygon") {
      setMapLayers({ id: layer._leaflet_id, latlngs: layer.getLatLngs()[0] });
    }
  };

  const _onEdited = (e) => {
    // console.log(e);
    const { layers } = e;

    Object.values(layers._layers).forEach(({ _leaflet_id, _latlngs }) => {
      console.log(_leaflet_id, _latlngs[0]);
      setMapLayers({ id: _leaflet_id, latlngs: _latlngs[0] });
    });
  };

  const _onDeleted = (e) => {
    // console.log(e);

    setMapLayers(null);
    setOnDraw(false);
  };

  const handleSubmit = () => {
    const latLang = mapLayers.latlngs
      .map(({ lat, lng }) => lat + "," + lng)
      .join(";");
    const areaLength = (
      L.GeometryUtil.geodesicArea(mapLayers?.latlngs) / 10000
    ).toFixed(2);
    onSubmit(latLang, areaLength);
  };

  return (
    <>
      <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50 overflow-auto">
        <div
          className="absolute w-full h-full bg-gray-900 opacity-50"
          onClick={onCancel}
        ></div>

        {/* Modal Content */}
        <div className="bg-soft w-11/12 md:w-3/4 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50">
          <div className="mb-5 text-base font-medium w-full flex justify-between items-center">
            <h1>Gambar lahan pada maps </h1>
            <button className="font-bold" onClick={onCancel}>
              Tutup (x)
            </button>
          </div>
          {/* {center &&  */}
          <MapContainer
            center={center}
            zoom={data ? 15 : ZOOM_LEVEL}
            ref={setMap}
            style={{ width: "100%", height: "75vh" }}
          >
            <FeatureGroup>
              <EditControl
                position="bottomright"
                onDrawStart={_ondDrawStart}
                onCreated={_onCreate}
                onEdited={_onEdited}
                onDeleted={_onDeleted}
                draw={{
                  rectangle: false,
                  polyline: false,
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  polygon: {
                    allowIntersection: true,
                    shapeOptions: { color: "lime" },
                    drawError: {
                      color: "#e1e100", // Color the shape will turn when intersects
                      message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
                    },
                    edit: false,
                    showLength: true,
                    metric: true,
                    feet: true,
                    showArea: true,
                  },
                }}
              />
            </FeatureGroup>

            {mapLayers && (
              <Marker
                position={L.latLngBounds(mapLayers.latlngs).getCenter()}
                icon={icon}
              >
                <Popup>
                  A pretty CSS3 popup
                  <br />
                  Easily customizable.
                </Popup>
              </Marker>
            )}

            {data && !onDraw && !mapLayers && (
              <Marker position={center} icon={icon}>
                <Popup>
                  Current position
                  <br />({center?.[0]},{center?.[1]})<br />
                  Luas Area :{" "}
                  {(L.GeometryUtil.geodesicArea(existKoor) / 10000).toFixed(
                    2
                  )}{" "}
                  Ha
                </Popup>
              </Marker>
            )}

            {data && !onDraw && !mapLayers && (
              <Polygon
                pathOptions={{ color: "yellow", fillColor: "lime" }}
                positions={existKoor}
              />
            )}

            {!data && !mapLayers && (
              <Marker position={center} icon={icon}>
                <Popup>
                  Current position
                  <br />({center?.[0]},{center?.[1]})
                </Popup>
              </Marker>
            )}

            <TileLayer
              maxZoom={25}
              id=""
              tileSize={512}
              zoomOffset={-1}
              attribution="by Team"
              // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            //   url="https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGFycnl3ZWJzcyIsImEiOiJja2xrYWJ2ZXkzNmt3Mm90a3o1Y25vZmJlIn0.LDlgeP6gjiBvGWbmkG5sFA"
			  url="https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmFuZ2dpcmV4YSIsImEiOiJjbHVsbXl4NGMwb2N0MmxsdzVhZmc2dmVlIn0.-siySpAxYSfOAW-pBX0Xpw"
			/>
          </MapContainer>
          {/* } */}
          {mapLayers && (
            <div className="flex items-center justify-between mt-3 -mb-3 font-medium">
              <div className="flex flex-col md:flex-row">
                <p className="mr-1">Luas Lahan :</p>
                <p>
                  {L.GeometryUtil.geodesicArea(mapLayers?.latlngs).toFixed(2)} (
                  {(
                    L.GeometryUtil.geodesicArea(mapLayers?.latlngs) / 10000
                  ).toFixed(2)}{" "}
                  Ha)
                </p>
              </div>
              <SaveButton onClick={handleSubmit} />
            </div>
          )}
        </div>
        {/* End Modal Content */}
      </div>
    </>
  );
};

export default ModalDrawMaps;
