import { ActionType } from "../action/actions";
import initialState from "./initialState";

const rootReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ActionType.SET_USER_DATA:
      const user = { ...state.user };
      for (const key in action.payload) {
        user[key] = action.payload[key];
      }
      return {
        ...state,
        user: user,
      };
    case ActionType.UPDATE_USER_DATA:
      const userUpdate = { ...state.user };
      userUpdate[action.key] = action.value;
      return {
        ...state,
        user: user,
      };
    case ActionType.SET_MENU_LIST:
      return {
        ...state,
        menu_list: action.data,
      };
    case ActionType.SET_USER_GROUP_LIST:
      return {
        ...state,
        user_group_list: action.data,
      };
    case ActionType.SET_COLLAPSE:
      return {
        ...state,
        collapse: action.value,
      };
    case ActionType.SET_TITLE_BAR:
      return {
        ...state,
        title_bar: action.value,
      };
    default:
      return state;
  }
};

export default rootReducer;
