import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;

export const QRCODE_URL = process.env.REACT_APP_QRCODE_URL;

const api = axios.create({
	baseURL: baseURL,
	headers: {
		authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});

export const authLogin = async (payload) => {
	try {
		const data = await api.post("/api/login", payload);
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const authRegister = async (payload) => {
	try {
		const data = await api.post("/api/register", payload);
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const authValidateOTP = async (payload) => {
	try {
		const data = await api.post("/api/otp-validate", payload);
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getSidebarMenu = async (token, roleID) => {
	try {
		const payload = {
			user_group_id_int: roleID,
		};

		const data = await axios.post(baseURL + "/api/login/menu", payload, {
			headers: {
				authorization: `Bearer ${token}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllMenu = async (token) => {
	try {

		const data = await axios.get(baseURL + "/api/login/all-menu", {
			headers: {
				authorization: `Bearer ${token}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllMenuByGroup = async (token, userGroupId) => {
	try {

		const payload = {
			user_group_id_int: userGroupId,
		};

		const data = await axios.post(baseURL + "/api/login/all-menu-bygroup", payload, {
			headers: {
				authorization: `Bearer ${token}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const editBulkMenuByGroup = async (token, payload) => {
	try {

		const data = await axios.post(baseURL + "/api/login/update-menu", payload, {
			headers: {
				authorization: `Bearer ${token}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getDashboard = async (commodity_id, project_id, period_plant_txt) => {
	// console.log('params ',commodity_id, project_id, period_plant_txt);
	try {
		const data = await axios.get(baseURL + `/api/dashboard/get-all?commodity_id=${commodity_id}&project_id=${project_id}&period_plant_txt=${period_plant_txt}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getActvitiesDashboard = async (userId, landCode, periodPlant) => {
	try {
		const data = await axios.get(
			baseURL +
			`/api/dashboard/get-activity?user_id_int=${userId}&land_code_var=${landCode}&period_plant_txt=${periodPlant}`,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		);
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getLogActivityFarmer = async (userId, landCode, periodPlant, monthIndex, yearIndex) => {
	try {
		const data = await axios.get(
			baseURL +
			`/api/dashboard/get-log-activity-farmer?user_id_int=${userId}&land_code_var=${landCode}&period_plant_txt=${periodPlant}&month_Index_txt=${monthIndex}&year_Index_txt=${yearIndex}`,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		);
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getProvince = async () => {
	try {
		const { data } = await axios.get(baseURL + "/api/location/prov-all");
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getCities = async (provID) => {
	try {
		const { data } = await axios.get(
			baseURL + "/api/location/city-prov?prov_id" + provID
		);
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllCities = async () => {
	try {
		const { data } = await axios.get(baseURL + "/api/location/city-all");
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getDistricts = async (cistyID) => {
	try {
		const { data } = await axios.get(
			baseURL + "/api/location/dis-city?city_id" + cistyID
		);
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllDistricts = async () => {
	try {
		const { data } = await axios.get(baseURL + "/api/location/dis-all");
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllLocation = async () => {
	try {
		const { data } = await axios.get(baseURL + "/api/location/all");
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export default api;
