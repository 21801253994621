import { useEffect, useState } from "react"
import { CancelButton, SaveButton } from "../../button/CustomButton"

const ModalFormKordinat = ({data, onCancel, onSubmit}) => {
    const [arrKoor, setArrKoor] = useState([
        {lat: 'lat_', long: 'long_', value_lat: '', value_long: '', key: 1 + new Date().getTime()},
        {lat: 'lat_', long: 'long_', value_lat: '', value_long: '', key: 2 + new Date().getTime()}
    ])

    useEffect(() => {
        if(data){
            const arr = data.split(';')
            const newArr = []
            let count = 0
            arr.forEach(async (value) => {
                count += 1
                const obj = {lat: 'lat_', long: 'long_', value_lat: value.split(',')[0] || '', value_long: value.split(',')[1] || '', key: count}
                await newArr.push(obj)
            })
            setArrKoor(newArr)
        }
    }, [data])

    const handleAddKordinat = () => {
        setArrKoor([...arrKoor, {lat: 'lat_', long: 'long_', value_lat: '', value_long: '', key: new Date().getTime()}])
    }

    const removeItem = (key) => {
        setArrKoor(arrKoor.filter(data => data.key !== key))
    }

    const handleChangeLat = (e) => {
        const indexConversation = arrKoor.findIndex((obj => e.target.name === obj.lat + obj.key))
        const conversations = [ ...arrKoor ]
        conversations[indexConversation].value_lat = e.target.value
        setArrKoor(conversations)
    }

    const handleChangeLong = (e) => {
        const indexConversation = arrKoor.findIndex((obj => e.target.name === obj.long + obj.key))
        const conversations = [ ...arrKoor ]
        conversations[indexConversation].value_long = e.target.value
        setArrKoor(conversations)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let koordinat = ''
        arrKoor.forEach(({lat, long, key}) => {
            if(e.target[lat + key].value !== '' && e.target[long + key].value !== ''){
                koordinat += `${e.target[lat + key].value},${e.target[long + key].value};`
            }
        })

        // console.log(koordinat.substring(0, koordinat.length -1))
        onSubmit(koordinat.substring(0, koordinat.length -1))
    }

    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-soft w-10/12 md:w-1/2 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8 sticky inset-0'>Form Input Koordinat Lahan</h1>
                
                <form onSubmit={handleSubmit}>
                    {arrKoor.map(({lat, long, value_lat, value_long, key}, index) => (
                    <div className='flex items-center mb-3' key={index}>
                        {/* <h1>{data.key}</h1> */}
                        <div className='flex flex-row w-full'>
                            <input name={lat + key} value={value_lat} onChange={handleChangeLat} placeholder='-3.67875' className='outline-none border-1 border-gray-200 rounded-lg py-3 px-3 w-full mr-2' />
                            <input name={long + key} value={value_long} onChange={handleChangeLong} placeholder='106.90906'  className='outline-none border-1 border-gray-200 rounded-lg py-3 px-3 w-full'/>
                        </div>
                        <span className='w-10 h-10 bg-red-600 hover:bg-red-900 flex items-center justify-center text-white text-base rounded-lg ml-2 cursor-pointer' onClick={() => removeItem(key)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                    ))}

                    <p className='mt-5 text-lightcayn hover:text-darkcayn cursor-pointer' onClick={handleAddKordinat}>+ Tambah Kordinat</p>
                    
                    <div className='flex justify-end mt-3'>
                        <CancelButton type='reset' onClick={onCancel} />
                        <SaveButton type='submit' />
                    </div>
                </form>

                {/* End Body */}
            </div>
            {/* End Modal Content */}
        </div>
    )
}

export default ModalFormKordinat