import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { IParameterPengamatan } from "@api/parameterpengamatan-api";
import Loader from "./Loader";
import useDropdownCommodity from "@hooks/useDropdownCommodity";
import useDropdownVariety from "@hooks/useDropdownVariety";
import { createNewCommodityRef } from "@api/commodity-api";
import { toast } from "react-toastify";
import { createNewVariety } from "@api/variety-api";
import ModalFormCommodityStandard from "./operatingparameter/ModalFormCommodityStandard";
import ModalFormVariety from "./ModalFormVariety";

type Props = {
  data: IParameterPengamatan;
  dataUser?: any;
  onCancel: (e: React.MouseEvent) => any;
  onSubmit: (data: IParameterPengamatan) => void;
};

const ModalFormParameterPengamatan: React.FC<Props> = ({
  data,
  onCancel,
  onSubmit,
}) => {
  // states
  const [commodityId, setCommodityId] = useState<any>(
    data ? data.commodity_id : null,
  );
  const [varietyId, setVarietyId] = useState(data ? data.variety_id : null);
  const [parameterPengamatan, setParameterPengamatan] = useState(
    data ? data.parameter_pengamatan : "",
  );
  const [valueParameterPengamatan, setValueParameterPengamatan] = useState(
    data ? data.value_parameter_pengamatan : [],
  );
  const userLoggedIn = JSON.parse(localStorage.getItem("pandawa-user"));
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [modalFormCommodity, showModalFormCommodity] = useState(false);
  const [modalFormVariety, showModalFormVariety] = useState(false);

  // hooks
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({ criteriaMode: "all" });
  const { dataCommodity, loadingCommodity } =
    useDropdownCommodity(isRefreshing);
  const { dataVariety, loadingVariety } = useDropdownVariety(isRefreshing);

  // functions
  const setdefaultValue = useCallback(
    (obj) => {
      Object.keys(obj).map((key) => {
        return setValue(key, obj[key], { shouldValidate: true });
      });
    },
    [setValue],
  );
  const onSubmitForm: SubmitHandler<IParameterPengamatan> = ({
    parameter_pengamatan,
    value_parameter_pengamatan,
    commodity_id,
    variety_id,
  }) => {
    const payload = {
      parameter_pengamatan,
      value_parameter_pengamatan: JSON.stringify(valueParameterPengamatan),
      commodity_id,
      variety_id,
    };
    console.log(payload);
    onSubmit(payload);
  };

  // effects
  useEffect(() => {
    if (data) {
      setParameterPengamatan(data.parameter_pengamatan);
      setValueParameterPengamatan(data.value_parameter_pengamatan);
      setCommodityId(data.commodity_id);
      setVarietyId(data.variety_id);
      setdefaultValue(data);
    }
  }, [data, setdefaultValue]);

  const handleReceiveDataFormCommodity = async (payload) => {
    let res = null;
    payload.created_by_var = userLoggedIn.fullname_var;
    res = await createNewCommodityRef(payload);

    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);
        setIsRefreshing(!isRefreshing);
        showModalFormCommodity(false);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(`${res.config?.url} ${res.message}`);
    }
  };

  const handleReceiveDataFormVariety = async (data) => {
    let res = null;
    data.created_by_var = userLoggedIn.fullname_var;
    res = await createNewVariety(data);
    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);
        setIsRefreshing(!isRefreshing);
        showModalFormVariety(false);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const handleInputChange = (index, value) => {
    const updatedValues = [...valueParameterPengamatan];
    updatedValues[index] = value;
    setValueParameterPengamatan(updatedValues);
  };

  const handleAddInput = () => {
    setValueParameterPengamatan([...valueParameterPengamatan, ""]); // Add a new empty input
  };

  const handleRemoveInput = (index) => {
    const updatedValues = valueParameterPengamatan.filter(
      (_, i) => i !== index,
    );
    setValueParameterPengamatan(updatedValues);
  };

  return (
    <>
      {!loadingCommodity && !loadingVariety ? (
        <div className="modal-form">
          <div className="modal-form-outside" onClick={onCancel}></div>

          <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
            <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
              <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                <h3>
                  {data
                    ? "Ubah Parameter Pengamatan"
                    : "Tambah Parameter Pengamatan"}
                </h3>
              </div>
              <button
                type="button"
                className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                onClick={onCancel}>
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
              </button>
            </div>

            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
              <div className="space-y-5">
                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                  <div className="w-full">
                    <label htmlFor="commodity_id" className="label-form">
                      Komoditas
                    </label>
                    <select
                      id="commodity_id"
                      className="input-form"
                      {...register("commodity_id", {
                        required: "Commodity is required",
                      })}
                      onChange={(e) => {
                        setCommodityId(e.target.value);
                      }}>
                      <option value="">- Pilih Komoditas -</option>
                      {dataCommodity!.map((item, key) => (
                        <option key={key} value={item.id_seq}>
                          {item.commodity_name_var}
                        </option>
                      ))}
                    </select>
                    <ErrorField errors={errors} name="commodity_id" />
                    <button
                      type="button"
                      className="mt-4 text-right"
                      onClick={() => showModalFormCommodity(true)}>
                      + Tambah Commodity
                    </button>
                  </div>
                </div>
                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                  <div className="w-full">
                    <label htmlFor="variety_id" className="label-form">
                      Variety
                    </label>
                    <select
                      id="variety_id"
                      className="input-form"
                      {...register("variety_id", {
                        required: "Variety is required",
                      })}
                      onChange={(e) => {
                        setVarietyId(e.target.value);
                      }}>
                      <option value="">- Pilih Variety -</option>
                      {dataVariety
                        ?.filter(
                          (item) => String(item.commodity_id) === commodityId,
                        )
                        ?.map((item, key) => (
                          <option key={key} value={item.id_seq}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    <ErrorField errors={errors} name="variety_id" />
                    <button
                      type="button"
                      className="mt-4 text-right"
                      onClick={() => showModalFormVariety(true)}>
                      + Tambah Variety
                    </button>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 gap-4">
                  <div className="w-full">
                    <label
                      htmlFor="parameter_pengamatan"
                      className="label-form">
                      Parameter Pengamatan
                    </label>
                    <input
                      id="parameter_pengamatan"
                      type="text"
                      className="input-form"
                      placeholder="Nama Parameter Pengamatan"
                      {...register("parameter_pengamatan", {
                        required: "parameter pengamatan is required.",
                      })}
                    />
                    <ErrorField errors={errors} name="parameter_pengamatan" />
                  </div>
                  <div className="w-full">
                    <label className="label-form">
                      Values Parameter Pengamatan
                    </label>
                    {valueParameterPengamatan.map((vp, vpIndex) => (
                      <div className="w-full mb-4" key={vpIndex}>
                        <input
                          id="value_parameter_pengamatan"
                          type="text"
                          className="input-form"
                          placeholder="value parameter pengamatan"
                          value={vp}
                          onChange={(e) =>
                            handleInputChange(vpIndex, e.target.value)
                          }
                        />
                        <button
                          type="button"
                          className="text-red-500 py-1"
                          onClick={() => handleRemoveInput(vpIndex)}>
                          Remove Value
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="text-blue-500 py-1"
                      onClick={handleAddInput}>
                      + Add Value
                    </button>
                  </div>
                </div>
              </div>

              {/* <!-- Modal footer --> */}
              <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                <button type="submit" className="btn-primary">
                  Simpan
                </button>
                <button
                  type="reset"
                  className="btn-secondary px-7"
                  onClick={onCancel}>
                  Batal
                </button>
              </div>
            </form>

            {modalFormCommodity && (
              <ModalFormCommodityStandard
                data={null}
                onCancel={() => showModalFormCommodity(false)}
                onSubmit={handleReceiveDataFormCommodity}
              />
            )}
            {modalFormVariety && (
              <ModalFormVariety
                data={null}
                onCancel={() => showModalFormVariety(false)}
                onSubmit={handleReceiveDataFormVariety}
              />
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ModalFormParameterPengamatan;
