import axios from "axios";
import { baseURL } from "./common-api";

export interface ICategoryVariable {
  id_seq?: number;
  category_variable_var?: string;

  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}


//#region Approval-REF
export const getAllCategoryVariableRef = async () => {
  try {
    const data = await axios.get(baseURL + "/api/category-variable-ref/get-all", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

// export const getOneCategoryVariableRef = async (commodityName: string) => {
//   try {
//     const data = await axios.get(
//       baseURL +
//         "/api/category-variable-ref/get-one?commodity_name_var=" +
//         commodityName,
//       {
//         headers: {
//           authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//       },
//     );
//     return data;
//   } catch (error: any) {
//     if (error.response) return error.response;
//     else return JSON.parse(JSON.stringify(error));
//   }
// };

export const createNewCategoryVariableRef = async (payload: any) => {
  try {
    const data = await axios.post(
      baseURL + "/api/category-variable-ref/create",
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const updateCategoryVariableRef = async (
  CategoryVariableRefID: string,
  payload: any,
) => {
  try {
    const data = await axios.patch(
      baseURL + "/api/category-variable-ref/update/" + CategoryVariableRefID,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteCategoryVariableRef = async (CategoryVariableRefID: number) => {
  try {
    const data = await axios.delete(
      baseURL + "/api/category-variable-ref/delete/" + CategoryVariableRefID,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const dropdownCategoryVariable = async () => {
  try {
    const data = await axios.get(baseURL + "/api/category-variable-ref/dropdownCategoryVariable/", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
//#endregion
