import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";

const ModalFormPasswordGuest = ({ data, onCancel, onSubmit }) => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])


    const onValid = (dataForm) => {
        const { password_var, phone_number_int } = dataForm

        const payload = { password_var, phone_number_int }

        onSubmit(payload)
    }

    return (
        <div className="modal-form">
            <div className="modal-form-outside" onClick={onCancel}></div>

            <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                    <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                        <i className="fa-solid fa-ranking-star flex justify-center items-center"></i>
                        <h3>{'Password Guest'}</h3>
                    </div>
                    <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                        onClick={onCancel}
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>

                {/* Body */}
                <form onSubmit={handleSubmit(onValid)} className='px-8'>
                    <div className='grid grid-cols-1 gap-x-8 gap-y-3'>
                        <div className="flex flex-col w-full mb-5">
							<label>Password</label>
							<input
								type="password"
								placeholder="xxxxx"
								className="mt-1.5 p-2.5 outline-none border-1 border-gray-300 rounded"
								{...register("password_var", {
									required: "Password is required.",
									minLength: {
										value: 5,
										message: "Password must exceed 4 characters.",
									},
								})}
							/>
							<ErrorField errors={errors} name="password_var" />
						</div>
                    </div>
                    {/* <!-- Modal footer --> */}
                    <div className="flex items-center mt-6 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10 mb-5">
                        <button type='submit' className="btn-primary">Log In as Guest</button>
                        <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Cancel</button>
                    </div>
                </form>

                {/* End Body */}
            </div>
            {/* End Modal Content */}
        </div>
    )
}

export default ModalFormPasswordGuest