import { useCallback, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import TableUser from "../../components/tables/Table";
import { DropdownLand } from "../../components/dropdown/SimpleDropdown";
import Loader from "../../components/modal/Loader";
import React from "react";
import { toast } from "react-toastify";
import { ActionType } from "../../reduxs/action/actions";
import SearchField from "../../components/SearchField";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalProvider";
import { IRecentPage } from "../../context/ContextInterface";
import useDropdownFarmer from "../../hooks/useDropdownFarmer";
import useDropdownLand, { useDropdownPeriod } from "../../hooks/useDropdownLand";
import { 
	getAllListFA,
	ILogActivities
} from "@api/logactivity-api";


const TITLE_BAR = "Log Aktifitas FA";

type Props = {
	user?: any;
};

const LogActivityManagement: React.FC<Props> = ({ user }) => {
	const [loader, showLoader] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [originData, setOriginData] = useState<ILogActivities[] | []>([]);
	const [filterData, setFilterData] = useState<ILogActivities[] | []>([]);
	const [selectedData, setSelectedData] = useState<ILogActivities | null>(null,);
	const { dataFarmer, loadingFarmer } = useDropdownFarmer();
	const [selectedFarmer, setSelectedFarmer] = useState<any>(null);
	const [selectedLand, setSelectedLand] = useState<any>(null);
	const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
	const { dataPeriod, loadingPeriod } = useDropdownPeriod(selectedLand?.land_code_var || "",);
	
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { pathname } = useLocation();
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);
	const { dataLand, loadingLand } = useDropdownLand(selectedFarmer?.id_seq || 0,);

	useEffect(() => {
		const payload: IRecentPage = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	const fetchData = useCallback(async () => {
		showLoader(true);

		const res = await getAllListFA()
		
		showLoader(false);
		console.log("LOG ACTIVITIES ==> ", res);
		if (res.data) {
			if (res.data.code === 0) {
				setOriginData(res.data.data);
				setFilterData(res.data.data);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData, dispatch]);

	// const handleEditData = (selectedData: ILogActivities) => {
	// 	setSelectedData(selectedData);
	// 	setIsUpdate(true);
	// 	showModalForm(true);
	// };

	const handleDetailData = (selectedData: any) => {
		let path = `/dashboard/log-activities-fa/${selectedData.id_seq}`;
		navigate(path);
	};

	// const handleReceiveDataForm = async (data: ILogActivities) => {
	// 	showLoader(true);

	// 	let res = null;
	// 	if (!isUpdate) {
	// 		data.created_by_var = user.fullname_var;
	// 		res = await createNewProject(data);

	// 		var payload = getPayloadRecentActivity(
	// 			1,
	// 			"Project",
	// 			data.project_name_var,
	// 			user.fullname_var,
	// 			pathname,
	// 		);
	// 	} else {
	// 		data.updated_by_var = user.fullname_var;
	// 		res = await updateProject(selectedData?.id_seq!, data);

	// 		payload = getPayloadRecentActivity(
	// 			2,
	// 			"Project",
	// 			data.project_name_var,
	// 			user.fullname_var,
	// 			pathname,
	// 		);
	// 	}

	// 	console.log("Create/Update ProManage :", res);

	// 	if (res.data) {
	// 		if (res.data.code === 0) {
	// 			toast.success(res.data.message);

	// 			dispatchCalActivity({ type: "push", payload });

	// 			fetchData();
	// 			resetForm();
	// 		} else {
	// 			toast.error(res.data.message);
	// 			showLoader(false);
	// 		}
	// 	} else {
	// 		toast.error(res.status + " " + res.message || "Connection timeout");
	// 	}
	// };

	// const handleDeleteData = async (data: ILogActivities) => {
	// 	showLoader(true);
	// 	const res = await deleteProject(data.id_seq!);

	// 	console.log("DELETE ProManage :", res);
	// 	if (res.data) {
	// 		if (res.data.code === 0) {
	// 			toast.success(res.data.message);

	// 			const payload = getPayloadRecentActivity(
	// 				3,
	// 				"Project",
	// 				data.project_name_var,
	// 				user.fullname_var,
	// 				pathname,
	// 			);
	// 			dispatchCalActivity({ type: "push", payload });

	// 			fetchData();
	// 			resetForm();
	// 		} else {
	// 			toast.error(res.data.message);
	// 			showLoader(false);
	// 		}
	// 	} else {
	// 		toast.error(res.status + " " + res.message || "Connection timeout");
	// 	}
	// };

	const resetForm = () => {
		setSelectedData(null);
		setIsUpdate(false);
		showLoader(false);
	};

	// const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	event.preventDefault();

	// 	const newData = [...originData];
	// 	if (event.target.value) {
	// 		const filtered = newData.filter((item) => {
	// 			return (
	// 				item.project_name_var
	// 					.toLowerCase()
	// 					.includes(event.target.value.toLowerCase()) ||
	// 				item.project_code_var
	// 					.toLowerCase()
	// 					.includes(event.target.value.toLowerCase()) ||
	// 				item.location
	// 					?.toLowerCase()
	// 					.includes(event.target.value.toLowerCase())
	// 			);
	// 		});

	// 		setFilterData(filtered);
	// 	} else {
	// 		setFilterData(originData);
	// 	}
	// };

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="pl-4 text-left">{row.index + 1}.</div>
			),
			width: 10,
		},
		{
			Header: "Periode",
			accessor: "period_plant_txt",
			width: 50,
		},
		{
			Header: "Pendamping",
			accessor: "fullname_var",
			width: 125,
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }: any) => {
				const data = row.original;
				return (
					<DropdownLand
						data={data}
						onDetail={() => handleDetailData(data)}
						onEdit={undefined}
						onDelete={undefined}					
					/>
				);
			},
		},
	];

	return (
		<div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
			<div className="w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6">
				<div className="mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
					<h1 className="font-bold uppercase text-xl">Daftar Pendamping</h1>
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{/* {modalForm && (
				<ModalFormProject
					data={selectedData!}
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
				/>
			)} */}
			{loader && <Loader />}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps, null)(LogActivityManagement);
