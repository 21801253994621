import axios from "axios";
import { baseURL } from "./common-api";

export interface RatioDividend {
  id_seq?: number;
  user_id_int: number;
  land_code_var?: string;
  material_support_name_var: string;
//   material_support_type_var: string;
  start_planting_dtm?: string;
  period_plant_txt?: string;
  harvest_prediction_int?: number;
  planting_method_int?: number;
  planting_phase_int?: number;
  source_funding_var: string;
  file_template_txt: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
  fullname_var?: string;
  land_name_var?: string;
  material_support_id?: number;
  variety_id?: number;
  variety_name?: string;
  ratio_farmer_int?: number;
  ratio_stakeholder_int?: number;
  budget_dividend_id_int?: number;
}


export const createNewRatioDividend = async (payload: any) => {
	try {
	  const data = await axios.post(
		baseURL + "/api/dividend/create",
		payload,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };
  
  export const updateRatioDividend = async (
	RatioDividendID: string,
	payload: any,
  ) => {
	try {
	  const data = await axios.patch(
		baseURL + "/api/dividend/update/" + RatioDividendID,
		payload,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };
  
  export const deleteRatioDividend = async (RatioDividendID: number) => {
	try {
	  const data = await axios.delete(
		baseURL + "/api/dividend/delete/" + RatioDividendID,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const getAllRatioDividend = async () => {
	try {
	  const data = await axios.get(baseURL + "/api/dividend/get-all", {
		headers: {
		  authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
	  });
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const getDropDownbyDividend = async () => {
	try {
	  const data = await axios.get(baseURL + "/api/dividend/dropdown-period-by-dividend", {
		headers: {
		  authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
	  });
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const getDropDownLandPrice = async () => {
	try {
	  const data = await axios.get(baseURL + "/api/dividend/dropdown-period-land-price", {
		headers: {
		  authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
	  });
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const getRatioListByPeriod = async (period_plant_txt: string) => {
    try {
        const data = await axios.get(baseURL + '/api/dividend/get-list-dividen?period_plant_txt=' + period_plant_txt, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
  //#endregion
  