import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { IParameterPascaPanenRef } from "@api/parameterpascapanenref-api";
import Loader from "./Loader";
import useDropdownCommodity from "@hooks/useDropdownCommodity";
import useDropdownVariety from "@hooks/useDropdownVariety";

type Props = {
  data: IParameterPascaPanenRef;
  dataUser?: any;
  onCancel: (e: React.MouseEvent) => any;
};

const ModalFormParameterPascaPanenRef: React.FC<Props> = ({
  data,
  onCancel,
}) => {
  // states
  const [param, setParam] = useState(data ? data.param : "");
  const [inputs, setInputs] = useState(data ? data.values : [{ value: "" }]);

  // hooks
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({ criteriaMode: "all" });

  // functions
  const setdefaultValue = useCallback(
    (obj) => {
      Object.keys(obj).map((key) => {
        return setValue(key, obj[key], { shouldValidate: true });
      });
    },
    [setValue],
  );

  // effects
  useEffect(() => {
    if (data) {
      setParam(data.param);
      setInputs(data.values);
      setdefaultValue(data);
    }
  }, [data, setdefaultValue]);

  return (
    <>
      <div className="modal-form">
        <div className="modal-form-outside" onClick={onCancel}></div>

        <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
          <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
              <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
              <h3>Detail Parameter Pasca Panen Ref</h3>
            </div>
            <button
              type="button"
              className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
              onClick={onCancel}>
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
            </button>
          </div>

          <form className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
            <div className="space-y-5">
              <div className="grid md:grid-cols-2 gap-x-4">
                <div className="w-full">
                  <label htmlFor="param" className="label-form">
                    Parameter
                  </label>
                  <input
                    id="param"
                    type="text"
                    className="input-form"
                    placeholder="Nama Parameter Pengamatan"
                    disabled
                    value={param}
                  />
                </div>
                <div>
                  <div className="w-full">
                    <label htmlFor="values" className="label-form">
                      Values
                    </label>
                    {inputs.map((inputValue, index) => (
                      <div className="my-2">
                        <input
                          type="text"
                          className="input-form"
                          value={inputValue.value}
                          disabled
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Modal footer --> */}
            <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
              <button
                type="reset"
                className="btn-secondary px-7"
                onClick={onCancel}>
                Tutup
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ModalFormParameterPascaPanenRef;
