import { useEffect, useState } from 'react';
import { getAllLand, getLandListByUser, ILandArea } from '../api/landarea-api';
import TaskApi from '../api/task-api';

export default function useDropdownLand(userID: number) {
    const [dataLand, setDataLand] = useState<ILandArea[]>([])
    const [loadingLand, setLoading] = useState(false)
    const [errorLand, setError] = useState(null)

    useEffect(() => {
        async function fetchDropdownLand() {
            setLoading(true)
            let res
            if(userID === 0 || userID === null || userID === undefined){
                res = await getAllLand()
            }else{
                res = await getLandListByUser(userID)
            }

            console.log("Fetching DropdownLand :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataLand(res.data.data)
                }else{
                    setError(res.data.message)
                }
            }else{
                setError(res.message)
            }
            setLoading(false)
        }

        fetchDropdownLand()
    }, [userID])

    return {dataLand, errorLand, loadingLand}
}

export function useDropdownPeriod(landCode: string | any) {
    const [dataPeriod, setDataPeriod] = useState<any[]>([])
    const [loadingPeriod, setLoading] = useState(false)
    const [errorPeriod, setError] = useState(null)

    useEffect(() => {
        async function fetchDropdownLand() {
            setLoading(true)
            const res = await TaskApi.dropdownPeriodByLandCode(landCode)

            console.log("Fetching Period :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataPeriod(res.data.data)
                }else{
                    setError(res.data.message)
                }
            }else{
                setError(res.message)
            }
            setLoading(false)
        }

        fetchDropdownLand()
    }, [landCode])

    return {dataPeriod, errorPeriod, loadingPeriod}
}