import axios from "axios";
import { baseURL } from "./common-api";

export interface IProductType {
  id_seq?: number;
  product_name_type_var: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
  status_int?: string;
}

export const getAll = async () => {
  try {
    const data = await axios.get(baseURL + "/api/product-type/get-all", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNew = async (payload: IProductType) => {
  try {
    const data = await axios.post(
      baseURL + "/api/product-type/create",
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const update = async (productTypeId: number, payload: IProductType) => {
  try {
    const data = await axios.patch(
      baseURL + "/api/product-type/update/" + productTypeId,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteData = async (productTypeId: number) => {
  try {
    const data = await axios.delete(
      baseURL + "/api/product-type/delete/" + productTypeId,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
