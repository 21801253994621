import { IActionRedux, IRecentActivity, IRecentPage, IRecentSearch } from "../../context/ContextInterface";

export const recentPagesReducer = (state: IRecentPage[], action: IActionRedux) => {
  const { type, payload } = action;
  switch (type) {
    case "push":
      // if(state.find(item => item.path === payload.path)){
      //     return state
      // }
      if (state.length > 2) {
        // state.push(payload);
        // const uniqueData = Array.from(state.reduce((map, obj) => map.set(obj.path, obj), new Map()).values());
        // return uniqueData.slice(uniqueData.length - 5, uniqueData.length)               // Return 2 values
        // return uniqueData.slice(uniqueData.length - 6, uniqueData.length).sort((a, b) => b.id - a.id); // Return 3 values
        // return state.slice(1, state.length);
        state.sort((a,b) => a.id - b.id)
        state.push(payload);
        return state.slice(1, state.length);
      } else {
        return [...state, payload];
      }
    case "update":
      return state.map((item) => (item.id === payload.id ? payload : item));
    case "delete":
      return state.filter((item) => item.id !== payload.id);
    case "truncate":
      return [];
    default:
      throw new Error();
  }
};

export const recentSearchesReducer = (state: IRecentSearch[], action: IActionRedux) => {
  const { type, payload } = action;
  switch (type) {
    case "push":
      if (state.length > 5) {
        state.sort((a,b) => a.id - b.id)
        state.push(payload);
        return state.slice(1, state.length);
      } else {
        return [...state, payload];
      }
    case "delete":
      return state.filter((item) => item.id !== payload.id);
    case "truncate":
      return [];
    default:
      throw new Error();
  }
};

export const recentActivitiesReducer = (state: IRecentActivity[], action: IActionRedux) => {
  const { type, payload } = action;
  switch (type) {
    case "push":
      if (state.length > 5) {
        state.sort((a,b) => a.id - b.id)
        state.push(payload);
        return state.slice(1, state.length);
      }else{
        return [...state, payload];
      }
    case "read":
      const newState = [...state];
      const readItemIndex = state.findIndex((item) => item.id === payload.id);
      newState[readItemIndex].is_read = true;
      return newState;
    case "read_all":
      const readAllState = state.map(item => {item.is_read = true; return item})
      return readAllState
    case "delete":
      return state.filter((item) => item.id !== payload.id);
    case "truncate":
      return [];
    default:
      throw new Error();
  }
};
