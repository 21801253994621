import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import TaskApi, {
	IObservartion,
	IActivity,
	IDocumentation,
	IRating,
	IChangeLog,
} from "../../../api/task-api";
import ConfirmModal from "../ConfirmModal";
import Activity from "./Activity";
import { DocumentationUpdate } from "./Documentation";
import Pengamatan from "./Pengamatan";
import Suggestion from "./Suggestion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GlobalContext } from "../../../context/GlobalProvider";
import Loader from "../Loader";

interface IPropsTask {
	daySelected: any;
	activites: IActivity[];
	observation: IObservartion[];
	documentation: IDocumentation[];
	rating: IRating[];
	onClose: (e: React.MouseEvent<HTMLElement>) => void;
	cultivation?: any;
}

const ModalTaskView: React.FC<IPropsTask> = ({
	daySelected,
	activites,
	observation,
	documentation,
	rating,
	onClose,
	cultivation,
}) => {
	const [loader, showLoader] = useState(false); // eslint-disable-line
	const [tabIndex, setTabIndex] = useState(0);
	const [dataStatusInt, setStatusint] = useState(0);
	const [dataSelectedId, setSelectedId] = useState('');
	const [activities, setActivities] = useState<IActivity[]>([]);
	const [changeLog, setChangeLog] = useState<IChangeLog[]>([]);
	const [obs, setObs] = useState<IObservartion>({} as IObservartion);
	const [documentations, setDocumentations] = useState<IDocumentation[]>([]);
	const [rate, setRate] = useState<IRating>({} as IRating);
	const [confirmDelete, showConfirmDelete] = useState(false);
	const [confirmUpdateActivity, showConfirmUpdateActivity] = useState(false);
	const [updateModal, showUpdateModal] = useState(false);
	const [selectedData, setSelectedData] = useState<any>(null);

	const { userStorage, ownerTask, setIsDataChanged } = useContext(GlobalContext);

	useEffect(() => {
		setActivities(activites);
		fetchChangeLog()
		// console.log('act ',activites);

	}, [activites]);

	useEffect(() => {
		if (observation.length > 0) {
			setObs(observation[0]);
		}
	}, [observation]);

	useEffect(() => {
		setDocumentations(documentation);
	}, [documentation]);

	useEffect(() => {
		if (rating.length > 0) {
			setRate(rating[0]);
		}
	}, [rating]);

	const fetchChangeLog = async () => {
		let temp_changeLog = []
		if (activites) {
			for (let i = 0; i < activites.length; i++) {
				const temp = await TaskApi.getChangeLogActivity(activites[i].id_seq, ownerTask.user_id_int, activites[i].land_code_var, ownerTask.period_plant_txt)
				// console.log('temp ',temp.data.data[0]?.change_log_json);

				if (temp.data.data[0]?.change_log_json.length > 0) {
					for (let j = 0; j < temp.data.data[0].change_log_json.length; j++) {
						temp_changeLog.push(temp.data.data[0].change_log_json[j])
					}
				}
			}
		}
		setChangeLog(temp_changeLog)
	}

	const handleDeleteTask = async (e: React.MouseEvent<HTMLElement>) => {
		showLoader(true);
		const payload = {
			user_id_int: ownerTask.user_id_int,
			land_code_var: ownerTask.land_code_var,
			period_plant_txt: ownerTask.period_plant_txt,
		};

		if (tabIndex === 0) {
			const res = await TaskApi.deleteActivity(selectedData.id_seq, payload);
			console.log("Delete Activity :", res);
			if (res.data && res.data.code === 0) {
				const resA = activities.filter(
					(item) => item.id_seq !== selectedData.id_seq,
				);
				setActivities(resA);
				setIsDataChanged(true);
			} else {
				toast.error(res.data?.message || res.message);
			}
		} else if (tabIndex === 1) {
			const res = await TaskApi.deleteObservation(selectedData.id_seq, payload);
			console.log("Delete Observation :", res);
			if (res.data && res.data.code === 0) {
				setObs({} as IObservartion);
				setIsDataChanged(true);
			} else {
				toast.error(res.data?.message || res.message);
			}
		} else if (tabIndex === 2) {
			const res = await TaskApi.deleteDocumentation(
				selectedData.id_seq,
				payload,
			);
			console.log("Delete Documentation :", res);
			if (res.data && res.data.code === 0) {
				const resA = documentations.filter(
					(item) => item.id_seq !== selectedData.id_seq,
				);
				setDocumentations(resA);
				setIsDataChanged(true);
			} else {
				toast.error(res.data?.message || res.message);
			}
		} else {
			const res = await TaskApi.deleteRating(selectedData.id_seq, payload);
			console.log("Delete Rating :", res);
			if (res.data && res.data.code === 0) {
				setRate({} as IRating);
				setIsDataChanged(true);
			} else {
				toast.error(res.data?.message || res.message);
			}
		}
		showLoader(false);
		showConfirmDelete(false);
	};

	const handleUpdateStatus = async (data: any) => {
		showLoader(true);
		let payload
		if (selectedData?.status_int === 20) {
			payload = {
				status_int: 0
			} as IActivity;
		} else {
			payload = {
				status_int: 20
			} as IActivity;
		}
		// console.log('obs ',obs);

		payload["user_id_int"] = ownerTask.user_id_int;
		payload["land_code_var"] = ownerTask.land_code_var;
		payload["period_plant_txt"] = ownerTask.period_plant_txt;

		payload["updated_by_var"] = userStorage.fullname_var;
		payload["time_calender_dte"] = daySelected;
		// console.log('obs ', obs.id_seq);
		// console.log('obs ', obs == null);

		// if (obs ? ) {
		// 	setSelectedId(selectedData.id_seq)
		// 	setStatusint(payload)
		// 	handleCloseUpdateModal()
		// 	setTabIndex(5)
		// } else {
		// 	const update_status = await TaskApi.updateStatusActivity(selectedData.id_seq, payload);
		// 	if (update_status) {
		// 		const res = activities.map((task) =>
		// 			task.id_seq === update_status.data.data[0].id_seq ? update_status.data.data[0] : task,
		// 		);
		// 		setActivities(res);
		// 		handleCloseUpdateModal()
		// 	}
		// }
		if (typeof obs.id_seq === 'undefined') {
			setSelectedId(selectedData.id_seq);  // Set selected ID
			setStatusint(payload);  // Set status using payload
			handleCloseUpdateModal();  // Close the update modal
			setTabIndex(5);  // Set tab index to 5
		} else {
			try {
				const update_status = await TaskApi.updateStatusActivity(selectedData.id_seq, payload);  // Call the API
				if (update_status) {
					const updatedTask = update_status.data.data[0];  // Extract the updated task
					const res = activities.map((task) =>
						task.id_seq === updatedTask.id_seq ? updatedTask : task
					);  // Map and update the activities list
					setActivities(res);  // Set the new activities list
					handleCloseUpdateModal();  // Close the update modal
				}
			} catch (error) {
				console.error("Failed to update status:", error);  // Handle potential errors
			}
		}

	}

	const handleSubmitDataUpdated = async (data: any) => {
		data["id_seq"] = selectedData.id_seq;
		// console.log("Data Update :", data);
		if (tabIndex === 0) {
			const res = activities.map((task) =>
				task.id_seq === data.id_seq ? data : task,
			);
			setActivities(res);
		} else if (tabIndex === 1) {
			const modifyData = {
				...data,
				params_values: JSON.parse(data.params_values),
				time_calender_dte: daySelected,
			};

			setObs(modifyData);
		} else if (tabIndex === 2) {
			const res = documentations.map((doc) =>
				doc.id_seq === data.id_seq ? data : doc,
			);
			setDocumentations(res);
		} else {
			setRate(data);
		}
		handleCloseUpdateModal();
	};

	const handleCloseUpdateModal = () => {
		showUpdateModal(false);
		showConfirmDelete(false);
		showConfirmUpdateActivity(false);
		setSelectedData(null);
		showLoader(false);
	};

	return (
		<>
			<div className="modal-form">
				<div className="modal-form-outside" onClick={onClose} />
				<div
					className="modal-form-content bg-white p-5 w-[95%] md:w-6/12 2xl:w-5/12"
					style={{ maxHeight: "90vh" }}>
					<div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
						<div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
							<h3>Task Farmer</h3>
						</div>
						<button
							type="button"
							className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
							onClick={onClose}>
							<svg
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"></path>
							</svg>
						</button>
					</div>
					<div className="mt-5 mb-6">
						<ul className="text-xsm md:text-sm text-center text-gray-400 grid grid-cols-4 border-b">
							<li>
								<button
									className={`font-semibold w-full pb-3 rounded-t-lg border-b-2 outline-none ${tabIndex === 0
										? "text-blue-500 border-blue-500"
										: "border-transparent"
										} hover:border-blue-500 hover:text-blue-500`}
									onClick={() => setTabIndex(0)}>
									<p>Aktivitas</p>
								</button>
							</li>
							<li>
								<button
									className={`font-semibold w-full pb-3 rounded-t-lg border-b-2 outline-none ${tabIndex === 1
										? "text-blue-500 border-blue-500"
										: "border-transparent"
										} hover:border-blue-500 hover:text-blue-500`}
									onClick={() => setTabIndex(1)}>
									<p>Pengamatan view</p>
								</button>
							</li>
							<li>
								<button
									className={`font-semibold w-full pb-3 rounded-t-lg border-b-2 outline-none ${tabIndex === 2
										? "text-blue-500 border-blue-500"
										: "border-transparent"
										} hover:border-blue-500 hover:text-blue-500`}
									onClick={() => setTabIndex(2)}>
									<p>Dokumentasi</p>
								</button>
							</li>
							<li>
								<button
									className={`font-semibold w-full pb-3 rounded-t-lg border-b-2 outline-none ${tabIndex === 3
										? "text-blue-500 border-blue-500"
										: "border-transparent"
										} hover:border-blue-500 hover:text-blue-500`}
									onClick={() => setTabIndex(3)}>
									<p>Saran dan Penilaian</p>
								</button>
							</li>
						</ul>
					</div>
					{tabIndex === 0 ? (
						<>
							{activities.map((item, key) => (
								<div
									className="flex flex-col mb-6 px-1 md:px-5 text-sm"
									key={key}>
									<div className="flex items-center justify-between">
										<h3 className="font-bold text-blue-500">
											Aktifitas {key + 1}  {item.status_int === 20 ?
												<span className="bg-green-100 text-green-800 px-2 py-1 rounded-xl font-medium">
													Done
												</span>
												: <span className="bg-red-100 text-red-800 px-2 py-1 rounded-xl font-medium">
													Not Done
												</span>}
										</h3>
										<div className="space-x-4 text-gray-400">
											<i
												className="fa-solid fa-circle-check cursor-pointer hover:opacity-50"
												onClick={() => {
													setSelectedData(item);
													showConfirmUpdateActivity(true);
												}}></i>
											<i
												className="fa-solid fa-pen cursor-pointer hover:opacity-50"
												onClick={() => {
													setSelectedData(item);
													showUpdateModal(true);
												}}></i>
											<i
												className="fa-solid fa-trash cursor-pointer hover:opacity-50"
												onClick={() => {
													setSelectedData(item);
													showConfirmDelete(true);
												}}></i>
										</div>
									</div>
									<h2 className="text-sm md:text-base font-semibold my-1.5">
										{item?.activity_txt}
									</h2>
									<table className="font-medium text-xs md:text-sm">
										<tbody>
											<tr>
												<td className="text-gray-500 pb-0.5 w-32 md:w-44">
													Bahan
												</td>
												<td>{item?.material_txt || ""}</td>
											</tr>
											<tr>
												<td className="text-gray-500 pb-0.5">Dosis</td>
												<td>{item?.dose_txt || ""}</td>
											</tr>
											<tr>
												{/* <td>{item?.jumlah_txt > 0 item.jumlah_txt : "" }</td> */}
												<td className="text-gray-500 pb-0.5">Jumlah HST</td>
												<td>{item?.jumlah_txt >= 0 ? item.jumlah_txt : ""}</td>
											</tr>
											<tr>
												<td className="text-gray-500 pb-0.5">Satuan HST</td>
												<td>{item?.satuan_txt || ""}</td>
											</tr>
										</tbody>
									</table>
								</div>
							))}
							<div className="flex mb-6 px-1 md:px-5 text-sm items-center justify-between">
								<h3 className="font-bold text-blue-500">
									Riwayat Perubahan Tanggal Aktifitas
								</h3>
							</div>
							{changeLog?.map((item, key) => (
								<div
									className="flex flex-col mb-6 px-1 md:px-5 text-sm"
									key={key}>
									{/* <div className="flex mb-6 px-1 md:px-5 text-sm items-center justify-between"> */}
									{key === 0 ? (
										<h3 className="text-gray-500 pb-0.5 w-32 md:w-44">
											tanggal rekomendasi
										</h3>
									) : (
										<h3 className="text-gray-500 pb-0.5 w-32 md:w-44">
											Perubahan ke {key}
										</h3>
									)}
									{/* </div> */}
									<table className="font-medium text-sm md:text-sm">
										<tbody>
											<tr>
												<td className="text-gray-500 pb-0.5 w-32 md:w-44">
													Tanggal
												</td>
												<td>{item?.old_date_dte || ""}</td>
											</tr>
											<tr>
												<td className="text-gray-500 pb-0.5">Jumlah HST</td>
												<td>{item?.old_jumlah_txt || ""}</td>
											</tr>
											<tr>
												<td className="text-gray-500 pb-0.5">Satuan HST</td>
												<td>{item?.old_satuan_txt || ""}</td>
											</tr>
										</tbody>
									</table>
								</div>
							))}
						</>
					) : tabIndex === 1 ? (
						Object.keys(obs).length !== 0 && (
							<div className="flex flex-col mb-5 px-1 md:px-5 text-sm">
								<div className="flex items-center justify-between">
									<h3 className="font-bold text-blue-500">Pengamatan</h3>
									<div className="space-x-3 text-gray-400">
										<i
											className="fa-solid fa-pen cursor-pointer hover:opacity-50"
											onClick={() => {
												setSelectedData(obs);
												showUpdateModal(true);
											}}></i>
										<i
											className="fa-solid fa-trash cursor-pointer hover:opacity-50"
											onClick={() => {
												setSelectedData(obs);
												showConfirmDelete(true);
											}}></i>
									</div>
								</div>
								<table className="font-medium text-xs md:text-sm mt-3">
									<tbody>
										{obs?.params_values.filter((par) => par != null)
											.map((param, index) => (
												<tr key={`param-${index}`}>
													<React.Fragment key={param.parameter}>

														<td className="text-gray-500 pb-1 w-36 md:w-60">
															{param.parameter ? param.parameter : ''}
														</td>
														<td>{String(param.value)}</td>
													</React.Fragment>
												</tr>
											))}
										<tr>
											<td className="text-gray-500 pb-1">Catatan Pengamatan</td>
											<td>{obs?.example_observation_txt}</td>
										</tr>
									</tbody>
								</table>
							</div>
						)
					) : tabIndex === 2 ? (
						documentations.length > 0 && (
							<div className="flex flex-col mb-5 px-1 md:px-5 text-sm">
								<div className="flex items-center justify-between">
									<h3 className="font-bold text-blue-500">
										Dokumentasi Lapangan
									</h3>
								</div>
								<div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-4 2xl:gap-5 mt-4">
									{documentations.map((item, key) => (
										<div
											className="w-full h-[11.5rem] 2xl:h-[12.5rem] rounded-xl"
											key={key}>
											<div className="relative w-full h-full cursor-pointer hover:scale-110 hover:duration-500">
												<div className="absolute inset-0 h-full w-full bg-black opacity-30 rounded-xl"
													onClick={() =>
														window.open(item.document_txt[0], "_blank")
													} />
												<LazyLoadImage
													alt=""
													src={item.document_txt[0]}
													className="h-full w-full rounded-lg object-cover"

												/>
												<div className="absolute top-0 right-0 space-x-2 m-2 text-gray-200">
													<i
														className="fa-solid fa-pen cursor-pointer hover:text-gray-500"
														onClick={() => {
															setSelectedData(item);
															showUpdateModal(true);
														}}></i>
													<i
														className="fa-solid fa-trash cursor-pointer hover:text-gray-500"
														onClick={() => {
															setSelectedData(item);
															showConfirmDelete(true);
														}}></i>
												</div>
											</div>
										</div>

									))}
								</div>
							</div>
						)
					) : tabIndex === 5 ? (
						<Pengamatan
							onCancel={onClose}
							daySelected={daySelected}
							cultivation={cultivation}
							dataStatus={dataStatusInt}
							dataSelectedData={dataSelectedId}

						/>
					) : (
						Object.keys(rate).length !== 0 && (
							<div className="flex flex-col mb-5 px-1 md:px-5 text-sm">
								<div className="flex items-center justify-between">
									<h3 className="font-bold text-blue-500">
										Saran dan Penilaian
									</h3>
									<div className="space-x-3 text-gray-400">
										<i
											className="fa-solid fa-pen cursor-pointer hover:opacity-50"
											onClick={() => {
												setSelectedData(rate);
												showUpdateModal(true);
											}}></i>
										<i
											className="fa-solid fa-trash cursor-pointer hover:opacity-50"
											onClick={() => {
												setSelectedData(rate);
												showConfirmDelete(true);
											}}></i>
									</div>
								</div>
								<table className="font-medium text-xs md:text-sm mt-3">
									<tbody>
										<tr>
											<td className="text-gray-500 w-24 md:w-36">Saran</td>
											<td className="pb-2">{rate?.suggest_txt}</td>
										</tr>
										<tr>
											<td className="text-gray-500">Penilaian</td>
											<td>{rate?.rating_txt}</td>
										</tr>
									</tbody>
								</table>
							</div>
						)
					)}
				</div>
			</div>

			{updateModal && (
				<div className="modal-form">
					<div
						className="modal-form-outside"
						onClick={handleCloseUpdateModal}
					/>
					<div
						className="modal-form-content bg-white p-5 w-[95%] md:w-6/12 2xl:w-5/12"
						style={{ maxHeight: "90vh" }}>
						<div className="flex items-center justify-between md:px-5 border-b pb-3">
							<div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
								<h3>
									Ubah{" "}
									{tabIndex === 0
										? "Aktivitas"
										: tabIndex === 1
											? "Pengamatan"
											: tabIndex === 3
												? "Saran dan Penilaian"
												: "Dokumentasi"}
								</h3>
							</div>
							<button
								type="button"
								className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
								onClick={handleCloseUpdateModal}>
								<svg
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"></path>
								</svg>
							</button>
						</div>
						{tabIndex === 0 ? (
							<Activity
								data={selectedData}
								daySelected={daySelected}
								onCancel={handleCloseUpdateModal}
								onSubmit={handleSubmitDataUpdated}
							/>
						) : tabIndex === 1 ? (
							<Pengamatan
								data={selectedData}
								daySelected={daySelected}
								onCancel={handleCloseUpdateModal}
								onSubmit={handleSubmitDataUpdated}
								cultivation={cultivation}
							/>
						) : tabIndex === 2 ? (
							<DocumentationUpdate
								data={selectedData}
								daySelected={daySelected}
								onCancel={handleCloseUpdateModal}
								onSubmit={handleSubmitDataUpdated}
							/>
						) : (
							<Suggestion
								data={selectedData}
								daySelected={daySelected}
								onCancel={handleCloseUpdateModal}
								onSubmit={handleSubmitDataUpdated}
							/>
						)}
					</div>
				</div>
			)}

			{confirmDelete && (
				<ConfirmModal
					onCancel={handleCloseUpdateModal}
					onOK={handleDeleteTask}
				/>
			)}
			{confirmUpdateActivity && (
				<ConfirmModal
					icon="fa-solid fa-check-to-slot"
					message="Ubah status aktifitas?"
					onCancel={handleCloseUpdateModal}
					// onOK={handleUpdateStatus}
					onSubmit={handleUpdateStatus}

				/>
			)}
			{loader && <Loader />}
		</>
	);
};

export default ModalTaskView;
