import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { ICommodity, createNewCommodityRef } from "@api/commodity-api";
import dayjs from "dayjs";
import Loader from "./Loader";
import Select from "react-select"
import useDropdownFarmer from "@hooks/useDropdownFarmer";
import useDropdownLand from "@hooks/useDropdownLand";
import useDropdownRatioDevidend, { useDropdownPeriodPlant } from "@hooks/useDropdownRatioDevidend";
import useDropdownCommodity, {
	useDropdownPlantingMethod,
	useDropdownTemplateTask,
} from "../../hooks/useDropdownCommodity";
import useDropdownVariety from "@hooks/useDropdownVariety";
import { createNewVariety } from "@api/variety-api";
import { toast } from "react-toastify";
import { getAllCultivation } from "@api/cultivation-api";
import useDropdownCultivation from "@hooks/useDropdownFarmerCultivation";
import { selectStylesForm } from "./TaskManagement/ModalTaskNew";

export const SOURCE_FUNDING_LIST = ["TPPAI", "Non TPPAI"];

type Props = {
	data: ICommodity;
	dataUser?: any;
	onCancel: (e: React.MouseEvent) => any;
	onSubmit: (data: ICommodity) => void;
};

const ModalFormCultivationPlanting: React.FC<Props> = ({
	data,
	onCancel,
	onSubmit,
}) => {
	const [userID, setUserID] = useState(data ? data.user_id_int : 0);
	const [commodityName, setCommodityName] = useState(data ? data.commodity_name_var : "");
	const [dataPlantingMethod, setPlantingMethod] = useState(data ? data.planting_method_int : 0);
	const [dataCultivationId, setCultivationId] = useState(data ? data.planting_method_int : 0);
	const [commodityId, setCommodityId] = useState<any>(data ? data.commodity_id : null);
	const [PeriodPlant, setPeriodPlant] = useState(data ? data.period_plant_txt : "");
	const [DataSourceFunding, setDataSourceFunding] = useState("");
	const [varietyId, setVarietyId] = useState(data ? data.variety_id : null);
	const [dataTemplate, setdataTemplate] = useState(data ? data.file_template_txt : null);
	const [dataRatioDividend, setRatioDividend] = useState(data ? data.ratio_dividend_int : null);
	const userLoggedIn = JSON.parse(localStorage.getItem("pandawa-user"));
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [modalFormCommodity, showModalFormCommodity] = useState(false);
	const [modalFormVariety, showModalFormVariety] = useState(false);
	// const { dataFarmer, loadingFarmer } = useDropdownFarmer();
	const { dataFarmerCultivation, loadingFarmerCultivation } = useDropdownCultivation(PeriodPlant); // eslint-disable-line
	const { dataLand, loadingLand } = useDropdownLand(userID); // eslint-disable-line
	const { dataCommodity, loadingCommodity } = useDropdownCommodity(isRefreshing);
	const { DataPeriodPlant, loadingPeriodPlant } = useDropdownPeriodPlant(isRefreshing)
	const [filteredCultivation, setfilteredCultivation] = useState<ICommodity[] | any[]>([]);


	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<any>({ criteriaMode: "all" });

	const setdefaultValue = useCallback(
		(obj) => {
			Object.keys(obj).map((key) => {
				if (key === "start_planting_dtm") {
					return setValue(key, dayjs(obj[key]).format("YYYY-MM-DD"));
				}
				else if (key === "period_plant_txt") {
					return setValue(key, obj[key]);
				}
				else {
					return setValue(key, obj[key], { shouldValidate: true });
				}
			});
		},
		[setValue, data?.period_plant_txt],
	);

	useEffect(() => {
		if (data) {
			setCommodityName(data.commodity_name_var);
			setPlantingMethod(data.planting_method_int);
			setUserID(data.user_id_int);
			setIsRefreshing(true)
			setdefaultValue(data);
		}
	}, [data, setdefaultValue]);

	const onSubmitForm: SubmitHandler<ICommodity> = ({
		// user_id_int,
		land_code_var,
		start_planting_dtm,
		period_plant_txt,
		// harvest_prediction_int,
		// planting_method_int,
		// planting_phase_int,
		// source_funding_var,
		// file_template_txt,
		// variety_id,
		// ratio_dividend_int
	}) => {

		const payload = {
			user_id_int: userID,
			land_code_var,
			commodity_name_var: commodityName,
			start_planting_dtm,
			period_plant_txt: period_plant_txt,
			planting_method_int: dataPlantingMethod,
			source_funding_var: DataSourceFunding,
			file_template_txt: dataTemplate,
			commodity_id: commodityId,
			variety_id: varietyId,
			ratio_dividend_int: dataRatioDividend,
			id_cultivation_int: dataCultivationId
		};

		console.log(payload);

		onSubmit(payload);
	};

	const handleSelectPeriod = async (e) => {
		try {
			await setPeriodPlant(e.target.value);
		} catch (error) {
			// Handle errors if any occur during the process
			console.error('Error:', error.message);
		}
	};

	const handleSelectUser = async (e) => {
		try {
			// await setUserID(Number(e.target.value));

			const res = await getAllCultivation();
			const resfilteredCultivation = res.data.data.filter(val => val.user_id_int === Number(e.user_id));

			if (resfilteredCultivation) {
				setfilteredCultivation(resfilteredCultivation)
			}

		} catch (error) {
			// Handle errors if any occur during the process
			console.error('Error:', error.message);
		}
	};


	const handleSelectLand = async (e) => {
		try {
			const filterCultivationbyLand = filteredCultivation.filter(val => val.land_code_var.includes(e.target.value));
			setdataTemplate(filterCultivationbyLand[0].file_template_txt)
			setVarietyId(filterCultivationbyLand[0].variety_id)
			setPlantingMethod(filterCultivationbyLand[0].planting_method_int)
			setDataSourceFunding(filterCultivationbyLand[0].source_funding_var)
			setCommodityId(filterCultivationbyLand[0].commodity_id)
			setRatioDividend(filterCultivationbyLand[0].ratio_dividend_int)
			setCommodityName(filterCultivationbyLand[0].commodity_name_var)
			setCultivationId(filterCultivationbyLand[0].id_seq)

			// setcultivationId(filterCultivationbyLand[0].id_seq)

		} catch (error) {
			// Handle errors if any occur during the process
			console.error('Error:', error.message);
		}
	};

	return (
		<>
			{!loadingCommodity ? (
				<div className="modal-form">
					<div className="modal-form-outside" onClick={onCancel}></div>

					<div className="modal-form-content" style={{ maxHeight: "90vh" }}>
						<div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
							<div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
								<i className="fa-brands fa-pagelines flex justify-center items-center"></i>
								<h3>{data ? "Ubah Cultivation" : "Tambah Cultivation"}</h3>
							</div>
							<button
								type="button"
								className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
								onClick={onCancel}>
								<svg
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"></path>
								</svg>
							</button>
						</div>

						<form
							onSubmit={handleSubmit(onSubmitForm)}
							className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
							<div className="space-y-5">
								{!loadingPeriodPlant && (
									<div className="w-full">
										<label htmlFor="period_plant_txt" className="label-form">
											Periode Tanam
										</label>
										<div className="flex item-center space-x-2">
											<select
												id="period_plant_txt"
												className="input-form"
												{...register("period_plant_txt", {
													required: "This field is required",
												})}
												onChange={(event) =>
													handleSelectPeriod(event)
												}>
												<option value="">- Pilih Periode Tanam -</option>
												{DataPeriodPlant?.map((item, key) => (
													<option key={key} value={item.period_plant_txt}>
														{item.period_plant_txt}
													</option>
												))}
											</select>
										</div>

										<ErrorField errors={errors} name="period_plant_txt" />
									</div>
								)}
								<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
									{!loadingFarmerCultivation && (
										<div className="w-full md:w-1/2">
											<label htmlFor="user_id_int" className="label-form">
												Petani
											</label>
											<Select
												id="user_id_int"
												styles={selectStylesForm}
												isClearable
												placeholder="- Pilih petani -"
												options={dataFarmerCultivation}
												isLoading={loadingFarmerCultivation}
												getOptionValue={(option: any) => option["user_id"]}
												getOptionLabel={(option: any) => option["fullname_var"]}
												onChange={(selectedOption) => {
													handleSelectUser(selectedOption);
													setUserID(selectedOption ? selectedOption.user_id : null);
												}}
											/>
											<ErrorField errors={errors} name="user_id_int" />
										</div>
									)}
									{!loadingLand && (
										<div className="w-full md:w-1/2">
											<label htmlFor="land_code_var" className="label-form">
												Lahan
											</label>
											<select
												id="land_code_var"
												className="input-form"
												{...register("land_code_var", {
													required: "Land code is required",
												})}
												onChange={(event) =>
													handleSelectLand(event)
												}>
												<option value="">- Pilih Lahan -</option>
												{userID > 0 && dataLand!.map((item, key) => (
													<option key={key} value={item.land_code_var}>
														[{item.land_code_var}] {item.land_name_var}
													</option>
												))}
											</select>
											<ErrorField errors={errors} name="land_code_var" />
										</div>
									)}
								</div>
								<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
									<div className="w-full">
										<label htmlFor="start_planting_dtm" className="label-form">
											Mulai Tanam
										</label>
										<input
											id="start_planting_dtm"
											type="date"
											className="input-form"
											{...register("start_planting_dtm", {
												required: "Start Planting is required.",
											})}
										/>
										<ErrorField errors={errors} name="start_planting_dtm" />
									</div>
								</div>
							</div>

							{/* <!-- Modal footer --> */}
							<div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
								<button type="submit" className="btn-primary">
									Simpan
								</button>
								<button
									type="reset"
									className="btn-secondary px-7"
									onClick={onCancel}>
									Batal
								</button>
							</div>
						</form>
					</div>
				</div>
			) : (
				<Loader />
			)}
		</>
	);
};

export default ModalFormCultivationPlanting;
