import { FC, useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import agrooLogo from '../assets/img/logo-light.png'
import tinyLogo from '../assets/img/logo.png'

interface ISidebar {
    user?: any,
    menuList?: any[],
    collapse?: boolean
}

export interface ISidebarMenu {
    "menu_id_int": number,
    "name_var": string,
    "url_var": string,
    "icon_var": string,
    children?: ISidebarMenu[]
}

const Sidebar: FC<ISidebar> = ({ user, menuList, collapse }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [menuActive, setMenuActive] = useState('')

    const localMenu = localStorage.getItem('pandawa-menu')
    const listMenu = menuList!.length > 0 ? menuList : localMenu? JSON.parse(localMenu) : []

    const location = useLocation()
    const navigate = useNavigate()

    const trigger = useRef<any>(null);
    const sidebar = useRef<any>(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: any) => {
            if (!sidebar.current || !trigger.current) return;
            if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
            setSidebarOpen(false);
            console.log('TARGET', target)
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: any) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });


    useEffect(() => {
        const arrPath = location.pathname.split('/')
        setMenuActive(arrPath[2])
    }, [location.pathname])

    const activePath = (path: string) => {
        if(location.pathname === '/dashboard'){
            if(path === '' || path === '/'){
                return true
            }
        }else if(location.pathname === '/dashboard/' + path){
            return true
        }else{
            return false
        }
    }

    const activeDrop = (path: string) => {
        const end = location.pathname.split('/')[2] || location.pathname
        if(end.includes(path.toLowerCase())) return true
        else return false
    }

    const handleShowSubMenu = (path: string) => {
        if(menuActive===path){
            setMenuActive('')
        }else if(menuActive?.toLowerCase().includes(path.toLowerCase())){
            setMenuActive('')
        }else{
            setMenuActive(path)
        }
    }

    const handleClickLogo = () => {
        navigate('/', {replace: true})
        window.location.reload()
    }

    const getRoutesPath = () => {
        return listMenu?.map(({name_var, url_var, icon_var, children}: ISidebarMenu, key: number) => 
            <div key={key} className='w-full px-3'>
                {children && children.length> 0?
                <div>
                    <div className={`${activeDrop(url_var) || menuActive===url_var? 'text-white': 'text-gray-400'} hover:text-white px-3 py-1.5 mb-1 rounded-lg flex justify-between items-center text-left transition duration-200 ease-in-out transform cursor-pointer relative`} onClick={()=>handleShowSubMenu(url_var)}>
                        {activeDrop(url_var) || menuActive===url_var? <span className="absolute -left-3 top-2.5 h-[55%] w-1 bg-white rounded-tr-full rounded-br-full"></span> : null}
                        <div className='flex item-center'>
                            <i className={`${icon_var} text-lg mr-3`}></i>
                            {/* <span className='fa-solid fa-circle-question text-lg mr-3'></span> */}
                            <p className="align-middle flex justify-center items-center">{name_var}</p>
                        </div>
                        {activeDrop(url_var) || menuActive===url_var? <i className="fa-solid fa-caret-down"></i> : <i className="fa-solid fa-caret-left"></i> }
                    </div>
                    <ul  className={`${menuActive?.includes(url_var)? 'block space-y-2 ml-6 transition duration-500' : 'hidden'}`}>
                    {children.map(({url_var, name_var, icon_var}: ISidebarMenu, key) => 
                        <li key={key}>
                            <Link to={url_var} onClick={() => setSidebarOpen(false)}
                                className={`${activePath(url_var)? 'bg-lightcayn text-white': 'text-gray-300'} hover:text-white hover:bg-lightcayn px-3 py-[5px] rounded-lg block transition duration-200`}>
                                <div className="flex items-center">
                                    <i className={`${icon_var} text-base mr-3`}></i>
                                    {/* <span className='fa-solid fa-circle-question text-lg mr-3'></span> */}
                                    <p>{name_var}</p>
                                </div>
                            </Link>
                        </li>
                    )}
                    </ul>
                </div>
                :
                <div  onClick={() => setSidebarOpen(false)}>
                    <Link to={url_var} className={`${activePath(url_var)? 'text-white': 'text-gray-400'} hover:text-white px-3 py-2 rounded-lg flex items-center text-left transition duration-200 ease-in-out transform cursor-pointer relative`}>
                        {activePath(url_var)? <span className="absolute -left-3 top-2.5 h-[55%] w-1 bg-white rounded-tr-full rounded-br-full"></span> : null}
                        <i className={`${icon_var} text-lg mr-3`}></i>
                        {/* <span className='fa-solid fa-circle-question text-lg mr-3'></span> */}
                        <p>{name_var}</p>
                    </Link>
                </div>
                }
            </div>
        )
    }

    const getRoutesPathUnCollapese = () => {
        return listMenu?.map(({url_var, icon_var, children}: ISidebarMenu, key: number) => 
            <div key={key} className='relative flex justify-center'>
                {children && children.length> 0?
                <div>
                    <div className={`cursor-pointer ${activeDrop(url_var) || menuActive===url_var? 'text-white scale-150' : 'text-slate-400'} hover:text-white mb-2.5 transition duration-300 ease-in-out transform hover:scale-150`} onMouseEnter={()=>handleShowSubMenu(url_var)} onClick={()=>handleShowSubMenu(url_var)}>
                        <i className={`${icon_var} text-lg`}></i>
                        {/* <span className='fa-solid fa-circle-question text-lg'></span> */}
                    </div>
                    <ul  className={`${menuActive===url_var? 'bg-darkcayn absolute top-0 left-16 2xl:left-20 z-50 pl-2 pr-5 w-max rounded-md transition duration-500 ease-in-out transform hover:scale-105' : 'hidden'}`}>
                    {children.map(({url_var, name_var, icon_var}, key) => 
                        <li key={key}>
                            <Link to={url_var} onClick={() => setSidebarOpen(false)}
                                className={`px-4 py-2.5 rounded-lg my-1 ${activePath(url_var)? 'text-white rounded -mr-3': 'text-slate-400'} hover:text-white block transition duration-150`}>
                                <div className="flex items-center">
                                    <i className={`${icon_var} text-base mr-3`}></i>
                                    {/* <span className='fa-solid fa-circle-question text-lg mr-3'></span> */}
                                    <p>{name_var}</p>
                                </div>
                            </Link>
                        </li>
                    )}
                    </ul>
                </div>
                :
                <Link to={url_var} onClick={() => setSidebarOpen(false)}
                    className={`${activePath(url_var)? 'text-white scale-150': 'text-slate-400'} mb-2.5 hover:text-white transition duration-500 ease-in-out transform hover:scale-150`}>
                    {/* <span className='fa-solid fa-circle-question text-lg'></span> */}
                    <i className={`${icon_var} text-lg`}></i>
                </Link>
                }
            </div>
        )
    }

    return (
        <div className='z-20 font-medium text-sms'>

            {/* SIDEBAR MOBILE */}
            <div className={`fixed inset-0 bg-darkcayn bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true" onClick={() => setSidebarOpen(false)}></div>
            <div className='lg:hidden bg-white sticky top-0 w-full h-12 py-8 px-4 sm:px-6 lg:px-8 flex items-center justify-between shadow z-30'>
                <button onClick={() => setSidebarOpen(!sidebarOpen)}
                    className='inline-flex items-center justify-center p-1 rounded-md text-darkcayn hover:bg-darkcayn hover:text-white outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkcayn'
                >
                    {sidebarOpen ?
                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        :
                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    }
                </button>
                <Header />
            </div>

            {/* SIDEBAR */}
            <div id="sidebar" ref={sidebar}
                className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto h-screen bg-darkcayn lg:translate-x-0 transform transition-all duration-700 ease-in-out
                    ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'} ${collapse ? 'w-64 2xl:w-72' : 'md::w-[4.4rem] 2xl:w-[5rem]'} `}
            >
                <div className={`overflow-y-auto overflow-x-hidden h-full flex flex-col items-center text-white text-left`}>
                    <div className='cursor-pointer z-10 w-full px-4 mb-7 mt-5' onClick={handleClickLogo}>
                        {collapse ?
                            <div className="flex items-center justify-center py-2">
                                <LazyLoadImage effect='blur' src={agrooLogo} className="w-[9.5rem]" alt="" />
                            </div>
                            :
                            <div className="flex justify-center items-center mt-2">
                                <LazyLoadImage effect='blur' src={tinyLogo} alt="" className="w-8" />
                            </div>
                        }
                    </div>

                    {collapse ?
                        <div className="w-full space-y-4">
                            {getRoutesPath()}
                        </div>
                        :
                        <div className='w-full flex flex-col absolute top-24 transition-all duration-1000 space-y-3'>
                            {getRoutesPathUnCollapese()}
                        </div>
                    }
                </div>

            </div>

        </div>

    )
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        collapse: state.collapse,
        menuList: state.menu_list
    }
}

export default connect(mapStateToProps, null)(Sidebar)