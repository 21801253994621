import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import TaskApi, { IRating } from '../../../api/task-api';
import { GlobalContext } from '../../../context/GlobalProvider';
import Loader from '../Loader';

type Props = {
    daySelected?: string,
    data?: IRating,
    onSubmit?: (data: IRating) => any,
    onCancel?: (e?: React.MouseEvent<HTMLElement>) => any
};

const Suggestion: React.FC<Props> = ({daySelected, data, onSubmit, onCancel}) => {
    const [loader, showLoader] = useState(false)

    const { ownerTask, userStorage, setIsDataChanged } = useContext(GlobalContext)

    const handleSubmitForm = async (e: React.ChangeEvent<HTMLFormElement>) => {
        showLoader(true)
        e.preventDefault()

        const { suggest_txt, rating_txt } = e.target

        const payload = {
            suggest_txt: suggest_txt.value,
            rating_txt: rating_txt.value,
            time_calender_dte: daySelected
        } as IRating

        payload['user_id_int'] = ownerTask.user_id_int
        payload['land_code_var'] = ownerTask.land_code_var
        payload['period_plant_txt'] = ownerTask.period_plant_txt
        
        let res = null
        if(!data){
            payload['created_by_var'] = userStorage.fullname_var
            res = await TaskApi.addRating(payload)
        }else{
            payload['updated_by_var'] = userStorage.fullname_var
            res = await TaskApi.updateRating(data.id_seq, payload)
        }
        
        console.log('Rating :', res)
        showLoader(false)
        if(res.data && res.data.code === 0){
            toast.success(res.data.message)
            setIsDataChanged(true)
            e.target.reset()
            if(onSubmit) onSubmit(payload)
            if(onCancel) onCancel()
        }else{
            toast.error(res.data?.message || res.message)
        }
    }

    return (
        <>
            <form className='mt-5 md:px-4' onSubmit={handleSubmitForm} >
                <input name='id_seq' className='hidden' />
                <div className='space-y-3'>
                    <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                        <div className="w-full">
                            <label htmlFor="note" className="label-form">Saran</label>
                            <textarea name='suggest_txt' id='note' className='input-task' placeholder='Tuliskan saran untuk petani...' rows={3} defaultValue={data?.suggest_txt} />
                        </div>
                    </div>
                    <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                        <div className="w-full md:w-1/2">
                            <label htmlFor="rating" className="label-form">Nilai untuk petani</label>
                            <select id="rating" name='rating_txt' className="input-task" defaultValue={data?.rating_txt} required>
                                <option value=''>- Pilih nilai petani -</option>
                                <option value='Dilakukan'>Dilakukan</option>
                                <option value='Tidak Dilakukan'>Tidak Dilakukan</option>
                            </select>
                        </div>
                    </div>
                </div>
                <footer className='flex justify-end mt-7 space-x-2'>
                    <button type='reset' className='text-gray-700 px-6 py-2.5 rounded-lg text-sm' onClick={onCancel}>Batal</button>
                    <button type='submit' className='bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-lg text-sm'>Simpan</button>
                </footer>
            </form>

            {loader && <Loader />}
        </>
    );
}

export default Suggestion;