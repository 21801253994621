import axios from "axios";
import { baseURL } from "./common-api";

export interface ILogActivities {
	id_seq?: number;
	user_id_int: number;
	land_code_var?: string;
	fullname_var?: string;
	project_code_var: string;
	project_name_var: string;
	status_int?: number;
	location?: string;
	created_by_var?: string;
	created_on_dtm?: string;
	updated_by_var?: string;
	updated_on_dtm?: string;
	time_calender_dte?: string;
	jumlah_txt?: string;
	satuan_txt?: string;
	period_plant_txt?: string;

}

export interface IDetailLogActivities {
	id_seq?: number;
	time_calender_dte?: string;
	user_id_int: number;
	land_code_var?: string;
	period_plant_txt?: string;
	jumlah_txt?: string;
	satuan_txt?: string;
	leaf_condition_txt?: string;
	land_condition_txt?: string;
	watering_condition_txt?: string;
	puppies_condition_txt?: string;
	grain_condition_txt?: string;
	hama_txt?: string;
	document_txt?: any;
	example_observation_txt?: string;
	params_values?: any;
	observation_value?: any;
	created_by_var?: string;
	updated_by_var?: string;
}

export const getAllListFA = async () => {
	try {
		const data = await axios.get(baseURL + "/api/log-activity/list-fa/get-all", {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getDetailTaksFA = async (userId) => {
	try {
		const data = await axios.get(baseURL + `/api/log-activity/list-fa/get-detail?user_id_int=${userId}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

//#endregion
