import { useCallback, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";

import TableUser from "../../components/tables/Table";
import { ButtonAdd } from "../../components/button/CustomButton";
import { DropdownLand } from "../../components/dropdown/SimpleDropdown";

import Loader from "../../components/modal/Loader";
import ModalFormLandManagement from "../../components/modal/ModalFormLandManagement";
import {
	createLandCommodity,
	disableLandCommodity,
	getAllLandCommodity,
	updateLandCommodity,
} from "../../api/landarea-api";
import { toast } from "react-toastify";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import SearchField from "../../components/SearchField";
import { ActionType } from "../../reduxs/action/actions";
import { GlobalContext } from "../../context/GlobalProvider";
import { getPayloadRecentActivity } from "../../helpers/helper";
import FarmerGroupApi from "../../api/farmergroup-api";

dayjs.extend(isBetween);

const TITLE_BAR = "Project Management / Land";

const LahanManagement = ({ user }) => {
	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [dataLahan, setDataLahan] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [isUpdate, setIsUpdate] = useState(false);
	const [selectedData, setSelectedData] = useState(null);
	const [farmerGroupList, setFarmerGroupList] = useState([]);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

	async function fetchFarmerGroup() {
		const res = await FarmerGroupApi.getAll();
		console.log("fetching farmer group :", res);

		if (res.data) {
			if (res.data.code === 0) {
				setFarmerGroupList(res.data.data);
			}
		}
	}

	useEffect(() => {
		fetchFarmerGroup();
		const payload = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	const fetchData = useCallback(async () => {
		showLoader(true);

		const res = await getAllLandCommodity();

		console.log("Get Lahan :", res);
		resetForm();
		if (res.data) {
			if (res.data.code === 0) {
				setDataLahan(res.data.data);
				setFilterData(res.data.data);
			} else if (res.data.code === 99) {
				showLoader(true);
				navigate("/auth", { replace: true });
			} else {
				toast.error(res.data.message);
			}
		} else {
			alert(`${res.config?.url} ${res.message}`);
		}
	}, [navigate]);

	useEffect(() => {
		fetchData();
	}, [fetchData, dispatch]);

	const handleEdit = (selectedData) => {
		setSelectedData(selectedData);
		setIsUpdate(true);
		showModalForm(true);
	};

	const handleReceiveDataForm = async (payload) => {
		showLoader(true);

		let res = null;
		if (!isUpdate) {
			payload.created_by_var = user.fullname_var;
			res = await createLandCommodity(payload);

			var payloadRecantActivity = getPayloadRecentActivity(
				1,
				"Lahan",
				`[${payload.land_code_var}] ${payload.land_name_var}`,
				user.fullname_var,
				pathname
			);
		} else {
			payload.updated_by_var = user.fullname_var;
			res = await updateLandCommodity(selectedData.land_code_var, payload);

			payloadRecantActivity = getPayloadRecentActivity(
				2,
				"Lahan",
				`[${payload.land_code_var}] ${payload.land_name_var}`,
				user.fullname_var,
				pathname
			);
		}

		console.log("Create/Update Lahan :", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				dispatchCalActivity({ type: "push", payload: payloadRecantActivity });

				fetchData();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(`${res.config?.url} ${res.message}`);
		}
	};

	const handleDeleteData = async (id_seq) => {
		showLoader(true);

		const res = await disableLandCommodity(id_seq);

		console.log("Delete Lahan :", res);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				const payload = getPayloadRecentActivity(
					3,
					"Lahan",
					id_seq,
					user.fullname_var,
					pathname
				);
				dispatchCalActivity({ type: "push", payload });

				fetchData();
			} else if (res.data.code === 99) {
				navigate("/auth", { replace: true });
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(`${res.config?.url} ${res.message}`);
		}
	};

	const resetForm = () => {
		showLoader(false);
		setIsUpdate(false);
		showModalForm(false);
		setSelectedData(null);
	};

	const handleSearch = (event) => {
		event.preventDefault();

		const newData = [...dataLahan];
		if (event.target.value) {
			const filtered = newData.filter((item) => {
				return (
					item.land_code_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.land_name_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.commodity_name_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.area_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.land_code_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.dis_name
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.city_name
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.prov_name
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.fullname_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.name_field_assist_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase())
				);
			});

			setFilterData(filtered);
		} else {
			setFilterData(dataLahan);
		}
	};

	const columns = [
		{
			Header: () => <span className="py-4 pl-2">No</span>,
			accessor: "no",
			Cell: ({ row }) => <div className="pl-2 text-left">{row.index + 1}.</div>,
		},
		{
			Header: "Lahan",
			accessor: "land_code_var",
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<span>
						[{value}] {data?.land_name_var}
					</span>
				);
			},
		},
		{
			Header: "Project",
			accessor: "project_code_var",
		},
		{
			Header: "Luas(Ha)",
			Footer: "Luas(Ha)",
			accessor: "area_var",
			Cell: ({ value }) => <span>{parseFloat(value).toFixed(2)}</span>,
		},
		{
			Header: "Luas Kordinat Lahan(Ha)",
			Footer: "Luas Kordinat Lahan(Ha)",
			accessor: "coordinates_area_var",
			Cell: ({ value }) => parseInt(value) > -1 ? (
				<span>{parseFloat(value).toFixed(2)}</span>
			) : (
				<span> - </span>
			)
		},
		// {
		//     Header: 'Kecamatan',
		//     accessor: 'dis_name'
		// },
		// {
		//     Header: 'Kabupaten',
		//     accessor: 'city_name'
		// },
		{
			Header: "Alamat",
			accessor: "dis_name",
			Cell: ({ value, row }) => {
				const data = row.original;
				return `${value}, ${data.city_name}, ${data.prov_name}`;
			},
		},
		{
			Header: "Petani",
			accessor: "fullname_var",
		},
		{
			Header: "FarmerGroup",
			accessor: "farmer_group_id_int",
			Cell: ({ value }) => {
				return (
					farmerGroupList.find(
						(item) => parseInt(item.id_seq) === parseInt(value)
					)?.farmer_group_name_var || ""
				);
			},
		},
		{
			Header: "Pendamping",
			accessor: "name_field_assist_var",
		},
		{
			Header: "Status",
			Footer: "Status",
			accessor: "status_int",
			Cell: ({ value }) =>
				parseInt(value) === 1 ? (
					<div className="float-left px-2 py-1 font-medium text-center text-green-800 bg-green-100 rounded-lg">
						Active
					</div>
				) : (
					<div className="float-left px-2 py-1 font-medium text-center text-red-800 bg-red-100 rounded-lg">
						Inactive
					</div>
				),
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }) => {
				const data = row.original;
				if (user?.user_group_id_int === 1 || user?.user_group_id_int === 2) {
					return (
						<DropdownLand
							data={data}
							onEdit={() => handleEdit(data)}
							onDelete={() => handleDeleteData(data.land_code_var)}
						/>
					);
				} else {
					return <></>;
				}
			},
		},
	];

	return (
		<div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
			<div className="flex flex-col items-center justify-between mb-6 md:flex-row 2xl:mb-8">
				<div className="flex items-center w-full px-5 py-8 mb-5 bg-white shadow-xl md:w-4/12 md:mb-0 md:mr-6 justify-evenly rounded-2xl">
					<div className="flex items-center justify-center text-2xl text-green-900 rounded-lg bg-green-50 w-14 h-14">
						<i className="fa-solid fa-list-ul"></i>
					</div>
					<div className="flex flex-col">
						<h1 className="mb-1 text-3xl font-semibold">{dataLahan?.length}</h1>
						<p>Total Lahan</p>
					</div>
				</div>
				<div className="flex items-center w-full px-5 py-8 mb-5 bg-white shadow-xl md:w-4/12 md:mb-0 md:mr-6 justify-evenly rounded-2xl">
					<div className="flex items-center justify-center text-2xl text-green-900 rounded-lg bg-green-50 w-14 h-14">
						<i className="fa-solid fa-road-circle-check"></i>
					</div>
					<div className="flex flex-col">
						<h1 className="mb-1 text-3xl font-semibold">
							{dataLahan?.filter((data) => data.status_int === 1).length}
						</h1>
						<p>Lahan Aktif</p>
					</div>
				</div>
				<div className="flex items-center w-full px-5 py-8 mb-5 bg-white shadow-xl md:w-4/12 md:mb-0 md:mr-6 justify-evenly rounded-2xl">
					<div className="flex items-center justify-center text-2xl text-green-900 rounded-lg bg-green-50 w-14 h-14">
						<i className="fa-solid fa-rectangle-xmark"></i>
					</div>
					<div className="flex flex-col">
						<h1 className="mb-1 text-3xl font-semibold">
							{dataLahan?.filter((data) => data.status_int !== 1).length}
						</h1>
						<p>Lahan Tidak Aktif</p>
					</div>
				</div>
				<div className="flex items-center w-full px-5 py-8 bg-white shadow-xl md:w-4/12 justify-evenly rounded-2xl">
					<div className="flex items-center justify-center text-2xl text-green-900 rounded-lg bg-green-50 w-14 h-14">
						<i className="fa-solid fa-boxes-stacked"></i>
					</div>
					<div className="flex flex-col">
						<h1 className="mb-1 text-3xl font-semibold">
							{
								dataLahan?.filter((data) =>
									dayjs(data.created_on_dtm).isBetween(
										dayjs().subtract(7, "days"),
										dayjs()
									)
								).length
							}
						</h1>
						<p>Lahan Baru</p>
					</div>
				</div>
			</div>

			<div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
				<div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
					<h1 className="text-xl font-bold uppercase">List Lahan</h1>
					<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
						<SearchField
							onChange={handleSearch}
							placeholder="Cari data lahan..."
						/>
						{user?.user_group_id_int === 1 || user?.user_group_id_int === 2 ? (
							<ButtonAdd onClick={() => showModalForm(true)} />
						) : null}
					</div>
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{loader && <Loader />}
			{modalForm && (
				<ModalFormLandManagement
					data={selectedData}
					farmerGroupList={farmerGroupList}
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps, null)(LahanManagement);
