import React from 'react';
import { ITaskReferenceDetail } from '../../../../api/taskreference-api';

type Props = {
    data?: ITaskReferenceDetail,
    onSubmit: (data: ITaskReferenceDetail) => any,
    onCancel?: (e?: React.MouseEvent<HTMLElement>) => any
};

const ModalFormTaskReferenceDetail: React.FC<Props> = ({ data, onSubmit, onCancel }) => {

    const handleSubmitTask = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        const { activity_txt, material_txt, dose_txt, time_txt } = e.target

        const payload = {
            activity_txt: activity_txt.value,
            material_txt: material_txt.value,
            dose_txt: dose_txt.value,
            time_txt: time_txt.value
        } as ITaskReferenceDetail

        onSubmit(payload)
    }

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                        <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                            <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                            <h3>{data ? 'Ubah Task Reference' : 'Tambah Task Reference'}</h3>
                        </div>
                        <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    <form className='px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8' onSubmit={handleSubmitTask} >
                        <div className="w-full">
                            <label htmlFor="activity_txt" className="label-form">Aktivitas</label>
                            <input name='activity_txt' id="activity_txt" className="input-task" defaultValue={data?.activity_txt || ''} placeholder="Penebaran benih + penutupan dengan jerami + Micoriza" required />
                        </div>
                        <div className='grid gap-5 grid-cols-1 md:grid-cols-2 mt-5'>
                            <div className="w-full">
                                <label htmlFor="material_txt" className="label-form">Bahan</label>
                                <input name='material_txt' id="material_txt" className="input-task" defaultValue={data?.material_txt || ''} placeholder="Benih POKOK" />
                            </div>
                            <div className="w-full">
                                <label htmlFor="dose_txt" className="label-form">Dosis</label>
                                <input name='dose_txt' id="dose_txt" className="input-task" defaultValue={data?.dose_txt || ''} placeholder="35 kg benih" />
                            </div>
                            <div className="w-full">
                                <label htmlFor="time_txt" className="label-form">Waktu (ST / HST)</label>
                                <input type='text' name='time_txt' id="time_txt" className="input-task" defaultValue={data?.time_txt || ''} placeholder="18 HST" />
                            </div>
                        </div>

                        <footer className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                            <button type='submit' className="btn-primary">Simpan</button>
                            <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                        </footer>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ModalFormTaskReferenceDetail;