import { useEffect, useState } from 'react';
import { dropdownFarmer, dropdownFarmerbyFA, getAllUser } from '../api/user-api';

export interface IUser {
	id_seq: number,
	fullname_var: string,
	user_group_id_int: number,
	farmer_group_id_int: number
}

export default function useDropdownFarmer() {
	const [dataFarmer, setDataFarmer] = useState<IUser[]>([])
	const [loadingFarmer, setLoading] = useState(false)
	const [errorFarmer, setError] = useState(null)

	useEffect(() => {
		async function fetchDropdownFarmer() {
			setLoading(true)
			const res = await dropdownFarmer()

			console.log("Fetching DropdownFarmer :", res)
			if (res.data) {
				if (res.data.code === 0) {
					setDataFarmer(res.data.data)
				} else {
					setError(res.data.message)
				}
			} else {
				setError(res.message)
			}
			setLoading(false)
		}

		fetchDropdownFarmer()
	}, [])

	return { dataFarmer, errorFarmer, loadingFarmer }
}

export function useDropdownFarmerbyFA(id_seq: number) {
	const [dataFarmer, setDataFarmer] = useState<IUser[]>([])
	const [loadingFarmer, setLoading] = useState(false)
	const [errorFarmer, setError] = useState(null)

	useEffect(() => {
		async function fetchDropdownFarmer() {
			setLoading(true)

			let res
			if (id_seq === 0 || id_seq === null || id_seq === undefined) {
				res = await dropdownFarmer()
			} else {
				res = await dropdownFarmerbyFA(id_seq)
			}

			console.log("Fetching DropdownFarmer :", res)
			if (res.data) {
				if (res.data.code === 0) {
					setDataFarmer(res.data.data)
				} else {
					setError(res.data.message)
				}
			} else {
				setError(res.message)
			}
			setLoading(false)
		}

		fetchDropdownFarmer()
	}, [id_seq])


	return { dataFarmer, errorFarmer, loadingFarmer }
}


/**
 * 
 * @param userGroupID { 1: SuperAdmin, 2: Admin PT, 3: Analis, 4: Farmer, 5: Field Asistant }
 * @returns 
 */
export function useDropdownUserByGroupID(userGroupID: number) {
	const [dataUser, setDataUser] = useState<IUser[]>([])
	const [loadingUser, setLoading] = useState(true)
	const [errorUser, setError] = useState(null)

	useEffect(() => {
		async function fetchData() {
			setLoading(true)
			const res = await getAllUser(localStorage.getItem('pandawa-token'))

			console.log("Fetching UserByGroupID :", res)
			if (res.data) {
				if (res.data.code === 0) {
					setDataUser(res.data.data.filter((user: IUser) => user.user_group_id_int === userGroupID))
				} else {
					setError(res.data.message)
				}
			} else {
				setError(res.message)
			}
			setLoading(false)
		}

		fetchData()
	}, [userGroupID])

	return { dataUser, errorUser, loadingUser }
}