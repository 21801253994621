import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
	IActivity,
	IDocumentation,
	IObservartion,
	IRating,
} from "../api/task-api";

export interface ITask {
	activity?: IActivity[];
	observation?: IObservartion[];
	documentation?: IDocumentation[];
	rating?: IRating[];
	cultivation?: [];
}

type Props = {
	day: any;
	monthIndex: number;
	onClickDate: (
		day: any,
		act: IActivity[],
		obser: IObservartion[],
		doc: IDocumentation[],
		rate: IRating[],
	) => any;
	onViewTask: (
		day: any,
		act: IActivity[],
		obser: IObservartion[],
		doc: IDocumentation[],
		rate: IRating[],
	) => any;
	tasks: ITask;
};

const getColorClasses = (task: any, dayObser: any): string => {
	// console.log('task ', task);
	
	const isDid = dayObser.length > 0; // check observation length to init has isdid or not
	const today = new Date();
	const taskDte = new Date(task.time_calender_dte);
	let colorClass;
	if (taskDte < today) {
		if (isDid) {
			colorClass = "bg-yellow-500";
		} else {
			colorClass = "bg-red-500";
		}
		if (task.status_int === 20) {
			colorClass = "bg-green-500";
		}
	} else if (taskDte > today) {
		colorClass = "bg-black";
	}
	return colorClass;
};

const DayInCalendar: React.FC<Props> = ({
	day,
	monthIndex,
	onClickDate,
	onViewTask,
	tasks,
}) => {
	const [dayActivities, setDayActivities] = useState<IActivity[]>([]);
	const [dayObser, setDayObser] = useState<IObservartion[]>([]);
	const [dayDocs, setDayDocs] = useState<IDocumentation[]>([]);
	const [dayRate, setDayRate] = useState<IRating[]>([]);

	const { activity, observation, documentation, rating } = tasks;

	useEffect(() => {
		if (activity?.length! > 0) {
			const taskInADay = activity?.filter(
				(task) =>
					dayjs(task.time_calender_dte).format("DD-MM-YY") ===
					day.format("DD-MM-YY"),
			);
			setDayActivities(taskInADay!);
		}
	}, [activity, day]);

	useEffect(() => {
		if (observation?.length! > 0) {
			const taskInADay = observation?.filter(
				(task) =>
					dayjs(task.time_calender_dte).format("DD-MM-YY") ===
					day.format("DD-MM-YY"),
			);
			setDayObser(taskInADay!);
		}
	}, [observation, day]);

	// console.log('obser ', dayObser);


	useEffect(() => {
		if (documentation?.length! > 0) {
			const taskInADay = documentation?.filter(
				(task) =>
					dayjs(task.time_calender_dte).format("DD-MM-YY") ===
					day.format("DD-MM-YY"),
			);
			setDayDocs(taskInADay!);
		}
	}, [documentation, day]);

	useEffect(() => {
		if (rating?.length! > 0) {
			const taskInADay = rating?.filter(
				(task) =>
					dayjs(task.time_calender_dte).format("DD-MM-YY") ===
					day.format("DD-MM-YY"),
			);
			setDayRate(taskInADay!);
		}
	}, [rating, day]);

	const getDayClass = (day: any) => {
		if (day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")) {
			// Current day
			return "text-blue-600";
		} else if (
			day.format("MM-YY") !== dayjs().month(monthIndex).format("MM-YY")
		) {
			// The day not in real current month
			return "text-gray-300";
		} else if (dayjs(day).format("dddd").toLowerCase() === "minggu") {
			// Sunday
			return "text-red-400";
		} else {
			return "";
		}
	};

	return (
		<>
			<div
				className={`relative border border-slate-200 flex flex-col items-center ${day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
					? "bg-blue-100"
					: ""
					}`}>
				<div
					className="absolute h-full w-full z-0"
					onClick={() =>
						onClickDate(day, dayActivities, dayObser, dayDocs, dayRate)
					}></div>
				<header className="flex flex-col items-center w-full z-10">
					<p className={`text-sm p-1 font-medium my-1 ${getDayClass(day)}`}>
						{dayjs(day).format("DD")}
					</p>
				</header>
				<div
					className="flex flex-1 flex-col w-full cursor-pointer mb-5 z-10"
					onClick={() =>
						dayActivities.length === 0 && dayObser.length <= 0 &&
						onClickDate(day, dayActivities, dayObser, dayDocs, dayRate)
					}>
					{dayActivities.slice(0, 1).map((task, key) => (
						<span
							key={key}
							className={`${getColorClasses(
								task,
								dayObser,
							)} p-[3px] px-1 2xl:p-1 ml-1 mr-2 mb-1 text-white font-medium rounded truncate hover:bg-opacity-70 text-xsm 2xl:text-xs`}
							onClick={() =>
								onViewTask(day, dayActivities, dayObser, dayDocs, dayRate)
							}>
							{task.activity_txt}
						</span>
					))}

					{dayObser.length > 0 && dayActivities.length === 0 && (
						<span
							className={`bg-blue-500 p-[3px] px-1 2xl:p-1 ml-1 mr-2 mb-1 text-white font-medium rounded truncate hover:bg-opacity-70 text-xsm 2xl:text-xs`}
							onClick={() =>
								onViewTask(day, dayActivities, dayObser, dayDocs, dayRate)
							}>
							+1 Pengamatan
						</span>
					)}

					{dayActivities.length > 1 && (
						dayActivities.slice(0, 1).map((task, key) => (
							<span
								key={key}
								className={`${getColorClasses(
									task,
									dayObser,
								)} p-[3px] px-1 2xl:p-1 ml-1 mr-2 mb-1 text-white font-medium rounded truncate hover:bg-opacity-70 text-xsm 2xl:text-xs`}
								onClick={() =>
									onViewTask(day, dayActivities, dayObser, dayDocs, dayRate)
								}>
								+{dayActivities.length - 1} Aktivitas Lainnya..
							</span>
						))
					)}
				</div>

				{dayObser.length < 0 || dayActivities.length > 0 && (
					<p
						className="text-center text-slate-400 font-medium mt-5 z-10"
						onClick={() =>
							onClickDate(day, dayActivities, dayObser, dayDocs, dayRate)
						}>
						{dayActivities[0]?.time_txt}
					</p>
				)}
			</div>
		</>
	);
};

export default DayInCalendar;
