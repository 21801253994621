import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { IDetailLogActivities } from "@api/logactivity-api";
import { getAllProjectforModal, IProjectManagement } from "@api/project-api";
import TableUser from "@components/tables/Table";

type Props = {
    data: IDetailLogActivities;
    onClose: (e: React.MouseEvent<HTMLElement>) => void;
    commodity_id: any;
};

const ModalListProject: React.FC<Props> = ({ commodity_id, data,  onClose }) => {
    // hooks
    const {
        formState: { errors },
        setValue,
    } = useForm<any>({ criteriaMode: "all" });

    // states
    const [dataListProject, setDataListProject] = useState<IProjectManagement[]>([])

    const fetchProject = async () => {
        console.log('comx ', commodity_id);

        let res
        if (commodity_id !== 0) {
            res = await getAllProjectforModal(commodity_id, 0);
        } else {
            res = await getAllProjectforModal(0, 0);
        }
        setDataListProject(res.data.data)
    }

    // effects
    const setdefaultValue = useCallback(
        (obj) => {
            Object.keys(obj).map((key) => {
                return setValue(key, obj[key], { shouldValidate: true });
            });
        },
        [setValue],
    );
    useEffect(() => {
        // console.log('comx ', commodity_id);

        fetchProject();
        if (data) {
            setdefaultValue(data);
        }
    }, [data, setdefaultValue]);

    const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="text-left pl-4">{row.index + 1}.</div>
			),
		},
		{
			Header: "Nama Projek",
			accessor: "project_name_var",
		},
		{
			Header: "Lokasi",
			accessor: "location",
		},
		{
			Header: "Awal Periode Tanam",
			accessor: "start_periode_tanam",
		},
		{
			Header: "Total Periode Tanam",
			accessor: "total_periode_tanam",
		},
	];


    return (
        <>

            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                <div
                    className="modal-form-content bg-white p-5 w-[95%] md:w-6/12 2xl:w-5/12"
                    style={{ maxHeight: "90vh" }}>
                    <div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
                        <div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
                            <h3>Project List</h3>
                        </div>
                        <button
                            type="button"
                            className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onClose}>
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>

                    <TableUser dataTable={dataListProject} columnTable={columns} />

                </div>
            </div>

        </>
    );

};



export default ModalListProject;
