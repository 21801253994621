import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { IRecentSearch } from "../../context/ContextInterface";
import { GlobalContext } from "../../context/GlobalProvider";
import { ISidebarMenu } from "../Sidebar";

function SearchModal() {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState<ISidebarMenu[] | ISidebarMenu[][]>([]);

  const trigger = useRef<any>(null);
  const searchContent = useRef<any>(null);

  const { recentPages, recentSearches, dispatchCalSearch } = useContext(GlobalContext);
  const menuList = localStorage.getItem("pandawa-menu") ? JSON.parse(localStorage.getItem("pandawa-menu") || "") : [];

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: any) => {
      if (!searchOpen || searchContent?.current?.contains(target) || trigger?.current?.contains(target)) return;
      setSearchOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (!searchOpen || keyCode !== 27) return;
      setSearchOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    const res3 = menuList
      .map((parent: any) => parent.children)
      .flat()
      .filter((item: any) => item.name_var.toLowerCase().includes(e.target.value.toLowerCase()));

    setSearchList(res3);
  };

  const handleClickSearchList = (item: ISidebarMenu) => {
    const payload: IRecentSearch = {
      id: new Date().getTime(),
      search_text: searchText,
      title: item.name_var,
      path: "/dashboard/" + item.url_var,
      url: window.location.href,
    };

    dispatchCalSearch({ type: "push", payload });

    setSearchOpen(false);
    setSearchText("");
  };

  return (
    <div>
      {/* Button */}
      <button
        ref={trigger}
        aria-controls="search-modal"
        className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ml-3 ${searchOpen && "bg-gray-200"}`}
        onClick={() => setSearchOpen(!searchOpen)}
      >
        <span className="sr-only">Search</span>
        <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path className="text-gray-500 fill-current" d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
          <path className="text-gray-400 fill-current" d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
        </svg>
      </button>

      {/* Modal backdrop */}
      {searchOpen && <div className="fixed inset-0 z-50 transition-opacity bg-gray-900 bg-opacity-30" />}

      {/* Modal dialog */}
      {searchOpen && (
        <div id="search-modal" role="dialog" aria-modal="true" className="fixed inset-0 z-50 flex items-start justify-center px-4 mb-4 overflow-hidden transform top-20 sm:px-6">
          <div className="bg-white overflow-auto max-w-2xl w-full max-h-full rounded-2xl shadow-lg py-1 md:py-1.5" ref={searchContent}>
            {/* Search form */}
            <form className="border-b border-gray-200">
              <div className="relative">
                <label htmlFor="modal-search" className="sr-only">
                  Search
                </label>
                <input
                  id="modal-search"
                  className="w-full py-3 pl-10 pr-4 placeholder-gray-400 border-0 outline-none appearance-none focus:ring-transparent"
                  type="search"
                  autoComplete="off"
                  placeholder="Search Anything…"
                  onChange={handleChange}
                  value={searchText}
                />
                <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                  <svg className="flex-shrink-0 w-4 h-4 ml-4 mr-2 text-gray-400 fill-current group-hover:text-gray-500" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                    <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                  </svg>
                </button>
              </div>
            </form>

            <div className="px-2 py-4" onFocus={() => setSearchOpen(true)} onBlur={() => setSearchOpen(false)}>
              {/* Recent searches */}
              {searchText ? (
                <div className="mb-3 last:mb-0">
                  <div>
                    <div className="flex items-center p-2 text-gray-800 rounded group">
                      <svg className="flex-shrink-0 w-4 h-4 mr-3 text-gray-400 fill-current" viewBox="0 0 16 16">
                        <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                      </svg>
                      <span>{searchText}</span>
                    </div>
                  </div>
                  <ul className="text-sm">
                    {searchList.map((item: any, key: number) => (
                      <li key={key}>
                        <Link
                          to={"/dashboard/" + item.url_var}
                          className="flex items-center p-2 text-gray-800 rounded hover:text-white hover:bg-indigo-500 group"
                          onClick={() => {
                            handleClickSearchList(item);
                          }}
                        >
                          <svg className="flex-shrink-0 w-4 h-4 mr-3 text-gray-400 fill-current group-hover:text-white group-hover:text-opacity-50" viewBox="0 0 16 16">
                            <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                          </svg>
                          <span>{item.name_var} - </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="mb-3 last:mb-0">
                  <div className="px-2 mb-2 text-xs font-semibold text-gray-400 uppercase md:text-sm">Recent searches</div>
                  <ul className="text-sm">
                    {recentSearches
                      .sort((a, b) => b.id - a.id)
                      .map((item, key) => (
                        <li key={key}>
                          <Link
                            to={item.url}
                            className="flex items-center p-2 text-gray-800 rounded hover:text-white hover:bg-indigo-500 group"
                            onClick={() => {
                              setSearchOpen(!searchOpen);
                            }}
                          >
                            <svg className="flex-shrink-0 w-4 h-4 mr-3 text-gray-400 fill-current group-hover:text-white group-hover:text-opacity-50" viewBox="0 0 16 16">
                              <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                            </svg>
                            <span>
                              {item.search_text} - {item.title}{" "}
                            </span>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              {/* Recent pages */}
              <div className="mb-3 last:mb-0">
                <div className="px-2 mb-2 text-xs font-semibold text-gray-400 uppercase">Recent pages</div>
                <ul className="text-sm">
                  {recentPages.sort((a, b) => b.id - a.id).map((item, key) => (
                    <li key={key}>
                      <Link className="flex items-center p-2 text-gray-800 rounded hover:text-white hover:bg-indigo-500 group" to={item.path} onClick={() => setSearchOpen(!searchOpen)}>
                        <svg className="flex-shrink-0 w-4 h-4 mr-3 text-gray-400 fill-current group-hover:text-white group-hover:text-opacity-50" viewBox="0 0 16 16">
                          <path d="M14 0H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h8l5-5V1c0-.6-.4-1-1-1zM3 2h10v8H9v4H3V2z" />
                        </svg>
                        <span className="text-xs md:text-sm">
                          <span className="text-sm font-medium text-gray-800 group-hover:text-white">{item.title.split("/")[item.title.split("/").length - 1]}</span> - {item.path}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchModal;
