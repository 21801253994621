import React from "react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { IProjectManagement } from "../../api/project-api";

type Props = {
  data: IProjectManagement;
  onCancel: (e: React.MouseEvent) => any;
};

const ModalDetailProject: React.FC<Props> = ({ data, onCancel }) => {
  // const { dataUser, loadingUser } = useDropdownUserByGroupID(2)

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<any>({ criteriaMode: "all" });

  const setdefaultValue = useCallback(
    (obj) => {
      Object.keys(obj).map((key) => {
        return setValue(key, obj[key], { shouldValidate: true });
      });
    },
    [setValue],
  );

  useEffect(() => {
    if (data) setdefaultValue(data);
  }, [data, setdefaultValue]);

  return (
    <>
      <div className="modal-form">
        <div className="modal-form-outside" onClick={onCancel}></div>

        <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
          <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
              <i className="fa-solid fa-building flex justify-center items-center"></i>
              <h3>Detail Projek</h3>
            </div>
            <button
              type="button"
              className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
              onClick={onCancel}>
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
            </button>
          </div>

          <form className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
            <div className="space-y-5">
              <div className="grid gap-5 grid-cols-1 md:grid-cols-2">
                <div className="w-full">
                  <label htmlFor="code" className="label-form">
                    Kode Projek
                  </label>
                  <input
                    id="code"
                    type="text"
                    className="input-form"
                    disabled
                    {...register("project_code_var", {
                      required: "Project code is required.",
                      minLength: {
                        value: 2,
                        message: "Project code length at least 2 characters",
                      },
                    })}
                  />
                  <ErrorField errors={errors} name="project_code_var" />
                </div>
                <div className="w-full">
                  <label htmlFor="name" className="label-form">
                    Nama Projek
                  </label>
                  <input
                    id="name"
                    type="text"
                    disabled
                    className="input-form"
                    {...register("project_name_var", {
                      required: "Project Name is required.",
                    })}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="location" className="label-form">
                    Lokasi Projek
                  </label>
                  <input
                    id="location"
                    type="text"
                    className="input-form"
                    disabled
                    {...register("location")}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="count_commodity" className="label-form">
                    Jumlah Komoditas
                  </label>
                  <input
                    id="count_commodity"
                    type="text"
                    className="input-form"
                    disabled
                    {...register("count_commodity")}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="count_variety" className="label-form">
                    Jumlah Varietas
                  </label>
                  <input
                    id="count_variety"
                    type="text"
                    className="input-form"
                    disabled
                    {...register("count_variety")}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="count_user" className="label-form">
                    Jumlah Pegawai
                  </label>
                  <input
                    id="count_user"
                    type="text"
                    className="input-form"
                    disabled
                    {...register("count_user")}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="count_admin" className="label-form">
                    Jumlah Admin
                  </label>
                  <input
                    id="count_admin"
                    type="text"
                    className="input-form"
                    placeholder="0"
                    disabled
                    {...register("count_admin")}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="count_farmer" className="label-form">
                    Jumlah Petani
                  </label>
                  <input
                    id="count_farmer"
                    type="text"
                    className="input-form"
                    placeholder="0"
                    disabled
                    {...register("count_farmer")}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="count_fa" className="label-form">
                    Jumlah FA
                  </label>
                  <input
                    id="count_fa"
                    type="text"
                    className="input-form"
                    placeholder="0"
                    disabled
                    {...register("count_fa")}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="start_planting_dtm" className="label-form">
                    Mulai Tanam
                  </label>
                  <input
                    id="start_planting_dtm"
                    type="text"
                    className="input-form"
                    disabled
                    {...register("start_planting_dtm")}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="status_int" className="label-form">
                    Status
                  </label>
                  <select
                    id="status_int"
                    className="input-form"
                    disabled
                    {...register("status_int", {
                      required: "This field is required",
                    })}>
                    <option value="">- Pilih status -</option>
                    <option key={`active`} value={1}>
                      Active
                    </option>
                    <option key={`inactive`} value={0}>
                      Inactive
                    </option>
                  </select>
                  <ErrorField errors={errors} name="status_int" />
                </div>
              </div>
            </div>

            {/* <!-- Modal footer --> */}
            <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
              <button
                type="reset"
                className="btn-secondary px-7"
                onClick={onCancel}>
                Tutup
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ModalDetailProject;
