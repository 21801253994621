import React from 'react';
import { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { IProjectManagement } from '../../api/project-api';
import { IFarmerGroup } from '../../api/farmergroup-api';

type Props = {
    data: IFarmerGroup,
    projectList: IProjectManagement[],
    onCancel: (e: React.MouseEvent) => any
    onSubmit: (data: IFarmerGroup) => void
};

const ModalFormFarmerGroup: React.FC<Props> = ({ data, projectList, onCancel, onSubmit }) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if (data) setdefaultValue(data)
    }, [data, setdefaultValue])

    const onSubmitForm: SubmitHandler<IFarmerGroup> = ({ farmer_group_name_var, project_code_var }) => {

        const payload = {
            farmer_group_name_var, project_code_var
        }

        onSubmit(payload)
    }

    return (
        <div className="modal-form">
            <div className="modal-form-outside" onClick={onCancel}></div>

            <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                    <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                        <i className="fa-solid fa-people-group flex justify-center items-center"></i>
                        <h3>{data ? 'Ubah Farmer Group' : 'Tambah Farmer Group'}</h3>
                    </div>
                    <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                        onClick={onCancel}
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                    <div className='space-y-5'>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="farmer_group_name_var" className="label-form">Nama Group Tani</label>
                                <input id='farmer_group_name_var' type='text' className='input-form'
                                    {...register('farmer_group_name_var', {
                                        required: 'Farmer Group Name is required.',
                                        minLength: { value: 2, message: 'Farmer Group Name length at least 2 characters' }
                                    })}
                                />
                                <ErrorField errors={errors} name='farmer_group_name_var' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="project_code_var" className="label-form">Projek</label>
                                <select id="project_code_var" className="input-form" {...register('project_code_var', { required: 'Project is required' })}>
                                    <option value=''>- Pilih Projek -</option>
                                    {projectList.map((item, key) =>
                                        <option key={key} value={item.project_code_var}>[{item.project_code_var}] {item.project_name_var}</option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='project_code_var' />
                            </div>
                        </div>
                    </div>

                    {/* <!-- Modal footer --> */}
                    <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                        <button type='submit' className="btn-primary">Simpan</button>
                        <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                    </div>

                </form>

            </div>

        </div>
    )
}

export default ModalFormFarmerGroup