import { useEffect, useState } from "react";
import { getRatioListByPeriod } from "@api/landprice-api";
import { ITaskReference, TaskReferenceApi } from "@api/taskreference-api";


export default function useDropdownActivity(isRefreshing: boolean = false, file_template_txt: string) {
	const [DataActivity, setDataActivity] = useState<ITaskReference[]>([]);
	const [loadingActivity, setLoading] = useState(false);
	const [errorActivity, setError] = useState(null);

	useEffect(() => {
		async function fetchDropdownActivity() {
			setLoading(true);
			let res = await TaskReferenceApi.getAllbyName(file_template_txt);

			console.log("DD Activity :", res);
			if (res.data) {
				if (res.data.code === 0) {
					setDataActivity(res.data.data);
				} else {
					setError(res.data.message);
				}
			} else {
				setError(res.message);
			}
			setLoading(false);
		}

		fetchDropdownActivity();
	}, [isRefreshing, file_template_txt]);

	return { DataActivity, errorActivity, loadingActivity };
}
