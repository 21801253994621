import { useEffect, useState } from "react";
import { dropdownVariety, IVariety } from "../api/variety-api";

export default function useDropdownVariety(isRefreshing: boolean = false) {
  const [dataVariety, setDataVariety] = useState<IVariety[]>([]);
  const [loadingVariety, setLoading] = useState(false);
  const [errorVariety, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownVariety() {
      setLoading(true);
      let res = await dropdownVariety();

      console.log("DD Variety :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataVariety(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownVariety();
  }, [isRefreshing]);

  return { dataVariety, errorVariety, loadingVariety };
}
