import { useState } from "react";
import { IDocumentation, IObservartion, IRating } from "../../../api/task-api";
import Activity from "./Activity";
import Documentation from "./Documentation";
import Pengamatan from "./Pengamatan";
import Suggestion from "./Suggestion";

type Props = {
	daySelected: any;
	observation: IObservartion[];
	documentation: IDocumentation[];
	rating: IRating[];
	onClose: (e?: React.MouseEvent<HTMLElement>) => void;
	cultivation?: [];
	activity0HST?: [];
};

export const ModalTaskNew: React.FC<Props> = ({
	onClose,
	observation,
	rating,
	daySelected,
	cultivation,
	activity0HST,
}) => {
	const [tabIndex, setTabIndex] = useState(0);

	return (
		<>
			<div className="modal-form">
				<div className="modal-form-outside" onClick={onClose} />
				<div
					className="modal-form-content bg-white p-5 w-[95%] md:w-7/12 2xl:w-5/12"
					style={{ maxHeight: "90vh" }}>
					<div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
						<div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
							<h3>Task Farmer</h3>
						</div>
						<button
							type="button"
							className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
							onClick={onClose}>
							<svg
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"></path>
							</svg>
						</button>
					</div>
					<div className="mt-5">
						<ul className="text-xsm md:text-sm text-center text-gray-400 flex item-center md:border-b">
							<li className="w-full">
								<button
									className={`font-semibold w-full pb-3 rounded-t-lg border-b-2 outline-none ${tabIndex === 0
										? "text-blue-500 border-blue-500"
										: "border-transparent"
										} hover:border-blue-500 hover:text-blue-500`}
									onClick={() => setTabIndex(0)}>
									<p>Aktivitas</p>
								</button>
							</li>
							{observation.length === 0 && (
								<li className="w-full">
									<button
										className={`font-semibold w-full pb-3 rounded-t-lg border-b-2 outline-none ${tabIndex === 1
											? "text-blue-500 border-blue-500"
											: "border-transparent"
											} hover:border-blue-500 hover:text-blue-500`}
										onClick={() => setTabIndex(1)}>
										<p>Pengamatan</p>
									</button>
								</li>
							)}
							<li className="w-full">
								<button
									className={`font-semibold w-full pb-3 rounded-t-lg border-b-2 outline-none ${tabIndex === 2
										? "text-blue-500 border-blue-500"
										: "border-transparent"
										} hover:border-blue-500 hover:text-blue-500`}
									onClick={() => setTabIndex(2)}>
									<p>Dokumentasi</p>
								</button>
							</li>
							{rating.length === 0 && (
								<li className="w-full">
									<button
										className={`font-semibold w-full pb-3 rounded-t-lg border-b-2 outline-none ${tabIndex === 3
											? "text-blue-500 border-blue-500"
											: "border-transparent"
											} hover:border-blue-500 hover:text-blue-500`}
										onClick={() => setTabIndex(3)}>
										<p>Saran dan Penilaian</p>
									</button>
								</li>
							)}
						</ul>
					</div>
					{tabIndex === 0 ? (
						<Activity
							onCancel={onClose}
							daySelected={daySelected}
							activity0HST={activity0HST} />
					) : tabIndex === 1 ? (
						<Pengamatan
							onCancel={onClose}
							daySelected={daySelected}
							cultivation={cultivation}
						/>
					) : tabIndex === 2 ? (
						<Documentation onCancel={onClose} daySelected={daySelected} />
					) : (
						<Suggestion onCancel={onClose} daySelected={daySelected} />
					)}
				</div>
			</div>
		</>
	);
};

export const selectStylesForm = {
	control: (provided: any, state: any) => ({
		...provided,
		borderRadius: 6,
		fontSize: 14,
		padding: "0px 3px",
		cursor: "pointer",
		border: "solid 1px #d0d8d0",
	}),
	singleValue: (provided: any, state: any) => ({
		...provided,
		marginTop: -2,
		height: "45px",
		display: "flex",
		alignItems: "center",
	}),
	valueContainer: (provided: any, state: any) => ({
		...provided,
		height: "45px",
		display: "flex",
		alignItems: "center",
	}),
	option: (provided: any, state: any) => ({
		...provided,
		fontWeight: 500,
		cursor: "pointer",
		fontSize: 14,
		padding: "12px 12px",
	}),
};
export default ModalTaskNew;
