import { useCallback, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import TableUser from "@components/tables/Table";

import Loader from "@components/modal/Loader";
import { toast } from "react-toastify";

import React from "react";
import { getAllUserGroup, IUserGroup } from "@api/usergroup-api";
import { DropdownLand } from "@components/dropdown/SimpleDropdown";
import { ActionType } from "@reduxs/action/actions";
import { GlobalContext } from "@context/GlobalProvider";
import { useLocation } from "react-router-dom";
import { IRecentPage } from "@context/ContextInterface";
import ModalDetailHakAkses from "@components/modal/ModalDetailHakAkses";
import { getAllMenu } from "@api/common-api";

const TITLE_BAR = "User Management / Hak Akses";

type Props = {
  user?: any;
  titleBar?: string;
};

const HakAksesManagement: React.FC<Props> = ({ user, titleBar }) => {
  const [loader, showLoader] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [originData, setOriginData] = useState<IUserGroup[] | []>([]);
  const [filterData, setFilterData] = useState<IUserGroup[] | []>([]);
  const [selectedData, setSelectedData] = useState<IUserGroup | null>(null);
  const [menus, setMenus] = useState<null>(null);

  const dispatch = useDispatch();
  const { dispatchCalActivity, dispatchCalPage } = useContext(GlobalContext);
  const { pathname } = useLocation();

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: "push", payload });
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
  }, [dispatch, dispatchCalPage, pathname]);

  const fetchData = useCallback(async () => {
    showLoader(true);
    const res = await getAllUserGroup(
      localStorage.getItem("pandawa-token") || "",
    );

    showLoader(false);
    console.log("User group :", res);
    if (res.data) {
      if (res.data.code === 0) {
        setOriginData(res.data.data);
        setFilterData(res.data.data);
        localStorage.setItem("pandawa-role", JSON.stringify(res.data.data));
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  }, []);

  const fetchAllMenu = async () => {
    const res = await getAllMenu(localStorage.getItem("pandawa-token") || "");
    console.log({ res });
    setMenus(res.data.data);
  };

  const handleDetail = (selectedData: IUserGroup) => {
    setSelectedData(selectedData);
    setModalDetail(true);
  };

  useEffect(() => {
    fetchData();
    fetchAllMenu();
  }, [fetchData]);

  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }: any) => (
        <div className="text-left pl-4">{row.index + 1}.</div>
      ),
    },
    {
      Header: "Group ID",
      accessor: "id_seq",
    },
    {
      Header: "Nama Group",
      accessor: "group_name_var",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status_int",
      Cell: ({ value }: any) =>
        parseInt(value) === 1 ? (
          <span className="bg-green-100 text-green-800 px-2 py-1 rounded-lg font-semibold">
            Active
          </span>
        ) : (
          <span className="bg-red-100 text-red-800 px-2 py-1 rounded-lg font-semibold">
            Inactive
          </span>
        ),
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }: any) => {
        const data = row.original;
        return (
          <DropdownLand
            data={data}
            onDetail={() => handleDetail(data)}
            onEdit={null}
            onDelete={null}
          />
        );
      },
    },
  ];

  return (
    <div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
      <div className="w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6">
        <div className="mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
          <h1 className="font-bold uppercase text-xl">List User Group</h1>
        </div>
        <TableUser dataTable={filterData} columnTable={columns} />
      </div>

      {modalDetail && (
        <ModalDetailHakAkses
          data={{ userGroup: selectedData!, menus: menus }}
          onCancel={() => {
            setModalDetail(false);
          }}
        />
      )}

      {loader && <Loader />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    titleBar: state.title_bar,
  };
};

export default connect(mapStateToProps, null)(HakAksesManagement);
