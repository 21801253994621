import { Transition } from '@tailwindui/react';
import React, { useState, useRef, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Helper from '../../helpers/helper';
import ModalConfirm from '../modal/ConfirmModal';

const BG_AVATAR = ['152e4d', '0891b2', '2E8B57', '8B4513', '4B0082', '999']

interface IUserMenu {
    user?: any
}

const UserMenu: React.FC<IUserMenu> = ({ user }) => {
    const [modalOut, showModalOut] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const userLocalStorage = localStorage.getItem('pandawa-user') ? JSON.parse(localStorage.getItem('pandawa-user') || '') : null

    const navigate = useNavigate()

    const trigger = useRef<any>(null);
    const dropdown = useRef<any>(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: any) => {
            if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: any) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });


    const handleLogout = async () => {
        // await Helper.asyncLocalStorage.clear()
        Helper.logout()
        navigate('/auth', { replace: true })

    }

    return (
        <div className="relative inline-flex">
            <button
                ref={trigger} className="inline-flex justify-center items-center group" aria-haspopup="true" aria-expanded={dropdownOpen}
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                <div className="w-9 h-9 rounded-full bg-gray-400">
                    <LazyLoadImage src={`https://ui-avatars.com/api/?name=${user.fullname_var || userLocalStorage?.fullname_var}&background=${BG_AVATAR[Math.floor(Math.random() * BG_AVATAR.length)]}&color=fff`} className='rounded-full' />
                </div>
                <div className="flex items-center truncate">
                    <div className='text-left ml-2'>
                        <span className="truncate text-sm font-medium group-hover:text-lightcayn">{user.fullname_var || userLocalStorage?.fullname_var}</span>
                        <div className="text-xs text-gray-500 italic">{Helper.getUserRole(user?.user_group_id_int || userLocalStorage?.user_group_id_int)}</div>
                    </div>
                </div>
            </button>

            <Transition show={dropdownOpen}
                enter="transition ease-out duration-300"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                className="origin-top-right z-10 absolute top-full right-0 w-max bg-white border border-gray-200 py-1.5 px-1 rounded shadow-lg overflow-hidden mt-1"
            >
                <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)} >
                    <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
                        <div className="font-medium text-gray-800">{user?.fullname_var || userLocalStorage?.fullname_var}</div>
                        <div className="text-xs text-gray-500 italic">{Helper.getUserRole(user?.user_group_id_int || userLocalStorage?.user_group_id_int)}</div>
                    </div>
                    <ul>
                        {/* <li>
                            <Link className="font-medium text-sm text-lightcayn hover:text-darkcayn flex items-center py-1 px-3"
                                to='/dashboard/profile' onClick={() => setDropdownOpen(!dropdownOpen)}
                            >
                                <i className="fa-solid fa-user-gear"></i>&ensp;Settings
                            </Link>
                        </li> */}
                        <li>
                            <span className="font-medium text-sm text-red-400 hover:text-darkcayn flex items-center py-1 px-3 cursor-pointer"
                                onClick={() => showModalOut(true)}
                            >
                                <i className="fa-solid fa-right-from-bracket"></i>&ensp; Sign Out
                            </span>
                        </li>
                    </ul>
                </div>
            </Transition>



            {modalOut && <ModalConfirm message="Apakah Anda yakin ingin keluar?" onOK={handleLogout} onCancel={() => showModalOut(false)} />}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(UserMenu);