import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ITaskReferenceDetail, TaskReferenceApi, TaskReferenceDetailApi } from '../../../api/taskreference-api';
import { ButtonAdd } from '../../../components/button/CustomButton';
import { DropdownLand } from '../../../components/dropdown/SimpleDropdown';
import Loader from '../../../components/modal/Loader';
import ModalFormTaskPreferenceDetail from '../../../components/modal/operatingparameter/taskreference/ModalFormTaskReferenceDetail';
import SearchField from '../../../components/SearchField';
import TableUser from '../../../components/tables/Table';
import { IRecentPage } from '../../../context/ContextInterface';
import { GlobalContext } from '../../../context/GlobalProvider';
import { getPayloadRecentActivity } from '../../../helpers/helper';
import { ActionType } from '../../../reduxs/action/actions';


const TITLE_BAR = 'Operating Parameter / Task Reference Detail'

type Props = {};

const TaskReferenceDetail: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [originData, setOriginData] = useState<ITaskReferenceDetail[] | []>([])
    const [filterData, setFilterData] = useState<ITaskReferenceDetail[] | []>([])
    const [selectedData, setSelectedData] = useState<any | null>(null)

    const dispatch = useDispatch()
    const user = useSelector((state: any) => state['user'])
    const { id }: any = useParams()
    const { pathname } = useLocation()
    const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext)

    useEffect(() => {
        const payload: IRecentPage = {
            id: new Date().getTime(),
            title: TITLE_BAR,
            path: pathname,
            url: window.location.href,
        };

        dispatchCalPage({ type: 'push', payload })
        dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR })
    }, [dispatch, dispatchCalPage, pathname])


    const fetchData = useCallback(async () => {
        showLoader(true)
        const res = await TaskReferenceApi.detail(id)

        showLoader(false)
        console.log("TASK PREFERENCE DETAIL ==>", res)
        if (res.data) {
            if (res.data.code === 0) {
                // setOriginData(res.data.data.activity.slice(0, 125))
                // setFilterData(res.data.data.activity.slice(0, 125))
                setOriginData(res.data.data)
                setFilterData(res.data.data)
            } else {
                toast.error(res.data.message)
            }
        } else {
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData, dispatch])


    const handleEditData = (selectedData: ITaskReferenceDetail) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (data: ITaskReferenceDetail) => {
        showLoader(true)
        const filename = id.split('.').length > 0 ? id.split('.')[0] : id
        data.file_name_txt = filename

        let res = null
        if (!isUpdate) {
            // data.created_by_var = user.fullname_var
            res = await TaskReferenceDetailApi.createNew(data)

            var payload = getPayloadRecentActivity(1, 'Task Reference Detail ' + filename, data.activity_txt, user?.fullname_var, pathname)
        } else {
            // data.updated_by_var = user.fullname_var
            res = await TaskReferenceDetailApi.update(selectedData?.id_seq!, data)

            payload = getPayloadRecentActivity(2, 'Task Reference Detail ' + filename, data.activity_txt, user?.fullname_var, pathname)
        }

        console.log('TASK PREFERENCE ACTION ==>', res)

        if (res.data) {
            if (res.data.code === 0) {
                toast.success(res.data.message)

                dispatchCalActivity({ type: 'push', payload })

                fetchData()
                resetForm()
            } else {
                toast.error(res.data.message)
                showLoader(false)
            }
        } else {
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const handleDeleteData = async (data: ITaskReferenceDetail) => {
        showLoader(true)

        const filename = id.split('.').length > 0 ? id.split('.')[0] : id
        const res = await TaskReferenceDetailApi.delete(data.id_seq!, filename)

        console.log("TASK PREFERENCE ACTION ==>", res)
        if (res.code === 0) {
            toast.success(res.message)

            const payload = getPayloadRecentActivity(3, 'Task Reference Detail', data.id_seq, user?.fullname_var, pathname)
            dispatchCalActivity({ type: 'push', payload })

            fetchData()
            resetForm()
        } else {
            toast.error(res.message)
            showLoader(false)
        }
    }

    const resetForm = () => {
        setSelectedData(null)
        setIsUpdate(false)
        showModalForm(false)
        showLoader(false)
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        const newData = [...originData]
        if (event.target.value) {
            const filtered = newData.filter(item => {
                return (
                    item.activity_txt?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.material_txt?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.dose_txt?.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.time_txt?.toString().toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        } else {
            setFilterData(originData)
        }
    }

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            accessor: 'no',
            Cell: ({ row }: any) => <div className='text-left pl-4'>{row.index + 1}.</div>,
        },
        {
            Header: 'Activity',
            accessor: 'activity_txt'
        },
        {
            Header: 'Material',
            accessor: 'material_txt',
            Cell: ({ value }: any) => {
                return value || '-'
            }
        },
        {
            Header: 'Dose',
            accessor: 'dose_txt',
            Cell: ({ value }: any) => {
                return value || '-'
            }
        },
        {
            Header: 'Time',
            accessor: 'time_txt',
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({ row }: any) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEditData(data)} onDelete={() => handleDeleteData(data)} />
            }
        }
    ]

    return (
        <>
            <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>
                <div className='bg-white rounded-2xl shadow-2xl p-5 md:p-6 2xl:px-7'>
                    <nav className="flex my-5 py-4 px-2 md:px-5 text-gray-700 rounded-lg border bg-blue-50 border-blue-100" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                                <Link to='/dashboard/operating-parameter-taskreference' className="inline-flex items-center text-sms md:text-sm font-semibold text-blue-500 hover:opacity-75">
                                    <svg className="mr-1 md:mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Task Reference
                                </Link>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                    <span className="ml-1 text-sms md:text-sm font-bold text-slate-800 hover:opacity-75 cursor-pointer">Task Reference Detail</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div className='mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0'>
                        <h1 className='font-bold text-xl'>Daftar Aktivitas</h1>
                        <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0'>
                            <SearchField onChange={handleSearch} placeholder='Cari data...' />
                            <ButtonAdd onClick={() => showModalForm(true)} />
                        </div>
                    </div>
                    <TableUser dataTable={filterData} columnTable={columns} />
                </div>
            </div>

            {modalForm && <ModalFormTaskPreferenceDetail data={selectedData} onSubmit={handleReceiveDataForm} onCancel={resetForm} />}
            {loader && <Loader />}
        </>
    );
}

export default TaskReferenceDetail;