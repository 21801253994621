import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { authLogin } from "../../api/common-api";

import ErrorField from "../../components/ErrorField";
import Helper from "../../helpers/helper";
import Loader from "../../components/modal/Loader";
import logo from "../../assets/img/logo.png";
import profile from "../../assets/img/profile-img.png";
import ModalFormPasswordGuest from "@components/modal/operatingparameter/ModalFormPasswordGuest";


const Login = () => {
	const [loader, showLoader] = useState(false);
	const [modalErr, showModalErr] = useState(false);
	const [errMessage, setErrMessage] = useState("");
	const [modalFormPasswordGuest, showmodalFormPasswordGuest] = useState(false);


	const navigate = useNavigate();

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({ criteriaMode: "all" });

	const onValid = (payload) => {
		if (!modalFormPasswordGuest) {
			showLoader(true);

			// console.log(decryptAes(payload.password_var))

			payload.phone_number_int = Helper.IDPhoneNumber(payload.phone_number_int);
			postLogin(payload);
		}
	};

	const handleClickLoginGuest = async () => {
		showmodalFormPasswordGuest(true)
	}

	const postLogin = async (payload) => {
		const res = await authLogin(payload);

		console.log(res);
		showLoader(false);

		if (res.data) {
			if (res.data.code === 0) {
				// localStorage.setItem('pandawa-token', res.data.data.token)
				await Helper.asyncLocalStorage.setItem(
					"pandawa-token",
					res.data.data.token
				);
				navigate("/dashboard", { replace: true });
			} else {
				setErrMessage(res.data.message);
				showModalErr(true);
			}
		} else {
			setErrMessage("Connection timeout..");
			showModalErr(true);
		}
	};

	const handleReceiveDataFormPasswordGuest = async (payload) => {
		payload.phone_number_int = '0000000000'
		showLoader(true);

		payload.phone_number_int = Helper.IDPhoneNumber(payload.phone_number_int);
		await postLogin(payload);
	};

	const resetFormPasswordGuest = () => {
		showmodalFormPasswordGuest(false);
	};

	return (
		<>
			<div className="w-11/12 py-10 md:w-1/3 2xl:w-1/4">
				{/* <i className="fa-thin fa-house-user"></i> */}
				<div className="bg-white rounded shadow-xl">
					<div className="flex justify-between w-full rounded-t bg-lightcayn bg-opacity-20">
						<div className="p-5 mb-5 rounded-t-2xl text-lightcayn">
							<h2 className="mb-1 font-medium">Welcome Back !</h2>
							<p className="text-sms">Sign in to continue</p>
						</div>
						<div className="flex justify-end">
							<LazyLoadImage effect="blur" src={profile} className="w-44" />
						</div>
					</div>
					<div className="w-16 h-16 p-3 ml-5 rounded-full bg-darkcayn -mt-7">
						<LazyLoadImage effect="blur" src={logo} />
					</div>

					{modalErr && (
						<div className="p-4 mx-6 mt-6 -mb-4 text-left text-red-800 bg-red-100 rounded text-sms">
							{errMessage}
						</div>
					)}

					<form
						onSubmit={handleSubmit(onValid)}
						className="flex flex-col items-center p-6 mt-2 font-medium text-gray-600 text-sms"
					>
						<div className="flex flex-col w-full mb-4">
							<label>Phone Number</label>
							<input
								type="tel"
								placeholder="081234567890"
								className="mt-1.5 p-2.5 outline-none border-1 border-gray-300 rounded"
								{...register("phone_number_int", {
									required: "Phone number is required.",
									pattern: {
										value: /^\d+$/,
										message: "Phone number is number only.",
									},
									minLength: {
										value: 10,
										message: "Phone number must exceed 9 characters.",
									},
									maxLength: {
										value: 14,
										message: "Phone number invalid.",
									},
								})}
							/>
							<ErrorField errors={errors} name="phone_number_int" />
						</div>
						<div className="flex flex-col w-full mb-5">
							<label>Password</label>
							<input
								type="password"
								placeholder="xxxxx"
								className="mt-1.5 p-2.5 outline-none border-1 border-gray-300 rounded"
								{...register("password_var", {
									required: "Password is required.",
									minLength: {
										value: 5,
										message: "Password must exceed 4 characters.",
									},
								})}
							/>
							<ErrorField errors={errors} name="password_var" />
						</div>
						<div className="flex justify-start w-full mb-5">
							<div className="flex items-center">
								<input
									id="rememberme"
									type="checkbox"
									className="mr-2 cursor-pointer border-1 "
								/>
								<label
									htmlFor="rememberme"
									className="cursor-pointer hover:text-lightcayn"
								>
									Remember Me
								</label>
							</div>
						</div>
						<button
							type="submit"
							className="bg-lightcayn hover:bg-darkcayn text-white w-full py-2.5 rounded mb-6 text-sms"
						>
							Log In
						</button>
						<button
							type="submit"
							className="bg-gray-400 hover:bg-gray-600 text-white w-1/2 py-2.5 rounded mb-6 text-sms float-right"
							onClick={handleClickLoginGuest}
						>
							Log In as Guest
						</button>
						<p className="font-normal text-gray-600 cursor-pointer hover:text-lightcayn">
							<span className="mr-2">
								<i className="fa-solid fa-lock"></i>
							</span>
							Forgot your password?
						</p>
					</form>
					{modalFormPasswordGuest && (
						<ModalFormPasswordGuest
							data={null}
							onCancel={resetFormPasswordGuest}
							onSubmit={handleReceiveDataFormPasswordGuest}
						/>
					)}
				</div>
				<div className="flex flex-col items-center text-gray-600 text-sms mt-7">
					<p>
						Don't have an account ?{" "}
						<span className="font-medium text-lightcayn hover:text-darkcayn">
							<Link to="/auth/sign-up">Signup Now</Link>
						</span>
					</p>
					<p className="mt-3">© {new Date().getFullYear()} FMS v2.0</p>
				</div>
			</div>
			{loader && <Loader />}
		</>
	);
};

export default Login;
