import { useEffect, useState } from "react";
import { dropdownMaterialResearch, IMaterialResearch } from "@api/materialResearch-api";

export default function useDropdownIMaterialResearch(isRefreshing: boolean = false) {
  const [dataMaterialResearch, setDataMaterialResearch] = useState<IMaterialResearch[]>([]);
  const [loadingMaterialResearch, setLoading] = useState(false);
  const [errorMaterialResearch, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownIMaterialResearch() {
      setLoading(true);
      let res = await dropdownMaterialResearch();

      console.log("DD Material Research :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataMaterialResearch(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownIMaterialResearch();
  }, [isRefreshing]);

  return { dataMaterialResearch, errorMaterialResearch, loadingMaterialResearch };
}
