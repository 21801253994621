import { useEffect, useState } from 'react';
import { dropdownCultivation } from '@api/cultivation-api';

export interface ICultivation {
	variety_id: any;
	file_template_txt: any;
	user_id: string | number | readonly string[];
	id_seq: number,
	fullname_var: string
}

export default function useDropdownCultivation(period_plant_txt: string) {
	const [dataFarmerCultivation, setDataFarmerCultivation] = useState<ICultivation[]>([])
	const [loadingFarmerCultivation, setLoading] = useState(false)
	const [errorFarmerCultivation, setError] = useState(null)

	useEffect(() => {
		async function fetchDropdownFarmerCultivation() {
			setLoading(true)
			console.log('per ',period_plant_txt);
			
			const res = await dropdownCultivation(period_plant_txt)

			console.log("Fetching DropdownFarmerCultivation :", res)
			if (res.data) {
				if (res.data.code === 0) {
					setDataFarmerCultivation(res.data.data)
				} else {
					setError(res.data.message)
				}
			} else {
				setError(res.message)
			}
			setLoading(false)
		}

		fetchDropdownFarmerCultivation()
	}, [period_plant_txt]);

	return { dataFarmerCultivation, errorFarmerCultivation, loadingFarmerCultivation }
}