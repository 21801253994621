import axios from "axios";
import { baseURL } from "./common-api";

export interface ISaprodiDistribution {
	id_seq?: number;
	user_id_int: number;
	land_code_var?: string;
	material_support_name_var: string;
	//   material_support_type_var: string;
	start_planting_dtm?: string;
	period_plant_txt?: string;
	harvest_prediction_int?: number;
	planting_method_int?: number;
	planting_phase_int?: number;
	source_funding_var: string;
	file_template_txt: string;
	created_by_var?: string;
	created_on_dtm?: string;
	updated_by_var?: string;
	updated_on_dtm?: string;
	fullname_var?: string;
	land_name_var?: string;
	material_support_id?: number;
	land_price_int?: number;
	ratio_farmer_int?: number;
	land_area_var?: number;
	ratio_stakeholder_int?: number;
	material_support?: any;
	name?: any;
	quantity?: any;
	unit?: any;
}


export const createNewSaprodiDistribution = async (payload: any) => {
	try {
		const data = await axios.post(
			baseURL + "/api/cultivation-management/saprodi-distribution/create",
			payload,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			},
		);
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const updateSaprodiDistribution = async (
	SaprodiDistributionID: string,
	payload: any,
) => {
	try {
		const data = await axios.patch(
			baseURL + "/api/cultivation-management/saprodi-distribution/update/" + SaprodiDistributionID,
			payload,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			},
		);
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const deleteSaprodiDistribution = async (SaprodiDistributionID: number) => {
	try {
		const data = await axios.delete(
			baseURL + "/api/cultivation-management/saprodi-distribution/delete/" + SaprodiDistributionID,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			},
		);
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllSaprodiDistribution = async () => {
	try {
		const data = await axios.get(baseURL + "/api/cultivation-management/saprodi-distribution/get-all", {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getRatioListByPeriod = async (period_plant_txt: string) => {
	try {
		const data = await axios.get(baseURL + '/api/cultivation-management/saprodi-distribution/get-list-land-price?period_plant_txt=' + period_plant_txt, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error: any) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const getOneSaprodiDistributionbyId = async (idSeq: number) => {
	try {
		const data = await axios.get(
			baseURL + "/api/cultivation-management/saprodi-distribution/get-one?id_seq=" + idSeq,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			},
		);
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

//#endregion
