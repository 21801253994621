import {
  editBulkMenuByGroup,
  getAllMenuByGroup,
  getSidebarMenu,
} from "@api/common-api";
import React, { useState } from "react";
import { useCallback, useEffect } from "react";

type Props = {
  data: any;
  onCancel: (e: React.MouseEvent) => any;
};

const ModalDetailHakAkses: React.FC<Props> = ({ data, onCancel }) => {
  const [currentMenus, setCurrentMenus] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const fetchMenuByGroup = async () => {
    const res = await getAllMenuByGroup(
      localStorage.getItem("pandawa-token") || "",
      data?.userGroup?.id_seq,
    );
    console.log({ res });
    setCurrentMenus(res.data.data);
    setCheckedItems(res.data.data.map((item) => item.menu_id_int));
  };

  const handleCheckMenus = (e) => {
    const { value, checked } = e.target;
    console.log({ value, checked });

    if (!checked) {
      setCheckedItems((prevItem) =>
        prevItem.filter((item) => item !== Number(value)),
      );
    } else {
      setCheckedItems((prevItem) => [...prevItem, Number(value)]);
    }
  };

  const handleSubmit = async () => {
    console.log({ checkedItems });

    const payload = {
      user_group_id_int: data?.userGroup?.id_seq,
      menus: checkedItems,
    };

    const res = await editBulkMenuByGroup(
      localStorage.getItem("pandawa-token") || "",
      payload,
    );

    console.log({ res });
  };

  useEffect(() => {
    fetchMenuByGroup();
  }, [data]);

  return (
    <>
      <div className="modal-form">
        <div className="modal-form-outside" onClick={onCancel}></div>

        <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
          <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
              <i className="fa-solid fa-building flex justify-center items-center"></i>
              <h3>Detail Group - {data?.userGroup?.group_name_var}</h3>
            </div>
            <button
              type="button"
              className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
              onClick={onCancel}>
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
            </button>
          </div>

          <form
            className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8"
            onSubmit={handleSubmit}>
            <div className="space-y-5">
              {data?.menus?.map((menu) => (
                <div className="grid grid-cols-3" key={`menus-${menu.id_seq}`}>
                  <div className="flex flex-row gap-x-2">
                    <input
                      type="checkbox"
                      id={`menu-${menu.id_seq}`}
                      checked={checkedItems.find(
                        (currMenu) => currMenu === menu.id_seq,
                      )}
                      value={menu.id_seq}
                      onChange={handleCheckMenus}
                    />
                    <label htmlFor={`menu-${menu.id_seq}`}>
                      {menu.name_var}
                    </label>
                  </div>
                </div>
              ))}
            </div>

            {/* <!-- Modal footer --> */}
            <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
              <button
                type="reset"
                className="btn-secondary px-7"
                onClick={onCancel}>
                Tutup
              </button>
              <button type="submit" className="btn-primary px-7">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ModalDetailHakAkses;
