import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import Select from "react-select";
import Loader from "./Loader";
import useDropdownLand, {
	useDropdownPeriod,
} from "../../hooks/useDropdownLand";
import useDropdownCommodity from "../../hooks/useDropdownCommodity";
import { selectStylesForm } from "./TaskManagement/ModalTaskNew";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IDataPanen } from "../../api/datapanen-api";
import useDropdownFarmer, { IUser } from "@hooks/useDropdownFarmer";
import useDropdownVariety from "@hooks/useDropdownVariety";
import { useDropdownParameterHasilPanen } from "@hooks/useDropdownParameterHasilPanen";
import ConfirmModal from "../../components/modal/ConfirmModal";
import { GlobalContext } from "../../context/GlobalProvider";
import TaskApi, {
	IDocumentation,
} from "../../api/task-api";
import { toast } from "react-toastify";



type Props = {
	data: IDataPanen;
	onCancel: (e: React.MouseEvent) => any;
	onSubmit?: (data: IDataPanen | any) => void;
};

const ModalFormPanen: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
	const [selectedCommodity, setSelectedCommodity] = useState<any>(null);
	const [selectedLand, setSelectedLand] = useState<any>(data ? data.land_code_var : "",);
	const [selectedFarmer, setSelectedFarmer] = useState<IUser | any>(null);
	const [selectedVariety, setSelectedVariety] = useState<any>(null);;
	const { dataPeriod, loadingPeriod } = useDropdownPeriod(selectedLand);
	const { dataCommodity, loadingCommodity } = useDropdownCommodity();
	const { dataFarmer, loadingFarmer } = useDropdownFarmer();
	const { dataVariety } = useDropdownVariety();
	const { dataLand, loadingLand } = useDropdownLand(selectedFarmer?.id_seq || 0); // eslint-disable-line
	const [loader, showLoader] = useState(false);
	const { dataParams } = useDropdownParameterHasilPanen(parseInt(selectedCommodity?.id_seq! || 0));
	const [selectedValues, setSelectedValues] = useState(data ? data.params_value : [],);
	const [images, setimages] = useState([]);
	const [updateModal, showUpdateModal] = useState(false);
	const [selectedData, setSelectedData] = useState<any>(null);
	const [dataDoc, setDataDoc] = useState<any>(null);
	const [confirmDelete, showConfirmDelete] = useState(false);
	// const { ownerTask, setIsDataChanged } = useContext(GlobalContext);
	// const [documentations, setDocumentations] = useState<IDocumentation[]>([]);



	const transformedOptions = selectedVariety && dataParams
		?.filter((fc) => fc.variety_id == selectedVariety.id_seq)
		?.map((item) => {
			const options = item?.value_parameter_pengamatan_hasil_panen;
			return {
				id_seq: item?.id_seq,
				label: item?.parameter_pengamatan_hasil_panen,
				options: options?.map((value) => ({
					value,
					label: value,
				})),
				isNumber: item.is_number_bool
			};
		});

	const handleSelectChange = (index, selectedOption) => {
		setSelectedValues((prevState) => {
			const updatedValues = [...prevState];
			updatedValues[index] = {
				parameter: selectedOption.parameter,
				value: selectedOption.value,
			};
			return updatedValues;
		});
	};

	const uploadImage = (e) => {
		const file = e.target.files;
		setimages(file)
	}

	const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault();
		showLoader(true);
		const formdata = new FormData(e.target);
		formdata.append("commodity_id", selectedCommodity.id_seq);
		formdata.append("variety_id", selectedVariety.id_seq);
		formdata.append("user_id_int", selectedFarmer.id_seq);
		formdata.append("params_value", JSON.stringify(selectedValues));

		if (images.length > 0) {
			for (let i = 0; i < images.length; i++) {
				formdata.append("images", images[i]);
			}
		}

		if (onSubmit) {
			onSubmit(formdata);
		}
	};

	useEffect(() => {
		if (data) {
			setSelectedFarmer(
				dataFarmer.find((item) => item.id_seq === data.user_id_int),
			);
			setSelectedCommodity(
				dataCommodity.find(
					(item) => item.commodity_name_var === data.commodity_name_var,
				),
			);
			setSelectedVariety(
				dataVariety.find(
					(item) => Number(item.id_seq) === Number(data.variety_id),
				),
			);
		}
		// eslint-disable-next-line
	}, [dataFarmer, dataCommodity, dataVariety]);


	const handleCloseUpdateModal = () => {
		showUpdateModal(false);
		showConfirmDelete(false);
		setSelectedData(null);
		showLoader(false);
	};

	const handleDeleteTask = async (e: React.MouseEvent<HTMLElement>) => {
		selectedData.images = selectedData.document_txt.filter(item => item !== dataDoc)

		delete selectedData.updated_by_var
		delete selectedData.document_txt
		delete selectedData.created_by_var
		delete selectedData.created_on_dtm
		delete selectedData.updated_on_dtm
		delete selectedData.status_int
		delete selectedData.count_harvest_int
		delete selectedData.status_name_var
		delete selectedData.fullname_var
		delete selectedData.variety_name
		delete selectedData.params_value

		var formData = new FormData();

		for (var key in selectedData) {
			if (key === 'images') {
				for (let i = 0; i < selectedData.images.length; i++) {

					formData.append('images[]', selectedData.images[i]);
				}
				formData.append('deleted_images', dataDoc)
			} else {
				formData.append(key, selectedData[key]);
			}
		}
		formData.append('isDeleted', 'true');
		onSubmit(formData)

	};


	return (
		<>
			<div className="modal-form">
				<div className="modal-form-outside" onClick={onCancel}></div>

				<div className="modal-form-content" style={{ maxHeight: "90vh" }}>
					<div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
						<div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
							<i className="fa-brands fa-pagelines flex justify-center items-center"></i>
							<h3>{data ? "Ubah Data Panen" : "Tambah Data Panen"}</h3>
						</div>
						<button
							type="button"
							className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
							onClick={onCancel}>
							<svg
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"></path>
							</svg>
						</button>
					</div>

					<form
						onSubmit={handleSubmit}
						className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
						<div className="space-y-5">
							<h2 className="text-sm font-bold text-lightcayn">
								Informasi Panen
							</h2>
							<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
								<div className="w-full">
									<label htmlFor="user_id_int" className="label-form">
										Petani
									</label>
									<Select
										id="user_id_int"
										styles={selectStylesForm}
										isClearable
										placeholder="- Pilih petani -"
										options={dataFarmer}
										isLoading={loadingFarmer}
										getOptionValue={(option: any) => option["id_seq"]}
										getOptionLabel={(option: any) => option["fullname_var"]}
										value={selectedFarmer}
										onChange={setSelectedFarmer}
									/>
								</div>
								{!loadingLand && (
									<div className="w-full">
										<label className="label-form">Lahan</label>
										<Select
											name="land_code_var"
											styles={selectStylesForm}
											options={dataLand}
											isLoading={loadingLand}
											defaultValue={
												data
													? dataLand.find(
														(item) =>
															item.land_code_var === data.land_code_var,
													)
													: ""
											}
											onChange={(item: any) =>
												setSelectedLand(item.land_code_var)
											}
											getOptionValue={(option: any) => option["land_code_var"]}
											getOptionLabel={(option: any) =>
												`[${option["land_code_var"]}] ${option["land_name_var"]}`
											}
											isClearable
											placeholder="- Pilih Lahan -"
										/>
									</div>
								)}
							</div>
							<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
								{!loadingCommodity && (

									<div className="w-full">
										<label className="label-form">Komoditas</label>
										<Select
											name="commodity_name_var"
											styles={selectStylesForm}
											options={dataCommodity}
											isLoading={loadingCommodity}
											defaultValue={
												data
													? dataCommodity.find(
														(item) =>
															item.commodity_name_var ===
															data.commodity_name_var,
													)
													: null
											}
											value={selectedCommodity}
											onChange={setSelectedCommodity}
											getOptionValue={(option: any) =>
												option["commodity_name_var"]
											}
											getOptionLabel={(option: any) =>
												option["commodity_name_var"]
											}
											isClearable
											placeholder="- Pilih Komoditas -"
										/>
									</div>
								)}

								<div className="w-full">
									<label htmlFor="variety_id" className="label-form">
										Varietas
									</label>
									<Select
										id="variety_id"
										styles={selectStylesForm}
										isClearable
										placeholder="- Pilih Varietas -"
										options={dataVariety?.filter(
											(item) =>
												Number(item.commodity_id) ===
												Number(selectedCommodity?.id_seq),
										)}
										getOptionValue={(option: any) => option["variety_id"]}
										getOptionLabel={(option: any) => option["name"]}
										value={selectedVariety}
										onChange={setSelectedVariety}
									/>
								</div>
							</div>

							<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
								<div className="w-full">
									<label className="label-form">Periode Tanam</label>
									{data ? (
										dataPeriod.length > 0 ? (
											<Select
												name="period_plant_txt"
												styles={selectStylesForm}
												options={dataPeriod}
												isLoading={loadingPeriod}
												defaultValue={
													data
														? dataPeriod.find(
															(item) =>
																item.period_plant_txt ===
																data.period_plant_txt,
														)
														: ""
												}
												// value={selectedPeriod} onChange={setSelectedPeriod}
												getOptionValue={(option: any) =>
													option["period_plant_txt"]
												}
												getOptionLabel={(option: any) =>
													option["period_plant_txt"]
												}
												isClearable
												placeholder="- Pilih Periode -"
											/>
										) : null
									) : (
										<Select
											name="period_plant_txt"
											styles={selectStylesForm}
											options={dataPeriod}
											isLoading={loadingPeriod}
											getOptionValue={(option: any) =>
												option["period_plant_txt"]
											}
											getOptionLabel={(option: any) =>
												option["period_plant_txt"]
											}
											isClearable
											placeholder="- Pilih Periode -"
										/>
									)}
								</div>
								<div className="w-full">
									<label htmlFor="harvest_flo" className="label-form">
										Hasil Panen (Kg)
									</label>
									<input
										id="harvest_flo"
										name="harvest_flo"
										type="number"
										className="input-form"
										defaultValue={data?.harvest_flo}
										placeholder="10"
									/>
								</div>
							</div>
							<div className="md:flex md:space-x-10 space-y-5 md:space-y-0 md:mr-10">
								<div className="w-full md:w-1/2">
									<label htmlFor="harvest_on_dte" className="label-form">
										Tanggal Panen
									</label>
									<input
										id="harvest_on_dte"
										name="harvest_on_dte"
										type="date"
										className="input-form"
										defaultValue={
											data
												? dayjs(data?.harvest_on_dte).format("YYYY-MM-DD")
												: ""
										}
									/>
								</div>
							</div>
						</div>

						<h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">
							Hasil Panen Penjemuran
						</h2>
						<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
							<div className="w-full">
								<label htmlFor="harvest_drying_dte" className="label-form">
									Tanggal Penjemuran
								</label>
								<input
									id="harvest_drying_dte"
									name="harvest_drying_dte"
									type="date"
									className="input-form"
									defaultValue={
										data
											? dayjs(data?.harvest_drying_dte).format("YYYY-MM-DD")
											: ""
									}
								/>
							</div>
							<div className="w-full">
								<label htmlFor="harvest_drying_flo" className="label-form">
									Hasil Penjemuran (Kg)
								</label>
								<input
									id="harvest_drying_flo"
									name="harvest_drying_flo"
									type="number"
									className="input-form"
									defaultValue={data?.harvest_drying_flo}
									placeholder="10"
								/>
							</div>
						</div>
						<h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">
							Hasil Panen Penggilingan
						</h2>
						<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
							<div className="w-full">
								<label htmlFor="harvest_milling_dte" className="label-form">
									Tanggal Penggilingan
								</label>
								<input
									id="harvest_milling_dte"
									name="harvest_milling_dte"
									type="date"
									className="input-form"
									defaultValue={
										data
											? dayjs(data?.harvest_milling_dte).format("YYYY-MM-DD")
											: ""
									}
								/>
							</div>
							<div className="w-full">
								<label htmlFor="harvest_milling_flo" className="label-form">
									Hasil Penggilingan (Kg)
								</label>
								<input
									id="harvest_milling_flo"
									name="harvest_milling_flo"
									type="number"
									className="input-form"
									defaultValue={data?.harvest_milling_flo}
									placeholder="10"
								/>
							</div>
						</div>

						<h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">
							Paramater Tambahan
						</h2>
						{selectedCommodity ? (
							<div className="grid grid-cols-2 gap-5">
								{transformedOptions?.map((param, index) => (

									<div key={`parameter-hasil-panen-1-${index}`} className="w-full">
										<label
											htmlFor={`parameter-hasil-panen-2-${index}`}
											className="label-form">
											{param?.label}
										</label>
										{(param.isNumber === 0 || param.isNumber == null) && [
											<Select
												id={`parameter-hasil-panen-3-${index}`}
												onChange={(event) =>
													handleSelectChange(index, {
														parameter: param?.label,
														value: event?.value,
													})
												}
												styles={selectStylesForm}
												options={param?.options}
												isClearable
												defaultValue={param?.options?.find(
													(po) =>
														po.value?.toLowerCase() ===
														selectedValues?.find((sv) =>
															sv?.parameter ? sv.parameter === param.label : [])?.value?.toLowerCase(),
												)}
												placeholder="- Pilih -"
												getOptionValue={(option: any) => option["label"]}
												getOptionLabel={(option: any) => option["value"]}

											/>
										]}
										{param.isNumber === 1 && [
											<input
												id={`parameter-hasil-panen-4-${index}`}
												// name={`parameter-hasil-panen-5-${index}`}
												type="number"
												className="input-form"
												onChange={(event) =>
													handleSelectChange(index, {
														parameter: param?.label,
														value: event?.target.value
													})
												}
												defaultValue={parseInt(selectedValues?.find(
													(pv) =>
														pv?.parameter ? pv.parameter === param.label : []
												)?.value)

												}
												placeholder="10"
											/>
										]}

									</div>
								))}
							</div>
						) : (
							<h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">

							</h2>
						)}


						<h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">
							Dokumentasi Panen
						</h2>
						<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
							<div className="w-full">
								<label htmlFor="images" className="label-form">
									Unggah Bukti Panen
								</label>
								{data ? (
									Object.keys(data).length !== 0 &&
									data?.document_txt?.map((url: string, key: number) => (
										<div className="relative w-full cursor-pointer hover:scale-110 hover:duration-500">
											<div className="absolute inset-0 w-full bg-black opacity-30 rounded-xl"
											/>
											<LazyLoadImage
												onClick={() =>
													window.open(url, "_blank")
												}
												effect="blur"
												alt={url}
												src={data?.document_txt[key]}
												className=" w-full rounded-lg object-cover"
												key={key}
											/>
											<div className="absolute top-0 right-0 space-x-2 m-2 text-gray-200">
												<i
													className="fa-solid fa-trash cursor-pointer hover:text-gray-500"
													onClick={() => {
														setDataDoc(data?.document_txt[key]);
														setSelectedData(data);
														showConfirmDelete(true);
													}}>
												</i>
											</div>
										</div>
									))

								) : (
									<h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">

									</h2>
								)
								}

								<input
									// id="images"
									// name="images"
									type="file"
									className="input-form"
									accept=".png, .jpg, .jpeg, .bmp"
									onChange={uploadImage}
									multiple
								/>
							</div>
						</div>

						{confirmDelete && (
							<ConfirmModal
								onCancel={handleCloseUpdateModal}
								onOK={handleDeleteTask}
							/>
						)}
						{loader && <Loader />}

						{/* <!-- Modal footer --> */}
						<div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
							<button type="submit" className="btn-primary">
								Simpan
							</button>
							<button
								type="reset"
								className="btn-secondary px-7"
								onClick={onCancel}>
								Batal
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export const ModalFormPanenDetail: React.FC<Props> = ({ data, onCancel }) => {
	// const [obs, setObs] = useState<IObservartion>({} as IObservartion);
	// const [updateModal, showUpdateModal] = useState(false);
	// const [selectedData, setSelectedData] = useState<any>(null);

	const getStatusStyle = () => {
		switch (data.status_int) {
			case -5:
				return "bg-red-50 text-red-500";
			case 400:
				return "bg-red-50 text-red-500";
			case 100:
				return "bg-slate-100 text-slate-500";
			case 150:
				return "bg-blue-50 text-blue-500";
			default:
				return "bg-green-50 text-green-500";
		}
	};

	return (
		<>
			<div className="modal-form">
				<div className="modal-form-outside" onClick={onCancel}></div>

				<div className="modal-form-content" style={{ maxHeight: "90vh" }}>
					<div className="flex justify-between items-start p-5 md:px-8 rounded-t border-b border-slate-300">
						<div className="text-base md:text-xl font-semibold flex item-center">
							<h3>{data ? "Data Panen" : "Data Panen"}</h3>
						</div>
						<button
							type="button"
							className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
							onClick={onCancel}>
							<svg
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"></path>
							</svg>
						</button>
					</div>

					<div
						className={`${getStatusStyle()} font-medium px-2 md:px-8 py-2 md:py-4 text-xs md:text-sms flex items-center justify-between`}>
						<p>Status Approval</p>
						<p className="italic font-bold capitalize">Disetujui</p>
					</div>
					<div className="p-5 md:p-8 space-y-6">
						<div>
							<div className="flex items-center justify-between">
								<h2 className="text-base text-lightcayn font-bold">
									Informasi Panen
								</h2>
							</div>
							<table className="font-medium text-sms md:text-sm mt-2.5">
								<tbody>
									<tr>
										<td className="text-gray-500 pb-1 w-[10rem] md:w-52">
											Komoditas
										</td>
										<td>{data?.commodity_name_var}</td>
									</tr>
									<tr>
										<td className="text-gray-500 pb-1">Nama Lahan</td>
										<td>
											[{data.land_code_var}] {data?.land_name_var}
										</td>
									</tr>
									<tr>
										<td className="text-gray-500 pb-1">Periode Tanam</td>
										<td>Periode {data.period_plant_txt}</td>
									</tr>
									<tr>
										<td className="text-gray-500 pb-1">Hasil Panen (Kg)</td>
										<td>{data.harvest_flo}</td>
									</tr>
									<tr>
										<td className="text-gray-500 pb-1">Tanggal Panen</td>
										<td>{dayjs(data.harvest_on_dte).format("DD/MM/YYYY")}</td>
									</tr>
									<tr>
										<td className="text-gray-500 pb-1">Panen ke </td>
										<td>{data.count_harvest_int}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div>
							<h2 className="text-base text-lightcayn font-bold">
								Hasil Panen Penjemuran
							</h2>
							<table className="font-medium text-sms md:text-sm mt-2.5">
								<tbody>
									<tr>
										<td className="text-gray-500 pb-1 w-48 md:w-52">
											Tanggal Penjemuran
										</td>
										<td>
											{dayjs(data.harvest_drying_dte).format("DD/MM/YYYY")}
										</td>
									</tr>
									<tr>
										<td className="text-gray-500 pb-1">
											Hasil Penjemuran ( Kg )
										</td>
										<td>{data.harvest_drying_flo}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div>
							<h2 className="text-base text-lightcayn font-bold">
								Hasil Panen Penggilingan
							</h2>
							<table className="font-medium text-sms md:text-sm mt-2.5">
								<tbody>
									<tr>
										<td className="text-gray-500 pb-1 w-48 md:w-52">
											Tanggal Penggilingan
										</td>
										<td>
											{dayjs(data.harvest_milling_dte).format("DD/MM/YYYY")}
										</td>
									</tr>
									<tr>
										<td className="text-gray-500 pb-1">
											Hasil Penggilingan ( Kg )
										</td>
										<td>{data.harvest_milling_flo}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div>
							<h2 className="text-base text-lightcayn font-bold">
								paramater tambahan
							</h2>
							<table className="font-medium text-sms md:text-sm mt-2.5">
								<tbody>
									{Object.keys(data).length !== 0 && (
										<div className="flex flex-col mb-5 px-1 md:px-5 text-sm">
											<table className="font-medium text-xs md:text-sm mt-3">
												<tbody>
													{data?.params_value?.map((param, index) => (
														<tr key={`param-${index}`}>
															<React.Fragment key={param.parameter}>
																<td className="text-gray-500 pb-1 w-36 md:w-60">
																	{param.parameter}
																</td>
																<td>{String(param.value)}</td>
															</React.Fragment>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									)}
								</tbody>
							</table>
						</div>
						<div className="mt-6">
							<h2 className="text-base text-lightcayn font-bold">
								Dokumentasi Panen
							</h2>
							<div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-4 2xl:gap-5 mt-4">
								{Object.keys(data).length !== 0 &&
									data.document_txt.map((url: string, key: number) => (
										<LazyLoadImage
											onClick={() =>
												window.open(data.document_txt[key], "_blank")
											}
											effect="blur"
											alt={url}
											src={url}
											className="rounded-lg shadow-md h-48 object-cover"
											key={key}
										/>
									))}
								{/* <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 object-cover' />
                                <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 object-cover' />
                                <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 object-cover' />
                                <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 object-cover' /> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ModalFormPanen;
