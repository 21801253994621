import React from 'react';
import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import Helper from "../../helpers/helper"
import { useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { getUserGroupDropdown } from "../../api/user-api";
import { IUserGroup } from "../../api/usergroup-api";
import Loader from './Loader';
import { IFarmerGroup } from '../../api/farmergroup-api';
import dayjs from 'dayjs';


type Props = {
	user?: any,
	title?: string,
	data: any,
	farmerGroupList: IFarmerGroup[],
	onCancel: (e: React.MouseEvent) => any,
	onSubmit: (data: any) => any
};

const ModalFormUser: React.FC<Props> = ({ user, title, data, farmerGroupList, onCancel, onSubmit }) => {
	const [errPass, showErrPas] = useState(false)
	const [loader, showLoader] = useState(true)
	const localRole = localStorage.getItem('pandawa-role') ? JSON.parse(localStorage.getItem('pandawa-role') || '') : []
	const [userGroup, setUserGroup] = useState<IUserGroup[]>(localRole)
	const [userGroupID, setUserGroupID] = useState(99)

	useEffect(() => {
		(async function fetchUserGroup() {
			const res = await getUserGroupDropdown(user.user_group_id_int)
			console.log("fetching dropdown role :", res)

			if (res.data) {
				if (res.data.code === 0) {
					// setUserGroup(res.data.data.sort((a: any,b: any) => b.id_seq - a.id_seq))
					setUserGroup(res.data.data)
					showLoader(false)
				}
			}
		})()

	}, [user.user_group_id_int])

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue
	} = useForm({ criteriaMode: "all" });

	const setdefaultValue = useCallback((obj) => {
		Object.keys(obj).map(key => {
			// console.log('key ',key);

			if (key === "tanggal_lahir_dtm") {
				console.log(key, dayjs(obj[key]).format("YYYY-MM-DD"));

				// return setValue(key, dayjs(obj[key]).format("DD/MM/YYYY"));
				return setValue(key, dayjs(obj[key]).format("YYYY-MM-DD"));

				// console.log();

			} else {
				return setValue(key, obj[key], { shouldValidate: true })
			}
		})
	}, [setValue])

	useEffect(() => {
		if (data) {
			setUserGroupID(data.user_group_id_int)
			setdefaultValue(data)
		}
	}, [data, setdefaultValue])

	const onValid = (dataForm: any) => {
		const {
			fullname_var, user_group_id_int, phone_number_int, email_var, password_var, confirm_pass,
			// farmer_group_id_int,
			no_nik_int,
			jenis_kelamin_var, tempat_lahir_var, tanggal_lahir_dtm, pendidikan_terakhir_var, status_pernikahan_var, kategori_petani_var,
			desa_rumah_var, kecamatan_rumah_var, kota_rumah_var, provinsi_rumah_var, alamat_rumah_lengkap_var, desa_lahan_var, kecamatan_lahan_var,
			kota_lahan_var, provinsi_lahan_var, alamat_lahan_lengkap_var, titik_koordinat_lahan_var, karakter_petani_var
		} = dataForm

		/* Password Feld hanya muncul ketika add user dan user role selain petani */
		if (!data) {
			if (password_var !== confirm_pass) {
				showErrPas(true)
				return
			}
		}

		const payload = {
			fullname_var: Helper.capitalEachWord(fullname_var),
			user_group_id_int,
			// farmer_group_id_int: parseInt(user_group_id_int) === 4 ? farmer_group_id_int || 99 : 99,
			phone_number_int: Helper.IDPhoneNumber(phone_number_int),
			password_var: password_var || phone_number_int,
			email_var: email_var || '',
			no_nik_int: no_nik_int || 0,
			jenis_kelamin_var: jenis_kelamin_var || '',
			tempat_lahir_var: tempat_lahir_var || '',
			tanggal_lahir_dtm: tanggal_lahir_dtm || new Date(),
			pendidikan_terakhir_var: pendidikan_terakhir_var || '',
			status_pernikahan_var: status_pernikahan_var || '',
			kategori_petani_var: kategori_petani_var || '',
			desa_rumah_var: desa_rumah_var || '',
			kecamatan_rumah_var: kecamatan_rumah_var || '',
			kota_rumah_var: kota_rumah_var || '',
			provinsi_rumah_var: provinsi_rumah_var || '',
			alamat_rumah_lengkap_var: alamat_rumah_lengkap_var || '',
			desa_lahan_var: desa_lahan_var || '',
			kecamatan_lahan_var: kecamatan_lahan_var || '',
			kota_lahan_var: kota_lahan_var || '',
			provinsi_lahan_var: provinsi_lahan_var || '',
			alamat_lahan_lengkap_var: alamat_lahan_lengkap_var || '',
			titik_koordinat_lahan_var: titik_koordinat_lahan_var || '',
			karakter_petani_var: karakter_petani_var || '',
		}

		if (data) delete payload.password_var

		onSubmit(payload)
	}

	return (
		<>
			{!loader ?
				<div className="modal-form">
					<div className="modal-form-outside" onClick={onCancel}></div>

					<div className="modal-form-content" style={{ maxHeight: '90vh' }}>
						<div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
							<div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
								<i className="fa-solid fa-user flex justify-center items-center"></i>
								<h3>{data ? 'Ubah User' : 'Tambah User'}</h3>
							</div>
							<button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
								onClick={onCancel}
							>
								<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
							</button>
						</div>

						<form onSubmit={handleSubmit(onValid)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8 space-y-5">
							<div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
								<div className='w-full md:w-1/2'>
									<label className='label-form'>Nama Lengkap</label>
									<input type='text' className='input-form' placeholder='Nama Lengkap'
										readOnly={title === 'Form Tambah User Baru' && data !== null}
										{...register("fullname_var", {
											required: "Fullname is required."
										})}
									/>
									<ErrorField errors={errors} name='fullname_var' />
								</div>
								<div className='w-full md:w-1/2'>
									<label className='label-form'>Role</label>
									<select id='routeUser' className='input-form' {...register("user_group_id_int", { required: "Role is required." })} onChange={(e) => setUserGroupID(parseInt(e.target.value))}
										disabled={title === 'Form Tambah User Baru' && data !== null}>
										<option value='99'>- Pilih Role -</option>
										{userGroup?.map(({ id_seq, group_name_var }: IUserGroup, key: number) =>
											<option key={key} value={id_seq} >[{id_seq}] {group_name_var}</option>
										)}
									</select>
									<ErrorField errors={errors} name='user_group_id_int' />
								</div>
							</div>
							<div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
								<div className='w-full md:w-1/2'>
									<label className='label-form'>No. Handphone</label>
									<input type='tel' className='input-form' placeholder='081234567890'
										readOnly={title === 'Form Tambah User Baru' && data !== null}
										{...register("phone_number_int", {
											required: "Phone number is required.",
											pattern: { value: /^\d+$/, message: "Phone number is number only." },
											minLength: { value: 10, message: "Phone number must exceed 9 characters." },
											maxLength: { value: 14, message: "Phone number too long." }
										})}
									/>
									<ErrorField errors={errors} name='phone_number_int' />
								</div>
								<div className='w-full md:w-1/2'>
									<label className='label-form'>Email</label>
									<input type='email' className='input-form' placeholder='user@mail.com'
										readOnly={title === 'Form Tambah User Baru' && data !== null}
										{...register("email_var", {
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: "Invalid email address."
											}
										})}
									/>
									<ErrorField errors={errors} name='email_var' />
								</div>
							</div>

							{/* Password hanya muncul ketika add user dan user role selain petani */}
							{!data &&
								<div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
									<div className='w-full md:w-1/2'>
										<label className='label-form'>Password</label>
										<input type='password' className='input-form' placeholder='*****'
											readOnly={title === 'Form Tambah User Baru' && data !== null}
											{...register("password_var", {
												required: "Password is required.",
												minLength: { value: 5, message: "Password must exceed 4 characters." }
											})}
										/>
										<ErrorField errors={errors} name='password_var' />
									</div>
									<div className='w-full md:w-1/2'>
										<label className='label-form'>Konfirmasi Password</label>
										<input type='password' className='input-form' placeholder='*****'
											readOnly={title === 'Form Tambah User Baru' && data !== null}
											{...register("confirm_pass", { required: "Confirm password is required." })}
										/>
										<ErrorField errors={errors} name='confirm_pass' />
									</div>
								</div>
							}

							{errPass && <p className='text-red-500 mt-3 mb-3 font-medium'>* Password not match</p>}


							{/* Farmer Group hanya muncul ketika user role nya hanya Farmer */}
							{userGroupID === 4 &&
								// this code need to be updated with new form 
								[
									<div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>No NIK</label>
											readOnly={title === 'Form Tambah User Baru' && data !== null}<input type='tel' className='input-form' placeholder='368756
											75385'

												{...register("no_nik_int", {
													required: "NIK is required.",
													pattern: { value: /^\d+$/, message: "NIK is number only." }
												})}
											/>
											<ErrorField errors={errors} name='no_nik_int' />
										</div>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Jenis Kelamin</label>
											<select id='Gender' className='input-form' {...register("jenis_kelamin_var", { required: "Jenis Kelamin is required." })}
												disabled={title === 'Form Tambah User Baru' && data !== null}>
												<option value=''>- Pilih Jenis Kelamin -</option>
												<option value='Laki-Laki'>Laki-Laki</option>
												<option value='Perempuan'>Perempuan</option>
											</select>
											<ErrorField errors={errors} name='jenis_kelamin_var' />
										</div>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Tempat Lahir</label>
											<input type='tel' className='input-form' placeholder='Mbay'
												readOnly={title === 'Form Tambah User Baru' && data !== null}
												{...register("tempat_lahir_var", {
													required: "Tempat Lahir is required.",
												})}
											/>
											<ErrorField errors={errors} name='tempat_lahir_var' />
										</div>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Tanggal Lahir</label>
											<input
												id='tanggal_lahir_dtm'
												type='date'
												className='input-form'
												{...register("tanggal_lahir_dtm", {
													required: "Tanggal Lahir is required.",
												})}
											/>
											<ErrorField errors={errors} name='tanggal_lahir_dtm' />
										</div>
									</div>,
									<div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Pendidikan Terakhir</label>
											<select id='pendidikan_terakhir' className='input-form' {...register("pendidikan_terakhir_var", { required: "Pendidikan Terakhir is required." })}
												disabled={title === 'Form Tambah User Baru' && data !== null}>
												<option value=''>- Pilih Pendidikan Terakhir -</option>
												<option value='Tidak Sekolah'>Tidak Sekolah</option>
												<option value='SD'>SD</option>
												<option value='SMP'>SMP</option>
												<option value='SMK'>SKP</option>
												<option value='SMA'>SMA</option>
												<option value='STM'>STM</option>
												<option value='SLTA'>SLTA</option>
												<option value='D3'>D3</option>
												<option value='S1'>S1</option>
												<option value='S1 Pertanian'>S1 Pertanian</option>
											</select>
											<ErrorField errors={errors} name='pendidikan_terakhir_var' />
										</div>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Status Pernikahan</label>
											<select id='marital_status' className='input-form' {...register("status_pernikahan_var", { required: "Status Pernikahan is required." })}
												disabled={title === 'Form Tambah User Baru' && data !== null}>
												<option value=''>- Pilih Status Pernikahan -</option>
												<option value='Belum Menikah'>Belum Menikah</option>
												<option value='Menikah'>Menikah</option>
												<option value='Cerai Hidup'>Cerai Hidup</option>
												<option value='Cerai Mati'>Cerai Mati</option>
											</select>
											<ErrorField errors={errors} name='status_pernikahan_var' />
										</div>
										{/* <div className='w-full md:w-1/2'>
											<label className='label-form'>Farmer Group</label>
											<select id='farmerGroup' className='input-form' {...register("farmer_group_id_int", { required: "Farmer Group is required." })}
											disabled={title === 'Form Tambah User Baru' && data !== null}>
												<option value=''>- Pilih Farmer Group -</option>
												{farmerGroupList.map(({ id_seq, farmer_group_name_var }, key: number) =>
													<option key={key} value={id_seq} >[{id_seq}] {farmer_group_name_var}</option>
												)}
											</select>
											<ErrorField errors={errors} name='farmer_group_id_int' />
										</div> */}
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Kategori Petani</label>
											<select id='farmer_category' className='input-form' {...register("kategori_petani_var", { required: "Kategori Petani is required." })}
												disabled={title === 'Form Tambah User Baru' && data !== null}>
												<option value=''>- Pilih Kategori Petani -</option>
												<option value='Pemilik Lahan'>Pemilik Lahan</option>
												<option value='Pemilik dan Penggarap'>Pemilik dan Penggarap</option>
												<option value='Penggarap'>Penggarap</option>
												<option value='Buruh'>Buruh</option>
											</select>
											<ErrorField errors={errors} name='kategori_petani_var' />
										</div>
									</div>,
									<div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Nama Desa Rumah</label>
											<input type='tel' className='input-form' placeholder='Gebang'
												readOnly={title === 'Form Tambah User Baru' && data !== null}
												{...register("desa_rumah_var", {
													required: "Nama Desa is required.",
												})}
											/>
											<ErrorField errors={errors} name='desa_rumah_var' />
										</div>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Nama Kecamatan Rumah</label>
											<input type='tel' className='input-form' placeholder='Patrang'
												readOnly={title === 'Form Tambah User Baru' && data !== null}
												{...register("kecamatan_rumah_var", {
													required: "Nama Kecamatan is required.",
												})}
											/>
											<ErrorField errors={errors} name='kecamatan_rumah_var' />
										</div>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Nama Kabupaten</label>
											<input type='tel' className='input-form' placeholder='Nagekeo'
												readOnly={title === 'Form Tambah User Baru' && data !== null}
												{...register("kota_rumah_var", {
													required: "Nama Kota is required.",
												})}
											/>
											<ErrorField errors={errors} name='kota_rumah_var' />
										</div>
									</div>,
									<div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Nama Provinsi Rumah</label>
											<input type='tel' className='input-form' placeholder='Nusa Tenggara Timur'
												readOnly={title === 'Form Tambah User Baru' && data !== null}
												{...register("provinsi_rumah_var", {
													required: "Nama Provinsi is required.",
												})}
											/>
											<ErrorField errors={errors} name='provinsi_rumah_var' />
										</div>
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Alamat Lengkap Rumah</label>
											<input type='tel' className='input-form' placeholder='....'
												readOnly={title === 'Form Tambah User Baru' && data !== null}
												{...register("alamat_rumah_lengkap_var", {
													required: "Alamat Lengkap is required.",
												})}
											/>
											<ErrorField errors={errors} name='alamat_rumah_lengkap_var' />
										</div>
									</div>,
									// <div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
									// 	<div className='w-full md:w-1/2'>
									// 		<label className='label-form'>Nama Desa Lahan</label>
									// 		<input type='tel' className='input-form' placeholder='Lape'
									// readOnly={title === 'Form Tambah User Baru' && data !== null} 			
									// {...register("desa_lahan_var", {
									// 				required: "Desa Lahan is required.",
									// 			})}
									// 		/>
									// 		<ErrorField errors={errors} name='desa_lahan_var' />
									// 	</div>
									// 	<div className='w-full md:w-1/2'>
									// 		<label className='label-form'>Nama Kecamatan Lahan</label>
									// 		<input type='tel' className='input-form' placeholder='Lape'
									// readOnly={title === 'Form Tambah User Baru' && data !== null}			
									// {...register("kecamatan_lahan_var", {
									// 				required: "Kecamatan Lahan is required.",
									// 			})}
									// 		/>
									// 		<ErrorField errors={errors} name='kecamatan_lahan_var' />
									// 	</div>
									// 	<div className='w-full md:w-1/2'>
									// 		<label className='label-form'>Nama Kota Lahan</label>
									// 		<input type='tel' className='input-form' placeholder='Nagekeo'
									// readOnly={title === 'Form Tambah User Baru' && data !== null}			
									// {...register("kota_lahan_var", {
									// 				required: "Kota Lahan is required.",
									// 			})}
									// 		/>
									// 		<ErrorField errors={errors} name='kota_lahan_var' />
									// 	</div>
									// </div>,
									// <div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
									// 	<div className='w-full md:w-1/2'>
									// 		<label className='label-form'>Nama Provinsi Lahan</label>
									// 		<input type='tel' className='input-form' placeholder='Nusa Tenggara Timur'
									// readOnly={title === 'Form Tambah User Baru' && data !== null}			
									// {...register("provinsi_lahan_var", {
									// 				required: "Provinsi Lahan is required.",
									// 			})}
									// 		/>
									// 		<ErrorField errors={errors} name='provinsi_lahan_var' />
									// 	</div>
									// 	<div className='w-full md:w-1/2'>
									// 		<label className='label-form'>Alamat Lengkap Lahan</label>
									// 		<input type='tel' className='input-form' placeholder='....'
									// readOnly={title === 'Form Tambah User Baru' && data !== null}			
									// {...register("alamat_lahan_lengkap_var", {
									// 				required: "Alamat Lengkap Lahan is required.",
									// 			})}
									// 		/>
									// 		<ErrorField errors={errors} name='alamat_lahan_lengkap_var' />
									// 	</div>
									// </div>,
									<div className='md:flex md:space-x-10 space-y-5 md:space-y-0'>
										{/* <div className='w-full md:w-1/2'>
											<label className='label-form'>Titik Koordinant Lahan</label>
											<input type='tel' className='input-form' placeholder='....'
											readOnly={title === 'Form Tambah User Baru' && data !== null}	
											{...register("titik_koordinat_lahan_var", {})}
											/>
											<ErrorField errors={errors} name='titik_koordinat_lahan_var' />
										</div> */}
										<div className='w-full md:w-1/2'>
											<label className='label-form'>Karakter Petani</label>
											<select id='mariage_status' className='input-form' {...register("karakter_petani_var", { required: "Kategori Petani is required." })}
												disabled={title === 'Form Tambah User Baru' && data !== null}>
												<option value='Baik'>Baik</option>
												<option value='Tidak Baik'>Tidak Baik</option>
											</select>
											<ErrorField errors={errors} name='karakter_petani_var' />
										</div>
									</div>
								]

							}
							{title == 'Form Tambah User Baru' && data != null ? (
								null
							) : <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
								<button type='submit' className="btn-primary">Simpan</button>
								<button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
							</div>}
						</form>

					</div>

				</div>
				:
				<Loader />
			}
		</>
	)
}


const mapStateToProps = (state: any) => {
	return {
		user: state.user
	}
}

export default connect(mapStateToProps, null)(ModalFormUser)