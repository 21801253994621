import { IChemical, createNew } from "@api/chemical-api";
import { IMixingChemical } from "@api/mixingchemical-api";
import useDropdownChemical from "@hooks/useDropdownChemical";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import ErrorField from "../ErrorField";
import Loader from "./Loader";
import ModalFormChemical from "./ModalFormChemical";
import { selectStylesForm } from "./TaskManagement/ModalTaskNew";

type Props = {
  data: IMixingChemical;
  dataUser?: any;
  onCancel: (e: React.MouseEvent) => any;
  onSubmit: (data: IMixingChemical) => void;
};

const ModalFormMixingChemical: React.FC<Props> = ({
  data,
  onCancel,
  onSubmit,
}) => {
  // states
  const [name, setName] = useState(data ? data.name : "");
  const [formValues, setFormValues] = useState<any[]>([
    { chemical_id: "", chemical_name: "", dose: "" },
  ]);
  const [selectedBahanKimia, setSelectedBahanKimia] = useState<any>([]);
  const [modalFormChemical, showModalFormChemical] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const userLoggedIn = JSON.parse(localStorage.getItem("pandawa-user"));

  // hooks
  const { dataChemical, loadingChemical } = useDropdownChemical(isRefreshing);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({ criteriaMode: "all" });

  // functions
  const setdefaultValue = useCallback(
    (obj) => {
      Object.keys(obj).map((key) => {
        return setValue(key, obj[key], { shouldValidate: true });
      });
    },
    [setValue],
  );
  const onSubmitForm: SubmitHandler<IMixingChemical> = ({
    name,
    combination,
  }) => {
    const payload = {
      name: name.toUpperCase(),
      combination: JSON.stringify(
        selectedBahanKimia
          .map((item: any, key: number) => {
            if (item) {
              return {
                chemical_id: item.id_seq,
                chemical_name: item.name,
                dose: formValues[key].dose || 0,
              };
            } else {
              return null;
            }
          })
          .filter((item: any) => item !== null),
      ),
    };
    console.log(payload);
    onSubmit(payload);
  };
  const handleReceiveDataFormChemical = async (data: IChemical) => {
    let res = null;
    res = await createNew(data);

    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);
        setIsRefreshing(!isRefreshing);
        showModalFormChemical(false);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };
  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { unique_code_var: "", quantity_int: "", measure_var: "" },
    ]);
  };
  let removeFormFields = (i: number) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let newArray = [...selectedBahanKimia];
    newArray.splice(i, 1);
    setSelectedBahanKimia(newArray);
  };
  let handleChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
    let { value, type } = e.target;
    const newArray = [...formValues];

    if (type === "number") {
      newArray[i][e.target.name] = Number(value);
    } else {
      newArray[i][e.target.name] = value;
    }

    setFormValues(newArray);
  };
  const handleChangeBahanKimia = (index: number, newData: any) => {
    const newArray = [...selectedBahanKimia];
    newArray[index] = newData;
    setSelectedBahanKimia(newArray);

    if (!newData) {
      let newFormValues = [...formValues];
      newFormValues[index] = { chemical_id: "", chemical_name: "", dose: "" };
      setFormValues(newFormValues);
    }
  };

  // effects
  useEffect(() => {
    if (data) {
      console.log(data);
      setName(data.name);
      setFormValues(data.combination);
      setSelectedBahanKimia(
        data.combination.map(({ chemical_id, chemical_name }: any) => {
          return {
            id_seq: Number(chemical_id),
            name: chemical_name,
          };
        }),
      );
      setdefaultValue(data);
    }
  }, [data, setdefaultValue]);

  return (
    <>
      {!loadingChemical ? (
        <div className="modal-form">
          <div className="modal-form-outside" onClick={onCancel}></div>

          <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
            <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
              <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                <h3>
                  {data
                    ? "Ubah Campuran Bahan Kimia"
                    : "Tambah Campuran Bahan Kimia"}
                </h3>
              </div>
              <button
                type="button"
                className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                onClick={onCancel}>
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
              </button>
            </div>

            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
              <div className="space-y-5">
                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                  <div className="w-full">
                    <label htmlFor="name" className="label-form">
                      Nama Campuran
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="input-form"
                      placeholder="Nama Bahan Kimia"
                      {...register("name", {
                        required: "Product Type Name is required.",
                      })}
                    />
                    <ErrorField errors={errors} name="name" />
                  </div>
                </div>

                <div className="flex gap-x-4 items-center">
                  <h2 className="text-base text-lightcayn font-bold">
                    Bahan Kimia
                  </h2>
                  <button
                    type="button"
                    className="text-right text-blue-600"
                    onClick={() => showModalFormChemical(true)}>
                    + Tambah Bahan Kimia
                  </button>
                </div>

                {formValues.map((item, index) => (
                  <div
                    className="flex items-center space-x-2 md:space-x-5"
                    key={index}>
                    <div className="grid gap-y-2 md:gap-y-0 gap-x-2 md:gap-x-5 grid-cols-1 md:grid-cols-2 w-full mb-5">
                      <div className="w-full">
                        <label htmlFor="chemical_id" className="label-form">
                          Nama Bahan Kimia
                        </label>
                        <Select
                          id="chemical_id"
                          styles={selectStylesForm}
                          isClearable
                          placeholder="- Pilih bahan kimia -"
                          options={dataChemical}
                          isLoading={loadingChemical}
                          value={selectedBahanKimia[index]}
                          onChange={(data) =>
                            handleChangeBahanKimia(index, data)
                          }
                          getOptionValue={(option: any) => option["id_seq"]}
                          getOptionLabel={(option: any) => option["name"]}
                        />
                      </div>
                      <div className="w-full">
                        <label htmlFor="dose" className="label-form">
                          Dosis
                        </label>
                        <input
                          id="dose"
                          className="input-form"
                          placeholder="Dosis"
                          name="dose"
                          type="number"
                          value={item?.dose || 0}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                    </div>
                    <i
                      className="fa-solid fa-trash text-lg text-rose-600 hover:opacity-70 cursor-pointer"
                      onClick={(e) => removeFormFields(index)}></i>
                  </div>
                ))}

                <button
                  type="button"
                  className="text-blue-500 hover:opacity-70 text-sms"
                  onClick={addFormFields}>
                  <span className="mr-2">
                    <i className="fa-solid fa-circle-plus"></i>
                  </span>
                  <span>Tambah Data</span>
                </button>
              </div>

              {/* <!-- Modal footer --> */}
              <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                <button type="submit" className="btn-primary">
                  Simpan
                </button>
                <button
                  type="reset"
                  className="btn-secondary px-7"
                  onClick={onCancel}>
                  Batal
                </button>
              </div>
            </form>

            {modalFormChemical && (
              <ModalFormChemical
                data={null}
                onCancel={() => showModalFormChemical(false)}
                onSubmit={handleReceiveDataFormChemical}
              />
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ModalFormMixingChemical;
