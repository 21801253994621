import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import TaskApi, { IActivity, IObservartion } from "@api/task-api";
import { GlobalContext } from "@context/GlobalProvider";
import { useDropdownParameterPengamatan } from "@hooks/useDropdownParameterPengamatan";
import Loader from "../Loader";
import { selectStylesForm } from "./ModalTaskNew";

type Props = {
	data?: IObservartion;
	daySelected?: string;
	onSubmit?: (data: IObservartion) => any;
	onCancel?: (e?: React.MouseEvent<HTMLElement>) => any;
	cultivation?: any;
	dataStatus?: any;
	dataSelectedData?: any;
};

const Pengamatan: React.FC<Props> = ({
	onCancel,
	onSubmit,
	data,
	daySelected,
	cultivation,
	dataStatus,
	dataSelectedData,
}) => {
	const [loader, showLoader] = useState(false);
	const [selectedValues, setSelectedValues] = useState(
		data ? data.params_values : [],
	);

	const { ownerTask, userStorage, setIsDataChanged } =
		useContext(GlobalContext);

	const { dataParams, loadingParams, errorParams } =
		useDropdownParameterPengamatan();

	const transformedOptions = dataParams
		?.filter((fc) => fc.variety_id === cultivation.variety_id)
		?.map((item) => {
			const options = item?.value_parameter_pengamatan;
			return {
				id_seq: item?.id_seq,
				label: item?.parameter_pengamatan,
				options: options?.map((value) => ({
					value,
					label: value,
				})),
			};
		});
	// console.log('transfomr ',transformedOptions);

	const handleSelectChange = (index, selectedOption) => {
		setSelectedValues((prevState) => {
			const updatedValues = [...prevState];
			updatedValues[index] = {
				parameter: selectedOption?.parameter,
				value: selectedOption?.value,
			};
			// {selectedValues.map((param, index) => (
			// 	console.log('selected ','index ',index,' ',param)
			// ))}
			return updatedValues;
		});
	};

	const handleSubmitPengamatan = async (
		e: React.ChangeEvent<HTMLFormElement>,
	) => {
		showLoader(true);
		e.preventDefault();
		const update_status = await TaskApi.updateStatusActivity(dataSelectedData, dataStatus);

		if (update_status) {
			const { example_observation_txt } = e.target;

			const payload = {
				leaf_condition_txt: null,
				land_condition_txt: null,
				watering_condition_txt: null,
				puppies_condition_txt: null,
				grain_condition_txt: null,
				hama_txt: null,
				example_observation_txt: example_observation_txt.value,
				time_calender_dte: daySelected,
				params_values: JSON.stringify(selectedValues),
			} as IObservartion;

			payload["user_id_int"] = ownerTask.user_id_int;
			payload["land_code_var"] = ownerTask.land_code_var;
			payload["period_plant_txt"] = ownerTask.period_plant_txt;

			let res = null;
			if (!data) {
				payload["created_by_var"] = userStorage.fullname_var;
				res = await TaskApi.createObservation(payload);
			} else {
				payload["updated_by_var"] = userStorage.fullname_var;
				res = await TaskApi.updateObservation(data.id_seq, payload);
			}

			// console.log("Observation :", res);
			showLoader(false);
			if (res.data && res.data.code === 0) {
				toast.success(res.data.message);
				setIsDataChanged(true);
				// e.target.reset()
				if (onSubmit) onSubmit(payload);
				if (onCancel) onCancel();
				// handleResetFormValue()
			} else {
				toast.error(res.data?.message || res.message);
			}
		} else {
			toast.error(update_status.data?.message || update_status.message);
		}
	};

	return (
		<>
			{!loadingParams ? (
				<form className="mt-5 md:px-4" onSubmit={handleSubmitPengamatan}>
					<div className="space-y-3">
						<div className="grid grid-cols-2 gap-5">
							{transformedOptions?.map((param, index) => (
								<div key={`parameter-pengamatan-${index}`} className="w-full">
									<label
										htmlFor={`parameter-pengamatan-${index}`}
										className="label-form">
										{param?.label}
									</label>
									<Select
										id={`parameter-pengamatan-${index}`}
										onChange={(event) =>
											handleSelectChange(index, {
												parameter: param?.label,
												value: event?.value,
											})
										}
										styles={selectStylesForm}
										options={param?.options}
										isClearable
										defaultValue={param?.options?.find(
											(po) =>
												po.value?.toLowerCase() ===
												selectedValues?.find((sv) =>
													sv?.parameter ? sv.parameter === param.label : [])?.value?.toLowerCase(),
										)}
										placeholder="- Pilih -"
										getOptionValue={(option: any) => option["label"]}
										getOptionLabel={(option: any) => option["value"]}
									/>
								</div>
							))}
						</div>

						<div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
							<div className="w-full">
								<label htmlFor="example_observation_txt" className="label-form">
									Catatan Pengamatan
								</label>
								<textarea
									required
									name="example_observation_txt"
									id="example_observation_txt"
									className="input-task"
									defaultValue={data?.example_observation_txt}
								/>
							</div>
						</div>
					</div>
					<footer className="flex justify-end mt-7 space-x-2">
						<button
							type="reset"
							className="text-gray-700 px-6 py-2.5 rounded-lg text-sm"
							onClick={onCancel}>
							Batal
						</button>
						<button
							type="submit"
							className="bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-lg text-sm">
							Simpan
						</button>
					</footer>
				</form>
			) : (
				<Loader />
			)}

			{loader && <Loader />}
		</>
	);
};

export default Pengamatan;
