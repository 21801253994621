import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { IProjectManagement } from "@api/project-api";
import FarmerGroupApi from "@api/farmergroup-api";
import TableUser from "@components/tables/Table";
import SearchField from "@components/SearchField";
import { toast } from "react-toastify";
import { ICultivatioLandProcess, deleteActivity, getByQueryCulltivation, updateCultivationLandProcess } from "@api/cultivationLandprocess-api";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ConfirmModal from "./ConfirmModal";

type Props = {
	data: ICultivatioLandProcess;
	onCancel: (e: React.MouseEvent) => any;
	onClose: (e: React.MouseEvent<HTMLElement>) => void;
	onSubmit?: (data: ICultivatioLandProcess | any) => void;
};

const ModalDetailActivity: React.FC<Props> = ({ data, onCancel, onClose, onSubmit, }) => {
	// hooks
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<any>({ criteriaMode: "all" });

	// states
	const [activities, setActivities] = useState<ICultivatioLandProcess[]>([]);
	const [selectedData, setSelectedData] = useState<any>(null);
	const [confirmDeleteDoc, showConfirmDeleteDoc] = useState(false);
	const [confirmDeleteActivities, showConfirmDeleteActivities] = useState(false);
	const [dataDoc, setDataDoc] = useState<any>(null);
	const [updateModal, showUpdateModal] = useState(false);
	const [loader, showLoader] = useState(false); // eslint-disable-line
	const [isUpdate, setIsUpdate] = useState(false);

	// functions
	const fetchActivity = async () => {
		const query = `id_cultivation_int=${data?.id_seq}`;
		const res = await getByQueryCulltivation(query);
		console.log('cultivation res ', res);
		setActivities(res.data.data);
	};

	const handleCloseUpdateModal = () => {
		showUpdateModal(false);
		showConfirmDeleteDoc(false);
		showConfirmDeleteActivities(false);
		setSelectedData(null);
		showLoader(false);
	};

	const handleDeleteDoc = async (e: React.MouseEvent<HTMLFormElement>) => {
		setIsUpdate(true);
		selectedData.images = selectedData.document_txt.filter(item => item !== dataDoc)
		delete selectedData.updated_by_var
		delete selectedData.document_txt
		delete selectedData.created_by_var
		delete selectedData.created_on_dtm
		delete selectedData.updated_on_dtm
		delete selectedData.status_int
		delete selectedData.activity_txt
		delete selectedData.material_txt
		delete selectedData.dose_txt
		delete selectedData.observation_value
		delete selectedData.example_observation_txt
		delete selectedData.id_cultivation_int
		delete selectedData.period_plant_txt
		delete selectedData.land_code_var

		var formData = new FormData();

		for (const key in selectedData) {
			if (key !== 'images') {
				formData.append(key, selectedData[key]);
			} else {
				for (let i = 0; i < selectedData.images.length; i++) {

					formData.append('images[]', selectedData.images[i]);
				}
				formData.append('deleted_images', dataDoc)
			}
		}

		formData.append('isDeleted', 'true');
		onSubmit(formData)
	};

	const handleDeleteActivities = async (e: React.MouseEvent<HTMLFormElement>) => {
		const res = await deleteActivity(selectedData.id_seq);
		console.log("Delete Activity :", res);
		if (res.data && res.data.code === 0) {
			const resA = activities.filter(
				(item) => item.id_seq !== selectedData.id_seq,
			);
			if (resA.length === 0) {
				window.location.reload();
			} else {
				setActivities(resA);
				showConfirmDeleteActivities(false);
				// setIsDataChanged(true);
			}

		} else {
			toast.error(res.data?.message || res.message);
		}
	};


	// effects
	const setdefaultValue = useCallback(
		(obj) => {
			Object.keys(obj).map((key) => {
				return setValue(key, obj[key], { shouldValidate: true });
			});
		},
		[setValue],
	);
	useEffect(() => {
		if (data) {
			setdefaultValue(data);
			fetchActivity();
		}
	}, [data, setdefaultValue]);


	return (
		<>

			<div className="modal-form">
				<div className="modal-form-outside" onClick={onClose} />
				<div
					className="modal-form-content bg-white p-5 w-[95%] md:w-6/12 2xl:w-5/12"
					style={{ maxHeight: "90vh" }}>
					<div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
						<div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
							<h3>Detail Aktifitas Petani</h3>
						</div>
						<button
							type="button"
							className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
							onClick={onClose}>
							<svg
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"></path>
							</svg>
						</button>
					</div>

					{activities?.map((item, key) => (
						<div className="w-full border mt-5 bg-white rounded-2xl md:p-6">
							<div className="flex flex-col mb-6 px-1 md:px-5 text-sm" key={key}>
								<div className="flex items-center justify-between">
									<h3 className="font-bold text-blue-500">
										Aktivitas {key + 1}
									</h3>
									<div className="top-0 right-0 space-x-2 m-2 text-black-200">
										<i
											className="fa-solid fa-pen cursor-pointer hover:text-gray-500"
											onClick={() => {
												setSelectedData(item);
												showUpdateModal(true);
											}}
										></i>
										<i
											className="fa-solid fa-trash cursor-pointer hover:text-gray-500"
											onClick={() => {
												setSelectedData(item);
												showConfirmDeleteActivities(true);
											}}
										></i>
									</div>
								</div>
								<h2 className="text-sm md:text-base font-semibold my-1.5">
									{item?.activity_txt}
								</h2>
								<table className="font-medium text-xs md:text-sm">
									<tbody>
										<tr>
											<td className="text-gray-500 pb-0.5 w-32 md:w-44">
												Tanggal Dilakukan
											</td>
											<td>{item?.date_performed_dtm.split("T")[0] || ""}</td>
										</tr>
										<tr>
											<td className="text-gray-500 pb-0.5 w-32 md:w-44">
												Bahan
											</td>
											<td>{item?.material_txt || ""}</td>
										</tr>
										<tr>
											<td className="text-gray-500 pb-0.5">Dosis</td>
											<td>{item?.dose_txt || ""}</td>
										</tr>
									</tbody>
								</table>

								<div className="flex flex-col mt-3 mb-5 px-1 md:px-1 text-sm">
									<div className="flex items-center justify-between">
										<h3 className="font-bold text-blue-500">Pengamatan</h3>
									</div>
									<table className="font-medium text-xs md:text-sm mt-3">
										<tbody>
											{item?.observation_value?.filter((par) => par != null)
												.map((param, index) => (
													<tr key={`param-${index}`}>
														<React.Fragment key={param.parameter}>

															<td className="text-gray-500 pb-1 w-36 md:w-60">
																{param.parameter ? param.parameter : ''}
															</td>
															<td>{String(param.value)}</td>
														</React.Fragment>
													</tr>
												))}
											<tr>
												<td className="text-gray-500 pb-1">Catatan Pengamatan</td>
												<td>{item?.example_observation_txt}</td>
											</tr>
											<tr>
												<td className="text-gray-500 pb-1">Dokumentasi Pengamatan</td>
												<td>
													{Object.keys(item).length !== 0 &&
														item.document_txt?.map((url: string, key: number) => (
															<div className="relative w-full h-full cursor-pointer hover:scale-110 hover:duration-500"
																key={key}>
																<LazyLoadImage
																	onClick={() => window.open(item.document_txt[key], "_blank")}
																	effect="blur"
																	alt={url}
																	src={url}
																	className="rounded-lg shadow-md h-48 object-cover"
																	key={key}
																/>
																<div className="absolute top-0 right-0 space-x-2 m-2 text-black-200">
																	<i
																		className="fa-solid fa-pen cursor-pointer hover:text-black-500"
																		onClick={() => {
																			setSelectedData(item);
																			showUpdateModal(true);
																			setDataDoc(item?.document_txt[key]);
																		}}
																	></i>
																	<i
																		className="fa-solid fa-trash cursor-pointer hover:text-black-500"
																		onClick={() => {
																			setSelectedData(item);
																			showConfirmDeleteDoc(true);
																			setDataDoc(item?.document_txt[key]);
																		}}
																	></i>
																</div>
															</div>
														))}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					))}

				</div>
			</div>

			{confirmDeleteDoc && (
				<ConfirmModal
					onCancel={handleCloseUpdateModal}
					onOK={handleDeleteDoc}
				/>
			)}
			{confirmDeleteActivities && (
				<ConfirmModal
					onCancel={handleCloseUpdateModal}
					onOK={handleDeleteActivities}
				/>
			)}
		</>
	);

};



export default ModalDetailActivity;
