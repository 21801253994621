import React, { useCallback, useContext, useRef, useState } from 'react';
import Dropzone from 'react-dropzone'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import TaskApi, { IDocumentation } from '../../../api/task-api';
import dropImg from '../../../assets/img/drop-img.png'
import { GlobalContext } from '../../../context/GlobalProvider';
import useMobileView from '../../../hooks/useMobileView';
import Loader from '../Loader';

type Props = {
    daySelected?: string,
    data?: IDocumentation,
    onSubmit?: (data: any) => any,
    onCancel?: (e?: React.MouseEvent<HTMLElement>) => any
};

interface IPhoto {
    id: any,
    file: any,
    base64: string | any
}

const Documentation: React.FC<Props> = ({ onCancel, daySelected }) => {
    const [arrPhoto, setArrPhoto] = useState<IPhoto[]>([])
    const [loader, showLoader] = useState(false)

    const { ownerTask, userStorage, setIsDataChanged } = useContext(GlobalContext)
    const dropzoneRef = useRef<any>();

    const openDialog = () => {
        // Note that the ref is set async,
        // so it might be null at some point 
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setArrPhoto(prevArray => [...prevArray, { id: new Date().getTime(), file: file, base64: binaryStr?.toString() }])
                // setDocumentUpload(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const handleDeletePhoto = (photo: IPhoto) => {
        const newArray = [...arrPhoto]
        setArrPhoto(newArray.filter(item => item.id !== photo.id))
    }

    const handleSubmitDocumentation = async (e: React.MouseEvent<HTMLButtonElement>) => {
        showLoader(true)
        for (const item of arrPhoto) {
            const formData = new FormData()

            formData.append('user_id_int', ownerTask.user_id_int.toString())
            formData.append('land_code_var', ownerTask.land_code_var)
            formData.append('period_plant_txt', ownerTask.period_plant_txt)
            formData.append('created_by_var', userStorage.fullname_var)
            formData.append('time_calender_dte', daySelected!)
            // formData.append('jumlah_txt', jumlah)
            // formData.append('satuan_txt', satuan)

            formData.append('images', item.file)

            console.log(Object.fromEntries(formData)) // Works if all fields are uniq

            const res = await TaskApi.addDocumentation(formData)
            if (res.data && res.data.code === 0) {
                toast.success('Success uploading documentation')
            } else {
                toast.error(res.data?.message || res.message)
            }
        }
        setIsDataChanged(true)
        showLoader(false)
        if (onCancel) {
            onCancel(e)
        }
    }


    return (
        <>
            <div className='mt-5 md:px-4'>
                {/* <div className="md:flex md:space-x-8 space-y-5 md:space-y-0 mb-5">
                    <div className="w-full">
                        <label htmlFor="jumlah" className="label-form">Jumlah HST</label>
                        <input type='number' name='jumlah_txt' id="jumlah" className="input-task" placeholder="18" value={jumlah} onChange={(e)=>setJumlah(e.target.value)} />
                    </div>
                    <div className="w-full">
                        <label htmlFor="satuan" className="label-form">Satuan HST</label>
                        <input id="satuan" name='satuan_txt' className="input-task" placeholder="ST" value={satuan} onChange={(e)=>setSatuan(e.target.value)} />
                    </div>
                </div> */}
                <h3 className='text-sm font-medium'>Dokumentasi Lapangan</h3>
                <div className='mt-5 h-64 rounded-2xl border-dashed border-2 border-gray-400 flex items-center justify-center text-center px-5'>
                    <Dropzone ref={dropzoneRef} onDrop={onDrop} multiple={false} noClick noKeyboard accept={{ 'image/*': ['.png', '.bmp', '.jpeg', '.jpg'] }}>
                        {({ getRootProps, getInputProps, acceptedFiles }) => {
                            return (
                                <div>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <LazyLoadImage effect='blur' alt='' src={dropImg} />
                                        <p className='text-sm md:text-base font-semibold mt-5 mb-1'>Drag dan drop gambar, atau <span className='text-blue-500 cursor-pointer hover:opacity-70 hover:underline' onClick={openDialog}>Browse</span></p>
                                        <p className='md:text-sm'>Foto atau gambar disarankan bertipe png, jpg, atau jpeg. Maks 10MB</p>
                                    </div>
                                </div>
                            );
                        }}
                    </Dropzone>
                </div>
                <div className='grid grid-cols-fit-100 gap-4'>
                    {arrPhoto[0] ?
                        <div className='h-[6.5rem] mt-5 border shadow-lg rounded-xl relative'>
                            <DeleteImageIC onClick={() => handleDeletePhoto(arrPhoto[0])} />
                            <img alt='' src={arrPhoto[0]?.base64} className='h-full w-full rounded-xl' />
                        </div>
                        :
                        <div className='flex justify-center items-center border-dashed border-2 border-gray-400 h-[6.5rem] mt-5 rounded-xl text-4xl text-gray-300 hover:text-gray-500 cursor-pointer hover:border-gray-500' onClick={openDialog}>
                            <i className="fa-solid fa-plus"></i>
                        </div>
                    }
                    {arrPhoto[1] ?
                        <div className='h-[6.5rem] mt-5 border shadow-lg rounded-xl relative'>
                            <DeleteImageIC onClick={() => handleDeletePhoto(arrPhoto[1])} />
                            <img alt='' src={arrPhoto[1]?.base64} className='h-full w-full rounded-xl' />
                        </div>
                        :
                        <div className='flex justify-center items-center border-dashed border-2 border-gray-400 h-[6.5rem] mt-5 rounded-xl text-4xl text-gray-300 hover:text-gray-500 cursor-pointer hover:border-gray-500' onClick={openDialog}>
                            <i className="fa-solid fa-plus"></i>
                        </div>
                    }
                    {arrPhoto[2] ?
                        <div className='h-[6.5rem] mt-5 border shadow-lg rounded-xl relative'>
                            <DeleteImageIC onClick={() => handleDeletePhoto(arrPhoto[2])} />
                            <img alt='' src={arrPhoto[2]?.base64} className='h-full w-full rounded-xl' />
                        </div>
                        :
                        <div className='flex justify-center items-center border-dashed border-2 border-gray-400 h-[6.5rem] mt-5 rounded-xl text-4xl text-gray-300 hover:text-gray-500 cursor-pointer hover:border-gray-500' onClick={openDialog}>
                            <i className="fa-solid fa-plus"></i>
                        </div>
                    }
                    {arrPhoto[3] ?
                        <div className='h-[6.5rem] mt-5 border shadow-lg rounded-xl relative'>
                            <DeleteImageIC onClick={() => handleDeletePhoto(arrPhoto[3])} />
                            <img alt='' src={arrPhoto[3]?.base64} className='h-full w-full rounded-xl' />
                        </div>
                        :
                        <div className='flex justify-center items-center border-dashed border-2 border-gray-400 h-[6.5rem] mt-5 rounded-xl text-4xl text-gray-300 hover:text-gray-500 cursor-pointer hover:border-gray-500' onClick={openDialog}>
                            <i className="fa-solid fa-plus"></i>
                        </div>
                    }
                    {arrPhoto[4] ?
                        <div className='h-[6.5rem] mt-5 border shadow-lg rounded-xl relative'>
                            <DeleteImageIC onClick={() => handleDeletePhoto(arrPhoto[4])} />
                            <img alt='' src={arrPhoto[4]?.base64} className='h-full w-full rounded-xl' />
                        </div>
                        :
                        <div className='flex justify-center items-center border-dashed border-2 border-gray-400 h-[6.5rem] mt-5 rounded-xl text-4xl text-gray-300 hover:text-gray-500 cursor-pointer hover:border-gray-500' onClick={openDialog}>
                            <i className="fa-solid fa-plus"></i>
                        </div>
                    }
                </div>
                <footer className='flex justify-end mt-7 space-x-2'>
                    <button type='reset' className='text-gray-700 px-6 py-2.5 rounded-lg text-sm' onClick={onCancel}>Batal</button>
                    <button type='submit' className='bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-lg text-sm' onClick={handleSubmitDocumentation}>Simpan</button>
                </footer>
            </div>

            {loader && <Loader />}
        </>
    );
}

export const DocumentationUpdate: React.FC<Props> = ({ onCancel, daySelected, data, onSubmit }) => {
    const [uploadPhoto, setUploadedPhoto] = useState<IPhoto | null>(null)
    const [loader, showLoader] = useState(false)

    const { ownerTask, userStorage, setIsDataChanged } = useContext(GlobalContext)
    const dropzoneRef = useRef<any>();
    const { windowWidth } = useMobileView()

    const openDialog = () => {
        // Note that the ref is set async,
        // so it might be null at some point 
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setUploadedPhoto({ id: new Date().getTime(), file: file, base64: binaryStr?.toString() })
                // setDocumentUpload(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const handleDeletePhoto = (photo: IPhoto) => {
        setUploadedPhoto(null)
    }

    const handleSubmitDocumentation = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if(!uploadPhoto) return

        showLoader(true)
        const formData = new FormData()

        formData.append('user_id_int', ownerTask.user_id_int.toString())
        formData.append('land_code_var', ownerTask.land_code_var)
        formData.append('period_plant_txt', ownerTask.period_plant_txt)
        formData.append('updated_by_var', userStorage.fullname_var)
        formData.append('time_calender_dte', daySelected!)

        formData.append('images', uploadPhoto?.file)

        console.log(Object.fromEntries(formData)) // Works if all fields are uniq

        const res = await TaskApi.updateDocumentation(data?.id_seq!, formData)
        showLoader(false)
        if (res.data && res.data.code === 0) {
            toast.success('Success uploading documentation')
            setIsDataChanged(true)
            const payload = { ...data }
            payload.document_txt = [uploadPhoto?.base64]
            if (onSubmit) onSubmit(payload)
        } else {
            toast.error(res.data?.message || res.message)
        }
    }
    
    
    return (
        <>
            <div className='mt-5 md:px-4'>
                <div className='flex item-center justify-center flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0'>
                    <div className='w-full h-64'>
                        <LazyLoadImage effect='blur' alt='' src={data?.document_txt[0]} className='object-cover rounded-2xl h-64 w-max shadow-lg' />
                    </div>
                    <div className='md:h-64 flex justify-center items-center text-xl text-gray-600'>
                        {windowWidth > 768 ?
                            <i className="fa-solid fa-angles-right"></i>
                            :
                            <i className="fa-solid fa-angles-down"></i>
                        }
                    </div>
                    {uploadPhoto ?
                        <div className='w-full h-64 relative'>
                            <LazyLoadImage effect='blur' alt='' src={uploadPhoto.base64} className='object-cover rounded-2xl h-64 w-max shadow-lg' />
                            <DeleteImageIC onClick={handleDeletePhoto} />
                        </div>
                        :
                        <div className='h-64 rounded-2xl border-dashed border-2 border-gray-400 flex items-center justify-center text-center px-5'>
                            <Dropzone ref={dropzoneRef} onDrop={onDrop} multiple={false} noClick noKeyboard accept={{ 'image/*': ['.png', '.bmp', '.jpeg', '.jpg'] }}>
                                {({ getRootProps, getInputProps, acceptedFiles }) => {
                                    return (
                                        <div>
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <LazyLoadImage effect='blur' alt='' src={dropImg} />
                                                <p className='text-sm md:text-base font-semibold mt-5 mb-1'>Drag dan drop gambar, atau <span className='text-blue-500 cursor-pointer hover:opacity-70 hover:underline' onClick={openDialog}>Browse</span></p>
                                                <p className='md:text-sm'>Foto atau gambar disarankan bertipe png, jpg, atau jpeg. Maks 10MB</p>
                                            </div>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                        </div>
                    }
                </div>
                <footer className='flex justify-end mt-7 space-x-2'>
                    <button type='reset' className='text-gray-700 px-6 py-2.5 rounded-lg text-sm' onClick={onCancel}>Batal</button>
                    <button type='submit' className='bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-lg text-sm' onClick={handleSubmitDocumentation}>Simpan</button>
                </footer>
            </div>

            {loader && <Loader />}
        </>
    );
}

const DeleteImageIC = ({ onClick }: any) => {
    return (
        <span className='flex justify-center items-center w-5 h-5 text-white rounded-full bg-red-500 absolute -right-1.5 -top-1.5 hover:opacity-80 cursor-pointer' onClick={onClick}>
            <i className="fa-solid fa-xmark"></i>
        </span>
    )
}

export default Documentation;