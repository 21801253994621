import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOneSaprodiDistributionbyId, getAllSaprodiDistribution, ISaprodiDistribution } from "@api/saprodidistribution-api";
import { ButtonAdd } from "../../../components/button/CustomButton";
import Dropdown from "../../../components/dropdown/Dropdown";
import BPDetailForm from "../../../components/modal/budgetplan/BPDetailForm";
import BudgetDetailApprovalModal from "../../../components/modal/budgetplan/BudgetDetailApprovalModal";
import Loader from "../../../components/modal/Loader";
import SearchField from "../../../components/SearchField";
import TableUser from "../../../components/tables/Table";
import Helper from "../../../helpers/helper";
import { ActionType } from "../../../reduxs/action/actions";

// type Props = {};

const SaprodiDistributionDetail = () => {
	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [modalDetail, showModalDetail] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [dataTotalSaprodi, setTotalSaprodi] = useState(0);
	const [dataSaprodiDistribution, setDataSaprodiDistribution] = useState<ISaprodiDistribution | null>(null);
	const [originData, setOriginData] = useState<ISaprodiDistribution[] | []>([]);
	const [filterData, setFilterData] = useState<ISaprodiDistribution[] | []>([]);
	const [selectedData, setSelectedData] = useState<ISaprodiDistribution | null>(null);
	const { id }: any = useParams();
	const user = useSelector((state: any) => state.user);
	const dispatch = useDispatch();

	const fetchData = useCallback(async () => {
		showLoader(true);
		const res = await getOneSaprodiDistributionbyId(id);

		showLoader(false);
		console.log("Saprodi DETAIL ==>", res.data.data);
		let TotalSaprodi = 0
		if (res.data) {
			if (res.data.code === 0) {
				setDataSaprodiDistribution(res.data.data);
				setOriginData(res.data.data.material_support);
				setFilterData(res.data.data.material_support);
				for (let i = 0; i < res.data.data.material_support.length; i++) {
					TotalSaprodi += (parseInt(res.data.data.material_support[i].price) * res.data.data.material_support[i].quantity)
				}
				setTotalSaprodi(TotalSaprodi);
				
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
		// eslint-disable-next-line
	}, [id]);

	useEffect(() => {
		dispatch({ type: ActionType.SET_TITLE_BAR, value: "Daftar Saprodi" });
		fetchData();
	}, [fetchData, dispatch, id]);

	const handleEditData = (selectedData: any) => {
		setSelectedData(selectedData);
		setIsUpdate(true);
		showModalForm(true);
	};


	const handleReceiveDataForm = async (data: FormData) => {
		const latestTotal =
			Number(data.get("quantity_var")) * Number(data.get("price_var"));

		// const currentTotal = calculateTotalPrice();

		// if (
		//   latestTotal + Number(currentTotal) >
		//   Number(dataSaprodiDistribution?.budget_plan_var)
		// ) {
		//   toast.error("Tidak boleh melebihi total Distribusi Saprodi");
		//   return false;
		// }

		showLoader(true);

		let res = null;
		// if (!isUpdate) {
		//   data.append("saprodi_distribution_id_int", id);
		//   data.append("created_by_var", user.fullname_var);
		//   res = await BudgetDetailApi.createNew(data);
		// } else {
		//   data.append("saprodi_distribution_id_int", id);
		//   data.append("updated_by_var", user.fullname_var);
		//   res = await BudgetDetailApi.update(selectedData?.id_seq!, data);
		// }

		for (var pair of data.entries() as any) {
			console.log(pair[0] + ", " + pair[1]);
		}

		console.log("Saprodi Distribution DETAIL ACTION ==>", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);
				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	//   const handleDeleteData = async (data: any) => {
	//     showLoader(true);
	//     const res = await BudgetDetailApi.delete(data.id_seq!);

	//     console.log("Saprodi Distribution DETAIL ACTION ==>", res);
	//     if (res.data) {
	//       if (res.data.code === 0) {
	//         toast.success(res.data.message);
	//         fetchData();
	//         resetForm();
	//       } else {
	//         toast.error(res.data.message);
	//         showLoader(false);
	//       }
	//     } else {
	//       toast.error(res.status + " " + res.message || "Connection timeout");
	//     }
	//   };

	//   const handleApply = async (data: IBudgetDetail, request: boolean) => {
	//     showLoader(true);
	//     const payload = {
	//       updated_by_var: user.fullname_var,
	//     };

	//     let res;
	//     if (request) {
	//       res = await BudgetDetailApi.request(data.id_seq!, payload);
	//     } else {
	//       res = await BudgetDetailApi.draft(data.id_seq!, payload);
	//     }

	//     console.log("BUDGETPLAN DETAIL APPLY ==>", res);
	//     if (res.data) {
	//       if (res.data.code === 0) {
	//         toast.success(res.data.message);
	//         fetchData();
	//         resetForm();
	//       } else {
	//         toast.error(res.data.message);
	//         showLoader(false);
	//       }
	//     } else {
	//       toast.error(res.status + " " + res.message || "Connection timeout");
	//     }
	//   };

	const resetForm = () => {
		setSelectedData(null);
		setIsUpdate(false);
		showModalForm(false);
		showLoader(false);
		showModalDetail(false);
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		const newData = [...originData];
		if (event.target.value) {
		  const filtered = newData.filter((item) => {
		    return (
		      item.name
		        ?.toLowerCase()
		        .includes(event.target.value.toLowerCase()) ||
		      item.unit
		        ?.toLowerCase()
		        .includes(event.target.value.toLowerCase()) ||
		      item.quantity
		        ?.toString()
		        .toLowerCase()
		        .includes(event.target.value.toLowerCase())
		    );
		  });

		  setFilterData(filtered);
		} else {
		  setFilterData(originData);
		}
	};

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="text-left pl-4">{row.index + 1}.</div>
			),
			width: 10,
		},
		{
			Header: "Saprodi",
			accessor: "name",
		},
		{
			Header: "Satuan",
			accessor: "unit",
		},
		{
			Header: "Kuantitas",
			accessor: "quantity",
		},
		{
			Header: () => <div className="pl-2 text-center">Harga</div>,
			accessor: "price",
			
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<div className="pl-2 text-center">
						Rp. {Helper.amount(`${parseFloat(data.price) * parseFloat(data.quantity)}`)}
					</div>
				);
			},
		},
		// {
		//   Header: "Action",
		//   Cell: ({ row }: any) => {
		//     const data = row.original;
		//     const status = data.status_int;
		//     // 100

		//     return (
		//       <Dropdown
		//         onDetail={() => {
		//           setSelectedData(data);
		//           showModalDetail(true);
		//         }}
		//         onEdit={() => handleEditData(data)}
		//         // onDelete={() => handleDeleteData(data)}
		//         // onApply={status === 100 ? () => handleApply(data, true) : undefined}
		//         // onCancelApply={
		//         //   status === 150 ? () => handleApply(data, false) : undefined
		//         // }
		//       />
		//     );
		//   },
		// },
	];

	return (
		<>
			<div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
				<div className="bg-white rounded-2xl shadow-2xl p-5 md:p-6 2xl:px-7">
					<h1 className="font-bold uppercase text-xl mt-2">
						Distribusi Saprodi Petani
					</h1>

					<nav
						className="flex my-5 py-4 px-2 md:px-5 text-gray-700 rounded-lg border bg-blue-50 border-blue-100"
						aria-label="Breadcrumb">
						<ol className="inline-flex items-center space-x-1 md:space-x-3">
							<li className="inline-flex items-center">
								<Link
									to="/dashboard/operating-parameter-saprodi-distribution"
									className="inline-flex items-center text-sms md:text-sm font-semibold text-blue-500 hover:opacity-75">
									<svg
										className="mr-1 md:mr-2 w-4 h-4"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
									</svg>
									pembagian saprodi
								</Link>
							</li>
							<li>
								<div className="flex items-center">
									<svg
										className="w-6 h-6 text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
											clipRule="evenodd"></path>
									</svg>
									<span className="ml-1 text-sms md:text-sm font-bold text-slate-800 hover:opacity-75 cursor-pointer">
										Distribusi Saprodi Petani
									</span>
								</div>
							</li>
						</ol>
					</nav>

					<div className="flex flex-col md:flex-row justify-between items-center p-3 md:px-5 md:py-4 border rounded-2xl mb-5">
						<div className="flex items-center mb-3 md:mb-0">
							<span className="flex items-center justify-center w-18 h-18 2xl:w-20 2xl:h-20 bg-gray-200 text-gray-400 rounded-lg text-xl">
								<i className="fa-solid fa-user"></i>
							</span>
							<div className="flex flex-col ml-4">
								<h3 className="font-bold text-2xl">
									Periode Tanam {dataSaprodiDistribution?.period_plant_txt}
								</h3>
								<span className="text-base">
									Luas Lahan {dataSaprodiDistribution?.land_area_var} Ha
								</span>
							</div>
						</div>
						<div className="flex flex-col text-center md:text-right">
							<h4 className="font-medium text-sm">Total Distribusi Saprodi</h4>
							<span className="text-2xl text-lightcayn font-bold">
								Rp{Helper.amount(dataSaprodiDistribution?.land_price_int)}
							</span>
						</div>
					</div>

					<div className="mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
						<h2 className="font-bold text-xl">Daftar Distribusi Saprodi</h2>
						<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
							<SearchField
								onChange={handleSearch}
								placeholder="Cari Saprodi..."
							/>
							<ButtonAdd onClick={() => showModalForm(true)} />
						</div>
					</div>
					{filterData && (
						<TableUser dataTable={filterData} columnTable={columns} />
					)}
				</div>
			</div>

			{/* {modalDetail && (
        <BudgetDetailApprovalModal data={selectedData!} onCancel={resetForm} />
      )}
      {modalForm && (
        <BPDetailForm
          onCancel={resetForm}
          onSubmit={handleReceiveDataForm}
          data={selectedData!}
        />
      )} */}
			{loader && <Loader />}
		</>
	);
};

export default SaprodiDistributionDetail;
