import { useEffect, useState } from "react";
import {
  dropdownProductList,
  dropdownProductListByType,
  dropdownProductType,
  dropdownUniqueCode,
  IProductList,
} from "../api/inventory-api";

export function useDropdownProductType(isRefreshing: boolean = false) {
  const [productTypeList, setProductTypeList] = useState<any[]>([]);
  const [loadingProductType, setLoading] = useState(true);
  const [errorProductType, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await dropdownProductType();

      console.log("Dropdown Product Type :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setProductTypeList(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchData();
  }, [isRefreshing]);

  return { productTypeList, errorProductType, loadingProductType };
}

export function useDropdownProductListByType(
  prductTypeId: number,
  isRefreshing: boolean = false,
) {
  const [productList, setProductList] = useState<IProductList[]>([]);
  const [loadingProduct, setLoading] = useState(true);
  const [errorProduct, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await dropdownProductListByType(prductTypeId || 0);

      console.log("Dropdown Product List By Type :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setProductList(res.data.data);
        } else {
          if (prductTypeId) {
            setProductList([]);
          }
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchData();
  }, [prductTypeId, isRefreshing]);

  return { productList, errorProduct, loadingProduct };
}

export function useDropdownProductList() {
  const [productList, setData] = useState<IProductList[]>([]);
  const [loadingProduct, setLoading] = useState(true);
  const [errorProduct, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await dropdownProductList();

      console.log("DD PRODUCT LIST ==>", res);
      if (res.data) {
        if (res.data.code === 0) {
          setData(res.data.data);
        } else {
          setData([]);
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  return { productList, errorProduct, loadingProduct };
}

export function useDropdownUniqueCode() {
  const [uniqueCodeList, setData] = useState<any[]>([]);
  const [loadingUniqueCode, setLoading] = useState(true);
  const [errorUniqueCode, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await dropdownUniqueCode();

      console.log("DD UNIQUECODE ==>", res);
      if (res.data) {
        if (res.data.code === 0) {
          setData(res.data.data);
        } else {
          setData([]);
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  return { uniqueCodeList, errorUniqueCode, loadingUniqueCode };
}
