import axios from "axios";
import { baseURL } from "./common-api";

export interface IChemical {
  id_seq?: number;
  name: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export const getAll = async () => {
  try {
    const data = await axios.get(
      baseURL + "/api/chemical-management/chemical/get-all",
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNew = async (payload: IChemical) => {
  try {
    const data = await axios.post(
      baseURL + "/api/chemical-management/chemical/create",
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const update = async (productTypeId: number, payload: IChemical) => {
  try {
    const data = await axios.patch(
      baseURL + "/api/chemical-management/chemical/update/" + productTypeId,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteData = async (productTypeId: number) => {
  try {
    const data = await axios.delete(
      baseURL + "/api/chemical-management/chemical/delete/" + productTypeId,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const dropdownChemicalName = async () => {
  try {
    const data = await axios.get(
      baseURL + "/api/chemical-management/chemical/dropdownChemical",
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
