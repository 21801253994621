import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NotFoundContainer from "./containers/NotFoundContainer";
import { ContextWrapper } from "./context/GlobalProvider";
import Auth from "./pages/Authpage";
import Home from "./pages/Homepage";
import QRProductDetail from "./pages/QRProductDetail";

function App() {
  return (
    <>
      <ContextWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/auth/*" element={<Auth />} />
            <Route path="/dashboard/*" element={<Home />} />
            <Route path="/qr-product-detail" element={<QRProductDetail />} />
            <Route path="/qr-product" element={<QRProductDetail />} />
            <Route
              path="*"
              element={
                <NotFoundContainer className="min-screen mt-[60%] md:mt-[20%]" />
              }
            />
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
          </Routes>
        </BrowserRouter>
      </ContextWrapper>
      <div className="text-sm font-medium font-poppins">
        <ToastContainer
          toastStyle={{
            color: "#222244",
            fontWeight: 500,
            fontFamily: "poppins",
          }}
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default App;
