import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BudgetDetailApi, IBudgetDetail } from "../../../api/budgetplan-api";
import Loader from "../../../components/modal/Loader";
import SearchField from "../../../components/SearchField";
import TableUser from "../../../components/tables/Table";
import { IRecentPage } from "../../../context/ContextInterface";
import { GlobalContext } from "../../../context/GlobalProvider";
import Helper from "../../../helpers/helper";
import { ActionType } from "../../../reduxs/action/actions";

const TITLE_BAR = "Budget Management / Budget Plan Approval List";

type Props = {};

const BugetApprovalList: React.FC<Props> = () => {
  const [loader, showLoader] = useState(false);
  const [originData, setOriginData] = useState<IBudgetDetail[] | []>([]);
  const [filterData, setFilterData] = useState<IBudgetDetail[] | []>([]);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { dispatchCalPage } = useContext(GlobalContext);

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: "push", payload });
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
  }, [dispatch, dispatchCalPage, pathname]);

  const fetchData = useCallback(async () => {
    showLoader(true);
    const res = await BudgetDetailApi.getAll();

    showLoader(false);
    console.log("BUDGETPLAN SUBMIT LIST ==>", res);
    if (res.data) {
      if (res.data.code === 0) {
        setOriginData(res.data.data);
        setFilterData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, dispatch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const newData = [...originData];
    if (event.target.value) {
      const filtered = newData.filter((item) => {
        return (
          item.activity_txt
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.category_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.area_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.quantity_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.price_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.total_price_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.satuan_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      });

      setFilterData(filtered);
    } else {
      setFilterData(originData);
    }
  };

  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }: any) => (
        <div className="pl-4 text-left">{row.index + 1}.</div>
      ),
    },
    {
      Header: "Kegiatan",
      accessor: "activity_txt",
    },
    {
      Header: "Kategori",
      accessor: "category_var",
    },
    {
      Header: "Luas",
      accessor: "area_var",
    },
    {
      Header: "Jumlah",
      accessor: "quantity_var",
    },
    {
      Header: "Satuan",
      accessor: "satuan_var",
    },
    {
      Header: "Harga (Rp)",
      accessor: "price_var",
      Cell: ({ value }: any) => {
        return Helper.amount(value);
      },
    },
    {
      Header: "Total (Rp)",
      accessor: "total_price_var",
      Cell: ({ value }: any) => {
        return Helper.amount(value);
      },
    },
    {
      Header: "PIC",
      accessor: "created_by_var",
    },
    {
      Header: "Status",
      accessor: "status_int",
      Cell: ({ value, row }: any) => {
        const data = row.original;
        const statusName = data.status_name_var;

        // 1 active, -5 inactive, 100 = draft, 150 = request to aproval, 400 rejected
        if (value === -5 || value === 400) {
          return (
            <span className="px-3 py-1 text-xs font-semibold text-white capitalize bg-red-500 rounded-full shadow">
              {statusName}
            </span>
          );
        } else if (value === 100) {
          return (
            <span className="px-3 py-1 text-xs font-semibold text-white capitalize rounded-full shadow bg-slate-400">
              {statusName}
            </span>
          );
        } else if (value === 150) {
          return (
            <span className="px-3 py-1 text-xs font-semibold text-white capitalize bg-blue-500 rounded-full shadow">
              {statusName}
            </span>
          );
        } else {
          return (
            <span className="px-3 py-1 text-xs font-semibold text-white capitalize bg-green-500 rounded-full shadow">
              {statusName}
            </span>
          );
        }
      },
    },
    {
      Header: "Action",
      Cell: ({ row }: any) => {
        const data = row.original;

        return (
          <Link to={"/dashboard/budget-management-approval/" + data.id_seq}>
            <i className="text-base cursor-pointer fa-solid fa-eye hover-anim hover:text-blue-500"></i>
          </Link>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
      <div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
        <div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
          <h1 className="text-xl font-bold uppercase">Budget Approval LIST</h1>
          <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
            <SearchField
              onChange={handleSearch}
              placeholder="Cari budgetplan..."
            />
          </div>
        </div>
        <TableUser dataTable={filterData} columnTable={columns} />
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default BugetApprovalList;
