import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { IResearchManagement } from "@api/researchManagement-api";

type Props = {
    data: IResearchManagement;
    onClose: (e: React.MouseEvent<HTMLElement>) => void;
};

const ModalDetailResearch: React.FC<Props> = ({ data, onClose }) => {
    // hooks

    // states
    const [selectedData, setSelectedData] = useState<any>(null);

    // functions
    const fetchDataResearch = async () => {
        // console.log('data research ', data);
        setSelectedData(data)
    };

    const groupedData = selectedData?.variable_var.reduce((acc, item) => {
        const lastEntry = acc[acc.length - 1];

        if (!lastEntry || lastEntry.category !== item.category) {
            // Initialize values with item.val if it's not a string
            acc.push({
                category: item.category,
                variables: item.variable !== item.category ? [item.variable] : [],
                subVariables: [item.sub_variable],
                values: typeof item.value !== 'string' ? [item.value] : []
            });
        } else {
            if (item.variable !== item.category) {
                lastEntry.variables.push(item.variable);
            }
            lastEntry.subVariables.push(item.sub_variable);
            if (typeof item.value !== 'string') {
                lastEntry.values.push(item.value);
            }
        }

        return acc;
    }, []);


    // effects
    useEffect(() => {
        if (data) {
            fetchDataResearch();
        }
    }, [data]);


    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                <div
                    className="modal-form-content bg-white p-5 w-[95%] md:w-6/12 2xl:w-5/12"
                    style={{ maxHeight: "90vh" }}>
                    <div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
                        <div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
                            <h3>Research Management</h3>
                        </div>
                        <button
                            type="button"
                            className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onClose}>
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>

                    {selectedData && (
                        <div className="w-full border mt-5 bg-white rounded-2xl md:p-6">
                            <div className="flex flex-col mb-6 px-1 md:px-5 text-sm">
                                <div className="flex flex-col mt-3 mb-5 px-1 md:px-1 text-sm">
                                    <div className="flex items-center justify-between">
                                        <h3 className="font-bold text-blue-500">General</h3>
                                    </div>
                                    <table className="font-medium text-xs md:text-sm mt-3">
                                        <tbody>
                                            <tr>
                                                <td className="text-gray-500 pb-1">Judul</td>
                                                <td>{selectedData?.title_research_var || "null"}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-gray-500 pb-1">Tanggal</td>
                                                <td>{selectedData?.created_on_dtm.split("T")[0] || "null"}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-gray-500 pb-1">Kode Lahan</td>
                                                <td>{selectedData?.research_land_code_var || "null"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />

                                    <div className="flex items-center justify-between">
                                        <h3 className="font-bold text-blue-500">Treatment</h3>
                                    </div>
                                    <table className="font-medium text-xs md:text-sm mt-3">
                                        <tbody>
                                            <tr>
                                                <td className="text-gray-500 pb-1">Total Perlakuan</td>
                                                <td>{selectedData?.total_treatment_int || "null"} Perlakuan</td>
                                            </tr>
                                            {selectedData?.treatment_var.map((item, key) => (
                                                <tr key={`param-${key}`}>
                                                    <React.Fragment key={key}>
                                                        <td className="text-gray-500 pb-1 w-36 md:w-60">
                                                            Perlakuan ke {key + 1}
                                                        </td>
                                                        {
                                                            <td className="text-gray-500 pb-1 w-36 md:w-60">
                                                                {selectedData?.treatment_var[key].map((treatment) =>
                                                                    `${treatment.material_research_var.name} / ${treatment.dose_material_var}`
                                                                ).join('; ')}
                                                            </td>
                                                        }
                                                    </React.Fragment>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="text-gray-500 pb-1">Total Ulangan</td>
                                                <td>{selectedData?.total_repetition_int || "null"} Ulangan</td>
                                            </tr>
                                            <tr>
                                                <td className="text-gray-500 pb-1">Total Unit</td>
                                                <td>{selectedData?.total_unit_int || "null"} unit/ulangan</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />

                                    <div className="flex items-center justify-between">
                                        <h3 className="font-bold text-blue-500">Variable</h3>
                                    </div>
                                    <table className="font-medium text-xs md:text-sm mt-3">
                                        <tbody>
                                            {groupedData.map((data, index) => {
                                                // Create a unique array of variables
                                                const uniqueVariables = Array.from(new Set(data.variables));
                                                return (
                                                    <tr key={`param-${index}`}>
                                                        <td className="text-gray-500 pb-1 w-36 md:w-60">
                                                            {data.category || null}
                                                        </td>
                                                        <td className="text-gray-500 pb-1 w-36 md:w-60">
                                                            {uniqueVariables.join(';')}
                                                        </td>
                                                        <td className="text-gray-500 pb-1 w-36 md:w-60">
                                                            {data.subVariables.join('; ')}
                                                        </td>
                                                        <td className="text-gray-500 pb-1 w-36 md:w-60">
                                                            {data.values.join(', ')}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};



export default ModalDetailResearch;
