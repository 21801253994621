import { useEffect, useState } from "react";
import { getParams, getParamValues } from "@api/parameterpascapanen-api";

export function useDropdownPascaPanenParams() {
  const [dataParams, setDataParams] = useState<any>([]);
  const [loadingParams, setLoading] = useState(false);
  const [errorParams, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownParams() {
      setLoading(true);
      let res = await getParams();

      console.log("DD pasca panen params ref :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataParams(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownParams();
  }, []);

  return { dataParams, errorParams, loadingParams };
}

export function useDropdownPascaPanenParamValues() {
  const [dataParamValues, setDataParamValues] = useState<any>([]);
  const [loadingParamValues, setLoading] = useState(false);
  const [errorParamValues, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownParamValues() {
      setLoading(true);
      let res = await getParamValues();

      console.log("DD pasca panen paramValues ref :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataParamValues(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownParamValues();
  }, []);

  return { dataParamValues, errorParamValues, loadingParamValues };
}
