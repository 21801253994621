import { useEffect, useState } from "react";
import { dropdownVariety, IVariety } from "../api/variety-api";
import { dropdownMaterialSupport, MaterialSupport } from "@api/materialsupport-api";

export default function useDropdownMaterialSupport(isRefreshing: boolean = false) {
  const [dataMaterialSupport, setDataMaterialSupport] = useState<MaterialSupport[]>([]);
  const [loadingMaterialSupport, setLoading] = useState(false);
  const [errorMaterialSupport, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownMaterialSupport() {
      setLoading(true);
      let res = await dropdownMaterialSupport();

      console.log("DD MaterialSupport :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataMaterialSupport(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownMaterialSupport();
  }, [isRefreshing]);

  return { dataMaterialSupport, errorMaterialSupport, loadingMaterialSupport };
}
