import { useEffect, useState } from "react";
import LandAreaApi from "../api/landarea-api";


export default function useDropdownFieldAssistant(isRefreshing: boolean = false) {
  const [dataFieldAssistant, setDataFieldAssistant] = useState<any[]>([]);
  const [loadingFieldAssistant, setLoadingFieldAssistant] = useState(false);
  const [errorFieldAssistant, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownFieldAssistant() {
      setLoadingFieldAssistant(true);
      let res = await LandAreaApi.getDropdownFieldAsistant();

      console.log("DD FieldAssistant :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataFieldAssistant(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoadingFieldAssistant(false);
    }

    fetchDropdownFieldAssistant();
  }, [isRefreshing]);

  return { dataFieldAssistant, errorFieldAssistant, loadingFieldAssistant };
}
