import axios from "axios";
import { baseURL } from "./common-api";

export interface IMaterialResearch {
    id_seq?: number;
    name: string;
    created_by_var?: string;
    created_on_dtm?: string;
    updated_by_var?: string;
    updated_on_dtm?: string;
}

export const getAllMaterialResearch = async () => {
    try {
        const data = await axios.get(baseURL + "/api/material-research-management/getAll", {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return data;
    } catch (error: any) {
        if (error.response) return error.response;
        else return JSON.parse(JSON.stringify(error));
    }
};

export const createNewMaterialResearch = async (payload: IMaterialResearch) => {
    try {
        const data = await axios.post(baseURL + "/api/material-research-management/create", payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return data;
    } catch (error: any) {
        if (error.response) return error.response;
        else return JSON.parse(JSON.stringify(error));
    }
};

export const updateMaterialResearch = async (materialResearchID: number, payload: IMaterialResearch) => {
    try {
        const data = await axios.patch(
            baseURL + "/api/material-research-management/update/" + materialResearchID,
            payload,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            },
        );
        return data;
    } catch (error: any) {
        if (error.response) return error.response;
        else return JSON.parse(JSON.stringify(error));
    }
};

export const deleteMaterialResearch = async (materialResearchID: number) => {
    try {
        const data = await axios.delete(
            baseURL + "/api/material-research-management/delete/" + materialResearchID,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            },
        );
        return data;
    } catch (error: any) {
        if (error.response) return error.response;
        else return JSON.parse(JSON.stringify(error));
    }
};

// export const disableMaterialResearch = async (materialResearchID: number) => {
//   try {
//     const data = await axios.delete(
//       baseURL + "/api/material-research-management/disable/" + materialResearchID,
//       {
//         headers: {
//           authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//       },
//     );
//     return data;
//   } catch (error: any) {
//     if (error.response) return error.response;
//     else return JSON.parse(JSON.stringify(error));
//   }
// };

export const dropdownMaterialResearch = async () => {
    try {
        const data = await axios.get(baseURL + "/api/material-research-management/dropdown", {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return data;
    } catch (error: any) {
        if (error.response) return error.response;
        else return JSON.parse(JSON.stringify(error));
    }
};
