export const SkeletonTable = () =>
    <>
        <div className="shadow rounded-md p-4 w-full">
            <div className="animate-pulse1s flex space-x-4">
                <div className="flex-1 py-1">
                    <div className="h-12 bg-slate-300 rounded-md mb-6"></div>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        {[0, 1, 2, 3, 4, 5].map((key) =>
                            <div key={key} className="h-6 bg-slate-300 rounded-md"></div>
                        )}
                    </div>
                    <div className="flex items-center justify-between mt-10">
                        <div className="h-4 bg-slate-300 rounded-md w-20 md:w-40 2xl:w-52"></div>
                        <div className="h-4 bg-slate-300 rounded-md w-20 md:w-40 2xl:w-52"></div>
                    </div>
                </div>
            </div>
        </div>
    </>

export const SkeletonQRDetail = () =>
    <>
        <div className="space-x-4 w-full md:w-[768px] bg-white rounded-xl shadow-2xl flex flex-col">
            <div className="bg-slate-300 w-full h-20 md:h-24 rounded-xl"></div>

            <div className="flex flex-col animate-pulse1s overflow-y-auto" style={{maxHeight: '80vh'}}>
                <div className="flex flex-col-reverse md:flex-row items-center justify-between p-5 pr-8 md:space-x-8">
                    <div className="flex flex-col items-center md:items-start w-8/12 mt-5 md:mt-0">
                        <span className="bg-slate-300 w-40 h-6"></span>
                        <span className="bg-slate-300 w-64 h-8 mt-4 mb-8"></span>
                        <span className="bg-slate-300 w-full h-8"></span>
                    </div>
                    <div className="flex items-center flex-col">
                        <span className="w-36 h-36 bg-slate-300 rounded"></span>
                        <span className="bg-slate-300 w-24 h-5 mt-4"></span>
                    </div>
                </div>

                <hr className="border mt-4 mb-5" />
                
                <span className="bg-slate-300 w-40 h-6 ml-5"></span>
                <div className="p-5 pr-8">
                    <div className="border border-gray-200 rounded-2xl flex flex-col">
                        <div className="border-b px-5 py-4">
                            <div className="bg-slate-300 w-64 h-8"></div>
                        </div>
                        <div className="p-5 grid grid-cols-2 md:grid-cols-3 gap-4">
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                            <span className="bg-slate-300 h-8"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>