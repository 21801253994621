import React, { MouseEvent } from "react";

type Props = {
	message?: string,
	onOK?: (e: MouseEvent<HTMLElement>) => void,
	onSubmit?: (e: MouseEvent<HTMLElement>) => void,
	onCancel: (e: MouseEvent<HTMLElement>) => void,
	icon?: string,
};


const ConfirmModal: React.FC<Props> = ({ message, onCancel, onOK, icon, onSubmit }: Props) => {
	return <>
		<div className="modal-form">
			<div className="modal-form-outside" onClick={onCancel} />

			{/* <!-- Modal content --> */}
			<div className="w-11/12 md:w-5/12 2xl:w-4/12 rounded-lg shadow bg-soft z-[999]">
				{/* <!-- Modal header --> */}
				<div className="flex justify-end p-2">
					<button type="button" className="text-gray-400 bg-transparent rounded-xl text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
						onClick={onCancel}
					>
						<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
					</button>
				</div>

				{/* <!-- Modal body --> */}
				<div className="p-6 pt-0 text-center">
					{!icon ?
						<svg className="mx-auto w-18 h-18 text-slate-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
						:
						<i className={`${icon} text-slate-500 text-6xl mb-1`}></i>
					}
					<h3 className="my-8 text-base font-normal text-slate-700">{message || 'Yakin untuk menghapus data ini?'}</h3>
					<div className="flex items-center justify-center space-x-5 md:space-x-8 2xl:space-x-10">
						{onOK ? <button type="button" className="btn-danger" onClick={onOK}>Ya, saya yakin</button> : ''}
						{onSubmit ? <button type="button" className="btn-primary" onClick={onSubmit}>Ya, simpan</button> : ''}
						<button type="button" className="btn-secondary" onClick={onCancel}>Tidak, batal</button>
					</div>
				</div>
			</div>

		</div>
	</>
};

export default ConfirmModal;
