import axios from "axios";
import { baseURL } from "./common-api";

export interface IParameterPengamatanHasilPanen {
  id_seq?: number;
  commodity_id: string;
  variety_id: string;
  parameter_pengamatan_hasil_panen: string;
  value_parameter_pengamatan_hasil_panen: any;
  variety_name?: string;
  commodity_name_var?: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
  is_number_bool?: number;
}

export const getAll = async () => {
  try {
    const data = await axios.get(
      baseURL +
        "/api/cultivation-management/parameter-pengamatan-hasil-panen/get-all",
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const dropdownForm = async (
	commodityId: Number,
  ) => {
	try {
	  const data = await axios.get(
		`${baseURL}/api/cultivation-management/parameter-pengamatan-hasil-panen/dropdown_form?commodity_id=${commodityId}`,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

export const createNew = async (payload: IParameterPengamatanHasilPanen) => {
  try {
    const data = await axios.post(
      baseURL +
        "/api/cultivation-management/parameter-pengamatan-hasil-panen/create",
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const update = async (
  productTypeId: number,
  payload: IParameterPengamatanHasilPanen,
) => {
  try {
    const data = await axios.patch(
      baseURL +
        "/api/cultivation-management/parameter-pengamatan-hasil-panen/update/" +
        productTypeId,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteData = async (productTypeId: number) => {
  try {
    const data = await axios.delete(
      baseURL +
        "/api/cultivation-management/parameter-pengamatan-hasil-panen/delete/" +
        productTypeId,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
