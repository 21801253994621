import Dashboard from "./containers/Dashboard";
import Profile from "./containers/Profile";
import Login from "./containers/auth/Login";
import OtpPage from "./containers/auth/OtpPage";
import Register from "./containers/auth/Register";

import CommodityStandard from "./containers/admin/operatingparameter/CommodityStandard";
import ProjectManagement from "./containers/admin/ProjectManagement";
import LogActivityManagement from "./containers/admin/LogActivityManagement";
import User from "./containers/admin/User";

import LahanManagement from "./containers/admin/LahanManagement";
import UserGroup from "./containers/admin/UserGroup";
import OperatingParameter from "./containers/admin/operatingparameter/OperatingParameter";
import FarmerGroupManagement from "./containers/admin/FarmerGroupManagement";
import HakAksesManagement from "./containers/admin/HakAksesManagement";
import CultivationManagement from "./containers/admin/CultivationManagement";
import CultivationPlantingManagement from "./containers/admin/CultivationPlantingManagement";
import CultivationProcessingManagement from "./containers/admin/CultivationProcessingManagement";
import TasksManagementTable from "./containers/admin/TasksManagementTable";
import TaskManagement from "./containers/admin/TaskManagement";
import DataPanenManagement from "./containers/admin/DataPanenManagement";
import TotalDataPanenManagement from "./containers/admin/TotalDataPanenManagement";
import DataPanenApproval from "./containers/admin/DataPanenApproval";
import LogDataPanen from "@containers/admin/LogDataPanen";
import VarietyManagement from "./containers/admin/operatingparameter/VarietyManagement";
import ProductTypeManagement from "./containers/admin/inventory/ProductTypeManagement";
import ChemicalManagement from "./containers/admin/inventory/ChemicalManagement";
import MixingChemicalManagement from "./containers/admin/inventory/MixingChemicalManagement";
import ParameterPengamatanManagement from "./containers/admin/operatingparameter/ParameterPengamatanManagement";
import ParameterPengamatanHasilPanenManagement from "./containers/admin/operatingparameter/ParameterPengamatanHasilPanenManagement";
import ParameterPascaPanenManagement from "./containers/admin/operatingparameter/ParameterPascaPanenManagement";
import ParameterPascaPanenRefManagement from "./containers/admin/operatingparameter/ParameterPascaPanenRefManagement";

import Analytics from "./containers/Analytics";
import Project from "./containers/Project";
import DashbboardAdmin from "./containers/dashboard/DashbboardAdmin";
import TaskPreference from "./containers/admin/operatingparameter/TaskReference";
import TaskPreferenceDetail from "./containers/admin/operatingparameter/TaskReferenceDetail";
import InventoryMonitoringStock from "./containers/admin/inventory/InventoryMonitoringStock";
import InventoryProductManagement from "./containers/admin/inventory/InventoryProductManagement";
import InventoryProductIn from "./containers/admin/inventory/InventoryEntryProduct";
import InventoryProductOut from "./containers/admin/inventory/InventoryExitProduct";
import InventoryProductMixing from "./containers/admin/inventory/InventoryCombinationProduct";
import BudgetPlanManagement from "./containers/admin/budgetplan/BudgetPlanManagement";
import BudgetPlanDetail from "./containers/admin/budgetplan/BudgetPlanDetail";
import BudgetApprovalDetail from "./containers/admin/budgetplan/BudgetApprovalDetail";
import BugetApprovalList from "./containers/admin/budgetplan/BugetApprovalList";
import BudgetPlanCategory from "./containers/admin/operatingparameter/BudgetPlanCategory";
import PlantingMethod from "./containers/admin/operatingparameter/PlantingMethod";
import FinancialScheme from "@containers/admin/budgetplan/FinancialScheme";
import MaterialSupport from "@containers/admin/operatingparameter/MaterialSupport";
import RatioDividend from "@containers/admin/operatingparameter/RatioDividend";
import LandPrice from "@containers/admin/operatingparameter/landPrice";
import SaprodiDistribution from "@containers/admin/operatingparameter/SaprodiDistribution";
import TotalSaprodiDistribution from "@containers/admin/operatingparameter/TotalSaprodiDistribution";
import SaprodiDistributionDetail from "@containers/admin/operatingparameter/SaprodiDistributionDetail";
import LogActivitiesDetail from "@containers/admin/LogActivitiesfaDetail";
import CultivationHarvestDetail from "@containers/admin/CultivationHarvestDetail";
import LogActivityFarmerManagement from "@containers/admin/LogActivityFarmerManagement";
import InnovationResearchManagement from "@containers/innov/InnovationResearchManagement";
import MaterialResearchManagement from "@containers/admin/operatingparameter/MaterialResearchManagement";
import ParameterVariableResearch from "@containers/admin/operatingparameter/ParameterVariableResearch";
import LandResearchManagement from "@containers/admin/LandResearchManagement";


export const RouteAdminRole = [
	{
		title: "Menu",
		routes: [
			{
				path: "",
				layout: "/dashboard",
				exact: true,
				name: "Dashboard",
			},
			{
				path: "/project-list",
				layout: "/dashboard",
				name: "Project",
			},
		],
	},
	{
		title: "Transaction",
		routes: [
			{
				path: "/transaction-layanan",
				layout: "/dashboard",
				name: "Layanan",
			},
			{
				path: "/transaction-analytics",
				layout: "/dashboard",
				name: "Analysis",
			},
		],
	},
	{
		title: "Admin",
		routes: [
			{
				path: "/project-management",
				layout: "/dashboard",
				name: "Project Management",
			},
			{
				path: "/land-management",
				layout: "/dashboard",
				name: "Lahan Management",
			},
			{
				path: "/commodity-standard",
				layout: "/dashboard",
				name: "Commodity Standard",
			},
			{
				path: "/probe-management",
				layout: "/dashboard",
				name: "Probe Management",
			},
			{
				path: "/probe-district-management",
				layout: "/dashboard",
				name: "Probe District",
			},
			{
				path: "/feature",
				layout: "/dashboard",
				name: "Feature",
			},
			{
				path: "/user-management",
				layout: "/dashboard",
				name: "User",
			},
		],
	},
	{
		title: "Other",
		routes: [
			{
				path: "/profile",
				layout: "/dashboard",
				name: "Profile",
			},
		],
	},
];

export const RoutePetaniRole = [
	{
		title: "Menu",
		routes: [
			{
				path: "",
				layout: "/dashboard",
				exact: true,
				name: "Dashboard",
			},
			{
				path: "/farmer-land",
				layout: "/dashboard",
				name: "Lahan",
			},
			{
				path: "/probe-activation",
				layout: "/dashboard",
				name: "Probe Activation",
			},
		],
	},
	{
		title: "Other",
		routes: [
			{
				path: "/profile",
				layout: "/dashboard",
				name: "Profile",
			},
		],
	},
];

export const RouteAnalisRole = [
	{
		title: "Menu",
		routes: [
			{
				path: "",
				layout: "/dashboard",
				exact: true,
				name: "Dashboard",
			},
			{
				path: "/land-analytics",
				layout: "/dashboard",
				name: "Analytics",
			},
			{
				path: "/history-analytics",
				layout: "/dashboard",
				name: "History",
			},
		],
	},
	{
		title: "Other",
		routes: [
			{
				path: "/profile",
				layout: "/dashboard",
				name: "Profile",
			},
		],
	},
];

export const RouteInvestorRole = [
	{
		title: "Menu",
		routes: [
			{
				path: "",
				layout: "/dashboard",
				exact: true,
				name: "Dashboard",
			},
			{
				path: "/project-list",
				layout: "/dashboard",
				name: "Project",
			},
			{
				path: "/analytics",
				layout: "/dashboard",
				name: "Analytics",
			},
			{
				path: "/schedule",
				layout: "/dashboard",
				name: "Schedule",
			},
		],
	},
	{
		title: "Report",
		routes: [
			{
				path: "/daily",
				layout: "/dashboard",
				name: "Daily",
			},
			{
				path: "/monthly",
				layout: "/dashboard",
				name: "Monthly",
			},
			{
				path: "/yearly",
				layout: "/dashboard",
				name: "Yearly",
			},
		],
	},
	{
		title: "Other",
		routes: [
			{
				path: "/profile",
				layout: "/dashboard",
				name: "Profile",
			},
		],
	},
];

// DYNAMIC ROUTES
export const dashboardRoutes = [
	// DASHBOARD
	{
		path: "",
		component: <DashbboardAdmin />,
	},
	{
		path: "dashboard",
		component: <DashbboardAdmin />,
	},

	// PROJECT MANAGEMENT
	{
		path: "project-management-project",
		component: <ProjectManagement />,
	},
	{
		path: "project-management-land",
		component: <LahanManagement />,
	},

	// Log Activity MANAGEMENT
	{
		path: "log-activity-fa",
		component: <LogActivityManagement />,
	},
	{
		path: "log-activity-farmer",
		component: <LogActivityFarmerManagement />,
	},

	// CULTIVATION MANAGEMENT
	{
		path: "cultivation-management-cultivation-planting",
		component: <CultivationPlantingManagement />,
	},
	{
		path: "cultivation-management-cultivation-land-processing",
		component: <CultivationProcessingManagement />,
	},
	{
		path: "cultivation-management-cultivation",
		component: <CultivationManagement />,
	},
	{
		path: "cultivation-management-task",
		component: <TaskManagement />,
	},
	{
		path: "cultivation-management-panen",
		component: <DataPanenManagement />,
	},
	{
		path: "cultivation-management-total-panen",
		component: <TotalDataPanenManagement />,
	},
	{
		path: "cultivation-management-task-management",
		component: <TasksManagementTable />,
	},

	// INVENTORY
	{
		path: "inventory-monitoring-stock",
		component: <InventoryMonitoringStock />,
	},
	{
		path: "inventory-daftar-produk",
		component: <InventoryProductManagement />,
	},
	{
		path: "inventory-barang-masuk",
		component: <InventoryProductIn />,
	},
	{
		path: "inventory-barang-keluar",
		component: <InventoryProductOut />,
	},
	{
		path: "inventory-pencampuran-produk",
		component: <InventoryProductMixing />,
	},
	{
		path: "inventory-product-type",
		component: <ProductTypeManagement />,
	},

	// CHEMICAL
	{
		path: "chemical-management-chemical",
		component: <ChemicalManagement />,
	},
	{
		path: "chemical-management-mixing-chemical",
		component: <MixingChemicalManagement />,
	},

	// BUTGET PLAN
	{
		path: "budget-management-plan",
		component: <BudgetPlanManagement />,
	},
	{
		path: "budget-management-approval",
		component: <BugetApprovalList />,
	},
	{
		path: "budget-management-financial-scheme",
		component: <FinancialScheme />,
	},

	// OPERATING PARAMETER
	{
		path: "operating-parameter-categoryreference",
		component: <BudgetPlanCategory />,
	},
	{
		path: "operating-parameter-commoditystandart",
		component: <CommodityStandard />,
	},
	{
		path: "operating-parameter-variety",
		component: <VarietyManagement />,
	},
	{
		path: "operating-parameter-parameter",
		component: <OperatingParameter />,
	},
	{
		path: "operating-parameter-taskreference",
		component: <TaskPreference />,
	},
	{
		path: "operating-parameter-taskreference-plantingmethod",
		component: <PlantingMethod />,
	},
	{
		path: "cultivation-management-parameter-pengamatan",
		component: <ParameterPengamatanManagement />,
	},
	{
		path: "cultivation-management-parameter-hasil-panen",
		component: <ParameterPengamatanHasilPanenManagement />,
	},
	{
		path: "cultivation-management-parameter-pasca-panen",
		component: <ParameterPascaPanenManagement />,
	},
	{
		path: "cultivation-management-parameter-pasca-panen-ref",
		component: <ParameterPascaPanenRefManagement />,
	},
	{
		path: "operating-parameter-material-support",
		component: <MaterialSupport />,
	},
	{
		path: "operating-parameter-ratio-dividend",
		component: <RatioDividend />,
	},
	{
		path: "operating-parameter-land-price",
		component: <LandPrice />,
	},
	{
		path: "operating-parameter-saprodi-distribution",
		component: <SaprodiDistribution />,
	},
	{
		path: "operating-parameter-total-distribution",
		component: <TotalSaprodiDistribution />,
	},
	{
		path: "operating-parameter-material",
		component: <MaterialResearchManagement />,
	},

	// USER MANAGEMENT
	{
		path: "user-management-user",
		component: <User />,
	},
	{
		path: "user-management-usergroup",
		component: <UserGroup />,
	},
	{
		path: "user-management-farmergroup",
		component: <FarmerGroupManagement />,
	},
	{
		path: "user-management-hakakses",
		component: <HakAksesManagement />,
	},

	// Innovation
	{
		path: "innovation-management",
		component: <DashbboardAdmin />,
	},
	{
		path: "innovation-management-research",
		component: <InnovationResearchManagement />,
	},
	{
		path: "operating-parameter-variable",
		component: <ParameterVariableResearch />,
	},
	{
		path: "innovation-management-land-research",
		component: <LandResearchManagement />,
	},
	// {
	// 	path: "innovation-management-variable",
	// 	component: <MixingChemicalManagement />,
	// },
	// {
	// 	path: "innovation-management-material",
	// 	component: <MixingChemicalManagement />,
	// },

];

// STATIC ROUTES
export const dashboardStaticRoutes = [
	{
		path: "dashboard-agroobot",
		component: <Dashboard />,
	},
	{
		path: "cultivation-management-panen-approval/:id",
		component: <DataPanenApproval />,
	},
	{
		path: "cultivation-management-log-panen/:commodity_name_var/:variety_name/:period_plant_txt",
		component: <LogDataPanen />,
	},
	{
		path: "budget-management-plan/:id",
		component: <BudgetPlanDetail />,
	},
	{
		path: "detail-cultivation-harvest/:id",
		component: <CultivationHarvestDetail />,
	},
	{
		path: "budget-management-approval/:id",
		component: <BudgetApprovalDetail />,
	},
	{
		path: "operating-parameter-saprodi-distribution/:id",
		component: <SaprodiDistributionDetail />,
	},
	{
		path: "log-activities-fa/:id",
		component: <LogActivitiesDetail />,
	},
	{
		path: "operating-parameter-taskreference/:id",
		component: <TaskPreferenceDetail />,
	},
	{
		path: "profile",
		component: <Profile />,
	},
	{
		path: "analytics",
		component: <Analytics />,
	},
	{
		path: "project",
		component: <Project />,
	},
];

export const routeAuth = [
	{
		path: "/sign-in",
		layout: "/auth",
		exact: true,
		name: "Login",
		component: <Login />,
	},
	{
		path: "/sign-up",
		layout: "/auth",
		name: "Register",
		component: <Register />,
	},
	{
		path: "/otp-validate",
		layout: "/auth",
		name: "OTP",
		component: <OtpPage />,
	},
];
