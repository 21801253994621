import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ITaskReference } from "../../../../api/taskreference-api";
import ErrorField from "../../../ErrorField";
import useDropdownCommodity, {
  useDropdownPlantingMethod,
} from "../../../../hooks/useDropdownCommodity";

type Props = {
  data: ITaskReference;
  onCancel: (e: React.MouseEvent) => any;
  onSubmit: (data: FormData) => void;
};

const ModalFormTaskPreference: React.FC<Props> = ({
  data,
  onCancel,
  onSubmit,
}) => {
  const [selectedtCommodityName, setSelectedtCommodityName] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<ITaskReference>({ criteriaMode: "all" });

  const { dataCommodity } = useDropdownCommodity();
  const { dataPlanting } = useDropdownPlantingMethod(selectedtCommodityName);

  const onSubmitForm: SubmitHandler<ITaskReference> = ({
    commodity_name_var,
    planting_method_int,
    file,
  }) => {
    const formData = new FormData();
    formData.append("commodity_name_var", commodity_name_var!);
    formData.append("planting_method_int", planting_method_int!);
    formData.append("file", file[0]);

    // console.log(file);

    for (var pair of formData.entries() as any) {
      console.log(pair[0] + ", " + pair[1]);
    }

    // console.log(payload)
    onSubmit(formData);
  };

  return (
    <>
      <div className="modal-form">
        <div className="modal-form-outside" onClick={onCancel}></div>

        <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
          <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
              <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
              <h3>
                {data ? "Ubah Task Preference" : "Tambah Task Preference"}
              </h3>
            </div>
            <button
              type="button"
              className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
              onClick={onCancel}>
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
            </button>
          </div>

          <form
            onSubmit={handleSubmit(onSubmitForm)}
            className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
            <div className="grid gap-5 grid-cols-1 md:grid-cols-2">
              <div className="w-full">
                <label htmlFor="commodity_name_var" className="label-form">
                  Nama Komoditas
                </label>
                <Controller
                  name="commodity_name_var"
                  control={control}
                  rules={{ required: "This field is required." }}
                  render={({ field }) => (
                    <select
                      id="commodity_name_var"
                      className="input-form"
                      placeholder="- Pilih komoditas -"
                      {...field}
                      onChange={(e) => {
                        setSelectedtCommodityName(e.target.value);
                        field.onChange(e.target.value);
                      }}>
                      <option value="">- Pilih komoditas -</option>
                      {dataCommodity.map((item, key) => (
                        <option value={item.commodity_name_var} key={key}>
                          {item.commodity_name_var}
                        </option>
                      ))}
                    </select>
                  )}
                />
                <ErrorField errors={errors} name="commodity_name_var" />
              </div>
              <div className="w-full">
                <label htmlFor="planting_method_int" className="label-form">
                  Metode
                </label>
                <Controller
                  name="planting_method_int"
                  control={control}
                  rules={{ required: "This field is required." }}
                  render={({ field }) => (
                    <select
                      id="planting_method_int"
                      className="input-form"
                      placeholder="- Pilih metode tanam -"
                      {...field}>
                      <option value="">- Pilih metode tanam -</option>
                      {dataPlanting.map((item, key) => (
                        <option value={item.id_seq} key={key}>
                          {item.name_planting_var}
                        </option>
                      ))}
                    </select>
                  )}
                />
                <ErrorField errors={errors} name="planting_method_int" />
              </div>
            </div>
            <div className="w-full mt-5">
              <label htmlFor="file" className="label-form">
                Unggah Bukti Panen
              </label>
              <input
                id="file"
                type="file"
                className="input-form"
                placeholder="Unggah file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                {...register("file", { required: "This field is required." })}
              />
              <ErrorField errors={errors} name="file" />
            </div>

            {/* <!-- Modal footer --> */}
            <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
              <button type="submit" className="btn-primary">
                Simpan
              </button>
              <button
                type="reset"
                className="btn-secondary px-7"
                onClick={onCancel}>
                Batal
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ModalFormTaskPreference;
