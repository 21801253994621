import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";

const ModalFormCategoryVariableRef = ({ data, onCancel, onSubmit }) => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])


    const onValid = (dataForm) => {
        const { category_variable_var } = dataForm

        const payload = { category_variable_var }

        onSubmit(payload)
    }

    return (
        <div className="modal-form">
            <div className="modal-form-outside" onClick={onCancel}></div>

            <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                    <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                        <i className="fa-solid fa-ranking-star flex justify-center items-center"></i>
                        <h3>{data ? 'Update Category Variable' : 'Add Category Variable'}</h3>
                    </div>
                    <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                        onClick={onCancel}
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>

                {/* Body */}
                <form onSubmit={handleSubmit(onValid)} className='px-8'>
                    <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                        <div className='w-full md:w-1/2'>
                            <label htmlFor="category_variable_var" className="label-form">Category Variable</label>
                            <input id="category_variable_var" type='text' className='input-form' placeholder='Category Variable'
                                {...register('category_variable_var', { required: 'This field is required.' })}
                            />
                            <ErrorField errors={errors} name='category_variable_var' />
                        </div>
                    </div>
                    {/* <!-- Modal footer --> */}
                    <div className="flex items-center mt-6 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10 mb-5">
                        <button type='submit' className="btn-primary">Save</button>
                        <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Cancel</button>
                    </div>
                </form>

                {/* End Body */}
            </div>
            {/* End Modal Content */}
        </div>
    )
}

export default ModalFormCategoryVariableRef 