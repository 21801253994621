import React, { ReactNode, useEffect, useReducer, useState } from 'react';
import { recentActivitiesReducer, recentPagesReducer, recentSearchesReducer } from '../reduxs/reducer/headerReducer';
import { getinitialRecentActivities, getinitialRecentPages, getinitialRecentSearches } from '../reduxs/reducer/initialState';
import { AppContextInterface, IActionRedux, IOwnerTask } from './ContextInterface';

export const GlobalContext = React.createContext({} as AppContextInterface )


const savedTasksReducer = (state: any[], action: IActionRedux) => {
    const { type, payload } = action
    switch (type) {
        case 'push':
            return [...state, payload]
        case 'update':
            return state.map((task) => task.id === payload.id? payload : task)
        case 'delete':
            return state.filter((task) => task.id !== payload.id)
        default:
            throw new Error()
    }
}


const getinitialTasks = () => {
    const storageTasks = localStorage.getItem('savedTasks')
    const parsedTasks = storageTasks? JSON.parse(storageTasks) : []
    return parsedTasks
}

const getInitialUserStorage = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const storedPrefs = window.localStorage.getItem('pandawa-user');
        if (typeof storedPrefs === 'string') {
            try {
                return JSON.parse(storedPrefs)
            } catch (error) {
                console.log(error)
                return {}
            }
        }
    }

   return {}
};

type Props = {
    children: ReactNode
};

export const ContextWrapper: React.FC<Props> = ({ children }) => {
    const [savedTasks, dispatchCalTask] = useReducer(savedTasksReducer, [], getinitialTasks )
    const [recentActivities, dispatchCalActivity] = useReducer(recentActivitiesReducer, [], getinitialRecentActivities )
    const [recentPages, dispatchCalPage] = useReducer(recentPagesReducer, [], getinitialRecentPages )
    const [recentSearches, dispatchCalSearch] = useReducer(recentSearchesReducer, [], getinitialRecentSearches )
    const [userStorage, setUserStorage] = useState(getInitialUserStorage)
    const [isDataChanged, setIsDataChanged] = useState(false)
    const [ownerTask, setOwnerTask] = useState<IOwnerTask | any>(null)

    useEffect(() => {
        localStorage.setItem('savedTasks', JSON.stringify(savedTasks))
    }, [savedTasks])

    useEffect(() => {
        if(recentActivities !== null){
            localStorage.setItem('recent-activities', JSON.stringify(recentActivities))
        }
    }, [recentActivities])

    useEffect(() => {
        if(recentPages !== null){
            localStorage.setItem('recent-pages', JSON.stringify(recentPages))
        }
    }, [recentPages])

    useEffect(() => {
        if(recentSearches !== null){
            localStorage.setItem('recent-searches', JSON.stringify(recentSearches))
        }
    }, [recentSearches])

    return (
        <GlobalContext.Provider 
            value={{
                savedTasks, dispatchCalTask,
                userStorage, setUserStorage,
                isDataChanged, setIsDataChanged,
                ownerTask, setOwnerTask,
                recentActivities, dispatchCalActivity,
                recentPages, dispatchCalPage,
                recentSearches, dispatchCalSearch
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}