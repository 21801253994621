import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getByQueryCulltivation } from "@api/cultivationLandprocess-api";
import { IDetailLogActivities } from "@api/logactivity-api";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
	data: IDetailLogActivities;
	dataObservation: any;
	onCancel: (e: React.MouseEvent) => any;
	onClose: (e: React.MouseEvent<HTMLElement>) => void;
	// onSubmit?: (data: ILogActivities | any) => void;
};

const ModalDetailObservation: React.FC<Props> = ({ data, onCancel, onClose, dataObservation }) => {
	// hooks
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<any>({ criteriaMode: "all" });

	// states
	const [dataLogActivities, setLogActivities] = useState<IDetailLogActivities[]>([]);
	const [selectedData, setSelectedData] = useState<any>(null);
	const [confirmDeleteDoc, showConfirmDeleteDoc] = useState(false);
	const [dataDoc, setDataDoc] = useState<any>(null);

	// functions
	const fetchActivity = async () => {
		const query = `id_cultivation_int=${data?.id_seq}`;

		console.log('datas ', data);
		const filtered = dataObservation.flatMap(arr => arr).filter(observation => observation.land_code_var === data?.land_code_var)
		console.log('data observation filtered ', filtered[0]);

		const res = await getByQueryCulltivation(query);
		const filter = dataObservation.filter(obs => obs.land_code_var === data.land_code_var)
		console.log('cultivation res ', dataObservation);
		if (dataObservation) {
			setLogActivities(filtered);
		}
	};

	// effects
	const setdefaultValue = useCallback(
		(obj) => {
			Object.keys(obj).map((key) => {
				return setValue(key, obj[key], { shouldValidate: true });
			});
		},
		[setValue],
	);
	useEffect(() => {
		if (data) {
			setdefaultValue(data);
			fetchActivity();
		}
	}, [data, setdefaultValue]);


	return (
		<>

			<div className="modal-form">
				<div className="modal-form-outside" onClick={onClose} />
				<div
					className="modal-form-content bg-white p-5 w-[95%] md:w-6/12 2xl:w-5/12"
					style={{ maxHeight: "90vh" }}>
					<div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
						<div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
							<h3>Hasil Pengamatan Pendamping</h3>
						</div>
						<button
							type="button"
							className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
							onClick={onClose}>
							<svg
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"></path>
							</svg>
						</button>
					</div>

					{dataLogActivities?.map((item, key) => (
						<div className="w-full border mt-5 bg-white rounded-2xl md:p-6">
							<div className="flex flex-col mb-6 px-1 md:px-5 text-sm" key={key}>
								<div className="flex flex-col mt-3 mb-5 px-1 md:px-1 text-sm">
									<div className="flex items-center justify-between">
										<h3 className="font-bold text-blue-500">Pengamatan</h3>
									</div>
									<table className="font-medium text-xs md:text-sm mt-3">
										<tbody>
											{item?.params_values?.filter((par) => par != null)
												.map((param, index) => (
													<tr key={`param-${index}`}>
														<React.Fragment key={param.parameter}>

															<td className="text-gray-500 pb-1 w-36 md:w-60">
																{param.parameter ? param.parameter : ''}
															</td>
															<td>{String(param.value)}</td>
														</React.Fragment>
													</tr>
												))}
											<tr>
												<td className="text-gray-500 pb-1">Tanggal Pengamatan</td>
												<td>{item?.time_calender_dte.split("T")[0] || ""}</td>
											</tr>
											<tr>
												<td className="text-gray-500 pb-1">Catatan Pengamatan</td>
												<td>{item?.example_observation_txt}</td>
											</tr>
											<tr>
												<td className="text-gray-500 pb-1">Dokumentasi Pengamatan</td>
												<td>
													{Object.keys(item).length !== 0 &&
														item.document_txt?.map((url: string, key: number) => (
															<div className="relative w-full h-full cursor-pointer hover:scale-110 hover:duration-500"
																key={key}>
																<LazyLoadImage
																	onClick={() => window.open(item.document_txt[key], "_blank")}
																	effect="blur"
																	alt={url}
																	src={url}
																	className="rounded-lg shadow-md h-48 object-cover"
																	key={key}
																/>
																<div className="absolute top-0 right-0 space-x-2 m-2 text-black-200">
																	<i
																		className="fa-solid fa-pen cursor-pointer hover:text-black-500"
																		onClick={() => {
																			setSelectedData(item);
																			setDataDoc(item?.document_txt[key]);
																		}}
																	></i>
																	<i
																		className="fa-solid fa-trash cursor-pointer hover:text-black-500"
																		onClick={() => {
																			setSelectedData(item);
																			showConfirmDeleteDoc(true);
																			setDataDoc(item?.document_txt[key]);
																		}}
																	></i>
																</div>
															</div>
														))}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					))}

				</div>
			</div>

		</>
	);

};



export default ModalDetailObservation;
