const initialState = {
    user: {
        
    },
    datetime: '',
    menu_list: [],
    user_group_list: [],
    user_list: [],
    collapse: true,
    title_bar: ''
}

export const getinitialRecentActivities = () => {
    const storageData = localStorage.getItem('recent-activities')
    const data = storageData? JSON.parse(storageData) : []
    return data
}

export const getinitialRecentPages = () => {
    const storageData = localStorage.getItem('recent-pages')
    const data = storageData? JSON.parse(storageData) : []
    return data
}

export const getinitialRecentSearches = () => {
    const storageData = localStorage.getItem('recent-searches')
    const data = storageData? JSON.parse(storageData) : []
    return data
}


export default initialState