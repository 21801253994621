import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import Loader from "../Loader";
import useDropdownCommodity from "@hooks/useDropdownCommodity";
import ModalFormCommodityStandard from "../operatingparameter/ModalFormCommodityStandard";
import { createNewCommodityRef } from "@api/commodity-api";
import { toast } from "react-toastify";
import { IResearchManagement } from "@api/researchManagement-api";
import useDropdownLandReserch from "@hooks/useDropdownLandResearch";
import Select from "react-select"
import { selectStylesForm } from "../TaskManagement/ModalTaskNew";
import { getOneLandResearch } from "@api/landResearch-api";
import useDropdownIMaterialResearch from "@hooks/useDropdownMaterialResearch";
import useDropdownCategoryVariable from "@hooks/useDropdownCategoryVariable";
import { getAllbyCategory } from "@api/paramaterVariableResearch-api";

type Props = {
    data: IResearchManagement;
    dataUser?: any;
    onCancel: (e: React.MouseEvent) => any;
    onSubmit: (data: IResearchManagement) => void;
};

const ModalFormResearch: React.FC<Props> = ({
    data,
    onCancel,
    onSubmit,
}) => {
    // states
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [commodityName, setCommodityName] = useState(data ? data.commodity_name_var : "",);
    const { dataLandResearch, loadingLandResearch } = useDropdownLandReserch(); // eslint-disable-line
    const { dataMaterialResearch, loadingMaterialResearch } = useDropdownIMaterialResearch(); // eslint-disable-line
    const [modalFormCommodity, showModalFormCommodity] = useState(false);
    const userLoggedIn = JSON.parse(localStorage.getItem("pandawa-user"));
    const { dataCommodity, loadingCommodity } = useDropdownCommodity(isRefreshing);
    const [dataLand, setDataLand] = useState([])
    const [landCode, setLandCode] = useState([])
    const [subFirstParameters, setSubFirstParameters] = useState([]);
    const [dataParamaterVariable, setParamaterVariable] = useState([]);
    const [dataSubParamaterVariable, setSubParamaterVariable] = useState([]);
    const { dataCategoryVariable, loadingCategoryVariable } = useDropdownCategoryVariable(isRefreshing);
    const [countTreatment, setCountTreatment] = useState(0)
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState([]);
    const [dataVariable, setDataVariable] = useState([]);
    const [materialResearchData, setMaterialResearchData] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedVariables, setSelectedVariables] = useState([]);
    const [selectedSubVariables, setSelectedSubVariables] = useState([]);
    const totalSteps = 4;

    // hooks
    const handleNext = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(prevStep => prevStep + 1);
        }
    };

    const handleInputTreatment = (data) => {
        if (data.target.value > 0) {
            setCountTreatment(data.target.value)
        } else {
            setCountTreatment(0)
        }
    };

    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep(prevStep => prevStep - 1);
        }
    };
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<any>({ criteriaMode: "all" });

    // functions

    const getLandInformation = useCallback(async () => {
        const res = await getOneLandResearch(landCode?.toString())

        if (res.data) {
            if (res.data.code === 0) {
                let data_land = []
                data_land.push(res.data.data)
                setDataLand(data_land)
            } else {
                setDataLand([])
            }
        } else {
            toast.error(`${res.config?.url} ${res.message}`)
        }
    }, [landCode])


    const handleCategoryVariable = async (selectedData, index) => {

        // setCategoryVariableId(selectedData);

        // setCategoryVariableVar([dataCategoryVariable.filter(
        //     (item) =>
        //         Number(item.id_seq) === Number(selectedData)
        // )[0].category_variable_var]);

        const newCategories = [...selectedCategories];
        newCategories[index] = dataCategoryVariable.filter(
            (item) =>
                Number(item.id_seq) === Number(selectedData)
        )[0].category_variable_var;
        setSelectedCategories(newCategories);

        const res = await getAllbyCategory(selectedData)
        if (res.data.code === 0) {
            setParamaterVariable(res.data.data)
        } else {
            toast.error(`${res.config?.url} ${res.message}`);
        }
    };


    const handleVariable = async (selectedData, index) => {


        // setVariableId(selectedData);

        // setVariableVar(dataParamaterVariable.filter(
        //     (item) =>
        //         Number(item.id_seq) === Number(selectedData)
        // )[0].parameter_variable_var);

        const newVariables = [...selectedVariables];
        newVariables[index] = dataParamaterVariable.filter(
            (item) =>
                Number(item.id_seq) === Number(selectedData)
        )[0].parameter_variable_var;
        setSelectedVariables(newVariables);

        if (dataParamaterVariable) {
            // check if array are contained object
            if (dataParamaterVariable.filter((item) => Number(item.id_seq) === Number(selectedData))[0].value_parameter_pengamatan[0][0] === undefined) {
                setSubParamaterVariable(transformObjectToArray(dataParamaterVariable.filter(
                    (item) =>
                        Number(item.id_seq) === Number(selectedData)
                )[0].value_parameter_pengamatan[0]))
            } else {
                setSubParamaterVariable(transformObjectToArray2(dataParamaterVariable.filter(
                    (item) =>
                        Number(item.id_seq) === Number(selectedData)
                )[0].value_parameter_pengamatan[0]))
            }
        } else {
            toast.error(`error data paramater variable`);
        }
    };

    const handleFirstSubInputChange = (vpIndex, subIndex, value) => {
        const updatedData = [...materialResearchData];
        if (!updatedData[vpIndex]) updatedData[vpIndex] = [];
        if (!updatedData[vpIndex][subIndex]) updatedData[vpIndex][subIndex] = {};
        updatedData[vpIndex][subIndex].material_research_var = value;
        setMaterialResearchData(updatedData);
    };

    const handleDoseChange = (vpIndex, subIndex, value) => {
        const updatedData = [...materialResearchData];
        if (!updatedData[vpIndex]) updatedData[vpIndex] = [];
        if (!updatedData[vpIndex][subIndex]) updatedData[vpIndex][subIndex] = {};
        updatedData[vpIndex][subIndex].dose_material_var = value;
        setMaterialResearchData(updatedData);
    };

    const formattedData = Array.from({ length: countTreatment }, (vp, vpIndex) =>
        materialResearchData[vpIndex]?.map(subItem => ({
            material_research_var: subItem.material_research_var,
            dose_material_var: subItem.dose_material_var
        }))
    );

    console.log('cat ', selectedCategories, ' var ', selectedVariables)
    const formattedData2 = dataVariable.map((_, index) => ({

        category: selectedCategories[index] || '',
        variable: selectedVariables[index] || '',
        sub_variable: selectedSubVariables[index]?.value_parameter_pengamatan || '',
        value: selectedValues[index] || [],
    }));

    // const handleSubCategoryVariable = (dataSubParamaterVariable) => {
    //     setSelectedOption(dataSubParamaterVariable);
    //     setSelectedValues([]);
    // };

    const handleSubCategoryVariable = (selectedOption, index) => {
        const newSubVariables = [...selectedSubVariables];
        newSubVariables[index] = selectedOption;
        setSelectedSubVariables(newSubVariables);

        setSelectedValues(prevValues => {
            const newValues = [...prevValues];
            newValues[index] = selectedOption?.values || [];
            return newValues;
        });
        // setSelectedValues(prevValues => {
        //     const newValues = [...prevValues];
        //     newValues[index] = selectedOption?.values || [];
        //     return newValues;
        // });
    };

    const handleRadioChange = (value, index) => {
        setSelectedValues(prevValues => {
            const newValues = [...prevValues];
            if (newValues[index].includes(value)) {
                newValues[index] = newValues[index].filter(val => val !== value);
            } else {
                newValues[index].push(value);
            }
            return newValues;
        });
    };

    const transformObjectToArray = (data) => {
        return Object.keys(data).map(key => ({
            id_seq: key, // Use key as id_seq for simplicity
            value_parameter_pengamatan: key,
            values: data[key]
        }));
    };

    const transformObjectToArray2 = (data) => {
        return Object.keys(data).map(key => ({
            id_seq: key, // Use key as id_seq for simplicity
            value_parameter_pengamatan: data[key],
            values: key
        }));
    };

    const handleAddMoreVariable = () => {
        setDataVariable([...dataVariable, ""]);
    };

    // const handleRemoveVariable = (index) => {
    //     const updatedValue = [...dataVariable];
    //     updatedValue.splice(index, 1);
    //     setDataVariable(updatedValue);
    // };

    // const handleRemoveVariable = (index) => {
    //     setDataVariable(dataVariable.filter((_, i) => i !== index));
    //     setSelectedValues(selectedValues.filter((_, i) => i !== index));
    // };

    const handleRemoveVariable = (index) => {
        setDataVariable(dataVariable.filter((_, i) => i !== index));
        setSelectedValues(selectedValues.filter((_, i) => i !== index));
        setSelectedCategories(selectedCategories.filter((_, i) => i !== index));
        setSelectedVariables(selectedVariables.filter((_, i) => i !== index));
        setSelectedSubVariables(selectedSubVariables.filter((_, i) => i !== index));
    };

    const handleFirstSubInput = (vpIndex) => {
        setSubFirstParameters((prev) => ({
            ...prev,
            [vpIndex]: [...(prev[vpIndex] || []), ""]
        }));
    };

    const handleRemoveFirstSubInput = (vpIndex, subIndex) => {
        const updatedSubParams = { ...subFirstParameters };
        updatedSubParams[vpIndex].splice(subIndex, 1);
        setSubFirstParameters(updatedSubParams);
    };

    // const handleFirstSubInputChange = (vpIndex, subIndex, value) => {
    //     const updatedSubParams = { ...subFirstParameters };
    //     updatedSubParams[vpIndex][subIndex] = value;
    //     setSubFirstParameters(updatedSubParams);
    // };

    const setdefaultValue = useCallback(
        (obj) => {
            Object.keys(obj).map((key) => {
                return setValue(key, obj[key], { shouldValidate: true });
            });
        },
        [setValue],
    );

    useEffect(() => {
        getLandInformation()
        if (data) {
            setCommodityName(data.commodity_name_var);
            setdefaultValue(data);
        }
        // setSelectedMaterialResearch(
        //     data.material_research.map(({ support_id, name, support_unit, support_price }: any) => {
        //         return {
        //             id_seq: Number(support_id),
        //             material_support_name_var: name,
        //             unit_size_txt: support_unit,
        //             material_price_int: Number(support_price),
        //         };
        //     }),
        // );
    }, [data, setdefaultValue, getLandInformation]);

    const onSubmitForm: SubmitHandler<IResearchManagement> = ({
        status_int,
        title_research_var,
        planting_distance_int,
        total_treatment_int,
        total_repetition_int,
        total_unit_int,
    }) => {
        const payload = {
            title_research_var,
            commodity_name_var: commodityName,
            research_land_code_var: landCode.toString(),
            planting_distance_int,
            total_treatment_int,
            total_repetition_int,
            total_unit_int,
            treatment_var: formattedData,
            variable_var: formattedData2
        };
        console.log('payload ', ' tittle ',
            title_research_var, ' commodity ',
            commodityName, ' land ',
            landCode.toString(), ' plant distance ',
            planting_distance_int, ' total_treatment ',
            total_treatment_int, ' repetitition ',
            total_repetition_int, ' unit ',
            total_unit_int, ' treament_var ',
            formattedData, ' variable_var ',
            formattedData2);

        onSubmit(payload);
    };

    const handleReceiveDataFormCommodity = async (payload) => {
        let res = null;
        payload.created_by_var = userLoggedIn.fullname_var;
        res = await createNewCommodityRef(payload);

        if (res.data) {
            if (res.data.code === 0) {
                toast.success(res.data.message);
                setIsRefreshing(!isRefreshing);
                showModalFormCommodity(false);
            } else {
                toast.error(res.data.message);
            }
        } else {
            toast.error(`${res.config?.url} ${res.message}`);
        }
    };

    // const handleRadioChange = (value) => {
    //     setSelectedValues((prevSelectedValues) => {
    //         if (prevSelectedValues.includes(value)) {
    //             return prevSelectedValues.filter((v) => v !== value);
    //         } else {
    //             return [...prevSelectedValues, value];
    //         }
    //     });
    // };

    const getDefaultLand = () => {
        // Logic to determine default user
        // if (data) {
        // 	return {
        // 		land_code_var: data.user_id_int,
        // 		fullname_var: data.fullname_var
        // 	};
        // }
    }

    const resetFormCommodity = () => {
        showModalFormCommodity(false);
    };

    return (

        <>
            {!loadingCommodity ? (
                <div className="modal-form">
                    <div className="modal-form-outside" onClick={onCancel}></div>

                    <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
                        <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                                <h3>
                                    {data ? "Update Variable Research" : "Add Variable Research"}
                                </h3>
                            </div>
                            <button
                                type="button"
                                className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                                onClick={onCancel}>
                                <svg
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"></path>
                                </svg>
                            </button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                            {currentStep === 1 && (
                                <>
                                    <div className="space-y-5">
                                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                            <div className="w-full">
                                                <label htmlFor="title_research_var" className="label-form">
                                                    Judul
                                                </label>
                                                <input
                                                    id="title_research_var"
                                                    type="text"
                                                    className="input-form"
                                                    placeholder="Nama"
                                                    {...register("title_research_var", {
                                                        required: "title is required.",
                                                    })}
                                                />
                                                <ErrorField errors={errors} name="title_research_var" />
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="commodity_name_var" className="label-form">
                                                    Komoditas
                                                </label>
                                                <select
                                                    id="commodity_name_var"
                                                    className="input-form"
                                                    {...register("commodity_name_var", {
                                                        required: "Commodity is required",
                                                    })}
                                                    value={commodityName}
                                                    onChange={(e) => {
                                                        setCommodityName(e.target.value);
                                                    }}>
                                                    <option value="">- Pilih Komoditas -</option>
                                                    {dataCommodity!.map((item, key) => (
                                                        <option key={key} value={item.commodity_name_var}>
                                                            {item.commodity_name_var}
                                                        </option>
                                                    ))}
                                                </select>
                                                <ErrorField errors={errors} name="commodity_name_var" />
                                                <div className="justify-end flex">
                                                    <button
                                                        type="button"
                                                        className="mt-4 text-right"
                                                        onClick={() => showModalFormCommodity(true)}>
                                                        + Tambah Commodity
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {!loadingLandResearch && (
                                            <div className="w-full md:w-1/2">
                                                <label htmlFor="research_land_code_var" className="label-form">
                                                    Lahan
                                                </label>
                                                <Select
                                                    id="research_land_code_var"
                                                    styles={selectStylesForm}
                                                    isClearable
                                                    placeholder="- Pilih Lahan -"
                                                    options={dataLandResearch}
                                                    value={getDefaultLand()}
                                                    // isLoading={loadingFarmerCultivation}
                                                    getOptionValue={(option: any) => option["land_code_var"].toString()}
                                                    getOptionLabel={(option: any) => option["land_name_var"]}
                                                    onChange={(selectedLand) => {
                                                        setLandCode(selectedLand ? selectedLand.land_code_var.toString() : null);
                                                    }}
                                                />
                                                <ErrorField errors={errors} name="research_land_code_var" />
                                                {dataLand.length > 0 ? <h1 className=' text-sm -mb-5 pt-5'> luas Lahan :{dataLand[0].area_var} ha </h1> : ''}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {currentStep === 2 && (
                                <>
                                    <div className="space-y-5">
                                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                            <div className="w-full">
                                                <label htmlFor="planting_distance_int" className="label-form">
                                                    Jarak Tanam <label style={{ color: 'grey', fontSize: 'small' }}> (Optional) </label>
                                                </label>
                                                <input
                                                    id="planting_distance_int"
                                                    type="number"
                                                    className="input-form"
                                                    placeholder="Optional"
                                                    {...register("planting_distance_int", {})}
                                                />
                                                <ErrorField errors={errors} name="planting_distance_int" />
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="total_treatment_int" className="label-form">
                                                    Perlakuan
                                                </label>
                                                <input
                                                    id="total_treatment_int"
                                                    type="number"
                                                    onInput={handleInputTreatment}
                                                    className="input-form"
                                                    placeholder="Optional"
                                                    {...register("total_treatment_int", {})}
                                                />
                                                <ErrorField errors={errors} name="total_treatment_int" />
                                            </div>
                                        </div>
                                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                            <div className="w-full">
                                                <label htmlFor="total_repetition_int" className="label-form">
                                                    Jumlah Ulangan
                                                </label>
                                                <input
                                                    id="total_repetition_int"
                                                    type="number"
                                                    className="input-form"
                                                    placeholder="Ulangan"
                                                    {...register("total_repetition_int", {})}
                                                />
                                                <ErrorField errors={errors} name="total_repetition_int" />
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="total_unit_int" className="label-form">
                                                    Unit
                                                </label>
                                                <input
                                                    id="total_unit_int"
                                                    type="number"
                                                    className="input-form"
                                                    placeholder="Unit"
                                                    {...register("total_unit_int", {})}
                                                />
                                                <ErrorField errors={errors} name="total_unit_int" />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {currentStep === 3 && (
                                <>
                                    <div className="space-y-5">
                                        {Array.from({ length: countTreatment }, (vp, vpIndex) => (
                                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0" key={vpIndex}>
                                                <div className="w-full">
                                                    <div className="w-full mb-4">
                                                        <label className="label-form">Treatment {vpIndex + 1}</label>
                                                        {subFirstParameters[vpIndex]?.map((sub, subIndex) => (
                                                            <div className="flex-shrink-0 w-auto mb-4 mr-4" key={subIndex}>
                                                                <label className="label-form">Bahan {subIndex + 1}</label>
                                                                <Select
                                                                    id={`material_research_var${vpIndex}_${subIndex}`}
                                                                    styles={selectStylesForm}
                                                                    isClearable
                                                                    placeholder="- Pilih Bahan -"
                                                                    options={dataMaterialResearch}
                                                                    isLoading={loadingMaterialResearch}
                                                                    value={sub?.material_research_var}
                                                                    onChange={(e) => handleFirstSubInputChange(vpIndex, subIndex, e)}
                                                                    getOptionValue={(option) => option["id_seq"]}
                                                                    getOptionLabel={(option) => `${option.name}`}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className="text-red-500 py-1"
                                                                    onClick={() => handleRemoveFirstSubInput(vpIndex, subIndex)}
                                                                >
                                                                    Remove Sub Value
                                                                </button>
                                                                <div className="w-full">
                                                                    <label htmlFor={`dose_material_var${vpIndex}_${subIndex}`} className="label-form">
                                                                        Dosis
                                                                    </label>
                                                                    <input
                                                                        id={`dose_material_var${vpIndex}_${subIndex}`}
                                                                        className="input-form"
                                                                        placeholder="0"
                                                                        name="dose_material_var"
                                                                        type="number"
                                                                        step=".01"
                                                                        value={sub?.dose_material_var}
                                                                        onChange={(e) => handleDoseChange(vpIndex, subIndex, e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button
                                                            type="button"
                                                            className="text-blue-500 py-1"
                                                            onClick={() => handleFirstSubInput(vpIndex)}
                                                        >
                                                            + Add Value
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}

                            {currentStep === 4 && (
                                <>
                                    <label htmlFor="category_variable_var" className="label-form">
                                        Category Variable
                                    </label>
                                    {/* {dataVariable.map((vp, vpIndex) => (
                                        <div className="space-y-5">
                                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                                <div className="w-full">
                                                    <Select
                                                        id="category_variable_var"
                                                        styles={selectStylesForm}
                                                        isClearable
                                                        placeholder="-Choose Category-"
                                                        options={dataCategoryVariable}
                                                        isLoading={loadingCategoryVariable}
                                                        getOptionValue={(option: any) => option["id_seq"]}
                                                        getOptionLabel={(option: any) => `${option.category_variable_var}`}
                                                        onChange={(e) => e ? handleCategoryVariable(e.id_seq) : null}
                                                    />
                                                    <ErrorField errors={errors} name="category_variable_var" />
                                                </div>
                                            </div>

                                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                                <div className="w-full">
                                                    <label htmlFor="parameter_variable_var" className="label-form">
                                                        Variable
                                                    </label>
                                                    <Select
                                                        id="parameter_variable_var"
                                                        styles={selectStylesForm}
                                                        isClearable
                                                        placeholder="-Choose Variable-"
                                                        options={dataParamaterVariable}
                                                        getOptionValue={(option: any) => option["id_seq"]}
                                                        getOptionLabel={(option: any) => `${option.parameter_variable_var}`}
                                                        onChange={(e) => e ? handleVariable(e.id_seq) : null}
                                                    />
                                                    <ErrorField errors={errors} name="parameter_variable_var" />
                                                </div>
                                                <div className="w-full">
                                                    <label htmlFor="sub_parameter_variable_var" className="label-form">
                                                        Sub Variable
                                                    </label>
                                                    <Select
                                                        id="sub_parameter_variable_var"
                                                        styles={selectStylesForm}
                                                        isClearable
                                                        placeholder="-Choose Variable-"
                                                        options={dataSubParamaterVariable}
                                                        getOptionValue={(option: any) => option["id_seq"]}
                                                        getOptionLabel={(option: any) => `${option.value_parameter_pengamatan}`}
                                                        onChange={handleSubCategoryVariable}
                                                    />
                                                    {selectedOption && isOption && typeof (selectedOption?.values) !== 'string' && (
                                                        <div className="w-full">
                                                            {selectedOption?.values.map((value, index) => (
                                                                <div className="flex items-center me-4">
                                                                    <label key={index} className="flex items-center label-form">
                                                                        <input
                                                                            type="checkbox"
                                                                            value={value}
                                                                            checked={selectedValues.includes(value)}
                                                                            onChange={() => handleRadioChange(value)}
                                                                        />
                                                                        {value}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <ErrorField errors={errors} name="sub_parameter_variable_var" />
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="text-red-500 py-1"
                                                onClick={() => handleRemoveVariable(vpIndex)}
                                            >
                                                Remove Variable
                                            </button>
                                        </div>
                                    ))} */}
                                    {dataVariable.map((vp, vpIndex) => (
                                        <div className="space-y-5" key={vpIndex}>
                                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                                <div className="w-full">
                                                    <Select
                                                        id={`category_variable_var_${vpIndex}`}
                                                        styles={selectStylesForm}
                                                        isClearable
                                                        placeholder="-Choose Category-"
                                                        options={dataCategoryVariable}
                                                        isLoading={loadingCategoryVariable}
                                                        getOptionValue={(option) => option["id_seq"].toString()}
                                                        getOptionLabel={(option) => `${option.category_variable_var}`}
                                                        onChange={(e) => handleCategoryVariable(e?.id_seq, vpIndex)}
                                                    />
                                                    <ErrorField errors={errors} name="category_variable_var" />
                                                </div>
                                            </div>

                                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                                <div className="w-full">
                                                    <label htmlFor={`parameter_variable_var_${vpIndex}`} className="label-form">
                                                        Variable
                                                    </label>
                                                    <Select
                                                        id={`parameter_variable_var_${vpIndex}`}
                                                        styles={selectStylesForm}
                                                        isClearable
                                                        placeholder="-Choose Variable-"
                                                        options={dataParamaterVariable}
                                                        getOptionValue={(option) => option["id_seq"]}
                                                        getOptionLabel={(option) => `${option.parameter_variable_var}`}
                                                        onChange={(e) => handleVariable(e?.id_seq, vpIndex)}
                                                    />
                                                    <ErrorField errors={errors} name="parameter_variable_var" />
                                                </div>
                                                <div className="w-full">
                                                    <label htmlFor={`sub_parameter_variable_var_${vpIndex}`} className="label-form">
                                                        Sub Variable
                                                    </label>
                                                    <Select
                                                        id={`sub_parameter_variable_var_${vpIndex}`}
                                                        styles={selectStylesForm}
                                                        isClearable
                                                        placeholder="-Choose Variable-"
                                                        options={dataSubParamaterVariable}
                                                        getOptionValue={(option) => option["id_seq"]}
                                                        getOptionLabel={(option) => `${option.value_parameter_pengamatan}`}
                                                        onChange={(selectedOption) => handleSubCategoryVariable(selectedOption, vpIndex)}
                                                    />
                                                    {selectedValues[vpIndex] && typeof (selectedValues[vpIndex]) !== 'string' && (
                                                        <div className="w-full">
                                                            {selectedValues[vpIndex].map((value, index) => (
                                                                <div className="flex items-center me-4" key={index}>
                                                                    <label className="flex items-center label-form">
                                                                        <input
                                                                            type="checkbox"
                                                                            value={value}
                                                                            checked={selectedValues[vpIndex].includes(value)}
                                                                            onChange={() => handleRadioChange(value, vpIndex)}
                                                                        />
                                                                        {value}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <ErrorField errors={errors} name="sub_parameter_variable_var" />
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="text-red-500 py-1"
                                                onClick={() => handleRemoveVariable(vpIndex)}
                                            >
                                                Remove Variable
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="text-blue-500 py-1"
                                        onClick={handleAddMoreVariable}
                                    >
                                        + Add More Variable
                                    </button>
                                </>
                            )}

                            {/* <!-- Modal footer --> */}
                            <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                                {currentStep > 1 && (
                                    <button type="button" className="btn-secondary px-7" onClick={handlePrevious}>
                                        Previous
                                    </button>
                                )}
                                {currentStep < totalSteps ? (
                                    <button type="button" className="btn-primary" onClick={handleNext}>
                                        Next
                                    </button>
                                ) : (
                                    <>
                                        <button type="submit" className="btn-primary">Simpan</button>
                                        <button type="reset" className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                                    </>
                                )}
                            </div>
                        </form>

                        {modalFormCommodity && (
                            <ModalFormCommodityStandard
                                data={null}
                                onCancel={resetFormCommodity}
                                onSubmit={handleReceiveDataFormCommodity}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default ModalFormResearch;
