import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select"
import ErrorField from "../ErrorField";
import { ICommodity, createNewCommodityRef } from "@api/commodity-api";
import dayjs from "dayjs";
import Loader from "./Loader";
import useDropdownFarmer, { IUser } from "@hooks/useDropdownFarmer";
import useDropdownLand from "@hooks/useDropdownLand";
import useDropdownRatioDevidend, { useDropdownPeriodPlant } from "@hooks/useDropdownRatioDevidend";
import useDropdownCommodity, {
	useDropdownPlantingMethod,
	useDropdownTemplateTask,
} from "../../hooks/useDropdownCommodity";
import { getFilename } from "@helpers/helper";
import useDropdownVariety from "@hooks/useDropdownVariety";
import { createNewVariety } from "@api/variety-api";
import { toast } from "react-toastify";
import ModalFormCommodityStandard from "./operatingparameter/ModalFormCommodityStandard";
import ModalFormVariety from "./ModalFormVariety";
import { selectStylesForm } from "./TaskManagement/ModalTaskNew";

export const SOURCE_FUNDING_LIST = ["TPPAI", "Non TPPAI"];

type Props = {
	data: ICommodity;
	dataUser?: any;
	onCancel: (e: React.MouseEvent) => any;
	onSubmit: (data: ICommodity) => void;
};

const ModalFormCultivation: React.FC<Props> = ({
	data,
	onCancel,
	onSubmit,
}) => {
	const [userID, setUserID] = useState(data ? data.user_id_int : 0);
	// console.log('user ', userID);

	const [commodityName, setCommodityName] = useState(
		data ? data.commodity_name_var : "",
	);
	const [plantingMethod, setPlantingMethod] = useState(
		data ? data.planting_method_int : 0,
	);
	const [commodityId, setCommodityId] = useState<any>(
		data ? data.commodity_id : null,
	);
	const [PeriodPlant, setPeriodPlant] = useState(
		data ? data.period_plant_txt : "",
	);
	const [DataSourceFunding, setDataSourceFunding] = useState("");
	const [varietyId, setVarietyId] = useState(data ? data.variety_id : null);
	const userLoggedIn = JSON.parse(localStorage.getItem("pandawa-user"));
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [modalFormCommodity, showModalFormCommodity] = useState(false);
	const [modalFormVariety, showModalFormVariety] = useState(false);

	const { dataFarmer, loadingFarmer } = useDropdownFarmer();
	const { dataLand, loadingLand } = useDropdownLand(userID); // eslint-disable-line
	const { dataCommodity, loadingCommodity } = useDropdownCommodity(isRefreshing);
	const { dataVariety, loadingVariety } = useDropdownVariety(isRefreshing);
	const { dataPlanting } = useDropdownPlantingMethod(commodityName);
	const { DataRatioDividend } = useDropdownRatioDevidend(PeriodPlant);
	const { DataPeriodPlant, loadingPeriodPlant } = useDropdownPeriodPlant(isRefreshing)
	const { dataTemplate } = useDropdownTemplateTask(commodityName, plantingMethod!);
	const [selectedFarmer, setSelectedFarmer] = useState<IUser | any>(null);
	// console.log('se ', selectedFarmer);


	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<any>({ criteriaMode: "all" });

	const setdefaultValue = useCallback(
		(obj) => {
			Object.keys(obj).map((key) => {
				if (key === "start_planting_dtm") {
					return setValue(key, dayjs(obj[key]).format("YYYY-MM-DD"));
				}
				else if (key === "period_plant_txt") {
					return setValue(key, obj[key]);
				}
				else {
					return setValue(key, obj[key], { shouldValidate: true });
				}
			});
		},
		[setValue, data?.period_plant_txt],
	);

	useEffect(() => {
		if (data) {
			setCommodityName(data.commodity_name_var);
			setPlantingMethod(data.planting_method_int);
			setUserID(data.user_id_int);
			setIsRefreshing(true)
			setdefaultValue(data);
		}
	}, [data, setdefaultValue]);

	const onSubmitForm: SubmitHandler<ICommodity> = ({
		user_id_int,
		land_code_var,
		// commodity_name_var,
		// commodity_type_var,
		start_planting_dtm,
		period_plant_txt,
		harvest_prediction_int,
		planting_method_int,
		planting_phase_int,
		source_funding_var,
		file_template_txt,
		variety_id,
		ratio_dividend_int
	}) => {
		// 2201
		// let existPeriodYear: any = "";
		// if (data && data.period_plant_txt?.length! > 2) {
		// 	existPeriodYear = data.period_plant_txt?.substring(0, 2);
		// } else {
		// 	existPeriodYear = dayjs().format("YY");
		// }

		const payload = {
			user_id_int: userID,
			land_code_var,
			// commodity_name_var: JSON.parse(commodity_name_var).commodity_name_var,
			commodity_name_var: commodityName,
			// commodity_type_var,
			// start_planting_dtm,
			period_plant_txt: period_plant_txt,
			// harvest_prediction_int,
			planting_method_int: planting_method_int,
			source_funding_var,
			file_template_txt:
				file_template_txt.split(".").length > 0
					? file_template_txt.split(".")[0]
					: file_template_txt,
			// planting_phase_int
			commodity_id: commodityId,
			variety_id,
			ratio_dividend_int
		};

		// console.log(payload);

		onSubmit(payload);
	};

	const handleReceiveDataFormCommodity = async (payload) => {
		let res = null;
		payload.created_by_var = userLoggedIn.fullname_var;
		res = await createNewCommodityRef(payload);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);
				setIsRefreshing(!isRefreshing);
				showModalFormCommodity(false);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(`${res.config?.url} ${res.message}`);
		}
	};

	const getDefaultUser = () => {
		// Logic to determine default user
		if (data) {
			return {
				id_seq: data.user_id_int,
				fullname_var: data.fullname_var
			};
		}
	}

	const handleReceiveDataFormVariety = async (data) => {
		let res = null;
		data.created_by_var = userLoggedIn.fullname_var;
		res = await createNewVariety(data);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);
				setIsRefreshing(!isRefreshing);
				showModalFormVariety(false);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleCommodity = (selectedData: any) => {
		setCommodityId(selectedData)
		setCommodityName(dataCommodity.filter(
			(item) =>
				Number(item.id_seq) === Number(selectedData)
		)[0].commodity_name_var);
	};

	return (
		<>
			{!loadingFarmer && !loadingCommodity ? (
				<div className="modal-form">
					<div className="modal-form-outside" onClick={onCancel}></div>

					<div className="modal-form-content" style={{ maxHeight: "90vh" }}>
						<div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
							<div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
								<i className="fa-brands fa-pagelines flex justify-center items-center"></i>
								<h3>{data ? "Ubah Cultivation" : "Tambah Cultivation"}</h3>
							</div>
							<button
								type="button"
								className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
								onClick={onCancel}>
								<svg
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"></path>
								</svg>
							</button>
						</div>

						<form
							onSubmit={handleSubmit(onSubmitForm)}
							className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
							<div className="space-y-5">
								<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
									<div className="w-full">
										<label htmlFor="user_id_int" className="label-form">
											Petani
										</label>
										<Select
											id="user_id_int"
											styles={selectStylesForm}
											isClearable
											placeholder="- Pilih petani -"
											options={dataFarmer}
											isLoading={loadingFarmer}
											getOptionValue={(option: any) => option["id_seq"]}
											getOptionLabel={(option: any) => option["fullname_var"]}
											onChange={(selectedOption) => setUserID(selectedOption ? selectedOption.id_seq : null)}
											value={getDefaultUser()}
										/>
										<ErrorField errors={errors} name="user_id_int" />
									</div>
									{!loadingLand && (
										<div className="w-full md:w-1/2">
											<label htmlFor="land_code_var" className="label-form">
												Lahan
											</label>
											<select
												id="land_code_var"
												className="input-form"
												{...register("land_code_var", {
													required: "Land code is required",
												})}>
												<option value="">- Pilih Lahan -</option>
												{dataLand!.map((item, key) => (
													<option key={key} value={item.land_code_var}>
														[{item.land_code_var}] {item.land_name_var}
													</option>
												))}
											</select>
											<ErrorField errors={errors} name="land_code_var" />
										</div>
									)}
								</div>

								<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
									<div className="w-full">
										<label htmlFor="commodity_id" className="label-form">
											Nama Komoditas
										</label>
										<select
											id="commodity_id"
											className="input-form"
											{...register("commodity_id", {
												required: "Commodity is required",
											})}
											onChange={(e) => handleCommodity(e.target.value)}
										>
											<option value="">- Pilih Komoditas -</option>
											{dataCommodity!.map((item, key) => (
												<option key={key} value={item.id_seq}>
													{item.commodity_name_var}
												</option>
											))}
										</select>
										<ErrorField errors={errors} name="commodity_id" />
										<button
											type="button"
											className="mt-4 text-right"
											onClick={() => showModalFormCommodity(true)}>
											+ Tambah Commodity
										</button>
									</div>
									{!loadingVariety && (
										<div className="w-full">
											<label htmlFor="variety_id" className="label-form">
												Variety
											</label>
											<select
												id="variety_id"
												className="input-form"
												{...register("variety_id", {
													required: "Variety is required",
												})}
												onChange={(e) => {
													setVarietyId(Number(e.target.value));
												}}>
												<option value="">- Pilih Variety -</option>
												{dataVariety
													?.filter(
														(item) =>
															Number(item.commodity_id) === Number(commodityId),
													)
													?.map((item, key) => (
														<option key={key} value={item.id_seq}>
															{item.name}
														</option>
													))}
											</select>
											<ErrorField errors={errors} name="variety_id" />
											<button
												type="button"
												className="mt-4 text-right"
												onClick={() => showModalFormVariety(true)}>
												+ Tambah Variety
											</button>
										</div>
									)}
								</div>
								<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
									{/* <div className="w-full">
										<label htmlFor="start_planting_dtm" className="label-form">
											Mulai Tanam
										</label>
										<input
											id="start_planting_dtm"
											type="date"
											className="input-form"
											{...register("start_planting_dtm", {
												required: "Start Planting is required.",
											})}
										/>
										<ErrorField errors={errors} name="start_planting_dtm" />
									</div> */}
									{!loadingPeriodPlant && (
										<div className="w-full">
											<label htmlFor="period_plant_txt" className="label-form">
												Periode Tanam
											</label>
											<div className="flex item-center space-x-2">
												<select
													id="period_plant_txt"
													className="input-form"
													{...register("period_plant_txt", {
														required: "This field is required",
													})}
													onChange={(e) => {
														setPeriodPlant(e.target.value);
													}}>
													<option value="">- Pilih Periode Tanam -</option>
													{DataPeriodPlant?.map((item, key) => (
														<option key={key} value={item.period_plant_txt}>
															{item.period_plant_txt}
														</option>
													))}
												</select>
											</div>
											<ErrorField errors={errors} name="period_plant_txt" />
										</div>
									)}
								</div>

								<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
									{data ? (
										dataPlanting.length > 0 ? (
											<div className="w-full">
												<label
													htmlFor="planting_method_int"
													className="label-form">
													Metode Tanam
												</label>
												<select
													id="planting_method_int"
													className="input-form"
													{...register("planting_method_int")}
													onChange={(e) =>
														setPlantingMethod(Number(e.target.value))
													}>
													<option value="">- Pilih Metode Tanam -</option>
													{dataPlanting!.map((item, key) => (
														<option key={key} value={item.id_seq}>
															{item.name_planting_var}
														</option>
													))}
												</select>
											</div>
										) : null
									) : (
										<div className="w-full">
											<label
												htmlFor="planting_method_int"
												className="label-form">
												Metode Tanam
											</label>
											<select
												id="planting_method_int"
												className="input-form"
												{...register("planting_method_int")}
												onChange={(e) =>
													setPlantingMethod(Number(e.target.value))
												}>
												<option value="">- Pilih Metode Tanam -</option>
												{dataPlanting!.map((item, key) => (
													<option key={key} value={item.id_seq}>
														{item.name_planting_var}
													</option>
												))}
											</select>
										</div>
									)}
									{!loadingPeriodPlant && (
										<div className="w-full">
											<label htmlFor="source_funding_var" className="label-form">
												Sumber Dana
											</label>
											<div className="flex item-center space-x-2">
												<select
													id="source_funding_var"
													className="input-form w-20 outline-none focus:outline-none focus:ring-0"
													{...register("source_funding_var", {
														required: "This field is required",
													})}
													onChange={(e) => setDataSourceFunding(e.target.value)}>

													<option value="">- Pilih Sumber Dana -</option>
													{SOURCE_FUNDING_LIST!.map((item, key) => (
														<option key={key} value={item}>
															{item}
														</option>
													))}
												</select>
												<ErrorField errors={errors} name="source_funding_var" />
												{DataSourceFunding === 'TPPAI' || data?.source_funding_var === 'TPPAI' ?
													<select
														id="ratio_dividend_int"
														className="input-form"
														{...register("ratio_dividend_int", {
															required: "This field is required",
														})}>
														<option value="">- Pilih Presentase -</option>
														{DataRatioDividend?.map((item, key) => (
															<option key={key} value={item.id_seq}>
																{item.ratio_farmer_int} : {item.ratio_stakeholder_int}
															</option>
														))}
													</select>

													: ''}
											</div>
											<ErrorField errors={errors} name="ratio_dividend_int" />
										</div>
									)}
									{!loadingPeriodPlant && (
										<div className="w-full">
											<label htmlFor="file_template_txt" className="label-form">
												File Template Task
											</label>
											<select
												id="file_template_txt"
												className="input-form"
												{...register("file_template_txt", {
													required: "This field is required",
												})}>
												<option value="">- Pilih Template -</option>
												{dataTemplate!.map((item, key) => (
													<option
														key={key}
														value={getFilename(item.file_name_txt)}>
														{getFilename(item.file_name_txt)}
													</option>
												))}
											</select>
											<ErrorField errors={errors} name="file_template_txt" />
										</div>
									)}
								</div>
							</div>

							{/* <!-- Modal footer --> */}
							<div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
								<button type="submit" className="btn-primary">
									Simpan
								</button>
								<button
									type="reset"
									className="btn-secondary px-7"
									onClick={onCancel}>
									Batal
								</button>
							</div>
						</form>

						{modalFormCommodity && (
							<ModalFormCommodityStandard
								data={null}
								onCancel={() => showModalFormCommodity(false)}
								onSubmit={handleReceiveDataFormCommodity}
							/>
						)}
						{modalFormVariety && (
							<ModalFormVariety
								data={null}
								onCancel={() => showModalFormVariety(false)}
								onSubmit={handleReceiveDataFormVariety}
							/>
						)}
					</div>
				</div>
			) : (
				<Loader />
			)}
		</>
	);
};

export default ModalFormCultivation;
