import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../../ErrorField";
import Loader from "./../../Loader";
import useDropdownCommodity from "@hooks/useDropdownCommodity";
import ModalFormCommodityStandard from "./../../operatingparameter/ModalFormCommodityStandard";
import { createNewCommodityRef } from "@api/commodity-api";
import { toast } from "react-toastify";
import { IPlantingMethod } from "@api/plantingmethod-api";

type Props = {
  data: IPlantingMethod;
  dataUser?: any;
  onCancel: (e: React.MouseEvent) => any;
  onSubmit: (data: IPlantingMethod) => void;
};

const ModalFormPlantingMethod: React.FC<Props> = ({
  data,
  onCancel,
  onSubmit,
}) => {
  // states
  const [name, setName] = useState(data ? data.name_planting_var : "");
  const [commodityName, setCommodityName] = useState(
    data ? data.commodity_name_var : "",
  );
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [modalFormCommodity, showModalFormCommodity] = useState(false);
  const userLoggedIn = JSON.parse(localStorage.getItem("pandawa-user"));

  // hooks
  const { dataCommodity, loadingCommodity } =
    useDropdownCommodity(isRefreshing);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({ criteriaMode: "all" });

  // functions
  const setdefaultValue = useCallback(
    (obj) => {
      Object.keys(obj).map((key) => {
        return setValue(key, obj[key], { shouldValidate: true });
      });
    },
    [setValue],
  );
  useEffect(() => {
    if (data) {
      setName(data.name_planting_var);
      setCommodityName(data.commodity_name_var);
      setdefaultValue(data);
    }
  }, [data, setdefaultValue]);
  const onSubmitForm: SubmitHandler<IPlantingMethod> = ({
    name_planting_var,
    commodity_name_var,
    status_int,
  }) => {
    const payload = {
      name_planting_var,
      commodity_name_var: commodity_name_var,
      status_int,
    };
    onSubmit(payload);
  };
  const handleReceiveDataFormCommodity = async (payload) => {
    let res = null;
    payload.created_by_var = userLoggedIn.fullname_var;
    res = await createNewCommodityRef(payload);

    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);
        setIsRefreshing(!isRefreshing);
        showModalFormCommodity(false);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(`${res.config?.url} ${res.message}`);
    }
  };
  const resetFormCommodity = () => {
    showModalFormCommodity(false);
  };

  return (
    <>
      {!loadingCommodity ? (
        <div className="modal-form">
          <div className="modal-form-outside" onClick={onCancel}></div>

          <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
            <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
              <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                <h3>
                  {data ? "Ubah Planting Method" : "Tambah Planting Method"}
                </h3>
              </div>
              <button
                type="button"
                className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                onClick={onCancel}>
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
              </button>
            </div>

            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
              <div className="space-y-5">
                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                  <div className="w-full">
                    <label htmlFor="name" className="label-form">
                      Nama
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="input-form"
                      placeholder="Nama"
                      {...register("name_planting_var", {
                        required: "NameType is required.",
                      })}
                    />
                    <ErrorField errors={errors} name="name" />
                  </div>
                  <div className="w-full">
                    <label htmlFor="commodity_name_var" className="label-form">
                      Nama Komoditas
                    </label>
                    <select
                      id="commodity_name_var"
                      className="input-form"
                      {...register("commodity_name_var", {
                        required: "Commodity is required",
                      })}
                      value={commodityName}
                      onChange={(e) => {
                        setCommodityName(e.target.value);
                      }}>
                      <option value="">- Pilih Komoditas -</option>
                      {dataCommodity!.map((item, key) => (
                        <option key={key} value={item.commodity_name_var}>
                          {item.commodity_name_var}
                        </option>
                      ))}
                    </select>
                    <ErrorField errors={errors} name="commodity_name_var" />
                    <div className="justify-end flex">
                      <button
                        type="button"
                        className="mt-4 text-right"
                        onClick={() => showModalFormCommodity(true)}>
                        + Tambah Commodity
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <label htmlFor="status_int" className="label-form">
                    Status
                  </label>
                  <select
                    id="status_int"
                    className="input-form"
                    {...register("status_int", {
                      required: "This field is required",
                    })}>
                    <option value="">- Pilih status -</option>
                    <option key={`active`} value={1}>
                      Active
                    </option>
                    <option key={`inactive`} value={0}>
                      Inactive
                    </option>
                  </select>
                  <ErrorField errors={errors} name="status_int" />
                </div>
              </div>

              {/* <!-- Modal footer --> */}
              <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                <button type="submit" className="btn-primary">
                  Simpan
                </button>
                <button
                  type="reset"
                  className="btn-secondary px-7"
                  onClick={onCancel}>
                  Batal
                </button>
              </div>
            </form>

            {modalFormCommodity && (
              <ModalFormCommodityStandard
                data={null}
                onCancel={resetFormCommodity}
                onSubmit={handleReceiveDataFormCommodity}
              />
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ModalFormPlantingMethod;
