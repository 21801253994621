import axios from "axios";
import { baseURL } from "./common-api";

export interface IResearchManagement {
    id_seq?: number;
    commodity_id_int?: number,
    planting_distance_int?: number,
    research_land_code_var?: string,
    commodity_name_var?: string,
    title_research_var?: string,
    category_variable_id_int?: number,
    total_repetition_int?: number,
    total_treatment_int?: number,
    total_unit_int?: number,
    status_int?: number,
    treatment_var?: any,
    variable_var?: any,

    created_by_var?: string;
    created_on_dtm?: string;
    updated_by_var?: string;
    updated_on_dtm?: string;
}

export const createNew = async (payload: IResearchManagement) => {
    try {
        const data = await axios.post(
            baseURL + "/api/reseach-management/create",
            payload,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            },
        );
        return data;
    } catch (error: any) {
        if (error.response) return error.response;
        else return JSON.parse(JSON.stringify(error));
    }
};

export const getAllResearchManagement = async () => {
    try {
      const data = await axios.get(
        baseURL + "/api/reseach-management/get-all",
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };