import { getAllCities, getAllDistricts, getAllLocation, getCities, getDistricts, getProvince } from "../api/common-api"

class Location{
    static getProvinces = async () => {
        const data = await getProvince()

        return data.data
    }

    static getCities = async (provID) => {
        const data = await getCities(provID)

        return data.data
    }

    static allCity = async() => {
        const data = await getAllCities()
        return data.data
    }

    static getDistricts = async (cityID) => {
        const data = await getDistricts(cityID)
        return data.data
    }

    static allDistricts = async () => {
        const data = await getAllDistricts()
        return data.data
    }

    static getAll = async () => {
        const data = await getAllLocation()
        return data.data
    }
}

export default Location