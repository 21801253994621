import axios from "axios";
import { baseURL } from "./common-api";

export interface IDashboard {
	id_seq?: number;
	user_id_int: number;
	land_code_var?: string;
	material_support_name_var: string;
	start_planting_dtm?: string;
	period_plant_txt?: string;
	harvest_prediction_int?: number;
	planting_method_int?: number;
	planting_phase_int?: number;
	source_funding_var: string;
	fullname_var?: string;
	land_name_var?: string;
	material_support_id?: number;
	variety_id?: number;
	variety_name?: string;
	ratio_farmer_int?: number;
	ratio_stakeholder_int?: number;
	budget_dividend_id_int?: number;
}

export const getDropDownPeriodPlant = async () => {
	try {
		const data = await axios.get(baseURL + "/api/dashboard/dropdown/period-plant", {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error: any) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};
