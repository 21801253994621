import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import TableUser from "@components/tables/Table";
import { getAllFAforModal } from "@api/user-api";
import SearchField from "@components/SearchField";
import Helper from "@helpers/helper";

type Props = {
    onClose: (e: React.MouseEvent<HTMLElement>) => void;
    commodity_id: any;
};

const ModalListFieldAssistant: React.FC<Props> = ({ commodity_id, onClose }) => {
    // hooks
    const {
        formState: { errors },
        setValue,
    } = useForm<any>({ criteriaMode: "all" });

    // states

    const [dataListUser, setDataListUser] = useState<any[]>([])
    const [filterData, setFilterData] = useState([]);

    const fetchUser = async () => {
        let res
        if (commodity_id !== 0) {
            res = await getAllFAforModal(commodity_id, 0);
        } else {
            res = await getAllFAforModal(0, 0);
        }
        setDataListUser(res.data.data)
        setFilterData(res.data.data)
    }

    // effects
    const setdefaultValue = useCallback(
        (obj) => {
            Object.keys(obj).map((key) => {
                return setValue(key, obj[key], { shouldValidate: true });
            });
        },
        [setValue],
    );
    useEffect(() => {
        // console.log('comx ', commodity_id);

        fetchUser();

    }, [setdefaultValue]);

    const handleSearch = (event) => {
        event.preventDefault();

        const newData = [...dataListUser];
        if (event?.target?.value) {
            const filtered = newData.filter((item) => {
                return (
                    item.fullname_var
                        ?.toLowerCase()
                        .includes(event?.target?.value?.toLowerCase()) ||
                    item.phone_number_int
                        ?.toLowerCase()
                        .includes(event?.target?.value?.toLowerCase())
                );
            });

            setFilterData(filtered);
        } else {
            setFilterData(dataListUser);
        }
    };

    const columns = [
        {
            Header: () => <span className="p-4">No</span>,
            accessor: "no",
            Cell: ({ row }: any) => (
                <div className="text-left pl-4">{row.index + 1}.</div>
            ),
        },
        {
            Header: "Nama Pendamping",
            accessor: "fullname_var",
        },
        {
            Header: "Nomor Telepon",
            accessor: "phone_number_int",
        },
    ];


    return (
        <>

            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                <div
                    className="modal-form-content bg-white p-5 w-[95%] md:w-6/12 2xl:w-5/12"
                    style={{ maxHeight: "90vh" }}>
                    <div className="flex flex-col md:flex-row items-right justify-between w-full mb-5 space-y-3 md:space-y-0 md:mb-4 2xl:mb-5">
                        <button
                            type="button"
                            className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onClose}>
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
                        <div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
                            <h3>Field Assistant List</h3>
                        </div>
                        <div className=" ml-auto inline-flex items-center justify-end">
                            <SearchField onChange={handleSearch} placeholder="Cari Pendamping..." />
                        </div>
                    </div>

                    <TableUser dataTable={filterData} columnTable={columns} />

                </div>
            </div>

        </>
    );

};



export default ModalListFieldAssistant;
