import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";

import TableUser from "@components/tables/Table";
import { ButtonAdd } from "@components/button/CustomButton";
import { DropdownLand } from "@components/dropdown/SimpleDropdown";

import Loader from "@components/modal/Loader";

import {
  createNew,
  deleteData,
  getAll,
  update,
  IParameterPascaPanenRef,
} from "@api/parameterpascapanenref-api";
import SearchField from "@components/SearchField";
import { ActionType } from "@reduxs/action/actions";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "@context/GlobalProvider";
import { IRecentPage } from "@context/ContextInterface";
import { getPayloadRecentActivity } from "@helpers/helper";
import ModalFormParameterPascaPanenRef from "@components/modal/ModalFormParameterPascaPanenRef";
import ModalFormParameterPascaPanenRefDetail from "@components/modal/ModalFormParameterPascaPanenRefDetail";

const TITLE_BAR = "Cultivation Management / Parameter Pasca Panen Ref";

type Props = {
  user?: any;
};

const ParameterPascaPanenRefManagement: React.FC<Props> = ({ user }) => {
  const [loader, showLoader] = useState(false);
  const [modalForm, showModalForm] = useState(false);
  const [modalDetail, showModalDetail] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [originData, setOriginData] = useState<IParameterPascaPanenRef[] | []>(
    [],
  );
  const [filterData, setFilterData] = useState<IParameterPascaPanenRef[] | []>(
    [],
  );
  const [selectedData, setSelectedData] =
    useState<IParameterPascaPanenRef | null>(null);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: "push", payload });
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
  }, [dispatch, dispatchCalPage, pathname]);

  const fetchData = useCallback(async () => {
    showLoader(true);
    const res = await getAll();

    showLoader(false);
    console.log("Parameter Pasca Panen Ref :", res);
    if (res.data) {
      if (res.data.code === 0) {
        setOriginData(res.data.data);
        setFilterData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, dispatch]);

  const handleEditData = (selectedData: IParameterPascaPanenRef) => {
    setSelectedData(selectedData);
    setIsUpdate(true);
    showModalForm(true);
  };
  const handleDetailData = (selectedData: IParameterPascaPanenRef) => {
    setSelectedData(selectedData);
    showModalDetail(true);
  };

  const handleReceiveDataForm = async (data: IParameterPascaPanenRef) => {
    showLoader(true);

    let res = null;
    if (!isUpdate) {
      // data.created_by_var = user.fullname_var;
      res = await createNew(data);

      var payload = getPayloadRecentActivity(
        1,
        "Parameter Pasca Panen Ref",
        data.param,
        user.fullname_var,
        pathname,
      );
    } else {
      // data.updated_by_var = user.fullname_var;
      res = await update(selectedData?.id_seq!, data);

      payload = getPayloadRecentActivity(
        2,
        "Parameter Pasca Panen Ref",
        data.param,
        user.fullname_var,
        pathname,
      );
    }

    console.log("Create/Update Parameter Pasca Panen Ref :", res);

    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);

        dispatchCalActivity({ type: "push", payload });

        fetchData();
        resetForm();
      } else {
        toast.error(res.data.message);
        showLoader(false);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const handleDeleteData = async (data: IParameterPascaPanenRef) => {
    showLoader(true);
    const res = await deleteData(data.id_seq!);

    console.log("DELETE Parameter Pasca Panen Ref :", res);
    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);

        const payload = getPayloadRecentActivity(
          3,
          "Parameter Pasca Panen Ref",
          data.param,
          user.fullname_var,
          pathname,
        );
        dispatchCalActivity({ type: "push", payload });

        resetForm();
        fetchData();
      } else {
        toast.error(res.data.message);
        showLoader(false);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const resetForm = () => {
    setSelectedData(null);
    setIsUpdate(false);
    showModalForm(false);
    showLoader(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const newData = [...originData];
    if (event.target.value) {
      const filtered = newData.filter((item) => {
        return item.param
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      console.log({ filtered });
      setFilterData(filtered);
    } else {
      setFilterData(originData);
    }
  };

  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }: any) => (
        <div className="pl-4 text-left">{row.index + 1}.</div>
      ),
    },
    {
      Header: "Parameter",
      accessor: "param",
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }: any) => {
        const data = row.original;
        if (user?.user_group_id_int === 1 || user?.user_group_id_int === 2) {
          return (
            <DropdownLand
              data={data}
              onEdit={() => handleEditData(data)}
              onDetail={() => handleDetailData(data)}
              onDelete={() => handleDeleteData(data)}
            />
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  return (
    <div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
      <div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
        <div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
          <h1 className="text-xl font-bold uppercase">
            List Parameter Pasca Panen Ref
          </h1>
          <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
            <SearchField
              onChange={handleSearch}
              placeholder="Cari data parameter Pasca Panen Ref..."
            />
            {user?.user_group_id_int === 1 || user?.user_group_id_int === 2 ? (
              <ButtonAdd onClick={() => showModalForm(true)} />
            ) : null}
          </div>
        </div>
        <TableUser dataTable={filterData} columnTable={columns} />
      </div>

      {modalForm && (
        <ModalFormParameterPascaPanenRef
          data={selectedData!}
          onCancel={resetForm}
          onSubmit={handleReceiveDataForm}
        />
      )}
      {modalDetail && (
        <ModalFormParameterPascaPanenRefDetail
          data={selectedData!}
          onCancel={() => showModalDetail(false)}
        />
      )}
      {loader && <Loader />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(ParameterPascaPanenRefManagement);
