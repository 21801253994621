import axios from "axios";
import { baseURL } from "./common-api";

export interface LandPrice {
  id_seq?: number;
  user_id_int: number;
  land_code_var?: string;
  material_support_name_var: string;
//   material_support_type_var: string;
  start_planting_dtm?: string;
  period_plant_txt?: string;
  harvest_prediction_int?: number;
  planting_method_int?: number;
  planting_phase_int?: number;
  source_funding_var: string;
  file_template_txt: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
  fullname_var?: string;
  land_name_var?: string;
  land_area_var?: string;
  material_support_id?: number;
  land_price_int?: number;
  ratio_farmer_int?: number;
  ratio_stakeholder_int?: number;
}


export const createNewLandPrice = async (payload: any) => {
	try {
	  const data = await axios.post(
		baseURL + "/api/land-price/create",
		payload,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };
  
  export const updateLandPrice = async (
	LandPriceID: string,
	payload: any,
  ) => {
	try {
	  const data = await axios.patch(
		baseURL + "/api/land-price/update/" + LandPriceID,
		payload,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };
  
  export const deleteLandPrice = async (LandPriceID: number) => {
	try {
	  const data = await axios.delete(
		baseURL + "/api/land-price/delete/" + LandPriceID,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const getAllLandPrice = async () => {
	try {
	  const data = await axios.get(baseURL + "/api/land-price/get-all", {
		headers: {
		  authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
	  });
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const getRatioListByPeriod = async (period_plant_txt: string) => {
    try {
        const data = await axios.get(baseURL + '/api/land-price/get-list-land-price?period_plant_txt=' + period_plant_txt, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
  //#endregion
  