import { useEffect, useCallback, useState, useContext } from "react";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import { setUserData, setMenuList } from "../reduxs/action/actions";
import Loader from "../components/modal/Loader";
import NotFoundContainer from "../containers/NotFoundContainer";
import { getSidebarMenu } from "../api/common-api";
import { dashboardRoutes, dashboardStaticRoutes } from "../routes";
import { getAllUserGroup } from "../api/usergroup-api";
import { toast } from "react-toastify";
import { GlobalContext } from "../context/GlobalProvider";
import Helper from "../helpers/helper";

function Home({ setUserData, setMenuList, titleBar }: any) {
  const [loader, showLoader] = useState(true);
  const [menuListString, setMenuListString] = useState('')

  const token = localStorage.getItem("pandawa-token");
  const navigate = useNavigate();
  const { pathname } = useLocation()

  const { setUserStorage } = useContext(GlobalContext);

  // useEffect(() => {
  //   const { pathname } = location;
  //   const titleBaseOnPath = pathname.split("/")[pathname.split("/").length - 1].split("-").join(" ");
  //   const payload: IRecentPage = {
  //     id: new Date().getTime(),
  //     title: titleBar || Helper.capitalEachWord(titleBaseOnPath),
  //     path: pathname,
  //     url: window.location.href,
  //   };

  //   dispatchCalPage({ type: "push", payload });
  //   // eslint-disable-next-line
  // }, [location, titleBar, dispatchCalPage]);

  const fetchUserGroup = async (token: string) => {
    const res = await getAllUserGroup(token);

    if (res.data) {
      if (res.data.code === 0) {
        localStorage.setItem("pandawa-role", JSON.stringify(res.data.data));
      }
    }
  };

  const fetchMenuList = useCallback(
    async (token: string, userGroupID: string | number) => {
      const res = await getSidebarMenu(token, userGroupID);

      /* FETCH ALL USER GROUP, STORE TO LOCAL  */
      fetchUserGroup(token);

      console.log("Menu List :", res);
      if (res.data) {
        if (res.data.code === 0) {
          if (res.data.data.length > 0) {
            showLoader(false);
            localStorage.setItem("pandawa-menu", JSON.stringify(res.data.data));
            setMenuList(res.data.data);
            setMenuListString(JSON.stringify(res.data.data))
          } else {
            toast.warning("Empty sidebar menu!");
          }
        } else if (res.data.code === 99) {
          navigate("/auth", { replace: true });
        } else {
          showLoader(false);
          toast.error(res.data.message);
        }
      } else {
        showLoader(false);
        fetchMenuList(token, userGroupID);
      }
      // eslint-disable-next-line
    },
    [navigate, setMenuList]
  );

  useEffect(() => {
    showLoader(true);
    if (!token) {
      navigate("/auth", { replace: true });
    } else {
      // console.log(token)
      const decode: any = jwtDecode(token);
      // console.log(decode)
      const currentTime = Date.now() / 1000;
      if (decode.exp < currentTime) {
        Helper.logout()
        navigate("/auth", { replace: true });
      } else {
        fetchMenuList(token, decode.user_group_id_int);
        setUserData(decode);

        setUserStorage(decode);
        localStorage.setItem('pandawa-user', JSON.stringify(decode))
      }
    }

    // eslint-disable-next-line
  }, [token]);

  const isMenuExist = () => {
    const listMenu = localStorage.getItem('pandawa-menu') || menuListString
    if(listMenu){
      const arrPath = pathname.split('/dashboard/')
      if(arrPath.length > 1){
        const path = arrPath[1]
        if(listMenu.includes(path)){
          return true
        }else{
          // const staticPath = path.split('/')            // ['cultivation-management-panen-approval', '8']
          // if(staticRoutes.includes(staticPath[0])){
          //   return true
          // }else{
          //   return false
          // }
          return false
        }
      }else{
        return true
      }
      //  ['', 'cultivation-management-task']
      // ['/dashboard']
    }else{
      return true
    }
  }

  // MAIN ROUTE
  const getMainRoutes = () => {
    return dashboardRoutes.map(({path, component}, key) => {
      return isMenuExist()?
      <Route path={path} element={component} key={key} />
      :
      <Route path={path} element={<NotFoundContainer />} key={key} />
    });
  };

  // STATIC ROUTE
  const getStaticRoutes = () => {
    return dashboardStaticRoutes.map(({path, component}, key) => {
      return <Route path={path} element={component} key={key} />
    });
  };

  return (
    <>
      {token && (
        <div className="flex flex-col lg:flex-row w-full h-screen overflow-hidden font-poppins text-xs bg-soft">
          <Sidebar />
          <div className="w-full overflow-x-hidden overflow-y-auto h-screen flex flex-col">
            {/* HEADER */}
            <div className="hidden lg:block bg-soft py-5 px-4 lg:px-5 sticky top-0 shadow-md z-10">
              <Header />
            </div>

            {/* DASHBOARD CONTENT */}
            <Routes>
              {getMainRoutes()}

              {getStaticRoutes()}

              <Route path="*" element={<NotFoundContainer />} />
              {/* <Route path='*' element={<Navigate replace to='/dashboard/page-not-found' />} /> */}
            </Routes>

            {/* FOOTER */}
            <div className="flex items-center justify-between p-5 sm:px-6 lg:px-8 md:text-sm mt-auto font-medium">
              <p>Design & Develop by FMS Dev Team</p>
              <p>{new Date().getFullYear()} © FMS v2.0</p>
            </div>
          </div>
        </div>
      )}
      {loader && <Loader />}
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ setUserData, setMenuList }, dispatch);
};

const mapStateToProps = (state: any) => {
  return {
    titleBar: state.title_bar,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
