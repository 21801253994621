import { LazyLoadImage } from "react-lazy-load-image-component"
import logo from '../assets/img/logo.png'

const dummyData = [
    {project: 'Sereh Wangi Bengkulu 2', lokasi: 'Rajang Lebong Bengkulu', tanggal: '25 November 2021', luas: '22', status: 'Masa Tanam', progress: '50', waktu: '30'},
    {project: 'Sereh Wangi', lokasi: 'Ciwidey Bandung', tanggal: '22 Februari 2021', luas: '50', status: 'Panen', progress: '90', waktu: '1'},
    {project: 'Sereh Sukabumi 2', lokasi: 'Purabaya, Sukabumi', tanggal: '8 April 2021', luas: '23', status: 'Persiapan', progress: '20', waktu: '40'},
    {project: 'Sereh Wangi Sukabumi 1', lokasi: 'Purabaya, Sukabumi', tanggal: '13 September 2021', luas: '14', status: 'Masa Tanam', progress: '60', waktu: '20'},
    {project: 'Sereh Wangi Bengkulu 1', lokasi: 'Rajang Lebong Bengkulu', tanggal: '14 Oktober 2021', luas: '25', status: 'Persiapan', progress: '10', waktu: '50'},
    {project: 'Sereh Wangi 2', lokasi: 'Ciwidey Bandung', tanggal: '13 September 2021', luas: '8', status: 'Masa Tanam', progress: '70', waktu: '10'},
]

const Project = () => {
    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between mb-5'>
                <h1 className='text-base font-semibold'>PROJECT</h1>
                <h2 className='text-base font-medium'>Menu<span className='text-xs'> / </span><span className='text-sm'>Project</span></h2>
            </div>
            <div className='lg:grid lg:grid-cols-3 2xl:grid-cols-4'>

            {dummyData.map((data, key) => 
                <div className='p-5 mb-5 bg-white rounded-2xl shadow-xl w-full lg:w-84' key={key}>
                    <div className='flex'>
                        <div className='bg-header rounded-full p-2 w-17 h-17 mr-6 lg:mr-5'>
                            <LazyLoadImage effect='blur' src={logo} />
                        </div>
                        <div>
                            <h2 className='font-semibold'>{data.project}</h2>
                            <h3 className='font-medium my-1'>{data.lokasi}</h3>
                            <div className='flex text-tag font-semibold mb-5 mt-2'>
                                <p className='mr-3 bg-gray-200 rounded-md px-2 py-1'><i className="fa-regular fa-calendar"></i>{data.tanggal}</p>
                                <p className='bg-gray-200 rounded-md px-2 py-1'><i className="fa-solid fa-expand"></i> {data.luas} Ha</p>
                            </div>
                            <button className='bg-lightcayn text-white py-2 px-3 rounded-md'>
                                Lihat Project <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <div className='border-t-2 border-gray-300 mt-5 pt-4 flex items-center justify-between w-full'>
                        <p className={`${parseInt(data.progress)>85? 'bg-green-200': parseInt(data.progress)<25? 'bg-red-200': 'bg-gray-200'} rounded-md px-2 py-2 text-tag font-semibold`}>{data.status}</p>
                        <div className="pt-1 w-1/2">
                            <div className="overflow-hidden h-3 text-xs flex rounded-md bg-purple-200">
                                <div style={{width: `${data.progress}%`}}
                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-darkcayn">
                                </div>
                            </div>
                        </div>
                        <p>-{data.waktu} Hari</p>
                    </div>
                </div>
            )}
            
            </div>
        </div>
    )
}

export default Project