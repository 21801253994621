import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";

import TableUser from "@components/tables/Table";
import { ButtonAdd } from "@components/button/CustomButton";
import { DropdownLand } from "@components/dropdown/SimpleDropdown";

import Loader from "@components/modal/Loader";

import {
	createNew,
	deleteData,
	getAll,
	update,
	IParameterPengamatanHasilPanen,
} from "@api/parameterpengamatanhasilpanen-api";
import ModalFormParameterPengamatanHasilPanen from "@components/modal/ModalFormParameterPengamatanHasilPanen";
import dayjs from "dayjs";
import SearchField from "@components/SearchField";
import { ActionType } from "@reduxs/action/actions";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "@context/GlobalProvider";
import { IRecentPage } from "@context/ContextInterface";
import { getPayloadRecentActivity } from "@helpers/helper";

const TITLE_BAR = "Cultivation Management / Parameter Hasil Panen";

type Props = {
	user?: any;
};

const ParameterPengamatanHasilPanenManagement: React.FC<Props> = ({ user }) => {
	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [originData, setOriginData] = useState<
		IParameterPengamatanHasilPanen[] | []
	>([]);
	const [filterData, setFilterData] = useState<
		IParameterPengamatanHasilPanen[] | []
	>([]);
	const [selectedData, setSelectedData] =
		useState<IParameterPengamatanHasilPanen | null>(null);

	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

	useEffect(() => {
		const payload: IRecentPage = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	const fetchData = useCallback(async () => {
		showLoader(true);
		const res = await getAll();

		showLoader(false);
		console.log("Parameter Pengamatan :", res);
		if (res.data) {
			if (res.data.code === 0) {
				setOriginData(res.data.data);
				setFilterData(res.data.data);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData, dispatch]);

	const handleEditData = (selectedData: IParameterPengamatanHasilPanen) => {
		setSelectedData(selectedData);
		setIsUpdate(true);
		showModalForm(true);
	};

	const handleReceiveDataForm = async (
		data: IParameterPengamatanHasilPanen,
	) => {
		showLoader(true);

		let res = null;
		if (!isUpdate) {
			// data.created_by_var = user.fullname_var;
			res = await createNew(data);

			var payload = getPayloadRecentActivity(
				1,
				"Parameter Pengamatan Hasil Panen",
				data.parameter_pengamatan_hasil_panen,
				user.fullname_var,
				pathname,
			);
		} else {
			data.updated_by_var = user.fullname_var;
			res = await update(selectedData?.id_seq!, data);

			payload = getPayloadRecentActivity(
				2,
				"Parameter Pengamatan Hasil Panen",
				data.parameter_pengamatan_hasil_panen,
				user.fullname_var,
				pathname,
			);
		}

		console.log("Create/Update Parameter Pengamatan :", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				dispatchCalActivity({ type: "push", payload });

				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleDeleteData = async (data: IParameterPengamatanHasilPanen) => {
		showLoader(true);
		const res = await deleteData(data.id_seq!);

		console.log("DELETE Parameter Pengamatan :", res);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				const payload = getPayloadRecentActivity(
					3,
					"Parameter Pengamatan Hasil Panen",
					data.parameter_pengamatan_hasil_panen,
					user.fullname_var,
					pathname,
				);
				dispatchCalActivity({ type: "push", payload });

				resetForm();
				fetchData();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const resetForm = () => {
		setSelectedData(null);
		setIsUpdate(false);
		showModalForm(false);
		showLoader(false);
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		const newData = [...originData];
		if (event.target.value) {
			const filtered = newData.filter((item) => {
				return (
					item.parameter_pengamatan_hasil_panen
						.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.value_parameter_pengamatan_hasil_panen
						.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.commodity_name_var
						.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.variety_name
						.toLowerCase()
						.includes(event.target.value.toLowerCase())
				);
			});
			console.log({ filtered });
			setFilterData(filtered);
		} else {
			setFilterData(originData);
		}
	};

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="pl-4 text-left">{row.index + 1}.</div>
			),
		},
		{
			Header: "Komoditas",
			accessor: "commodity_name_var",
		},
		{
			Header: "Variety",
			accessor: "variety_name",
		},
		{
			Header: "Parameter Hasil Panen",
			accessor: "parameter_pengamatan_hasil_panen",
		},
		{
			Header: "Value Parameter Hasil Panen",
			accessor: "value_parameter_pengamatan_hasil_panen",
			Cell: ({ value }: any) =>
				value.length > 0 ? (
					<div>{value.join(", ")}</div>
				) : (
					<span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
						Kosong
					</span>
				),
		},
		{
			Header: "Isian Angka",
			accessor: "is_number_bool",
			Cell: ({ value }: any) =>
				parseInt(value) === 1 ? (
					<span className="px-2 py-1 font-medium text-green-800 bg-green-100 rounded-xl">
						True
					</span>
				) : (
					<span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
						False
					</span>
				),
		},
		{
			Header: "Status",
			Footer: "Status",
			accessor: "status_int",
			Cell: ({ value }: any) =>
				parseInt(value) === 1 ? (
					<span className="px-2 py-1 font-medium text-green-800 bg-green-100 rounded-xl">
						Active
					</span>
				) : (
					<span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
						Inactive
					</span>
				),
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }: any) => {
				const data = row.original;
				if (user?.user_group_id_int === 1 || user?.user_group_id_int === 2) {
					return (
						<DropdownLand
							data={data}
							onEdit={() => handleEditData(data)}
							onDelete={() => handleDeleteData(data)}
						/>
					);
				} else {
					return <></>;
				}
			},
		},
	];

	return (
		<div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
			<div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
				<div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
					<h1 className="text-xl font-bold uppercase">
						List Parameter Hasil Panen
					</h1>
					<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
						<SearchField
							onChange={handleSearch}
							placeholder="Cari data parameter pengamatan..."
						/>
						{user?.user_group_id_int === 1 || user?.user_group_id_int === 2 ? (
							<ButtonAdd onClick={() => showModalForm(true)} />
						) : null}
					</div>
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{modalForm && (

				<ModalFormParameterPengamatanHasilPanen
					data={selectedData!}
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
				/>
			)}
			{loader && <Loader />}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		user: state.user,
	};
};

export default connect(
	mapStateToProps,
	null,
)(ParameterPengamatanHasilPanenManagement);
