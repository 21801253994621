// import { createRoot } from 'react-dom/client';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './assets/css/style.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'leaflet/dist/leaflet.css';
import "leaflet-draw/dist/leaflet.draw.css";
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import configureStore from './reduxs/store/configureStore';

const store = configureStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);

// const container: any = document.getElementById('root');
// const root = createRoot(container);
// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
