import { useCallback, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import Helper, { getPayloadRecentActivity } from "../../helpers/helper";
import {
	createNewUser,
	disableUser,
	enableUser,
	getAllUser,
	getUserDownload,
	updateUser,
} from "../../api/user-api";
import Select from "react-select";
import { SelectCustomStyles } from "../admin/TaskManagement";
import TableUser from "../../components/tables/Table";
import { ButtonAdd } from "../../components/button/CustomButton";

import ModalFormUser from "../../components/modal/ModalFormUser";
import Loader from "../../components/modal/Loader";
import DropdownTableUser from "../../components/dropdown/DropdownTableUser";
import ModalFormChangePassword from "../../components/modal/ModalFormChangePassword";
import { toast } from "react-toastify";
import FarmerGroupApi from "../../api/farmergroup-api";
import SearchField from "../../components/SearchField";
import { ActionType } from "../../reduxs/action/actions";
import { GlobalContext } from "../../context/GlobalProvider";
import { useLocation } from "react-router-dom";

const TITLE_BAR = "User Management / User";

const User = ({ user, titleBar }) => {
	const [loader, showLoader] = useState(false);
	const [modalUser, showModalUser] = useState(false);
	const [modalChangePass, showModalChangePass] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [dataUser, setDataUser] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [farmerGroupList, setFarmerGroupList] = useState([]);

	const dispatch = useDispatch();
	const { dispatchCalActivity, dispatchCalPage } = useContext(GlobalContext);
	const { pathname } = useLocation();

	useEffect(() => {
		const payload = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	const fetchUser = useCallback(async () => {
		showLoader(true);
		const res = await getAllUser(localStorage.getItem("pandawa-token"));

		showLoader(false);
		console.log("User Management :", res);
		if (res.data) {
			if (res.data.code === 0) {
				setDataUser(res.data.data);
				setFilterData(res.data.data);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	}, []);

	async function fetchFarmerGroup() {
		const res = await FarmerGroupApi.getAll();
		console.log("fetching farmer group :", res);

		if (res.data) {
			if (res.data.code === 0) {
				setFarmerGroupList(res.data.data);
			}
		}
	}

	useEffect(() => {
		fetchUser();
		fetchFarmerGroup();
	}, [fetchUser]);

	const handleEditData = (selectedData) => {
		setSelectedUser(selectedData);
		setIsUpdate(true);
		showModalUser(true);
	};

	const handleDetailData = (selectedData) => {
		setSelectedUser(selectedData);
		showModalUser(true);
	};

	const handleChangePassword = (selectedData) => {
		setSelectedUser(selectedData);
		setIsUpdate(true);
		showModalChangePass(true);
	};

	const handleDownload = async () => {
		const res = await getUserDownload(
			// selectedFarmer.id_seq,
			// selectedLand.land_code_var,
			// selectedPeriod.period_plant_txt,
		);
		console.log("Download User :", res);

		const url = window.URL.createObjectURL(new Blob([res.data]));
		const a = document.createElement("a");
		a.href = url;
		a.download = "user.xlsx"; // Set the desired file name
		a.click();
		return window.URL.revokeObjectURL(url);
	};

	const handleReceiveDataForm = async (data) => {
		showLoader(true);

		let res = null;
		if (!isUpdate) {
			data.created_by_var = user.fullname_var;
			res = await createNewUser(data);

			var payload = getPayloadRecentActivity(
				1,
				"User",
				data.fullname_var,
				user.fullname_var,
				pathname
			);
		} else {
			data.updated_by_var = user.fullname_var;
			res = await updateUser(selectedUser.id_seq, data);

			payload = getPayloadRecentActivity(
				2,
				"User",
				data.fullname_var,
				user.fullname_var,
				pathname
			);
		}

		console.log("Create/Update User :", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				dispatchCalActivity({ type: "push", payload });

				fetchUser();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleDeleteItem = async (userID) => {
		const res = await disableUser(userID);

		console.log("DELETE USER :", res);
		if (res.data) {
			if (res.data.code === 0) {
				const payload = getPayloadRecentActivity(
					3,
					"User",
					userID,
					user.fullname_var,
					pathname
				);

				dispatchCalActivity({ type: "push", payload });

				toast.success(res.data.message);
				fetchUser();
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleActivateItem = async (userID) => {
		const res = await enableUser(userID);

		console.log("Activate USER :", res);
		if (res.data) {
			if (res.data.code === 0) {
				const payload = getPayloadRecentActivity(
					3,
					"User",
					userID,
					user.fullname_var,
					pathname
				);

				dispatchCalActivity({ type: "push", payload });

				toast.success(res.data.message);
				fetchUser();
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const resetForm = () => {
		setSelectedUser(null);
		setIsUpdate(false);
		showModalUser(false);
		showLoader(false);
		showModalChangePass(false);
	};

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }) => <div className="pl-4 text-left">{row.index + 1}.</div>,
		},
		{
			Header: "Name",
			accessor: "fullname_var",
		},
		{
			Header: "Phone Number",
			Footer: "Phone Number",
			accessor: "phone_number_int",
		},
		{
			Header: "Email",
			Footer: "Email",
			accessor: "email_var",
		},
		{
			Header: "Status",
			Footer: "Status",
			accessor: "status_int",
			Cell: ({ value }) =>
				parseInt(value) === 1 ? (
					<span className="px-2 py-1 font-medium text-green-800 bg-green-100 rounded-xl">
						Active
					</span>
				) : (
					<span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
						Inactive
					</span>
				),
		},
		{
			Header: "Role",
			Footer: "Role",
			accessor: "user_group_id_int",
			Cell: ({ value }) => {
				return Helper.getUserRole(value);
			},
		},
		{
			Header: "FarmerGroup",
			accessor: "farmer_group_id_int",
			Cell: ({ value }) => {
				return (
					farmerGroupList.find(
						(item) => parseInt(item.id_seq) === parseInt(value)
					)?.farmer_group_name_var || ""
				);
			},
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }) => {
				const data = row.original;
				if (user?.user_group_id_int === 1) {
					if (data.user_group_id_int !== 1) {
						return (
							<DropdownTableUser
							  onEdit={() => handleEditData(data)}
							  onDetail={() => handleDetailData(data)}
							  onChangePassword={() => handleChangePassword(data)}
							  {...(data.status_int === 1
								? { onInActive: () => handleDeleteItem(data.id_seq) }
								: { onActive: () => handleActivateItem(data.id_seq) })}
							/>
						  );
						  
					}
				} else {
					if (data.user_group_id_int !== 1 && data.user_group_id_int !== 2) {
						return (
							<DropdownTableUser
							  onEdit={() => handleEditData(data)}
							  onDetail={() => handleDetailData(data)}
							  onChangePassword={() => handleChangePassword(data)}
							  {...(data.status_int === 1
								? { onInActive: () => handleDeleteItem(data.id_seq) }
								: { onActive: () => handleActivateItem(data.id_seq) })}
							/>
						  );
						  
					}
				}

				return <span></span>;
			},
		},
	];

	const handleSearch = (event) => {
		event.preventDefault();

		const newData = [...dataUser];
		if (event?.target?.value) {
			const filtered = newData.filter((item) => {
				return (
					item.fullname_var
						?.toLowerCase()
						.includes(event?.target?.value?.toLowerCase()) ||
					item.phone_number_int
						?.toLowerCase()
						.includes(event?.target?.value?.toLowerCase()) ||
					item.email_var
						?.toLowerCase()
						.includes(event?.target?.value?.toLowerCase()) ||
					Helper.getUserRole(item?.user_group_id_int)
						?.toLowerCase()
						.includes(event?.target?.value?.toLowerCase())
				);
			});

			setFilterData(filtered);
		} else {
			setFilterData(dataUser);
		}
	};

	return (
		<div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
			<div className="flex flex-col items-center justify-between mb-6 md:flex-row 2xl:mb-8">
				<div className="flex items-center w-full px-5 py-8 mb-5 bg-white shadow-xl md:w-4/12 md:mb-0 md:mr-6 justify-evenly rounded-2xl">
					<div className="flex items-center justify-center text-2xl text-green-900 rounded-lg bg-green-50 w-14 h-14">
						<i className="fa-solid fa-users"></i>
					</div>
					<div className="flex flex-col">
						<h1 className="mb-1 text-3xl font-semibold">{dataUser?.length}</h1>
						<p>Total User</p>
					</div>
				</div>
				<div className="flex items-center w-full px-5 py-8 mb-5 bg-white shadow-xl md:w-4/12 md:mb-0 md:mr-6 justify-evenly rounded-2xl">
					<div className="flex items-center justify-center text-2xl text-green-900 rounded-lg bg-green-50 w-14 h-14">
						<i className="fa-solid fa-user-check"></i>
					</div>
					<div className="flex flex-col">
						<h1 className="mb-1 text-3xl font-semibold">
							{dataUser?.filter((data) => data.status_int === 1).length}
						</h1>
						<p>User Aktif</p>
					</div>
				</div>
				<div className="flex items-center w-full px-5 py-8 mb-5 bg-white shadow-xl md:w-4/12 md:mb-0 md:mr-6 justify-evenly rounded-2xl">
					<div className="flex items-center justify-center text-2xl text-green-900 rounded-lg bg-green-50 w-14 h-14">
						<i className="fa-solid fa-user-large-slash"></i>
					</div>
					<div className="flex flex-col">
						<h1 className="mb-1 text-3xl font-semibold">
							{dataUser?.filter((data) => data.status_int !== 1).length}
						</h1>
						<p>User Tidak Aktif</p>
					</div>
				</div>
				<div className="flex items-center w-full px-5 py-8 bg-white shadow-xl md:w-4/12 justify-evenly rounded-2xl">
					<div className="flex items-center justify-center text-2xl text-green-900 rounded-lg bg-green-50 w-14 h-14">
						<i className="fa-solid fa-people-group"></i>
					</div>
					<div className="flex flex-col">
						<h1 className="mb-1 text-3xl font-semibold">
							{dataUser?.filter((data) => data.user_group_id_int === 4).length}
						</h1>
						<p>Total Petani</p>
					</div>
				</div>
			</div>

			<div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
				{/* <div className="flex flex-col items-center justify-between w-full mb-5 space-y-3 md:w-max md:flex-row md:justify-center md:space-x-3 md:space-y-0 md:mb-4 2xl:mb-5"> */}
				<div className="flex flex-col md:flex-row items-center justify-between w-full mb-5 space-y-3 md:space-y-0 md:mb-4 2xl:mb-5">
				{/* <div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0"> */}
					<Select
						name="farmer"
						styles={SelectCustomStyles}
						// options={dataFarmer}
						// onChange={setSelectedFarmer}
						isClearable
						placeholder="- Pilih Petani -"
						className="w-full md:w-[200px]"
						// isLoading={loadingFarmer}
						getOptionValue={(option: any) => option["id_seq"]}
						getOptionLabel={(option: any) => option["fullname_var"]}
					/>
					<Select
						name="land"
						styles={SelectCustomStyles}
						// options={dataLand}
						// onChange={setSelectedLand}
						isClearable
						placeholder="- Pilih Lahan -"
						className="w-full md:w-[200px] 2xl:md:w-[300px]"
						// isLoading={loadingLand}
						getOptionValue={(option: any) => option["land_code_var"]}
						getOptionLabel={(option: any) =>
							`[${option["land_code_var"]}] ${option["land_name_var"]}`
						}
					/>
					<Select
						name="period"
						styles={SelectCustomStyles}
						// options={dataPeriod}
						// onChange={setSelectedPeriod}
						isClearable
						placeholder="- Pilih Periode -"
						className="w-full md:w-[175px] 2xl:md:w-[200px]"
						// isLoading={loadingPeriod}
						getOptionValue={(option: any) => option["period_plant_txt"]}
						getOptionLabel={(option: any) => option["period_plant_txt"]}
					/>
					<button
						type="submit"
						className="w-full px-6 py-3 text-sm text-white bg-blue-600 rounded-lg md:w-max hover:bg-blue-500"
					// onClick={fetchActivities}
					>
						Terapkan
					</button>
					<div className="items-end justify-end">
						<SearchField onChange={handleSearch} placeholder="Cari user..." />
					</div>
				</div>
				<div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
					<h1 className="text-xl font-bold uppercase">List User</h1>
					<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
						{user?.user_group_id_int === 1 || user?.user_group_id_int === 2 ? (
							<ButtonAdd onClick={() => showModalUser(true)} />
						) : null}
						<button
							type="submit"
							className="w-full md:w-max bg-green-600 hover:bg-green-500 text-white px-2 py-2 rounded-full text-sm flex justify-end mb-5"
							onClick={handleDownload}>
							Download File
						</button>
					</div>
				</div>
				<div className="flex justify-end">
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{modalUser && (
				<ModalFormUser
					title={isUpdate ? "Form Ubah User" : "Form Tambah User Baru"}
					data={selectedUser}
					farmerGroupList={farmerGroupList}
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
				/>
			)}
			{modalChangePass && (
				<ModalFormChangePassword
					onCancel={() => showModalChangePass(false)}
					onSubmit={handleReceiveDataForm}
				/>
			)}
			{loader && <Loader />}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
		titleBar: state.title_bar,
	};
};

export default connect(mapStateToProps, null)(User);
