const ModalOptionKoordinat = ({onCancel, onClickManual, onClickDraw}) => {
    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50 overflow-auto">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-soft w-9/12 md:w-1/2 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50">
                {/* Body */}
                <div className='flex flex-col justify-center items-center text-center'>
                    <h1 className='text-base md:text-lg mb-6' >Pilih metode tambah/ubah koordinat</h1>
                    <div className='w-full flex flex-col md:flex-row item-center justify-center text-base'>
                        <button className='text-sm md:text-base font-medium flex flex-col items-center w-full md:w-1/2 rounded-xl shadow-2xl bg-white p-4 my-2 md:mx-4 hover:bg-soft' onClick={onClickManual}>
                            Input Data Manual
                            <p className='text-xs mt-2 font-normal'>Masukkan data koordinat Latitude dan Longitude pada form</p>
                        </button>
                        <button className='text-sm md:text-base font-medium flex flex-col items-center w-full md:w-1/2 rounded-xl shadow-2xl bg-white p-4 my-2 md:mx-4 hover:bg-soft' onClick={onClickDraw}>
                            Data GPS
                            <p className='text-xs mt-2 font-normal'>Gambar koordinat lahan pada maps</p>
                        </button>
                    </div>
                </div>
                {/* End Body */}
            </div>
            {/* End Modal Content */}

        </div>
    )
}

export default ModalOptionKoordinat