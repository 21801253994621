import { bindActionCreators } from "redux"
import UserMenu from "./headers/UserMenu"
import { setCollapse } from "../reduxs/action/actions";
import { connect } from "react-redux";
import SearchModal from "./headers/SearchModal";
import Notifications from "./headers/Notification";

const Header = ({collapse, titleBar, setCollapse, user}) => {
    return (
        <div className='flex justify-between items-center z-40'>
            <div className="flex item-center">
                <button className='text-xl transition duration-100 ease-in-out transform hover:scale-105 hidden lg:block' onClick={()=>setCollapse(!collapse)}>
                    {collapse?
                        <i className="fa-solid fa-outdent"></i>
                        : 
                        <i className="fa-solid fa-indent"></i>
                    } 
                </button>
                <div className="hidden sm:flex items-center font-semibold ml-5 text-base">
                    <h1>{titleBar}</h1>
                </div>
            </div>
            <div className='flex items-center'>
                <SearchModal />
                {/* <Help /> */}
                {user?.user_group_id_int === 1 || user?.user_group_id_int === 2?
                <Notifications /> : null
                }
                <hr className="w-px h-6 bg-gray-200 mx-3" />
                <UserMenu />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        collapse: state.collapse,
        titleBar: state.title_bar,
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({setCollapse}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)