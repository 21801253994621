import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BudgetPlanApi, IBudgetPlan } from "../../../api/budgetplan-api";
import { ButtonAdd } from "../../../components/button/CustomButton";
import Dropdown from "../../../components/dropdown/Dropdown";
import BPManagementForm from "../../../components/modal/budgetplan/BPManagementForm";
import Loader from "../../../components/modal/Loader";
import SearchField from "../../../components/SearchField";
import TableUser from "../../../components/tables/Table";
import { IRecentPage } from "../../../context/ContextInterface";
import { GlobalContext } from "../../../context/GlobalProvider";
import Helper, { getPayloadRecentActivity } from "../../../helpers/helper";
import { ActionType } from "../../../reduxs/action/actions";

const TITLE_BAR = "Budget Management / Budget Plan";

type Props = {};

const BudgetPlanManagement: React.FC<Props> = () => {
	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	// eslint-disable-next-line
	const [originData, setOriginData] = useState<IBudgetPlan[] | []>([]);
	const [filterData, setFilterData] = useState<IBudgetPlan[] | []>([]);
	const [selectedData, setSelectedData] = useState<IBudgetPlan | null>(null);

	const user = useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

	useEffect(() => {
		const payload: IRecentPage = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	const fetchData = useCallback(async () => {
		showLoader(true);
		const res = await BudgetPlanApi.getAll();

		showLoader(false);
		console.log("BUDGETPLAN ==>", res);
		if (res.data) {
			if (res.data.code === 0) {
				setOriginData(res.data.data);
				setFilterData(res.data.data);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData, dispatch]);

	const handleEditData = (selectedData: any) => {
		setSelectedData(selectedData);
		setIsUpdate(true);
		showModalForm(true);
	};

	let navigate = useNavigate();

	const handleDetail = (selectedData: any) => {
		let path = `/dashboard/budget-management-plan/${selectedData.id_seq}`;
		navigate(path);
	};

	const handleReceiveDataForm = async (data: IBudgetPlan) => {
		showLoader(true);

		let res = null;
		if (!isUpdate) {
			data.created_by_var = user.fullname_var;
			res = await BudgetPlanApi.createNew(data);

			var payload = getPayloadRecentActivity(
				1,
				"Budget Plan",
				data.id_seq,
				user?.fullname_var,
				pathname,
			);
		} else {
			data.updated_by_var = user.fullname_var;
			res = await BudgetPlanApi.update(selectedData?.id_seq!, data);

			payload = getPayloadRecentActivity(
				2,
				"Budget Plan",
				data.id_seq,
				user?.fullname_var,
				pathname,
			);
		}

		console.log("BUDGETPLAN ACTION ==>", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				dispatchCalActivity({ type: "push", payload });

				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleDeleteData = async (data: IBudgetPlan) => {
		showLoader(true);
		const res = await BudgetPlanApi.delete(data.id_seq!);

		console.log("BUDGETPLAN ACTION ==>", res);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				const payload = getPayloadRecentActivity(
					3,
					"Budget Plan",
					data.id_seq,
					user?.fullname_var,
					pathname,
				);
				dispatchCalActivity({ type: "push", payload });

				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const resetForm = () => {
		setSelectedData(null);
		setIsUpdate(false);
		showModalForm(false);
		showLoader(false);
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		const newData = [...originData];
		if (event.target.value) {
			const filtered = newData.filter((item) => {
				return (
					item.fullname_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.commodity_name_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.land_code_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.period_plant_txt
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.budget_plan_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.variety_name
						?.toLowerCase()
						.includes(event.target.value.toLowerCase())
				);
			});

			setFilterData(filtered);
		} else {
			setFilterData(originData);
		}
	};

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="text-left pl-4">{row.index + 1}.</div>
			),
		},
		{
			Header: "Petani",
			accessor: "fullname_var",
		},
		{
			Header: "Lahan",
			accessor: "land_code_var",
			Cell: ({ value, row }: any) => {
				const data = row.original;
				return (
					<span>
						[{value}] {data?.land_name_var}
					</span>
				);
			},
		},
		{
			Header: "Komoditas",
			accessor: "commodity_name_var",
		},
		{
			Header: "Varietas",
			accessor: "variety_name",
		},
		{
			Header: "Periode Tanam",
			accessor: "period_plant_txt",
		},
		{
			Header: "Budget Plan (Rp)",
			accessor: "budget_plan_var",
			Cell: ({ value }: any) => {
				return Helper.amount(value);
			},
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }: any) => {
				const data = row.original;
				if (user.user_group_id_int === 5) {
					return (
						<Link to={"/dashboard/budget-management-plan/" + data.id_seq}>
							<i className="fa-solid fa-eye hover-anim cursor-pointer hover:text-blue-500 text-base"></i>
						</Link>
					);
				}
				return (
					<Dropdown
						onEdit={() => handleEditData(data)}
						onDelete={() => handleDeleteData(data)}
						onDetail={() => handleDetail(data)}
					/>
				);
			},
		},
	];

	return (
		<div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
			<div className="w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6">
				<div className="mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
					<h1 className="font-bold uppercase text-xl">Budget Plan Petani </h1>
					<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
						<SearchField
							onChange={handleSearch}
							placeholder="Cari budgetplan..."
						/>
						{/* {user.user_group_id_int !== 5 && (
							<ButtonAdd onClick={() => showModalForm(true)} />
						)} */}
					</div>
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{modalForm && (
				<BPManagementForm
					data={selectedData!}
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
				/>
			)}
			{loader && <Loader />}
		</div>
	);
};

export default BudgetPlanManagement;
