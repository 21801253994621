import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import useDropdownRatioDevidend, { useDropdownPeriodLandPrice, useDropdownPeriodPlant } from "@hooks/useDropdownRatioDevidend";
import Select from "react-select";
import { selectStylesForm } from "../TaskManagement/ModalTaskNew";
import useDropdownMaterialSupport from "@hooks/useDropdownMaterialSupport";
import useDropdownLandArea from "@hooks/useDropdownLandArea";
import useDropdownCommodity from "@hooks/useDropdownCommodity";


const ModalFormSaprodiDistribution = ({ data, onCancel, onSubmit }) => {
	
	const [isRefreshing, setIsRefreshing] = useState(false);
	const { DataPeriodPlant, loadingPeriodPlant } = useDropdownPeriodLandPrice()
	const [PeriodPlant, setPeriodPlant] = useState( data ? data.period_plant_txt : null,);
	const { dataLandArea, loadingLandArea } = useDropdownLandArea(PeriodPlant)
	const [commodityName, setCommodityName] = useState( data ? data.commodity_name_var : "",);
	const [commodityId, setCommodityId] = useState(data ? data.commodity_id : "");
	const { dataMaterialSupport, loadingMaterialSupport } = useDropdownMaterialSupport(isRefreshing);
	const [formValues, setFormValues] = useState<any[]>([
		{ support_id: "", support_name: "", quantity: "", support_unit: "", support_price: "" },
	]);	
	const [modalFormCommodity, showModalFormCommodity] = useState(false);

	const [selectedMaterialSupport, setSelectedMaterialSupport] = useState<any>([]);
	const { dataCommodity, loadingCommodity } = useDropdownCommodity(isRefreshing);
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm({ criteriaMode: "all" });

	const setdefaultValue = useCallback((obj) => {
		Object.keys(obj).map(key => {
			return setValue(key, obj[key], { shouldValidate: true })
		})
	}, [setValue])

	useEffect(() => {
		if (data) {
			setdefaultValue(data)
			setCommodityName(data.commodity_name_var);
			setCommodityId(data.commodity_id);
			setFormValues(data.material_support);
			setSelectedMaterialSupport(
				data.material_support.map(({ support_id, name, support_unit, support_price }: any) => {
					return {
						id_seq: Number(support_id),
						material_support_name_var: name,
						unit_size_txt: support_unit,
						material_price_int: Number(support_price),
					};
				}),
			);
			setdefaultValue(data);
		}
	}, [data, setdefaultValue])

	let addFormFields = () => {
		setFormValues([
			...formValues,
			{ unique_code_var: "", quantity_int: "", measure_var: "" },
		]);
	};
	let removeFormFields = (i: number) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		setFormValues(newFormValues);

		let newArray = [...selectedMaterialSupport];
		newArray.splice(i, 1);
		setSelectedMaterialSupport(newArray);
	};

	const handleChangeMaterialSupport = (index: number, newData: any) => {
		const newArray = [...selectedMaterialSupport];
		newArray[index] = newData;
		setSelectedMaterialSupport(newArray);

		if (!newData) {
			let newFormValues = [...formValues];
			newFormValues[index] = { support_id: "", support_name: "", quantity: "", support_unit: "", support_price: "" };
			setFormValues(newFormValues);
		}
	};

	let handleChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
		let { value, type } = e.target;
		const newArray = [...formValues];

		if (type === "number") {
			newArray[i][e.target.name] = Number(value);
		} else {
			newArray[i][e.target.name] = value;
		}

		setFormValues(newArray);
	};

	const onValid = (dataForm) => {
		const { land_price_id_int, period_plant_txt, status_int, commodity_name_var, } = dataForm

		const payload = {
			land_price_id_int,
			period_plant_txt,
			status_int,
			commodity_name_var: JSON.parse(commodity_name_var).commodity_name_var,
			commodity_id: Number(JSON.parse(commodity_name_var).id_seq),
			material_support: JSON.stringify(
				selectedMaterialSupport
					.map((item: any, key: number) => {
						if (item) {
							return {
								// support_id: item.id_seq,
								price: item.material_price_int,
								unit: item.unit_size_txt,
								name: item.material_support_name_var,
								quantity: formValues[key].quantity || 0,
							};
						} else {
							return null;
						}
					})
					.filter((item: any) => item !== null),
			),
		}

		onSubmit(payload)
	}

	return (
		<div className="modal-form">
			<div className="modal-form-outside" onClick={onCancel}></div>

			<div className="modal-form-content" style={{ maxHeight: '90vh' }}>

				<div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
					<div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
						<i className="fa-solid fa-ranking-star flex justify-center items-center"></i>
						<h3>{data ? 'Ubah Data' : 'Tambah Data'}</h3>
					</div>
					<button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
						onClick={onCancel}
					>
						<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
					</button>
				</div>

				{/* Body */}
				<form onSubmit={handleSubmit(onValid)} className='px-8'>
					<div className='grid grid-cols-1 gap-x-8 gap-y-3'>
						<div className="w-full">
							<label htmlFor="commodity_name_var" className="label-form">
								Nama Komoditas
							</label>
							<select
								id="commodity_name_var"
								className="input-form"
								{...register("commodity_name_var", {
									required: "Commodity is required",
								})}
								onChange={(e) => {
									setCommodityId(
										Number(JSON.parse(e.target.value).id_seq),
									);
									setCommodityName(
										JSON.parse(e.target.value).commodity_name_var,
									);
								}}>
								<option value="">- Pilih Komoditas -</option>
								{dataCommodity!.map((item, key) => (
									<option key={key} value={JSON.stringify(item)}>
										{item.commodity_name_var}
									</option>
								))}
							</select>
							<ErrorField errors={errors} name="commodity_name_var" />
							<div className="justify-end flex">
								<button
									type="button"
									className="mt-4 text-right"
									onClick={() => showModalFormCommodity(true)}>
									+ Tambah Commodity
								</button>
							</div>
						</div>

						{!loadingPeriodPlant && (
							<div className="w-full">
								<label htmlFor="period_plant_txt" className="label-form">
									Periode Tanam
								</label>
								<div className="flex item-center space-x-2">
									<select
										id="period_plant_txt"
										className="input-form"
										{...register("period_plant_txt", {
											required: "This field is required",
										})}
										onChange={(e) => {
											setPeriodPlant(e.target.value);
										}}>
										<option value="">- Pilih Periode Tanam -</option>
										{DataPeriodPlant?.map((item, key) => (
											<option key={key} value={item.period_plant_txt}>
												{item.period_plant_txt}
											</option>
										))}
									</select>
								</div>
								<ErrorField errors={errors} name="period_plant_txt" />
							</div>
						)}

						{!loadingLandArea && (
							<div className="w-full">
								<label htmlFor="land_price_id_int" className="label-form">
									Luas Lahan
								</label>
								<div className="flex item-center space-x-2">
									<select
										id="land_price_id_int"
										className="input-form"
										{...register("land_price_id_int", {
											required: "This field is required",
										})}>
										<option value="">- Pilih Luasan Lahan -</option>
										{dataLandArea?.map((item, key) => (
											<option key={key} value={item.id_seq}>
												{item.land_area_var}
											</option>
										))}
									</select>

								</div>
								<ErrorField errors={errors} name="land_price_id_int" />
							</div>
						)}
						{formValues.map((item, index) => (
							<div
								className="flex items-center space-x-2 md:space-x-5"
								key={index}>
								<div className="grid gap-y-2 md:gap-y-0 gap-x-2 md:gap-x-5 grid-cols-1 md:grid-cols-2 w-full mb-5">
									<div className="w-full">
										<label htmlFor="support_id" className="label-form">
											Bantuan
										</label>
										<Select
											id="support_id"
											styles={selectStylesForm}
											isClearable
											placeholder="- Pilih Bantuan -"
											options={dataMaterialSupport}
											isLoading={loadingMaterialSupport}
											value={selectedMaterialSupport[index]}
											onChange={(data) =>
												handleChangeMaterialSupport(index, data)
											}
											getOptionValue={(option: any) => option["id_seq"]}
											getOptionLabel = {(option) => `${option.material_support_name_var} - ${option.unit_size_txt}`}

										/>
									</div>
									<div className="w-full">
										<label htmlFor="quantity" className="label-form">
											Quantitas
										</label>
										<input
											id="quantity"
											className="input-form"
											placeholder="0"
											name="quantity"
											type="number"
											step=".01"
											value={item?.quantity || null}
											onChange={(e) => handleChange(index, e)}

										/>
									</div>
								</div>
								<i
									className="fa-solid fa-trash text-lg text-rose-600 hover:opacity-70 cursor-pointer"
									onClick={(e) => removeFormFields(index)}></i>
							</div>
						))}

						<button
							type="button"
							className="text-blue-500 hover:opacity-70 text-sms"
							onClick={addFormFields}>
							<span className="mr-2">
								<i className="fa-solid fa-circle-plus"></i>
							</span>
							<span>Tambah Bantuan</span>
						</button>
					</div>
					{/* <!-- Modal footer --> */}
					<div className="flex items-center mt-6 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10 mb-5">
						<button type='submit' className="btn-primary">Simpan</button>
						<button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
					</div>
				</form>

				{/* End Body */}
			</div>
			{/* End Modal Content */}
		</div>
	)
}

export default ModalFormSaprodiDistribution