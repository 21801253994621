import { IDashboard, getDropDownPeriodPlant } from '@api/dashboard-api';
import { useEffect, useState } from 'react';

export function useDropdownPeriodPlantALL(isRefreshing: boolean = false) {
	const [DataPeriodPlant, setDataPeriodPlant] = useState<IDashboard[]>([])
	const [loadingPeriodPlant, setLoading] = useState(false)
	const [errorPeriodPlant, setError] = useState(null)

	useEffect(() => {
		async function fetchDropdownPeriodPlant() {

			setLoading(true)
			let res
			res = await getDropDownPeriodPlant()

			console.log("Fetching DropdownPeriodPlant :", res)
			if (res.data) {
				if (res.data.code === 0) {
					setDataPeriodPlant(res.data.data)
				} else {
					setError(res.data.message)
				}
			} else {
				setError(res.message)
			}
			setLoading(false)
		}

		fetchDropdownPeriodPlant()
	}, [isRefreshing]);

	return { DataPeriodPlant, errorPeriodPlant, loadingPeriodPlant }
}