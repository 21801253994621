import React, { useEffect, useState } from "react";
import Select from 'react-select'
import { toast } from "react-toastify";
import { IMixingProduct } from "../../../api/inventory-api";
import { useDropdownProductList, useDropdownUniqueCode } from "../../../hooks/useDropdownInventory";
import Loader from "../Loader";
import { selectStylesForm } from "../TaskManagement/ModalTaskNew";

type Props = {
    data: IMixingProduct,
    onCancel: (e: React.MouseEvent) => any
    onSubmit: (data: any) => void
};

const ModalFormCombinationProduct: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
    const [formValues, setFormValues] = useState<any[]>([{ unique_code_var: "", quantity_int: "", measure_var: '' }])
    const [selectedUnique, setSelectedUnique] = useState<any>([])

    const { productList, loadingProduct } = useDropdownProductList()
    const { uniqueCodeList, loadingUniqueCode } = useDropdownUniqueCode()

    useEffect(() => {
        if (data) {
            setSelectedUnique(data.combination_json.map(({ unique_code_var, quantity_int, measure_var }: any) => {
                return {
                    unique_code_var, quantity_int, measure_var
                }
            }))
            setFormValues(data.combination_json)
        }
    }, [data])

    let handleChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
        let { value, min, max, type } = e.target;
        const newArray = [...formValues]

        if (type === 'number') {
            const res = Math.max(Number(min), Math.min(Number(max), Number(value)));
            newArray[i][e.target.name] = res;
        } else {
            newArray[i][e.target.name] = value;
        }

        setFormValues(newArray)
    }

    let addFormFields = () => {
        setFormValues([...formValues, { unique_code_var: "", quantity_int: "", measure_var: '' }])
    }

    let removeFormFields = (i: number) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)

        let newArray = [...selectedUnique];
        newArray.splice(i, 1);
        setSelectedUnique(newArray)
    }

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        const { id_product_var } = e.target
        const payload = {
            id_product_var: id_product_var?.value,
            quantity_int: formValues.reduce((sum: any, item: any) => sum + (item?.quantity_int || 0), 0),
            measure_var: "kilogram",
            combination_json: JSON.stringify(
                selectedUnique.map((item: any, key: number) => {
                    if (item) {
                        return {
                            unique_code_var: item.unique_code_var,
                            quantity_int: formValues[key].quantity_int || 0,
                            measure_var: 'kilogram'
                        }
                    } else {
                        return null
                    }
                }).filter((item: any) => item !== null)
            ),
        }

        // console.log(payload)
        onSubmit(payload)
    }

    const handleChangeUniqueCode = (index: number, newData: any) => {
        const newArray = [...selectedUnique]
        newArray[index] = newData
        setSelectedUnique(newArray)

        if (!newData) {
            let newFormValues = [...formValues];
            newFormValues[index] = { unique_code_var: "", quantity_int: "", measure_var: '' }
            setFormValues(newFormValues)
        }
    }

    return (
        <>
            {!loadingProduct && !loadingUniqueCode ?
                <div className="modal-form">
                    <div className="modal-form-outside" onClick={onCancel}></div>

                    <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                        <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                                <h3>{data ? 'Ubah Data Pencampuran Produk' : 'Tambah Pencampuran Produk'}</h3>
                            </div>
                            <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                                onClick={onCancel}
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>

                        <form onSubmit={handleSubmit} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                            <div className="w-full mb-7">
                                <label htmlFor="id_product_var" className="label-form">Nama Produk</label>
                                <Select id='id_product_var' name="id_product_var" styles={selectStylesForm} isClearable placeholder='- Pilih nama produk -' options={productList} isLoading={loadingProduct}
                                    defaultValue={productList.find(item => item['id_product_var'] === data?.id_product_var)}
                                    getOptionValue={(option: any) => option['id_product_var']}
                                    getOptionLabel={(option: any) => `[${option['id_product_var']}] ${option['product_name_var']}`}
                                />
                            </div>
                            <h2 className='text-base text-lightcayn font-bold mb-5'>Bahan Campuran</h2>
                            {formValues.map((item, index) =>
                                <div className="flex items-center space-x-2 md:space-x-5" key={index}>
                                    <div className='grid gap-y-2 md:gap-y-0 gap-x-2 md:gap-x-5 grid-cols-1 md:grid-cols-3 w-full mb-5'>
                                        <div className="w-full">
                                            <label htmlFor="unique_code" className="label-form">Kode Unik</label>
                                            <Select id='unique_code' styles={selectStylesForm} isClearable placeholder='- Pilih kode unik -' options={uniqueCodeList} isLoading={loadingUniqueCode}
                                                value={selectedUnique[index]} onChange={(data) => handleChangeUniqueCode(index, data)}
                                                getOptionValue={(option: any) => option['unique_code_var']}
                                                getOptionLabel={(option: any) => option['unique_code_var']}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <label htmlFor="quantity_int" className="label-form">Berat Bersih</label>
                                            <input id='quantity_int' name="quantity_int" type='number' className='input-form' placeholder="0"
                                                value={item?.quantity_int || ''} onChange={e => handleChange(index, e)}
                                                min={0} max={data ? uniqueCodeList.find(item => item.unique_code_var === selectedUnique[index]?.unique_code_var)?.quantity_int : selectedUnique[index]?.quantity_int}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <label htmlFor="measure_var" className="label-form">Satuan</label>
                                            <input id='measure_var' className='input-form' readOnly value={selectedUnique[index]?.measure_var || ''} />
                                        </div>
                                    </div>
                                    <i className="fa-solid fa-trash text-lg text-rose-600 hover:opacity-70 cursor-pointer" onClick={(e) => removeFormFields(index)}></i>
                                </div>
                            )}
                            <button type='button' className='text-blue-500 hover:opacity-70 text-sms' onClick={addFormFields}>
                                <span className='mr-2'><i className="fa-solid fa-circle-plus"></i></span>
                                <span>Tambah Data</span>
                            </button>

                            {/* <!-- Modal footer --> */}
                            <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                                <button type='submit' className="btn-primary">Simpan</button>
                                <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                            </div>

                        </form>

                    </div>
                </div>
                :
                <Loader />
            }
        </>
    )
}


type PropsDetail = {
    data: IMixingProduct,
    onCancel: (e: React.MouseEvent) => any
};

export const ModalDetailCombinationProduct: React.FC<PropsDetail> = ({ data, onCancel }) => {

    function copyToClipboard(str: string) {
        navigator.clipboard.writeText(str)
        toast.info('Kode unik disalin')
    };

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                        <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                            <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                            <h3>Detail Pencampuran Produk</h3>
                        </div>
                        <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <div className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className="w-full mb-4">
                            <label htmlFor="id_product_var" className="label-form">Nama Produk</label>
                            <input className='input-form' readOnly defaultValue={`[${data?.id_product_var}] ${data?.product_name_var}`} />
                        </div>
                        <div className="w-full mb-4">
                            <label htmlFor="id_product_var" className="label-form">Total Berat</label>
                            <input className='input-form' readOnly defaultValue={`${data?.quantity_int} ${data?.measure_var}`} />
                        </div>
                        <div className="w-full mb-6 relative">
                            <label htmlFor="id_product_var" className="label-form">Kode QR</label>
                            <input className='input-form' readOnly defaultValue={data?.qr_code_var} />
                            <i className="fa-regular fa-copy text-base ml-5 cursor-pointer text-blue-500 hover-anim hover:opacity-50 absolute top-10 right-5" onClick={() => copyToClipboard(data?.qr_code_var)}></i>
                        </div>
                        <h2 className='text-base text-lightcayn font-bold mb-5'>Bahan Campuran</h2>
                        {data.combination_json.map((item: any, index: any) =>
                            <div className="flex items-center space-x-2 md:space-x-5" key={index}>
                                <div className='grid gap-y-2 md:gap-y-0 gap-x-2 md:gap-x-5 grid-cols-1 md:grid-cols-3 w-full mb-5'>
                                    <div className="w-full">
                                        <label className="label-form">Kode Unik</label>
                                        <input className='input-form' readOnly defaultValue={item?.unique_code_var} />
                                    </div>
                                    <div className="w-full">
                                        <label className="label-form">Berat Bersih</label>
                                        <input className='input-form' readOnly defaultValue={item?.quantity_int} />
                                    </div>
                                    <div className="w-full">
                                        <label htmlFor="measure_var" className="label-form">Satuan</label>
                                        <input className='input-form' readOnly defaultValue={item?.measure_var} />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                            <button type='button' className="btn-primary" onClick={onCancel}>Tutup</button>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}


export default ModalFormCombinationProduct