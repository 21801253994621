import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataPanenApprovalApi, getOneDataPanen, IDataPanen } from '../../api/datapanen-api';
import ConfirmModal from '../../components/modal/ConfirmModal';
import Loader from '../../components/modal/Loader';
import { IRecentActivity, IRecentPage } from '../../context/ContextInterface';
import { GlobalContext } from '../../context/GlobalProvider';
import { ActionType } from '../../reduxs/action/actions';


const TITLE_BAR = 'Cultivation Management / Data Panen Aproval'

type Props = {};

const DataPanenApproval: React.FC<Props> = () => {
    const [statusCode, setStatusCode] = useState(99)
    const [loader, showLoader] = useState(false)
    const [data, setData] = useState<IDataPanen | null>(null)
    const [confirmModal, setConfirmModal] = useState(false)

    const { id }: any = useParams()
    const user = useSelector((state: any) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext)
  
    useEffect(() => {
      const payload: IRecentPage = {
        id: new Date().getTime(),
        title: TITLE_BAR,
        path: pathname,
        url: window.location.href,
      };
  
      dispatchCalPage({ type: 'push', payload })
      dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR })
    }, [dispatch, dispatchCalPage, pathname])
    

    useEffect(() => {
        (
            async function fetchDataPanen() {
                showLoader(true)
                const res = await getOneDataPanen(id)

                console.log('Fetch data panen :', res)
                showLoader(false)
                if (res.data && res.data.code === 0) {
                    setStatusCode(res.data.data?.status_int)
                    setData(res.data.data)
                } else {
                    toast.error(res.data?.message || res.message)
                }
            }
        )()
    }, [id])

    const handleUpdateStatus = async () => {
        showLoader(true)
        const payload = {
            updated_by_var: user.fullname_var
        }

        const payloadRecentActivity: IRecentActivity = {
            id: new Date().getTime(),
            title: '',
            activity: '',
            path: pathname,
            url: window.location.href,
            is_read: false,
        }

        let res
        if (statusCode === 200) {
            res = await DataPanenApprovalApi.approval(id, payload)

            payloadRecentActivity.title = 'Data Panen Approved'
            payloadRecentActivity.activity = `Data panen ${id} approved by ${user.fullname_var}`
        } else {
            res = await DataPanenApprovalApi.reject(id, payload)
            
            payloadRecentActivity.title = 'Data Panen Rejected'
            payloadRecentActivity.activity = `Data panen ${id} rejected by ${user.fullname_var}`
        }

        console.log("Data Panen APPROVAL ==>", res)
        if (res.data) {
            if (res.data.code === 0) {
                toast.success(res.data.message)

                dispatchCalActivity({ type: 'push', payload: payloadRecentActivity })
                navigate('/dashboard/cultivation-management-panen')
            } else {
                toast.error(res.data.message)
                showLoader(false)
            }
        } else {
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    return (
        <>
            <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>
                <div className='flex flex-col md:flex-row bg-white md:min-h-[76vh] rounded-2xl shadow-2xl p-5 md:p-6 2xl:px-7'>

                    <div className='w-full md:pr-5'>
                        <h1 className='font-bold uppercase text-xl mt-2'>Approval Data Panen</h1>

                        <nav className="flex my-5 py-4 px-2 md:px-5 text-gray-700 rounded-lg border bg-blue-50 border-blue-100" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li className="inline-flex items-center">
                                    <Link to='/dashboard/cultivation-management-panen' className="inline-flex items-center text-sms md:text-sm font-semibold text-blue-500 hover:opacity-75">
                                        <svg className="mr-1 md:mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Data Panen
                                    </Link>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                        <span className="ml-1 text-sms md:text-sm font-bold text-slate-800 hover:opacity-75 cursor-pointer">Approval Data Panen</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>

                        <div className='w-full space-y-6'>
                            <div>
                                <h2 className='text-base text-lightcayn font-bold'>Informasi Panen</h2>
                                <table className="font-medium text-sms md:text-sm mt-2.5">
                                    <tbody>
                                        <tr>
                                            <td className='text-gray-500 pb-1 w-32 md:w-44'>Komoditas</td>
                                            <td>{data?.commodity_name_var}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-gray-500 pb-1'>Nama Lahan</td>
                                            <td>[{data?.land_code_var}] {data?.land_name_var}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-gray-500 pb-1'>Periode Tanam</td>
                                            <td>Periode {data?.period_plant_txt}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-gray-500 pb-1'>Hasil Panen ( Kg )</td>
                                            <td>{data?.harvest_flo}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-gray-500 pb-1'>Tanggal Panen</td>
                                            <td>{dayjs(data?.harvest_on_dte).format('DD/MM/YYYY')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <h2 className='text-base text-lightcayn font-bold'>Hasil Panen Penjemuran</h2>
                                <table className="font-medium text-sms md:text-sm mt-2.5">
                                    <tbody>
                                        <tr>
                                            <td className='text-gray-500 pb-1 w-48 md:w-56'>Tanggal Penjemuran</td>
                                            <td>{dayjs(data?.harvest_drying_dte).format('DD/MM/YYYY')}</td>

                                        </tr>
                                        <tr>
                                            <td className='text-gray-500 pb-1'>Hasil Penjemuran ( Kg )</td>
                                            <td>{data?.harvest_drying_flo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <h2 className='text-base text-lightcayn font-bold'>Hasil Panen Penggilingan</h2>
                                <table className="font-medium text-sms md:text-sm mt-2.5">
                                    <tbody>
                                        <tr>
                                            <td className='text-gray-500 pb-1 w-48 md:w-56'>Tanggal Penggilingan</td>
                                            <td>{dayjs(data?.harvest_milling_dte).format('DD/MM/YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-gray-500 pb-1'>Hasil Penggilingan ( Kg )</td>
                                            <td>{data?.harvest_milling_flo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <h2 className='text-base text-lightcayn font-bold'>Dokumentasi Panen</h2>
                                <div className='grid grid-cols-1 md:grid-cols-4 gap-5 md:gap-3 2xl:gap-5 mt-4'>
                                    {data?.document_txt.length > 0 && data?.document_txt.map((url: string, key: number) =>
                                        <LazyLoadImage effect='blur' alt='' src={url} key={key} className='rounded-lg shadow-md h-48 md:h-[9.5rem] 2xl:h-52 object-cover' />
                                    )}
                                    {/* <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 md:h-[9.5rem] 2xl:h-52 object-cover' /> */}
                                    {/* <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 md:h-[9.5rem] 2xl:h-52 object-cover' /> */}
                                    {/* <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 md:h-[9.5rem] 2xl:h-52 object-cover' /> */}
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='w-full md:w-[40%] 2xl:w-[30%] md:border-l border-gray-300 md:px-5 text-sms mt-5 md:mt-0 flex flex-col'>
                        <div className='mb-5'>
                            <h2 className='text-base text-lightcayn font-bold my-2'>Approval</h2>
                            <p className='font-medium text-sm'>Berikan persetujuan terhadap data hasil panen ini</p>
                            <span className={`rounded-full border-2 hover:text-blue-500 hover:border-blue-300 flex items-center px-4 py-1 cursor-pointer ${statusCode === 200 ? 'border-blue-300 text-blue-500' : 'border-gray-300 text-gray-500'} mt-5 mb-3`} onClick={() => setStatusCode(200)}>
                                <i className="fa-solid fa-circle-check mr-2 text-sm"></i>
                                <p>Setujui</p>
                            </span>
                            <span className={`rounded-full border-2 hover:text-red-500 hover:border-red-300 flex items-center px-4 py-1 cursor-pointer ${statusCode === 400 ? 'border-red-300 text-red-500' : 'border-gray-300 text-gray-500'}`} onClick={() => setStatusCode(400)}>
                                <i className="fa-solid fa-circle-xmark mr-2 text-sm"></i>
                                <p>Tolak</p>
                            </span>
                            <div className='pt-2 border-t mt-8 text-sms text-gray-600 flex'>
                                <i className="fa-solid fa-lightbulb text-yellow-500 2xl:text-lg mr-2 2xl:mr-3.5 mt-0.5 2xl:mt-0"></i>
                                <p className='italic 2xl:text-sm'><span className='font-bold'>Keterangan : </span>Hasil panen yang di setujui akan dilanjutkan ke proses penggilingan</p>
                            </div>
                        </div>
                        {statusCode === 200 || statusCode === 400 ?
                            <div className='flex items-center justify-center space-x-5 mt-auto'>
                                <button className='border border-blue-500 text-blue-500 hover:bg-blue-50 px-8 py-2 rounded-2xl text-sm' onClick={() => setStatusCode(99)}>Batal</button>
                                <button className='btn-blue' onClick={() => setConfirmModal(true)}>Simpan</button>
                            </div> : null
                        }
                    </div>

                </div>

            </div>

            {loader && <Loader />}
            {confirmModal && <ConfirmModal
                icon='fa-solid fa-file-circle-question'
                message='Apa kamu yakin untuk mengubah status approval data panen ini?'
                onCancel={() => setConfirmModal(false)}
                onOK={handleUpdateStatus}
            />}
        </>
    );
}

export default DataPanenApproval;