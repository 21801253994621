import { useEffect, useState } from "react";
import { getAll } from "@api/parameterpengamatan-api";

export function useDropdownParameterPengamatan() {
  const [dataParams, setDataParams] = useState<any>([]);
  const [loadingParams, setLoading] = useState(false);
  const [errorParams, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownParams() {
      setLoading(true);
      let res = await getAll();

      console.log("DD parameter pengamatan :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataParams(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownParams();
  }, []);

  return { dataParams, errorParams, loadingParams };
}
