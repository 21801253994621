import React, { useCallback, useContext, useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { toast } from "react-toastify"

import ParameterApi, { IOperatingParameter } from "../../../api/parameter-api"

import TableUser from "../../../components/tables/Table"
import { ButtonAdd } from "../../../components/button/CustomButton"
import { DropdownLand } from "../../../components/dropdown/SimpleDropdown"
import Loader from "../../../components/modal/Loader";
import ModalFormOperatingParameter from "../../../components/modal/operatingparameter/ModalFornOperatingParameter"
import SearchField from "../../../components/SearchField"
import { ActionType } from "../../../reduxs/action/actions"
import { useLocation } from "react-router-dom"
import { GlobalContext } from "../../../context/GlobalProvider"
import { IRecentPage } from "../../../context/ContextInterface"
import { getPayloadRecentActivity } from "../../../helpers/helper"


const TITLE_BAR = 'Operating Parameter / Parameter'

type Props = {
    user?: any,
};

const OperatingParameter: React.FC<Props> = ({ user }) => {
    const [loader, showLoader] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [originData, setOriginData] = useState<IOperatingParameter[] | []>([])
    const [filterData, setFilterData] = useState<IOperatingParameter[] | []>([])
    const [selectedData, setSelectedData] = useState<IOperatingParameter | null>(null)

    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext)
  
    useEffect(() => {
      const payload: IRecentPage = {
        id: new Date().getTime(),
        title: TITLE_BAR,
        path: pathname,
        url: window.location.href,
      };
  
      dispatchCalPage({ type: 'push', payload })
      dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR })
    }, [dispatch, dispatchCalPage, pathname])


    const fetchData = useCallback(async () => {
        showLoader(true)
        const res = await ParameterApi.getAll()

        showLoader(false)
        console.log("parameter :", res)
        if (res.data) {
            if (res.data.code === 0) {
                setOriginData(res.data.data)
                setFilterData(res.data.data)
            } else {
                toast.error(res.data.message)
            }
        } else {
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData, dispatch])


    const handleEditData = (selectedData: IOperatingParameter) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (data: IOperatingParameter) => {
        showLoader(true)

        let res = null
        if (!isUpdate) {
            data.created_by_var = user.fullname_var
            res = await ParameterApi.createNew(data)

            var payload = getPayloadRecentActivity(1, 'Operating Parameter', data.operating_parameters_code_var, user?.fullname_var, pathname)
        } else {
            data.updated_by_var = user.fullname_var
            res = await ParameterApi.update(selectedData?.id_seq!, data)

            payload = getPayloadRecentActivity(2, 'Operating Parameter', data.operating_parameters_code_var, user?.fullname_var, pathname)
        }

        console.log('Create/Update parameter :', res)

        if (res.data) {
            if (res.data.code === 0) {
                toast.success(res.data.message)

                dispatchCalActivity({ type: 'push', payload })

                fetchData()
                resetForm()
            } else {
                toast.error(res.data.message)
                showLoader(false)
            }
        } else {
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const handleDeleteData = async (data: IOperatingParameter) => {
        showLoader(true)
        const res = await ParameterApi.delete(data.id_seq!)

        console.log("DELETE parameter :", res)
        if (res.data) {
            if (res.data.code === 0) {
                toast.success(res.data.message)
        
                const payload = getPayloadRecentActivity(3, 'Operating Parameter', data.operating_parameters_code_var, user?.fullname_var, pathname)
                dispatchCalActivity({ type: 'push', payload })

                fetchData()
                resetForm()
            } else {
                toast.error(res.data.message)
                showLoader(false)
            }
        } else {
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const resetForm = () => {
        setSelectedData(null)
        setIsUpdate(false)
        showModalForm(false)
        showLoader(false)
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        const newData = [...originData]
        if (event.target.value) {
            const filtered = newData.filter(item => {
                return (
                    item.operating_parameters_code_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.operating_parameters_description_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.operating_parameters_measure_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.operating_parameters_value_var.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        } else {
            setFilterData(originData)
        }
    }

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            accessor: 'no',
            Cell: ({ row }: any) => <div className='text-left pl-4'>{row.index + 1}.</div>,
        },
        {
            Header: 'Kode',
            accessor: 'operating_parameters_code_var',
        },
        {
            Header: 'Desckripsi',
            accessor: 'operating_parameters_description_var'
        },
        {
            Header: 'Value',
            accessor: 'operating_parameters_value_var',
        },
        {
            Header: 'Satuan',
            accessor: 'operating_parameters_measure_var'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({ value }: any) => (
                parseInt(value) === 1 ?
                    <span className='bg-green-100 text-green-800 px-2 py-1 rounded-xl font-medium'>Active</span>
                    :
                    <span className='bg-red-100 text-red-800 px-2 py-1 rounded-xl font-medium'>Inactive</span>
            )
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({ row }: any) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEditData(data)} onDelete={() => handleDeleteData(data)} />
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>
            <div className='w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6'>
                <div className='mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0'>
                    <h1 className='font-bold uppercase text-xl'>List Parameter</h1>
                    <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0'>
                        <SearchField onChange={handleSearch} placeholder='Cari parameter...' />
                        <ButtonAdd onClick={() => showModalForm(true)} />
                    </div>
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalForm && <ModalFormOperatingParameter data={selectedData!} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, null)(OperatingParameter)