import axios from "axios"
import { baseURL } from "./common-api"

export interface IOperatingParameter {
    id_seq?: number,
    operating_parameters_code_var: string,
    operating_parameters_value_var: string,
    operating_parameters_measure_var : string,
    operating_parameters_description_var : string, 
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string,
}

class ParameterApi {
    static getAll = async () => {
        try {
            const data = await axios.get(baseURL + '/api/parameter/get-all', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static getOne = async (operatingParamCode: string) => {
        try {
            const data = await axios.get(baseURL + '/api/parameter/get-one?operating_parameters_code_var=' + operatingParamCode, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    
    static createNew = async (payload: IOperatingParameter) => {
        try {
            const data = await axios.post(baseURL + '/api/parameter/create', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    
    static update = async (parameterID: number, payload: IOperatingParameter) => {
        try {
            const data = await axios.patch(baseURL + '/api/parameter/update/' + parameterID, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    
    static delete = async (parameterID: number) => {
        try {
            const data = await axios.delete(baseURL + '/api/parameter/delete/' + parameterID, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
}

export default ParameterApi