import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { IParameterVariableResearch } from "@api/paramaterVariableResearch-api";
import Select from "react-select"
import { selectStylesForm } from "./TaskManagement/ModalTaskNew";
import useDropdownCategoryVariable from "@hooks/useDropdownCategoryVariable";
import { createNewCategoryVariableRef } from "@api/categoryVariable-api";
import { toast } from "react-toastify";
import ModalFormCategoryVariableRef from "./operatingparameter/ModalFormCategoryVariable";

type Props = {
    data: IParameterVariableResearch;
    dataUser?: any;
    onCancel: (e: React.MouseEvent) => any;
    onSubmit: (data: IParameterVariableResearch) => void;
};

const ModalFormParameterVariableResearch: React.FC<Props> = ({
    data,
    onCancel,
    onSubmit,
}) => {
    // states
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [parameterPengamatan, setParameterPengamatan] = useState(data ? data.parameter_variable_var : "");
    const [valueParameterPengamatan, setValueParameterPengamatan] = useState(data ? data.value_parameter_pengamatan : []);
    const [valueParameterPengamatanSA, setValueParameterPengamatanSA] = useState(data ? data.value_parameter_pengamatan : []);
    const [dataSubParameterPengamatan, setSubParameterPengamatan] = useState(data ? data.value_parameter_pengamatan : []);
    const userLoggedIn = JSON.parse(localStorage.getItem("pandawa-user"));
    const [subParameters, setSubParameters] = useState({});
    const [subFirstParameters, setSubFirstParameters] = useState([]);
    const { dataCategoryVariable, loadingCategoryVariable } = useDropdownCategoryVariable(isRefreshing);
    const [category_variable_id_int, setCategoryVariableId] = useState(data ? data.category_variable_id_int : "");
    const [category_variable_var, setCategoryVariableVar] = useState(data ? data.category_variable_var : "",);
    const [modalFormCategoryVariable, showModalFormCategoryVariable] = useState(false);

    // hooks
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<any>({ criteriaMode: "all" });

    interface ParamType {
        [key: string]: string[];
    }

    // functions
    const setdefaultValue = useCallback(
        (obj) => {
            Object.keys(obj).map((key) => {
                return setValue(key, obj[key], { shouldValidate: true });
            });
        },
        [setValue],
    );
    const onSubmitForm: SubmitHandler<IParameterVariableResearch> = ({
        // parameter_variable_var
    }) => {
        let payload
        if (Object.keys(subParameters).length !== 0) {
            let merge_value_paramater = []
            merge_value_paramater.push(mergeParams(JSON.stringify(subParameters), JSON.stringify(valueParameterPengamatan)))
            payload = {
                parameter_variable_var: dataSubParameterPengamatan[0],
                category_variable_id_int: Number(category_variable_id_int),
                value_parameter_pengamatan: JSON.stringify(merge_value_paramater),
            };
        } else if (subFirstParameters.length !== 0) {
            payload = {
                parameter_variable_var: dataSubParameterPengamatan[0],
                category_variable_id_int: Number(category_variable_id_int),
                value_parameter_pengamatan: JSON.stringify(subFirstParameters),
            };
        }
        else {
            if (valueParameterPengamatanSA.length !== 0) {
                payload = {
                    parameter_variable_var: dataSubParameterPengamatan[0],
                    category_variable_id_int: Number(category_variable_id_int),
                    value_parameter_pengamatan: JSON.stringify(valueParameterPengamatanSA),
                };
            } else {
                payload = {
                    parameter_variable_var: dataSubParameterPengamatan[0],
                    category_variable_id_int: Number(category_variable_id_int),
                    value_parameter_pengamatan: JSON.stringify(valueParameterPengamatan),
                };
            };
        }

        onSubmit(payload);
    };

    // effects
    useEffect(() => {
        if (data) {
            setParameterPengamatan(data.parameter_variable_var);
            setValueParameterPengamatan(data.value_parameter_pengamatan);
            setdefaultValue(data);
        }
    }, [data, setdefaultValue]);

    const mergeParams = (
        subParameters: string,
        valueParameterPengamatan: string
    ): ParamType => {
        const parsedSubParameters = JSON.parse(subParameters);
        const parsedValueParameterPengamatan = JSON.parse(valueParameterPengamatan);

        const result: ParamType = {};

        parsedValueParameterPengamatan.forEach((key: string, index: number) => {
            result[key] = parsedSubParameters[index.toString()];
        });

        return result;
    };

    const handleCategoryVariable = (selectedData: any) => {
        setCategoryVariableId(selectedData);

        setCategoryVariableVar(dataCategoryVariable.filter(
            (item) =>
                Number(item.id_seq) === Number(selectedData)
        )[0].category_variable_var);
    };

    const handleAddInput = () => {
        setValueParameterPengamatan([...valueParameterPengamatan, ""]);
    };

    const handleAddValueSA = () => {
        setValueParameterPengamatanSA([...valueParameterPengamatanSA, ""]);
    };

    const handleAddSub = () => {
        // setValueParameterPengamatan([...valueParameterPengamatan, ""]);
        setSubParameterPengamatan([...dataSubParameterPengamatan, ""]);
    };

    const handleRemoveInput = (index) => {
        const updatedValue = [...valueParameterPengamatan];
        updatedValue.splice(index, 1);
        setValueParameterPengamatan(updatedValue);

        // Remove associated sub-variables
        const updatedSubParams = { ...subParameters };
        delete updatedSubParams[index];
        setSubParameters(updatedSubParams);
    };

    const handleRemoveFirstSub = (index) => {
        const updatedValue = [...dataSubParameterPengamatan];
        updatedValue.splice(index, 1);
        setSubParameterPengamatan(updatedValue);
    };

    const handleRemoveValueSA = (index) => {
        const updatedValue = [...valueParameterPengamatanSA];
        updatedValue.splice(index, 1);
        setValueParameterPengamatanSA(updatedValue);
    };

    const handleInputChange = (vpIndex, value) => {
        const updatedValues = [...valueParameterPengamatan];
        updatedValues[vpIndex] = value;
        setValueParameterPengamatan(updatedValues);
    };

    const handleInputFirstSub = (vpIndex, value) => {
        const updatedValues = [...dataSubParameterPengamatan];
        updatedValues[vpIndex] = value;
        setSubParameterPengamatan(updatedValues);
    };

    const handleValInputSA = (vpIndex, value) => {
        const updatedValues = [...valueParameterPengamatanSA];
        updatedValues[vpIndex] = value;
        setValueParameterPengamatanSA(updatedValues);
    };

    const handleSubInput = (vpIndex) => {
        setSubParameters((prev) => ({
            ...prev,
            [vpIndex]: [...(prev[vpIndex] || []), ""]
        }));
    };
    const handleFirstSubInput = (vpIndex) => {
        setSubFirstParameters((prev) => ({
            ...prev,
            [vpIndex]: [...(prev[vpIndex] || []), ""]
        }));
    };

    const handleRemoveSubInput = (vpIndex, subIndex) => {
        const updatedSubParams = { ...subParameters };
        updatedSubParams[vpIndex].splice(subIndex, 1);
        setSubParameters(updatedSubParams);
    };

    const handleRemoveFirstSubInput = (vpIndex, subIndex) => {
        const updatedSubParams = { ...subFirstParameters };
        updatedSubParams[vpIndex].splice(subIndex, 1);
        setSubFirstParameters(updatedSubParams);
    };

    const handleSubInputChange = (vpIndex, subIndex, value) => {
        const updatedSubParams = { ...subParameters };
        updatedSubParams[vpIndex][subIndex] = value;
        setSubParameters(updatedSubParams);
    };

    const handleFirstSubInputChange = (vpIndex, subIndex, value) => {
        const updatedSubParams = { ...subFirstParameters };
        updatedSubParams[vpIndex][subIndex] = value;
        setSubFirstParameters(updatedSubParams);
    };

    const resetFormCategoryVariable = () => {
        showModalFormCategoryVariable(false);
    };

    const handleReceiveDataFormCategoryVariable = async (payload) => {
        let res = null;
        payload.created_by_var = userLoggedIn.fullname_var;
        res = await createNewCategoryVariableRef(payload);

        if (res.data) {
            if (res.data.code === 0) {
                toast.success(res.data.message);
                setIsRefreshing(!isRefreshing);
                showModalFormCategoryVariable(false);
            } else {
                toast.error(res.data.message);
            }
        } else {
            toast.error(`${res.config?.url} ${res.message}`);
        }
    };

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
                    <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                        <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                            <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                            <h3>
                                {data
                                    ? "Ubah Variable Research"
                                    : "Tambah Variable Research"}
                            </h3>
                        </div>
                        <button
                            type="button"
                            className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}>
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className="space-y-5">
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="category_variable_var" className="label-form">
                                        Category Variable
                                    </label>
                                    <Select
                                        id="category_variable_var"
                                        styles={selectStylesForm}
                                        isClearable
                                        placeholder="-Choose Category-"
                                        options={dataCategoryVariable}
                                        isLoading={loadingCategoryVariable}
                                        getOptionValue={(option: any) => option["id_seq"]}
                                        getOptionLabel={(option: any) => `${option.category_variable_var}`}
                                        onChange={(e) => e ? handleCategoryVariable(e.id_seq) : null}
                                    />
                                    <ErrorField errors={errors} name="category_variable_var" />
                                    <div className="justify-end flex">
                                        <button
                                            type="button"
                                            className="mt-4 text-right"
                                            onClick={() => showModalFormCategoryVariable(true)}>
                                            + Add New Type
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-2 gap-4">
                                <div className="w-full">
                                    <label htmlFor="parameter_variable_var" className="label-form">
                                        Variable Research <label style={{ color: 'grey', fontSize: 'small' }}> (Optional) </label>
                                    </label>
                                    {dataSubParameterPengamatan.map((vp, vpIndex) => (
                                        <div className="w-full mb-4" key={vpIndex}>
                                            <input
                                                id={`data_sub_paramater_pengamatan_${vpIndex}`}
                                                type="text"
                                                className="input-form"
                                                placeholder="value parameter variable research"
                                                value={vp}
                                                onChange={(e) => handleInputFirstSub(vpIndex, e.target.value)}
                                            />
                                            <ErrorField errors={errors} name="parameter_variable_var" />
                                            <button
                                                type="button"
                                                className="text-red-500 py-1"
                                                onClick={() => handleRemoveFirstSub(vpIndex)}>
                                                Remove
                                            </button>
                                            <div className="flex flex-wrap mb-4">
                                                {subFirstParameters[vpIndex]?.map((sub, subIndex) => (
                                                    <div className="flex-shrink-0 w-auto mb-4 mr-4" key={subIndex}>
                                                        <input
                                                            id={`sub_parameter_pengamatan_${vpIndex}_${subIndex}`}
                                                            type="text"
                                                            className="input-form"
                                                            placeholder="sub parameter pengamatan"
                                                            value={sub}
                                                            onChange={(e) => handleFirstSubInputChange(vpIndex, subIndex, e.target.value)}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="text-red-500 py-1"
                                                            onClick={() => handleRemoveFirstSubInput(vpIndex, subIndex)}
                                                        >
                                                            Remove Sub Value
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                            <button
                                                type="button"
                                                className="text-blue-500 py-1"
                                                onClick={() => handleFirstSubInput(vpIndex)}
                                            >
                                                + Add Value
                                            </button>
                                        </div>
                                    ))}
                                    {
                                        dataSubParameterPengamatan.length === 0 ?
                                            <button
                                                type="button"
                                                className="text-blue-500 py-1"
                                                onClick={handleAddSub}>
                                                + Add Variable
                                            </button> :
                                            null
                                    }
                                </div>
                                <div className="w-full">
                                    {dataSubParameterPengamatan.length !== 0 ? (
                                        <>
                                            <label className="label-form">Sub Variable Research</label>
                                            {valueParameterPengamatan.map((vp, vpIndex) => (
                                                <div className="w-full mb-4" key={vpIndex}>
                                                    <input
                                                        id={`value_parameter_variable_research_${vpIndex}`}
                                                        type="text"
                                                        className="input-form"
                                                        placeholder="value sub variable research"
                                                        value={vp}
                                                        onChange={(e) => handleInputChange(vpIndex, e.target.value)}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="text-red-500 py-1"
                                                        onClick={() => handleRemoveInput(vpIndex)}
                                                    >
                                                        Remove Value
                                                    </button>
                                                    <div className="flex flex-wrap mb-4">
                                                        {subParameters[vpIndex]?.map((sub, subIndex) => (
                                                            <div className="flex-shrink-0 w-auto mb-4 mr-4" key={subIndex}>
                                                                <input
                                                                    id={`sub_parameter_pengamatan_${vpIndex}_${subIndex}`}
                                                                    type="text"
                                                                    className="input-form"
                                                                    placeholder="sub parameter pengamatan"
                                                                    value={sub}
                                                                    onChange={(e) => handleSubInputChange(vpIndex, subIndex, e.target.value)}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className="text-red-500 py-1"
                                                                    onClick={() => handleRemoveSubInput(vpIndex, subIndex)}
                                                                >
                                                                    Remove Sub Value
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="text-blue-500 py-1"
                                                        onClick={() => handleSubInput(vpIndex)}
                                                    >
                                                        + Add Value
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                className="text-blue-500 py-1"
                                                onClick={handleAddInput}
                                            >
                                                + Add Sub
                                            </button>
                                        </>
                                    ) : null}
                                    {subFirstParameters.length !== 0 ? (
                                        <>
                                            <label className="label-form">Value Variable Research</label>
                                            {valueParameterPengamatanSA?.map((sub, subIndex) => (
                                                <div className="flex-shrink-0 w-auto mb-4 mr-4" key={subIndex}>
                                                    <input
                                                        id={`sub_parameter_pengamatan_sa${subIndex}`}
                                                        type="text"
                                                        className="input-form"
                                                        placeholder="sub parameter pengamatan"
                                                        value={sub}
                                                        onChange={(e) => handleValInputSA(subIndex, e.target.value)}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="text-red-500 py-1"
                                                        onClick={() => handleRemoveValueSA(subIndex)}
                                                    >
                                                        Remove Value
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                className="text-blue-500 py-1"
                                                onClick={handleAddValueSA}
                                            >
                                                + Add Value
                                            </button>
                                        </>
                                    ) : null}
                                </div>

                            </div>
                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                            <button type="submit" className="btn-primary">
                                Simpan
                            </button>
                            <button
                                type="reset"
                                className="btn-secondary px-7"
                                onClick={onCancel}>
                                Batal
                            </button>
                        </div>
                    </form>

                    {modalFormCategoryVariable && (
                        <ModalFormCategoryVariableRef
                            data={null}
                            onCancel={resetFormCategoryVariable}
                            onSubmit={handleReceiveDataFormCategoryVariable}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ModalFormParameterVariableResearch;
