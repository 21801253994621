import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IDetailLogActivities, ILogActivities, getDetailTaksFA } from "@api/logactivity-api";
import Dropdown from "../../components/dropdown/Dropdown";
import Loader from "../../components/modal/Loader";
import SearchField from "../../components/SearchField";
import TableUser from "../../components/tables/Table";
import Helper from "../../helpers/helper";
import Select from "react-select";
import { ActionType } from "../../reduxs/action/actions";
import dayjs from "dayjs";
import { SelectCustomStyles } from "./TaskManagement";
import useDropdownFarmer, { useDropdownFarmerbyFA } from "@hooks/useDropdownFarmer";
import useDropdownLand, { useDropdownPeriod } from "../../hooks/useDropdownLand";
import { DropdownLand } from "@components/dropdown/SimpleDropdown";
import ModalDetailObservation from "@components/modal/ModalDetailObservation";

// type Props = {};

const LogActivitiesDetail = () => {
	const { id }: any = useParams();

	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [modalDetail, showModalDetail] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [originData, setOriginData] = useState<ILogActivities[] | []>([]);
	const [filterData, setFilterData] = useState<ILogActivities[] | []>([]);
	const [selectedData, setSelectedData] = useState<IDetailLogActivities | null>(null);
	const [activities, setActivities] = useState<any[]>([]);
	const [DataObservation, setDataObservation] = useState<any[]>([]);
	const [DataNotObserved, setDataNotObserved] = useState<any[]>([]);
	const [selectedObservation, setselectedObservation] = useState<any[]>([]);
	const { dataFarmer, loadingFarmer } = useDropdownFarmerbyFA(id);
	const [selectedFarmer, setSelectedFarmer] = useState<any>(null);
	const [selectedLand, setSelectedLand] = useState<any>(null);
	const { dataLand, loadingLand } = useDropdownLand(selectedFarmer?.id_seq || 0,);
	const { dataPeriod, loadingPeriod } = useDropdownPeriod(selectedLand?.land_code_var || "");
	const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
	const [modalDetailObservation, showModalDetailObservation] = useState(false);

	const user = useSelector((state: any) => state.user);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: ActionType.SET_TITLE_BAR, value: "Riwayat Aktivitas FA" });
		fetchData();
	}, [dispatch, id]);

	const fetchData = async () => {
		showLoader(true);
		try {
			const res = await getDetailTaksFA(
				selectedFarmer?.id_seq || id,
			);

			console.log("DASHBOARD ACTIVITIES ==> ", res);
			console.log("RES OBSERVATION ==> ", res.data.data[1]);


			if (res.data && res.data.code === 0) {
				setActivities(res.data.data[0]);
				setOriginData(res.data.data[0]);
				setFilterData(res.data.data[0]);
				setDataObservation(res.data.data[1]);
				setDataNotObserved(res.data.data[2]);
			} else {
				// Handle error condition
				console.error("Error fetching data:", res.data?.message || res.status + ' ' + res.message || 'Connection timeout');
			}
		} catch (error) {
			// Handle network errors or unexpected errors
			console.error("Error fetching data:", error);
		} finally {
			showLoader(false);
		}
	};

	const resetForm = () => {
		setSelectedData(null);
		setIsUpdate(false);
		showModalForm(false);
		showLoader(false);
		showModalDetail(false);
	};

	const handleCloseModal = () => {
		setSelectedData(null);
		showModalDetailObservation(false);
	};


	// const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	event.preventDefault();

	// 	const newData = [...originData];
	// 	if (event.target.value) {
	// 	  const filtered = newData.filter((item) => {
	// 	    return (
	// 	      item.name
	// 	        ?.toLowerCase()
	// 	        .includes(event.target.value.toLowerCase()) ||
	// 	      item.unit
	// 	        ?.toLowerCase()
	// 	        .includes(event.target.value.toLowerCase()) ||
	// 	      item.quantity
	// 	        ?.toString()
	// 	        .toLowerCase()
	// 	        .includes(event.target.value.toLowerCase())
	// 	    );
	// 	  });

	// 	  setFilterData(filtered);
	// 	} else {
	// 	  setFilterData(originData);
	// 	}
	// };

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="pl-4 text-left">{row.index + 1}.</div>
			),
			width: 10,
		},
		{
			Header: "Aktivitas",
			accessor: "activity_txt",
			width: 300,
		},
		{
			Header: "Material",
			accessor: "material_txt",
			width: 300,
		},
		{
			Header: "Dosis",
			accessor: "dose_txt",
			width: 300,
		},
		{
			Header: "Waktu",
			accessor: "time_txt",
			width: 60,
		},
		{
			Header: "Tanggal",
			accessor: "time_calender_dte",
			width: 90,
			Cell: ({ value }: any) => {
				return dayjs(value).format("DD/MM/YYYY");
			},
		},
		{
			Header: "Komoditas",
			accessor: "commodity_name",
			width: 100,
		},
		{
			Header: "Metode Tanam",
			accessor: "name_planting",
			width: 110,
		},
		{
			Header: "Lahan",
			accessor: "land_code_var",
			width: 125,
			Cell: ({ value, row }: any) => {
				const data = row.original;
				return (
					<span>
						[{value}] {data.land_name_var}
					</span>
				);
			},
		},
		{
			Header: "Petani",
			accessor: "fullname_var",
			width: 150,
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }: any) => {
				const data = row.original;

				const filterdataObservation = DataObservation.filter(subArray => {
					return subArray[0].time_calender_dte === data.time_calender_dte;
				});
				return (
					<DropdownLand
						data={data}
						onActivity={() => {
							setSelectedData(data);
							showModalDetailObservation(true);
							setselectedObservation(filterdataObservation)
							setIsUpdate(true);
						}} onEdit={undefined} onDelete={undefined}
					/>
				);
			},
		},
	];

	return (
		<>
			<div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
				<div className="bg-white rounded-2xl shadow-2xl p-5 md:p-6 2xl:px-7">
					<h2 className="font-bold uppercase text-xl mt-2">
						Riwayat Aktivitas Pendamping
					</h2>

					<nav
						className="flex my-5 py-4 px-2 md:px-5 text-gray-700 rounded-lg border bg-blue-50 border-blue-100"
						aria-label="Breadcrumb">
						<ol className="inline-flex items-center space-x-1 md:space-x-3">
							<li className="inline-flex items-center">
								<Link
									to="/dashboard/log-activity-fa"
									className="inline-flex items-center text-sms md:text-sm font-semibold text-blue-500 hover:opacity-75">
									<svg
										className="mr-1 md:mr-2 w-4 h-4"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
									</svg>
									Daftar Pendamping
								</Link>
							</li>
							<li>
								<div className="flex items-center">
									<svg
										className="w-6 h-6 text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
											clipRule="evenodd"></path>
									</svg>
									<span className="ml-1 text-sms md:text-sm font-bold text-slate-800 hover:opacity-75 cursor-pointer">
										Riwayat Aktivitas Pendamping
									</span>
								</div>
							</li>
						</ol>
					</nav>

					<div className='w-full space-y-6'>
						<div>
							<table className="font-medium text-sms md:text-sm mt-2.5">
								<tbody>
									<tr>
										<td className='text-gray-500 pb-1 w-32 md:w-44'>Nama Pendamping</td>
										<td>{DataNotObserved[0]?.field_assistant_name_var}</td>
									</tr>
									<tr>
										<td className='text-gray-500 pb-1 w-32 md:w-44'>Periode</td>
										<td>{DataNotObserved[0]?.period_plant_txt}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div className="flex flex-col items-center justify-between w-full mt-5 mb-5 space-y-3 md:w-max md:flex-row md:justify-center md:space-x-3 md:space-y-0 md:mb-4 2xl:mb-5">
						<Select
							name="farmer"
							styles={SelectCustomStyles}
							options={dataFarmer}
							onChange={setSelectedFarmer}
							isClearable
							placeholder="- Pilih Petani -"
							className="w-full md:w-[200px]"
							isLoading={loadingFarmer}
							getOptionValue={(option: any) => option["id_seq"]}
							getOptionLabel={(option: any) => option["fullname_var"]}
						/>
						<Select
							name="land"
							styles={SelectCustomStyles}
							options={dataLand}
							onChange={setSelectedLand}
							isClearable
							placeholder="- Pilih Lahan -"
							className="w-full md:w-[200px] 2xl:md:w-[300px]"
							isLoading={loadingLand}
							getOptionValue={(option: any) => option["land_code_var"]}
							getOptionLabel={(option: any) =>
								`[${option["land_code_var"]}] ${option["land_name_var"]}`
							}
						/>
						<Select
							name="period"
							styles={SelectCustomStyles}
							options={dataPeriod}
							onChange={setSelectedPeriod}
							isClearable
							placeholder="- Pilih Periode -"
							className="w-full md:w-[175px] 2xl:md:w-[200px]"
							isLoading={loadingPeriod}
							getOptionValue={(option: any) => option["period_plant_txt"]}
							getOptionLabel={(option: any) => option["period_plant_txt"]}
						/>
						<button
							type="submit"
							className="w-full px-6 py-3 text-sm text-white bg-blue-600 rounded-lg md:w-max hover:bg-blue-500"
							onClick={fetchData}>
							Terapkan
						</button>
					</div>
					<h4 className='font-bold uppercase text-m mt-2 mb-2 text-red-500'>Aktivitas Belum Dilakukan Pengamatan</h4>
					{filterData && (
						<TableUser dataTable={DataNotObserved} columnTable={columns} />
					)}
				</div>

				{modalDetailObservation && (
					<ModalDetailObservation
						data={selectedData!}
						onCancel={resetForm}
						onClose={handleCloseModal}
						dataObservation={selectedObservation!}
					/>
				)}
				{loader && <Loader />}
			</div>

			<div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
				<div className="bg-white rounded-2xl shadow-2xl p-5 md:p-6 2xl:px-7">
					<div className="flex flex-col items-center justify-between w-full mt-5 mb-5 space-y-3 md:w-max md:flex-row md:justify-center md:space-x-3 md:space-y-0 md:mb-4 2xl:mb-5">
						<Select
							name="farmer"
							styles={SelectCustomStyles}
							options={dataFarmer}
							onChange={setSelectedFarmer}
							isClearable
							placeholder="- Pilih Petani -"
							className="w-full md:w-[200px]"
							isLoading={loadingFarmer}
							getOptionValue={(option: any) => option["id_seq"]}
							getOptionLabel={(option: any) => option["fullname_var"]}
						/>
						<Select
							name="land"
							styles={SelectCustomStyles}
							options={dataLand}
							onChange={setSelectedLand}
							isClearable
							placeholder="- Pilih Lahan -"
							className="w-full md:w-[200px] 2xl:md:w-[300px]"
							isLoading={loadingLand}
							getOptionValue={(option: any) => option["land_code_var"]}
							getOptionLabel={(option: any) =>
								`[${option["land_code_var"]}] ${option["land_name_var"]}`
							}
						/>
						<Select
							name="period"
							styles={SelectCustomStyles}
							options={dataPeriod}
							onChange={setSelectedPeriod}
							isClearable
							placeholder="- Pilih Periode -"
							className="w-full md:w-[175px] 2xl:md:w-[200px]"
							isLoading={loadingPeriod}
							getOptionValue={(option: any) => option["period_plant_txt"]}
							getOptionLabel={(option: any) => option["period_plant_txt"]}
						/>
						<button
							type="submit"
							className="w-full px-6 py-3 text-sm text-white bg-blue-600 rounded-lg md:w-max hover:bg-blue-500"
							onClick={fetchData}>
							Terapkan
						</button>
					</div>
					<h4 className='font-bold uppercase text-m mt-2 mb-2 text-green-500'>Aktivitas Selesai Pengamatan</h4>
					{filterData && (
						<TableUser dataTable={activities} columnTable={columns} />
					)}
				</div>
				{loader && <Loader />}
			</div>
		</>
	);
};

export default LogActivitiesDetail;
