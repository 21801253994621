import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";

import TableUser from "../../../components/tables/Table";
import { ButtonAdd } from "../../../components/button/CustomButton";

import Loader from "../../../components/modal/Loader";

import SearchField from "../../../components/SearchField";
import { ActionType } from "../../../reduxs/action/actions";
import ModalFormProductOut from "../../../components/modal/Inventory/ModalFormExitProduct";
import dayjs from "dayjs";
import { ExitProductApi, IExitProduct } from "../../../api/inventory-api";
import Dropdown from "../../../components/dropdown/Dropdown";
import ModalQRProduct from "../../../components/modal/Inventory/ModalQRProduct";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalProvider";
import { IRecentPage } from "../../../context/ContextInterface";
import Helper, { getPayloadRecentActivity } from "../../../helpers/helper";

const TITLE_BAR = "Inventory / Barang Keluar";

type Props = {
  user?: any;
};

const InventoryExitProduct: React.FC<Props> = ({ user }) => {
  const [loader, showLoader] = useState(false);
  const [modalForm, showModalForm] = useState(false);
  const [modalQR, showModalQR] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [originData, setOriginData] = useState<IExitProduct[] | []>([]);
  const [filterData, setFilterData] = useState<IExitProduct[] | []>([]);
  const [selectedData, setSelectedData] = useState<IExitProduct | null>(null);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: "push", payload });
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
  }, [dispatch, dispatchCalPage, pathname]);

  const fetchData = useCallback(async () => {
    showLoader(true);
    const res = await ExitProductApi.getAll();

    showLoader(false);
    console.log("EXIT PRODUCT ==>", res);
    if (res.data) {
      if (res.data.code === 0) {
        setOriginData(res.data.data);
        setFilterData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, dispatch]);

  const handleEditData = (selectedData: IExitProduct) => {
    setSelectedData(selectedData);
    setIsUpdate(true);
    showModalForm(true);
  };

  const handleReceiveDataForm = async (data: IExitProduct) => {
    showLoader(true);

    let res = null;
    if (!isUpdate) {
      data.created_by_var = user.fullname_var;
      res = await ExitProductApi.createNew(data);

      var payload = getPayloadRecentActivity(
        1,
        "Barang Keluar",
        data.id_product_var,
        user?.fullname_var,
        pathname,
      );
    } else {
      data.updated_by_var = user.fullname_var;
      res = await ExitProductApi.update(selectedData?.id_seq!, data);

      payload = getPayloadRecentActivity(
        2,
        "Barang Keluar",
        data.id_product_var,
        user?.fullname_var,
        pathname,
      );
    }

    console.log("EXIT PRODUCT ACTION ==>", res);

    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);

        dispatchCalActivity({ type: "push", payload });

        fetchData();
        resetForm();
      } else {
        toast.error(res.data.message);
        showLoader(false);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const handleDeleteData = async (data: IExitProduct) => {
    showLoader(true);
    const res = await ExitProductApi.delete(data.id_seq!);

    console.log("EXIT PRODUCT ACTION ==>", res);
    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);

        const payload = getPayloadRecentActivity(
          3,
          "Barang Keluar",
          data.id_product_var,
          user?.fullname_var,
          pathname,
        );
        dispatchCalActivity({ type: "push", payload });

        fetchData();
        resetForm();
      } else {
        toast.error(res.data.message);
        showLoader(false);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const resetForm = () => {
    setSelectedData(null);
    setIsUpdate(false);
    showModalForm(false);
    showLoader(false);
    showModalQR(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const newData = [...originData];
    if (event.target.value) {
      const filtered = newData.filter((item) => {
        return (
          item.id_product_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.product_name_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.quantity_int
            ?.toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.price_int
            ?.toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.measure_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.customer_name_var
            ?.toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.unique_code_var
            ?.toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.product_name_type_var
            ?.toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      });

      setFilterData(filtered);
    } else {
      setFilterData(originData);
    }
  };

  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }: any) => (
        <div className="pl-4 text-left">{row.index + 1}.</div>
      ),
    },
    {
      Header: "Tanggal Keluar",
      accessor: "time_on_dte",
      Cell: ({ value }: any) =>
        value ? dayjs(value).format("YYYY-MM-DD") : "",
    },
    {
      Header: "Nama Produk",
      accessor: "product_name_var",
      Cell: ({ value, row }: any) => {
        const data = row.original;
        return `[${data.id_product_var}] ${value}`;
      },
    },
    {
      Header: "Tipe Produk",
      accessor: "product_name_type_var",
    },
    {
      Header: "Jmlh Barang",
      accessor: "quantity_int",
    },
    {
      Header: "Satuan",
      accessor: "measure_var",
    },
    {
      Header: "Harga Satuan (Rp)",
      accessor: "price_int",
      Cell: ({ value }: any) => {
        return Helper.amount(value);
      },
    },
    {
      Header: "Total Harga (Rp)",
      accessor: "price_total",
      Cell: ({ row }: any) => {
        const data = row.original;
        return Helper.amount(data.price_int * data.quantity_int);
      },
    },
    {
      Header: "Pelanggan",
      accessor: "customer_name_var",
    },
    {
      Header: "Kode Unik",
      accessor: "unique_code_var",
      Cell: ({ value }: any) => {
        return value || "-";
      },
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status_int",
      Cell: ({ value }: any) =>
        parseInt(value) === 1 ? (
          <span className="bg-green-100 text-green-800 px-2 py-1 rounded-xl font-medium">
            Done
          </span>
        ) : (
          <span className="bg-red-100 text-red-800 px-2 py-1 rounded-xl font-medium">
            Canceled
          </span>
        ),
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }: any) => {
        const data = row.original;
        // return <DropdownLand data={data} onEdit={() => handleEditData(data)} onDelete={()=>handleDeleteData(data)} />
        return (
          <Dropdown
            onEdit={() => handleEditData(data)}
            onDelete={() => handleDeleteData(data)}
            onShowQR={() => {
              setSelectedData(data);
              showModalQR(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
      <div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
        <div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
          <h1 className="text-xl font-bold uppercase">Data Barang Keluar</h1>
          <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
            <SearchField onChange={handleSearch} placeholder="Cari produk..." />
            <ButtonAdd onClick={() => showModalForm(true)} />
          </div>
        </div>
        <TableUser dataTable={filterData} columnTable={columns} />
      </div>

      {modalQR && <ModalQRProduct data={selectedData!} onCancel={resetForm} />}
      {modalForm && (
        <ModalFormProductOut
          data={selectedData!}
          onCancel={resetForm}
          onSubmit={handleReceiveDataForm}
        />
      )}
      {loader && <Loader />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(InventoryExitProduct);
