import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
	BudgetDetailApi,
	BudgetPlanApi,
	IBudgetDetail,
	IBudgetPlan,
} from "../../../api/budgetplan-api";
import { ButtonAdd } from "../../../components/button/CustomButton";
import Dropdown from "../../../components/dropdown/Dropdown";
import BPDetailForm from "../../../components/modal/budgetplan/BPDetailForm";
import BudgetDetailApprovalModal from "../../../components/modal/budgetplan/BudgetDetailApprovalModal";
import Loader from "../../../components/modal/Loader";
import SearchField from "../../../components/SearchField";
import TableUser from "../../../components/tables/Table";
import Helper from "../../../helpers/helper";
import { ActionType } from "../../../reduxs/action/actions";

type Props = {};

const BudgetPlanDetail: React.FC<Props> = () => {
	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [modalDetail, showModalDetail] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [dataTotalSaprodi, setTotalSaprodi] = useState(0);
	const [dataBugdetPlan, setDataBudgetPlan] = useState<IBudgetPlan | null>(null,);
	const [dataMaterialSupport, setDataMaterialSupport] = useState<IBudgetDetail | null>(null);
	const [originData, setOriginData] = useState<IBudgetDetail[] | []>([]);
	const [filterData, setFilterData] = useState<IBudgetDetail[] | []>([]);
	const [selectedData, setSelectedData] = useState<IBudgetDetail | null>(null);

	const { id }: any = useParams();
	const user = useSelector((state: any) => state.user);
	const dispatch = useDispatch();

	const fetchData = useCallback(async () => {
		showLoader(true);
		const res = await BudgetPlanApi.getOne(id);
		const res2 = await BudgetDetailApi.getOneDetail(res.data.data.budget_plan[0].land_code_var, res.data.data.budget_plan[0].period_plant_txt)
		showLoader(false);
		console.log("BUDGETPLAN 1 ==>", res);
		console.log("BUDGETPLAN DETAIL ==>", res2);
		let TotalSaprodi = 0
		if (res.data) {
			if (res.data.code === 0) {
				setOriginData(res2.data.data.budget_plan);
				setFilterData(res2.data.data.budget_plan);
				setDataBudgetPlan(res.data.data.budget_plan[0]);
				setDataMaterialSupport(res2.data.data.budget_plan)
				for (let i = 0; i < res2.data.data.budget_plan.length; i++) {
					TotalSaprodi += (parseFloat(res2.data.data.budget_plan[i].price_var_var) * parseFloat(res2.data.data.budget_plan[i].quantity_var))
				}

				setTotalSaprodi(TotalSaprodi);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
		// eslint-disable-next-line
	}, [id]);

	useEffect(() => {
		dispatch({ type: ActionType.SET_TITLE_BAR, value: "Budget Plan Petani" });
		fetchData();
	}, [fetchData, dispatch, id]);

	const handleEditData = (selectedData: any) => {
		setSelectedData(selectedData);
		setIsUpdate(true);
		showModalForm(true);
	};

	function calculateTotalPrice_var() {
		let sum = 0;
		originData.forEach((item: any) => {
			sum += Number(item.total_price_var_var);
		});
		return sum;
	}

	const handleReceiveDataForm = async (data: FormData) => {
		const latestTotal =
			Number(data.get("quantity_var")) * Number(data.get("price_var_var"));

		const currentTotal = calculateTotalPrice_var();

		if (
			latestTotal + Number(currentTotal) >
			Number(dataBugdetPlan?.budget_plan_var)
		) {
			toast.error("Tidak boleh melebihi total budget plan");
			return false;
		}

		showLoader(true);

		let res = null;
		if (!isUpdate) {
			data.append("budget_id_int", id);
			data.append("created_by_var", user.fullname_var);
			res = await BudgetDetailApi.createNew(data);
		} else {
			data.append("budget_id_int", id);
			data.append("updated_by_var", user.fullname_var);
			res = await BudgetDetailApi.update(selectedData?.id_seq!, data);
		}

		for (var pair of data.entries() as any) {
			console.log(pair[0] + ", " + pair[1]);
		}

		console.log("BUDGETPLAN DETAIL ACTION ==>", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);
				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleDeleteData = async (data: any) => {
		showLoader(true);
		const res = await BudgetDetailApi.delete(data.id_seq!);

		console.log("BUDGETPLAN DETAIL ACTION ==>", res);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);
				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleApply = async (data: IBudgetDetail, request: boolean) => {
		showLoader(true);
		const payload = {
			updated_by_var: user.fullname_var,
		};

		let res;
		if (request) {
			res = await BudgetDetailApi.request(data.id_seq!, payload);
		} else {
			res = await BudgetDetailApi.draft(data.id_seq!, payload);
		}

		console.log("BUDGETPLAN DETAIL APPLY ==>", res);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);
				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const resetForm = () => {
		setSelectedData(null);
		setIsUpdate(false);
		showModalForm(false);
		showLoader(false);
		showModalDetail(false);
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		const newData = [...originData];
		if (event.target.value) {
			const filtered = newData.filter((item) => {
				return (
					item.name
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.unit
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.quantity
						?.toString()
						.toLowerCase()
						.includes(event.target.value.toLowerCase())
				);
			});

			setFilterData(filtered);
		} else {
			setFilterData(originData);
		}
	};

	// const columns = [
	// 	{
	// 		Header: () => <span className="p-4">No</span>,
	// 		accessor: "no",
	// 		Cell: ({ row }: any) => (
	// 			<div className="text-left pl-4">{row.index + 1}.</div>
	// 		),
	// 		width: 10,
	// 	},
	// 	{
	// 		Header: "Kategori",
	// 		accessor: "category_var",
	// 	},
	// 	{
	// 		Header: "Kegiatan",
	// 		accessor: "activity_txt",
	// 	},
	// 	{
	// 		Header: "Luas",
	// 		accessor: "area_var",
	// 	},
	// 	{
	// 		Header: "Jumlah",
	// 		accessor: "quantity_var",
	// 	},
	// 	{
	// 		Header: "Satuan",
	// 		accessor: "satuan_var",
	// 	},
	// 	{
	// 		Header: "Harga (Rp)",
	// 		accessor: "price_var_var",
	// 		Cell: ({ value }: any) => {
	// 			return Helper.amount(value);
	// 		},
	// 	},
	// 	{
	// 		Header: "Total (Rp)",
	// 		accessor: "total_price_var_var",
	// 		Cell: ({ value }: any) => {
	// 			return Helper.amount(value);
	// 		},
	// 	},
	// 	{
	// 		Header: "Status",
	// 		accessor: "status_int",
	// 		Cell: ({ value, row }: any) => {
	// 			const data = row.original;
	// 			const statusName = data.status_name_var;

	// 			// 1 active, -5 inactive, 100 = draft, 150 = request to aproval, 400 rejected
	// 			if (value === -5 || value === 400) {
	// 				return (
	// 					<span className="bg-red-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
	// 						{statusName}
	// 					</span>
	// 				);
	// 			} else if (value === 100) {
	// 				return (
	// 					<span className="bg-slate-400 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
	// 						{statusName}
	// 					</span>
	// 				);
	// 			} else if (value === 150) {
	// 				return (
	// 					<span className="bg-blue-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
	// 						{statusName}
	// 					</span>
	// 				);
	// 			} else {
	// 				return (
	// 					<span className="bg-green-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
	// 						{statusName}
	// 					</span>
	// 				);
	// 			}
	// 		},
	// 	},
	// 	{
	// 		Header: "Action",
	// 		Cell: ({ row }: any) => {
	// 			const data = row.original;
	// 			const status = data.status_int;
	// 			// 100

	// 			return (
	// 				<Dropdown
	// 					onDetail={() => {
	// 						setSelectedData(data);
	// 						showModalDetail(true);
	// 					}}
	// 					onEdit={() => handleEditData(data)}
	// 					onDelete={() => handleDeleteData(data)}
	// 					onApply={status === 100 ? () => handleApply(data, true) : undefined}
	// 					onCancelApply={
	// 						status === 150 ? () => handleApply(data, false) : undefined
	// 					}
	// 				/>
	// 			);
	// 		},
	// 	},
	// ];
	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="text-left pl-4">{row.index + 1}.</div>
			),
			width: 10,
		},
		{
			Header: "Saprodi",
			accessor: "material_support",
		},
		{
			Header: "Satuan",
			accessor: "unit_txt",
		},
		{
			Header: "Kuantitas",
			accessor: "quantity_var",
		},
		{
			Header: () => <div className="pl-2 text-center">Harga</div>,
			accessor: "price_var",
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<div className="pl-2 text-center">
						Rp. {Helper.amount(`${parseFloat(data.price_var) * parseFloat(data.quantity_var)}`)}
					</div>
				);
			},
		},
		{
			Header: "Status",
			accessor: "status_int",
			Cell: ({ value, row }: any) => {
				const data = row.original;
				const statusName = data.status_name_var;

				// 1 active, -5 inactive, 100 = draft, 150 = request to aproval, 400 rejected
				if (value === -5 || value === 400) {
					return (
						<span className="bg-red-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
							{statusName}
						</span>
					);
				} else if (value === 100) {
					return (
						<span className="bg-slate-400 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
							{statusName}
						</span>
					);
				} else if (value === 150) {
					return (
						<span className="bg-blue-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
							{statusName}
						</span>
					);
				} else {
					return (
						<span className="bg-green-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
							{statusName}
						</span>
					);
				}
			},
		},
	];

	return (
		<>
			<div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
				<div className="bg-white rounded-2xl shadow-2xl p-5 md:p-6 2xl:px-7">
					<h1 className="font-bold uppercase text-xl mt-2">
						Budget Plan Petani
					</h1>

					<nav
						className="flex my-5 py-4 px-2 md:px-5 text-gray-700 rounded-lg border bg-blue-50 border-blue-100"
						aria-label="Breadcrumb">
						<ol className="inline-flex items-center space-x-1 md:space-x-3">
							<li className="inline-flex items-center">
								<Link
									to="/dashboard/budget-management-plan"
									className="inline-flex items-center text-sms md:text-sm font-semibold text-blue-500 hover:opacity-75">
									<svg
										className="mr-1 md:mr-2 w-4 h-4"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
									</svg>
									Budget Plan
								</Link>
							</li>
							<li>
								<div className="flex items-center">
									<svg
										className="w-6 h-6 text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
											clipRule="evenodd"></path>
									</svg>
									<span className="ml-1 text-sms md:text-sm font-bold text-slate-800 hover:opacity-75 cursor-pointer">
										Budget Plan Petani
									</span>
								</div>
							</li>
						</ol>
					</nav>

					<div className="flex flex-col md:flex-row justify-between items-center p-3 md:px-5 md:py-4 border rounded-2xl mb-5">
						<div className="flex items-center mb-3 md:mb-0">
							<span className="flex items-center justify-center w-18 h-18 2xl:w-20 2xl:h-20 bg-gray-200 text-gray-400 rounded-lg text-xl">
								<i className="fa-solid fa-user"></i>
							</span>
							<div className="flex flex-col ml-4">
								<h3 className="font-bold text-2xl">
									{dataBugdetPlan?.fullname_var}
								</h3>
								<span className="text-base">
									[{dataBugdetPlan?.land_code_var}]{" "}
									{dataBugdetPlan?.land_name_var}
								</span>
							</div>
						</div>
						<div className="flex flex-col text-center md:text-right">
							<h4 className="font-medium text-sm">Total Budget Plan</h4>
							<span className="text-2xl text-lightcayn font-bold">
								Rp{Helper.amount(dataBugdetPlan?.budget_plan_var)}
							</span>
						</div>
					</div>

					<div className="mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
						<h2 className="font-bold text-xl">Daftar Saprodi</h2>
						<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
							<SearchField
								onChange={handleSearch}
								placeholder="Cari budgetplan..."
							/>
							<ButtonAdd onClick={() => showModalForm(true)} />
						</div>
					</div>
					{filterData && (
						<TableUser dataTable={filterData} columnTable={columns} />
					)}
				</div>
			</div>

			{modalDetail && (
				<BudgetDetailApprovalModal data={selectedData!} onCancel={resetForm} />
			)}
			{modalForm && (
				<BPDetailForm
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
					data={selectedData!}
				/>
			)}
			{loader && <Loader />}
		</>
	);
};

export default BudgetPlanDetail;
