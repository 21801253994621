import axios from "axios"
import { baseURL } from "./common-api"


export const getOneUser = async (token, phoneNumber) => {
	try {
		const data = await axios.get(`${baseURL}/api/user/get-one?phone_number_int=${phoneNumber}`, {
			headers: {
				authorization: `Bearer ${token}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const getOneUserByID = async (token, userID) => {
	try {
		const data = await axios.get(`${baseURL}/api/user/get-one?id_seq=${userID}`, {
			headers: {
				authorization: `Bearer ${token}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const getAllUser = async (token) => {
	try {
		const data = await axios.get(baseURL + '/api/user/get-all', {
			headers: {
				authorization: `Bearer ${token}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const getAllPetani = async () => {
	try {
		const data = await axios.get(baseURL + '/api/user/get?user_group_id_int=4&status_int=1', {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const getAllUserforModal = async (commodity_id, period_plant_txt) => {

	try {
		const data = await axios.get(baseURL + `/api/user/get-modal-user?commodity_id=${commodity_id}&period_plant_txt=${period_plant_txt}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const getAllFAforModal = async (commodity_id, period_plant_txt) => {

	try {
		const data = await axios.get(baseURL + `/api/user/get-modal-fa?commodity_id=${commodity_id}&period_plant_txt=${period_plant_txt}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return data;
	} catch (error) {
		if (error.response) return error.response;
		else return JSON.parse(JSON.stringify(error));
	}
};

export const createNewUser = async (payload) => {
	try {
		const data = await axios.post(baseURL + '/api/user/create', payload, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}


export const updateUser = async (userID, payload) => {
	try {
		const data = await axios.patch(baseURL + '/api/user/update/' + userID, payload, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const deleteUser = async (userID) => {
	try {
		const data = await axios.delete(baseURL + '/api/user/delete/' + userID, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const disableUser = async (userID) => {
	try {
		const data = await axios.delete(baseURL + '/api/user/disable/' + userID, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const enableUser = async (userID) => {
	try {
		const data = await axios.delete(baseURL + '/api/user/enable/' + userID, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const getUserGroupDropdown = async (userGroupID) => {
	try {
		const payload = {
			user_group_id_int: userGroupID
		}

		const data = await axios.post(baseURL + '/api/user/dropdown', payload, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const dropdownFarmer = async () => {
	try {
		const data = await axios.get(baseURL + '/api/land-commodity/dropdownFarmer', {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const dropdownFarmerbyFA = async (id_seq) => {
	try {
		const data = await axios.get(baseURL + `/api/land-commodity/dropdownFarmerbyFA?user_id_int=${id_seq}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		})
		return data
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
}

export const getUserDownload = async (
	// userID: number,
	// landCode: string,
	// period: any,
) => {
	try {
		const payload = {
			// 	user_id_int: userID,
			// 	land_code_var: landCode,
			// 	period_plant_txt: period,
		};

		const response = await axios.post(
			baseURL + '/api/user/download',
			payload,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				responseType: "blob",
			},
		);
		return response;
	} catch (error) {
		if (error.response) return error.response
		else return JSON.parse(JSON.stringify(error))
	}
};