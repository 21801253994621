import axios from "axios"
import { baseURL } from "./common-api"

export interface IUserGroup {
    id_seq?: number,
    group_name_var: string,
    created_by_var?: string,
    updated_by_var?: string
    user_group_name? : string,          // for dropdown
}

//#region 
export const getOneUserGroup = async (token: string, idSeq: number) => {
    try {
        const data = await axios.get(`${baseURL}/api/usergroup/get-one?id_seq=${idSeq}`, {
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getAllUserGroup = async (token: string) => {
    try {
        const data = await axios.get(baseURL + '/api/usergroup/get-all', {
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewUserGroup = async (payload: IUserGroup) => {
    try {
        const data = await axios.post(baseURL + '/api/usergroup/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateUserGroup = async (idSeq: number, payload: IUserGroup) => {
    try {
        const data = await axios.patch(baseURL + '/api/usergroup/update/' + idSeq, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteUserGroup = async (idSeq: number) => {
    try {
        const data = await axios.delete(baseURL + '/api/usergroup/delete/' + idSeq, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error)) 
    }
}
//#endregion
