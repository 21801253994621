import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableUser from "../../components/tables/Table";
import { ButtonAdd } from "../../components/button/CustomButton";
import { DropdownLand } from "../../components/dropdown/SimpleDropdown";

import Loader from "../../components/modal/Loader";

import {
	deleteCommodity,
	getAllCommodity,
	updateCommodity,
} from "../../api/commodity-api";

import { ICultivatioLandProcess, createNewCultivation, getAllCultivationbyLandProcess, updateCultivationLandProcess } from "@api/cultivationLandprocess-api";
import ModalFormCultivationLandProcess from "@components/modal/ModalFormCultivationLandProcess";
import dayjs from "dayjs";
import SearchField from "../../components/SearchField";
import { ActionType } from "../../reduxs/action/actions";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalProvider";
import { IRecentPage } from "../../context/ContextInterface";
import { getPayloadRecentActivity } from "../../helpers/helper";
import ModalDetailActivity from "@components/modal/ModalDetailActivity";

const TITLE_BAR = "Cultivation Management / Cultivation";

type Props = {
	user?: any;
};

const CultivationProcessingManagement: React.FC<Props> = ({ user }) => {
	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [originData, setOriginData] = useState<ICultivatioLandProcess[] | []>([]);
	const [filterData, setFilterData] = useState<ICultivatioLandProcess[] | []>([]);
	const [selectedData, setSelectedData] = useState<ICultivatioLandProcess | null>(null);
	const [modalDetailActivity, showModalDetailActivity] = useState(false);

	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

	let navigate = useNavigate();
	useEffect(() => {
		const payload: IRecentPage = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	const fetchData = useCallback(async () => {
		showLoader(true);
		const res = await getAllCultivationbyLandProcess();

		showLoader(false);
		console.log("Culti Management :", res);
		if (res.data) {
			if (res.data.code === 0) {
				setOriginData(res.data.data);
				setFilterData(res.data.data);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData, dispatch]);

	const handleEditData = (selectedData: ICultivatioLandProcess) => {
		setSelectedData(selectedData);
		setIsUpdate(true);
		showModalForm(true);
	};


	const handleViewBudgetPlan = (selectedData: ICultivatioLandProcess) => {
		let path = `/dashboard/budget-management-plan/${selectedData.financial_scheme_id_int}`;
		navigate(path);
	};

	const handleCloseModal = () => {
		setSelectedData(null);
		showModalDetailActivity(false);
	};

	const handleReceiveDataForm = async (data: FormData) => {
		showLoader(true);
		let res = null;
		if (!isUpdate) {
			data.append("created_by_var", user?.fullname_var!);

			res = await createNewCultivation(data);

			var payload = getPayloadRecentActivity(
				1,
				"Cultivation Land Process",
				// data.commodity_name_var,
				"",
				user.fullname_var,
				pathname,
			);
		}
		else {
			data.append("updated_by_var", user?.fullname_var!);

			let id_seq 
			if (data.get("isDeleted") === null) {
				data.append("isDeleted", 'false');
				id_seq = selectedData.id_seq
			} else {
				id_seq= Number(data.get("id_seq"));
			}			

			res = await updateCultivationLandProcess(id_seq, data);

			console.log('res update ', res);
			handleCloseModal()
			payload = getPayloadRecentActivity(
				2,
				"Cultivation Land Process",
				"",
				user?.fullname_var,
				pathname,
			);
			
		}

		console.log("Create/Update Culti :", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				dispatchCalActivity({ type: "push", payload });

				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	// const handleDeleteData = async (data: ICultivatioLandProcess) => {
	// 	showLoader(true);
	// 	const res = await deleteCommodity(data.id_seq!);

	// 	console.log("DELETE Culti :", res);
	// 	if (res.data) {
	// 		if (res.data.code === 0) {
	// 			toast.success(res.data.message);

	// 			const payload = getPayloadRecentActivity(
	// 				3,
	// 				"Cultivation",
	// 				data.commodity_name_var,
	// 				user.fullname_var,
	// 				pathname,
	// 			);
	// 			dispatchCalActivity({ type: "push", payload });

	// 			resetForm();
	// 			fetchData();
	// 		} else {
	// 			toast.error(res.data.message);
	// 			showLoader(false);
	// 		}
	// 	} else {
	// 		toast.error(res.status + " " + res.message || "Connection timeout");
	// 	}
	// };

	const resetForm = () => {
		setSelectedData(null);
		setIsUpdate(false);
		showModalForm(false);
		showLoader(false);
		showModalDetailActivity(false);

	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		const newData = [...originData];
		if (event.target.value) {
			const filtered = newData.filter((item) => {
				return (
					item.fullname_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.source_funding_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.land_name_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.land_code_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.commodity_name_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					//   item.commodity_type_var
					//     ?.toLowerCase()
					//     .includes(event.target.value.toLowerCase()) ||
					item.variety_name
						?.toLowerCase()
						?.includes(event.target.value.toLowerCase()) ||
					item.start_planting_dtm
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.period_plant_txt
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.harvest_prediction_int
						?.toString()
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.file_template_txt
						?.toString()
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.planting_method_int
						?.toString()
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.planting_phase_int
						?.toString()
						?.toLowerCase()
						.includes(event.target.value.toLowerCase())
				);
			});

			setFilterData(filtered);
		} else {
			setFilterData(originData);
		}
	};

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="pl-4 text-left">{row.index + 1}.</div>
			),
		},
		{
			Header: "Petani",
			accessor: "fullname_var",
		},
		{
			Header: "Lahan",
			accessor: "land_code_var",
			Cell: ({ value, row }: any) => {
				const data = row.original;
				return (
					<span>
						[{value}] {data?.land_name_var}
					</span>
				);
			},
		},
		{
			Header: "Komoditas",
			accessor: "commodity_name_var",
		},
		{
			Header: "Varietas",
			accessor: "variety_name",
		},
		// {
		//   Header: "Tipe",
		//   accessor: "commodity_type_var",
		// },
		// {
		//   Header: "Mulai Tanam",
		//   accessor: "start_planting_dtm",
		//   Cell: ({ value }: any) => dayjs(value).format("YYYY-MM-DD"),
		// },
		{
			Header: "Periode Tanam",
			accessor: "period_plant_txt",
		},
		// {
		//     Header: 'Prediksi Panen',
		//     accessor: 'harvest_prediction_int'
		// },
		{
			Header: "Metode Tanam",
			accessor: "name_planting_var",
		},
		// {
		//   Header: "Fase Tanam",
		//   accessor: "name_phase_var",
		// },
		{
			Header: "Sumber Dana",
			accessor: "source_funding_var",
		},
		{
			Header: "Template",
			accessor: "file_template_txt",
		},
		{
			Header: "Status",
			Footer: "Status",
			accessor: "status_pra_tanam",
			Cell: ({ value }: any) =>
				parseInt(value) === 1 ? (
					<span className="px-2 py-1 font-medium text-green-800 bg-green-100 rounded-xl">
						Active
					</span>
				) :  parseInt(value) === 10 ? (
					<span className="px-2 py-1 font-medium text-purple-800 bg-purple-100 rounded-xl">
						Merged
					</span>
				) : (
					<span className="px-2 py-1 font-medium text-red-800 bg-red-100 rounded-xl">
						Inactive
					</span>
				),
		},
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }: any) => {
				const data = row.original;
				if (user?.user_group_id_int === 1 || user?.user_group_id_int === 2) {
					if (data.source_funding_var === 'TPPAI') {
						return (
							<DropdownLand
								data={data}
								// onEdit={() => handleEditData(data)}
								// onDelete={() => handleDeleteData(data)}
								onBudgetPlan={() => handleViewBudgetPlan(data)}
								onActivity={() => {
									setSelectedData(data);
									showModalDetailActivity(true);
									setIsUpdate(true);

								} } onEdit={undefined} onDelete={undefined}							/>
						);
					} else {
						return (
							<DropdownLand
								data={data}
								// onEdit={() => handleEditData(data)}
								// onDelete={() => handleDeleteData(data)}
								onActivity={() => {
									setSelectedData(data);
									showModalDetailActivity(true);
									setIsUpdate(true);
								} } onEdit={undefined} onDelete={undefined}
							/>
						);
					}
				} else {
					return <></>;
				}
			},
		},
	];

	return (
		<div className="flex flex-col p-5 mb-auto item-center md:p-6 2xl:p-10">
			<div className="w-full p-5 bg-white shadow-2xl rounded-2xl md:p-6">
				<div className="flex flex-col items-center justify-between mb-5 space-y-3 md:flex-row md:space-y-0">
					<h1 className="text-xl font-bold uppercase">List Cultivation</h1>
					<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
						<SearchField
							onChange={handleSearch}
							placeholder="Cari data cultivation..."
						/>
						{user?.user_group_id_int === 1 || user?.user_group_id_int === 2 ? (
							<ButtonAdd onClick={() => showModalForm(true)} />
						) : null}
					</div>
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{modalForm && (
				<ModalFormCultivationLandProcess
					data={selectedData!}
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
				/>
			)}
			{modalDetailActivity && (
				<ModalDetailActivity 
				data={selectedData!} 
				onCancel={resetForm} 
				onClose={handleCloseModal} 
				onSubmit={handleReceiveDataForm} 
				/>
			)}
			{loader && <Loader />}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps, null)(CultivationProcessingManagement);
