import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableUser from "../../components/tables/Table";
import { ButtonAdd } from "../../components/button/CustomButton";
import Loader from "../../components/modal/Loader";
import dayjs from "dayjs";
import SearchField from "../../components/SearchField";
import { ActionType } from "../../reduxs/action/actions";
import ModalFormPanen, {
	ModalFormPanenDetail,
} from "../../components/modal/ModalFormPanen";
import { Link, useLocation } from "react-router-dom";
import {
	createNewDataPanen,
	DataPanenApprovalApi,
	deleteDataPanen,
	getAllDataPanen,
	IDataPanen,
	updateDataPanen,
	getSumAllDataPanen,
} from "../../api/datapanen-api";
import { IUser } from "../../hooks/useDropdownFarmer";
import Dropdown from "../../components/dropdown/Dropdown";
import { GlobalContext } from "../../context/GlobalProvider";
import { IRecentPage } from "../../context/ContextInterface";
import { getPayloadRecentActivity } from "../../helpers/helper";
// import { DropdownLand } from "@components/dropdown/SimpleDropdown";

const TITLE_BAR = "Cultivation Management / Total Panen";

type Props = {
	user?: IUser;
};

const TotalDataPanenManagement: React.FC<Props> = ({ user }) => {
	const navigate = useNavigate(); 
	const [loader, showLoader] = useState(false);
	const [modalForm, showModalForm] = useState(false);
	const [modalDetail, showModalDetail] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [originData, setOriginData] = useState<IDataPanen[] | []>([]);
	const [filterData, setFilterData] = useState<IDataPanen[] | []>([]);
	const [selectedData, setSelectedData] = useState<IDataPanen | null>(null);

	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

	useEffect(() => {
		const payload: IRecentPage = {
			id: new Date().getTime(),
			title: TITLE_BAR,
			path: pathname,
			url: window.location.href,
		};

		dispatchCalPage({ type: "push", payload });
		dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
	}, [dispatch, dispatchCalPage, pathname]);

	const fetchData = useCallback(async () => {
		showLoader(true);
		const res = await getSumAllDataPanen();

		showLoader(false);
		console.log("Panen Management :", res);
		if (res.data) {
			if (res.data.code === 0) {
				setOriginData(res.data.data);
				setFilterData(res.data.data);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData, dispatch]);

	// const handleEditData = (selectedData: IDataPanen) => {
	// 	setSelectedData(selectedData);
	// 	setIsUpdate(true);
	// 	showModalForm(true);
	// };

	const handleLogHarvest = (selectedData: IDataPanen) => {
		let path = `/dashboard/cultivation-management-log-panen/${selectedData.commodity_name_var}/${selectedData.variety_name}/${selectedData.period_plant_txt}`
		navigate(path);
	};

	const handleReceiveDataForm = async (data: FormData) => {
		showLoader(true);

		let res = null;
		if (!isUpdate) {
			data.append("created_by_var", user?.fullname_var!);
			res = await createNewDataPanen(data);

			var payload = getPayloadRecentActivity(
				1,
				"Data Panen",
				"",
				user?.fullname_var,
				pathname,
			);
		} else {
			data.append("updated_by_var", user?.fullname_var!);

			if (data.get("isDeleted") === null) {
				data.append("isDeleted", 'false');
			}

			res = await updateDataPanen(selectedData?.id_seq!, data);


			payload = getPayloadRecentActivity(
				2,
				"Data Panen",
				"",
				user?.fullname_var,
				pathname,
			);
		}

		console.log("Create/Update Data Panen :", res);

		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				dispatchCalActivity({ type: "push", payload });

				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleDeleteData = async (data: IDataPanen) => {
		showLoader(true);
		const res = await deleteDataPanen(data.id_seq!);

		console.log("Delete Data Panen :", res);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);

				const payload = getPayloadRecentActivity(
					3,
					"Data Panen",
					data.id_seq,
					user?.fullname_var,
					pathname,
				);
				dispatchCalActivity({ type: "push", payload });

				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const handleApply = async (data: IDataPanen, request: boolean) => {
		showLoader(true);
		const payload = {
			updated_by_var: user!.fullname_var,
		};

		let res;
		if (request) {
			res = await DataPanenApprovalApi.request(data.id_seq!, payload);
		} else {
			res = await DataPanenApprovalApi.draft(data.id_seq!, payload);
		}

		console.log("BUDGETPLAN DETAIL APPLY ==>", res);
		if (res.data) {
			if (res.data.code === 0) {
				toast.success(res.data.message);
				fetchData();
				resetForm();
			} else {
				toast.error(res.data.message);
				showLoader(false);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}
	};

	const resetForm = () => {
		setSelectedData(null);
		setIsUpdate(false);
		showModalForm(false);
		showLoader(false);
		showModalDetail(false);
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		const newData = [...originData];
		if (event.target.value) {
			const filtered = newData.filter((item) => {
				return (
					item.commodity_name_var
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.period_plant_txt
						?.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					item.variety_name
						?.toLowerCase()
						.includes(event.target.value.toLowerCase())
				);
			});

			setFilterData(filtered);
		} else {
			setFilterData(originData);
		}
	};

	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="text-left pl-4">{row.index + 1}.</div>
			),
		},
		{
			Header: "Komoditas",
			accessor: "commodity_name_var",
		},
		{
			Header: "Varietas",
			accessor: "variety_name",
		},
		{
			Header: "Periode Tanam",
			accessor: "period_plant_txt",
			Cell: ({ row, value }: any) => {
				const data = row.original;
				return value || data.period_plant_int;
			},
		},
		{
			Header: "Hasil Panen (kg)",
			accessor: "harvest_flo",
		},
		// {
		// 	Header: "Status Approval",
		// 	accessor: "status_int",
		// 	Cell: ({ value, row }: any) => {
		// 		const data = row.original;
		// 		const statusName = data.status_name_var;

		// 		// 1 active, -5 inactive, 100 = draft, 150 = request to aproval, 400 rejected
		// 		if (value === -5 || value === 400) {
		// 			return (
		// 				<span className="bg-red-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
		// 					{statusName || "Rejected"}
		// 				</span>
		// 			);
		// 		} else if (value === 100) {
		// 			return (
		// 				<span className="bg-slate-400 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
		// 					{statusName || "Draft"}
		// 				</span>
		// 			);
		// 		} else if (value === 150) {
		// 			return (
		// 				<span className="bg-blue-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
		// 					{statusName || "Approval"}
		// 				</span>
		// 			);
		// 		} else {
		// 			return (
		// 				<span className="bg-green-500 text-white px-3 py-1 font-semibold text-xs rounded-full shadow capitalize">
		// 					{statusName || "Approved"}
		// 				</span>
		// 			);
		// 		}
		// 	},
		// },
		{
			Header: "Action",
			Footer: "Action",
			Cell: ({ row }: any) => {
				const data = row.original;
				const status = data.status_int;
				const userId = user?.user_group_id_int;

				// if (userId !== 5) {
				// 	return (
						// <Link
						// to={
						// 		`/dashboard/cultivation-management-log-panen/${data.commodity_name_var}/${data.variety_name}/${data.period_plant_txt}`
						// 		// `/dashboard/cultivation-management-log-panen/`+
						// 		// data.commodity_name_var + data.variety_name + data.period_plant_txt
						// 	}>
						// 	<i className="fa-solid fa-eye hover-anim cursor-pointer hover:text-blue-500 text-base"></i>
						// </Link>
				// 	);
				// }

				return (
					<Dropdown
						// onDetail={() => {
						// 	setSelectedData(data);
						// 	showModalDetail(true);
						// }}
						// onDelete={() => handleDeleteData(data)}
						onHarvest={() => handleLogHarvest(data)}
						// onApply={status === 100 ? () => handleApply(data, true) : undefined}
						// onCancelApply={
						// 	status === 150 ? () => handleApply(data, false) : undefined
						// }
					/>
				);
			},
		},
	];

	return (
		<div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
			<div className="w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6">
				<div className="mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
					<h1 className="font-bold uppercase text-xl">Data Total Panen</h1>
					<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
						<SearchField
							onChange={handleSearch}
							placeholder="Cari data panen..."
						/>
						{user?.user_group_id_int === 5 && (
							<ButtonAdd onClick={() => showModalForm(true)} />
						)}
					</div>
				</div>
				<TableUser dataTable={filterData} columnTable={columns} />
			</div>

			{modalForm && (
				<ModalFormPanen
					data={selectedData!}
					onCancel={resetForm}
					onSubmit={handleReceiveDataForm}
				/>
			)}
			{modalDetail && (
				<ModalFormPanenDetail data={selectedData!} onCancel={resetForm} />
			)}
			{loader && <Loader />}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps, null)(TotalDataPanenManagement);
