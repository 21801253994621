import axios from "axios";
import { baseURL } from "./common-api";

export interface IVariety {
  id_seq?: number;
  commodity_id: number;
  name: string;
  commodity_name_var: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export const dropdownCommodityName = async () => {
  try {
    const data = await axios.get(baseURL + "/api/commodity/dropdownCommodity", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const getAllVariety = async () => {
  try {
    const data = await axios.get(baseURL + "/api/variety/get-all", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNewVariety = async (payload: IVariety) => {
  try {
    const data = await axios.post(baseURL + "/api/variety/create", payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const updateVariety = async (varietyID: number, payload: IVariety) => {
  try {
    const data = await axios.patch(
      baseURL + "/api/variety/update/" + varietyID,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteVariety = async (varietyID: number) => {
  try {
    const data = await axios.delete(
      baseURL + "/api/variety/delete/" + varietyID,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const disableVariety = async (varietyID: number) => {
  try {
    const data = await axios.delete(
      baseURL + "/api/variety/disable/" + varietyID,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const dropdownVariety = async () => {
  try {
    const data = await axios.get(baseURL + "/api/variety/dropdownVariety", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
