import { useState } from "react"
import { CancelButton, SaveButton } from "../button/CustomButton"
import { useForm } from "react-hook-form";
import ErrorField from "../ErrorField";

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3  mt-1'

const ModalFormChangePassword = ({onCancel, onSubmit}) => {
    const [errPass, showErrPas] = useState(false)

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({ criteriaMode: "all" });

    const onValid = (payload) => {
        const {password_var, confirm_pass} = payload

        if(password_var !== confirm_pass){
            showErrPas(true)
            return
        }

        const data = {
            password_var: password_var,
        }

        onSubmit(data)
    }
    
    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-soft w-10/12 md:w-1/2 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8 sticky inset-0'>Form Ganti Password Baru</h1>
                <form onSubmit={handleSubmit(onValid)}>
                    <div className='flex flex-col sm:flex-row'>
                        <div className={containerInput}>
                            <label>Password Baru</label>
                            <input type='password' name='password_var' className={inputText}
                            {...register("password_var", {
                                required: "Password is required.",
                                minLength: { value: 5, message: "Password must exceed 4 characters."}
                            })} onChange={() => showErrPas(false)} />
                            <ErrorField name='password_var' errors={errors} />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Konfirmasi Password Baru</label>
                            <input type='password' name='confirm_pass' className={inputText}
                            {...register("confirm_pass", {
                                required: "Confirm password is required."
                            })} onChange={() => showErrPas(false)}  />
                            <ErrorField errors={errors} name='confirm_pass' />
                        </div>
                    </div>
                    
                    {errPass && <p className='text-red-500 mt-3 mb-3'>* Password not match</p>}

                    <div className='flex justify-end mt-8'>
                        <CancelButton type='reset' onClick={onCancel} />
                        <SaveButton type='submit' />
                    </div>
                </form>
        
                {/* End Body */}
            </div>
            {/* End Modal Content */}

        </div>
    )
}

export default ModalFormChangePassword