import axios from 'axios'
import { baseURL } from './common-api'

export interface ILandResearch {
    id_seq: number,
    user_id_int?: number,
    land_code_var: string,
    land_name_var: string,
    address_land_txt?: string,
    district_var?: number,
    city_var?: number,
    province_var?: number,
    dis_name?: string,
    city_name?: string,
    prov_name?: string,
    area_var?: string,
    land_coordinates_txt?: string,
    status_int?: number,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string
}


export const getAllLandResearch = async () => {
    try {
        const data = await axios.get(baseURL + '/api/land-research/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const getOneLandResearch = async (landCode: string) => {
    try {
        const data = await axios.get(baseURL + '/api/land-research/get-one?land_code_var=' + landCode, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const getDetailLandResearch = async (userID: number, landCode: string) => {
    try {
        const data = await axios.get(baseURL + '/api/land-research/get-one-detail?user_id_int=' + userID + '&land_code_var=' + landCode, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

// export const getLandListByUser = async (userID: number) => {
//     try {
//         const data = await axios.get(baseURL + '/api/land-research/get-list-user?user_id_int=' + userID, {
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })
//         return data
//     } catch (error: any) {
//         if(error.response) return error.response
//         else return JSON.parse(JSON.stringify(error))  
//     }
// }

// export const getDetailLandListByUser = async (userID: number) => {
//     try {
//         const data = await axios.get(baseURL + '/api/land-research/list-detail-user?user_id_int=' + userID, {
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })
//         return data
//     } catch (error: any) {
//         if(error.response) return error.response
//         else return JSON.parse(JSON.stringify(error))  
//     }
// }

export const createLandResearch = async (payload: ILandResearch) => {
    try {
        const data = await axios.post(baseURL + '/api/land-research/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const updateLandResearch = async (landID: number, payload: ILandResearch) => {
    try {
        const data = await axios.patch(baseURL + '/api/land-research/update/' + landID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const deleteLandResearch = async (landID: number) => {
    try {
        const data = await axios.delete(baseURL + '/api/land-research/delete/' + landID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const disableLandResearch = async (landID: number) => {
    try {
        const data = await axios.delete(baseURL + '/api/land-research/disable/' + landID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}


// export const getAllLandCommodity = async () => {
//     try {
//         const data = await axios.get(baseURL + '/api/land-commodity/get-all', {
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })
//         return data
//     } catch (error: any) {
//         if(error.response) return error.response
//         else return JSON.parse(JSON.stringify(error))  
//     }
// }

// export const getOneLandCommodity = async (landCode: string) => {
//     try {
//         const data = await axios.get(baseURL + '/api/land-commodity/get-one?land_code_var=' + landCode, {
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })
//         return data
//     } catch (error: any) {
//         if(error.response) return error.response
//         else return JSON.parse(JSON.stringify(error))  
//     }
// }

// export const createLandCommodity = async (payload: any) => {
//     try {
//         const data = await axios.post(baseURL + '/api/land-commodity/create', payload, {
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })
//         return data
//     } catch (error: any) {
//         if(error.response) return error.response
//         else return JSON.parse(JSON.stringify(error))  
//     }
// }

// export const updateLandCommodity = async (landCode: string, payload: any) => {
//     try {
//         const data = await axios.post(baseURL + '/api/land-commodity/update?land_code_var=' + landCode, payload, {
//             headers: {
//                 authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })
//         return data
//     } catch (error: any) {
//         if(error.response) return error.response
//         else return JSON.parse(JSON.stringify(error))  
//     }
// }