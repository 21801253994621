import { useEffect, useState } from 'react';
// import { getAllLand, getLandListByUser, RatioDividend } from '../api/landarea-api';
import { getAllRatioDividend ,getDropDownbyDividend,getDropDownLandPrice,getRatioListByPeriod,RatioDividend} from '@api/ratiodividend-api';
// import TaskApi from '../api/task-api';

export default function useDropdownRatioDevidend(period_plant_txt: string) {
    const [DataRatioDividend, setDataRatioDividend] = useState<RatioDividend[]>([])
    const [loadingRatioDividend, setLoading] = useState(false)
    const [errorRatioDividend, setError] = useState(null)

    useEffect(() => {
        async function fetchDropdownRatioDevidend() {
			
            setLoading(true)
            let res
            if(period_plant_txt === '0' || period_plant_txt === null || period_plant_txt === undefined){
				res = await getAllRatioDividend()

            }else{
				    res = await getRatioListByPeriod(period_plant_txt)
				}

            console.log("Fetching DropdownRatioDevidend :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataRatioDividend(res.data.data)
                }else{
                    setError(res.data.message)
                }
            }else{
                setError(res.message)
            }
            setLoading(false)
        }

        fetchDropdownRatioDevidend()
    }, [period_plant_txt])

    return {DataRatioDividend, errorRatioDividend, loadingRatioDividend}
}

export function useDropdownPeriodPlant(isRefreshing: boolean = false) {
    const [DataPeriodPlant, setDataPeriodPlant] = useState<RatioDividend[]>([])
    const [loadingPeriodPlant, setLoading] = useState(false)
    const [errorPeriodPlant, setError] = useState(null)

    useEffect(() => {
        async function fetchDropdownPeriodPlant() {
			
            setLoading(true)
            let res
			res = await getDropDownbyDividend()

            console.log("Fetching DropdownPeriodPlant :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataPeriodPlant(res.data.data)
                }else{
                    setError(res.data.message)
                }
            }else{
                setError(res.message)
            }
            setLoading(false)
        }

        fetchDropdownPeriodPlant()
    }, [isRefreshing]);

    return {DataPeriodPlant, errorPeriodPlant, loadingPeriodPlant}
}

export function useDropdownPeriodLandPrice(isRefreshing: boolean = false) {
    const [DataPeriodPlant, setDataPeriodPlant] = useState<RatioDividend[]>([])
    const [loadingPeriodPlant, setLoading] = useState(false)
    const [errorPeriodPlant, setError] = useState(null)

    useEffect(() => {
        async function fetchDropdownPeriodPlant() {
			
            setLoading(true)
            let res
			res = await getDropDownLandPrice()

            console.log("Fetching DropdownPeriodPlant :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataPeriodPlant(res.data.data)
                }else{
                    setError(res.data.message)
                }
            }else{
                setError(res.message)
            }
            setLoading(false)
        }

        fetchDropdownPeriodPlant()
    }, [isRefreshing]);

    return {DataPeriodPlant, errorPeriodPlant, loadingPeriodPlant}
}