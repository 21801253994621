export const ActionType = {
    SET_USER_DATA: "SET_USER_DATA",
    UPDATE_USER_DATA: "UPDATE_USER_DATA",
    SET_DATETIME_NOW: "SET_DATETIME_NOW",
    SET_MENU_LIST: 'SET_MENU_LIST',
    SET_USER_GROUP_LIST: 'SET_USER_GROUP_LIST',
    SET_COLLAPSE: "SET_COLLAPSE",
    SET_TITLE_BAR: "SET_TITLE_BAR",
}

export const setUserData = (payload) => {
    return {
        type: ActionType.SET_USER_DATA,
        payload: payload
    }
}

export const updateUserData = (key, value) => {
    return {
        type: ActionType.UPDATE_USER_DATA,
        key: key,
        value: value
    }
}

export const setTimeNow = (momentTime) => {
    return {
        type: ActionType.SET_DATETIME_NOW,
        data: momentTime
    }
}

export const setMenuList = (data) => {
    return {
        type: ActionType.SET_MENU_LIST,
        data: data
    }
}

export const setUserGroupList = (data) => {
    return {
        type: ActionType.SET_USER_GROUP_LIST,
        data: data
    }
}

export const setCollapse = (value) => {
    return {
        type: ActionType.SET_COLLAPSE,
        value: value
    }
}

export const setTitleBar = (value) => {
    return {
        type: ActionType.SET_TITLE_BAR,
        value: value
    }
}