import axios from "axios";
import { baseURL } from "./common-api";

export interface IParameterPascaPanenRef {
  id_seq?: number;
  param: string;
  values?: any;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export const getAll = async () => {
  try {
    const data = await axios.get(
      baseURL + "/api/cultivation-management/parameter-pasca-panen-ref/get-all",
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNew = async (payload: IParameterPascaPanenRef) => {
  try {
    const data = await axios.post(
      baseURL + "/api/cultivation-management/parameter-pasca-panen-ref/create",
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const update = async (
  productTypeId: number,
  payload: IParameterPascaPanenRef,
) => {
  try {
    const data = await axios.patch(
      baseURL +
        "/api/cultivation-management/parameter-pasca-panen-ref/update/" +
        productTypeId,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteData = async (productTypeId: number) => {
  try {
    const data = await axios.delete(
      baseURL +
        "/api/cultivation-management/parameter-pasca-panen-ref/delete/" +
        productTypeId,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
