import axios from "axios";
import { baseURL } from "./common-api";

export interface MaterialSupport {
  id_seq?: number;
  user_id_int: number;
  land_code_var?: string;
  material_support_name_var: string;
//   material_support_type_var: string;
  start_planting_dtm?: string;
  period_plant_txt?: string;
  harvest_prediction_int?: number;
  planting_method_int?: number;
  planting_phase_int?: number;
  source_funding_var: string;
  file_template_txt: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
  fullname_var?: string;
  land_name_var?: string;
  material_support_id?: number;
  variety_id?: number;
  variety_name?: string;
}


export const createNewMaterialSupport = async (payload: any) => {
	try {
	  const data = await axios.post(
		baseURL + "/api/material-support/create",
		payload,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };
  
  export const updateMaterialSupport = async (
	MaterialSupportID: string,
	payload: any,
  ) => {
	try {
	  const data = await axios.patch(
		baseURL + "/api/material-support/update/" + MaterialSupportID,
		payload,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };
  
  export const deleteMaterialSupport = async (MaterialSupportID: number) => {
	try {
	  const data = await axios.delete(
		baseURL + "/api/material-support/delete/" + MaterialSupportID,
		{
		  headers: {
			authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
			Accept: "application/json",
			"Content-Type": "application/json",
		  },
		},
	  );
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const getAllMaterialSupport = async () => {
	try {
	  const data = await axios.get(baseURL + "/api/material-support/get-all", {
		headers: {
		  authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
	  });
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const getAllDistributionMaterialSupport = async (period_plant_txt, commodity_id) => {
	try {
	  const data = await axios.get(baseURL + `/api/material-support/get-all-distribution?period_plant_txt=${period_plant_txt}&commodity_id=${commodity_id}`, {
		headers: {
		  authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
	  });
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };

  export const dropdownMaterialSupport = async () => {
	try {
	  const data = await axios.get(baseURL + "/api/material-support/dropdownMaterialSupport", {
		headers: {
		  authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
	  });
	  return data;
	} catch (error: any) {
	  if (error.response) return error.response;
	  else return JSON.parse(JSON.stringify(error));
	}
  };
  //#endregion
  