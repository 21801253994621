import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IPlantingMethod,
  createNewPlantingMethod,
  deletePlantingMethod,
  getAllPlantingMethod,
  updatePlantingMethod,
} from "@api/plantingmethod-api";
import { ButtonAdd } from "@components/button/CustomButton";
import Dropdown from "@components/dropdown/Dropdown";
import ModalFormPlantingMethod from "@components/modal/operatingparameter/taskreference/ModalFormPlantingMethod";
import Loader from "@components/modal/Loader";
import SearchField from "@components/SearchField";
import TableUser from "@components/tables/Table";
import { ActionType } from "@reduxs/action/actions";
import { GlobalContext } from "@context/GlobalProvider";
import { IRecentPage } from "@context/ContextInterface";
import { getPayloadRecentActivity } from "@helpers/helper";

const TITLE_BAR = "Operating Parameter / Planting Method";

type Props = {};

const PlantingMethod: React.FC<Props> = () => {
  const [loader, showLoader] = useState(false);
  const [modalForm, showModalForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  // eslint-disable-next-line
  const [originData, setOriginData] = useState<IPlantingMethod[] | []>([]);
  const [filterData, setFilterData] = useState<IPlantingMethod[] | []>([]);
  const [selectedData, setSelectedData] = useState<IPlantingMethod | null>(
    null,
  );

  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext);

  useEffect(() => {
    const payload: IRecentPage = {
      id: new Date().getTime(),
      title: TITLE_BAR,
      path: pathname,
      url: window.location.href,
    };

    dispatchCalPage({ type: "push", payload });
    dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR });
  }, [dispatch, dispatchCalPage, pathname]);

  const fetchData = useCallback(async () => {
    showLoader(true);
    const res = await getAllPlantingMethod();

    showLoader(false);
    console.log("BUDGETCATEGORY ==>", res);
    if (res.data) {
      if (res.data.code === 0) {
        setOriginData(res.data.data);
        setFilterData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, dispatch]);

  const handleEditData = (selectedData: any) => {
    setSelectedData(selectedData);
    setIsUpdate(true);
    showModalForm(true);
  };

  const handleReceiveDataForm = async (data: IPlantingMethod) => {
    showLoader(true);

    let res = null;
    if (!isUpdate) {
      data.created_by_var = user.fullname_var;
      res = await createNewPlantingMethod(data);

      var payload = getPayloadRecentActivity(
        1,
        "Planting Method",
        data.id_seq,
        user?.fullname_var,
        pathname,
      );
    } else {
      data.updated_by_var = user.fullname_var;
      res = await updatePlantingMethod(selectedData?.id_seq!, data);

      payload = getPayloadRecentActivity(
        2,
        "Planting Method",
        data.id_seq,
        user?.fullname_var,
        pathname,
      );
    }

    console.log("Planting Method ACTION ==>", res);

    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);

        dispatchCalActivity({ type: "push", payload });

        fetchData();
        resetForm();
      } else {
        toast.error(res.data.message);
        showLoader(false);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const handleDeleteData = async (data: any) => {
    showLoader(true);
    const res = await deletePlantingMethod(data.id_seq!);

    console.log("Planting Method ACTION ==>", res);
    if (res.data) {
      if (res.data.code === 0) {
        toast.success(res.data.message);

        const payload = getPayloadRecentActivity(
          3,
          "Planting Method",
          data.id_seq,
          user?.fullname_var,
          pathname,
        );
        dispatchCalActivity({ type: "push", payload });

        fetchData();
        resetForm();
      } else {
        toast.error(res.data.message);
        showLoader(false);
      }
    } else {
      toast.error(res.status + " " + res.message || "Connection timeout");
    }
  };

  const resetForm = () => {
    setSelectedData(null);
    setIsUpdate(false);
    showModalForm(false);
    showLoader(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const newData = [...originData];
    if (event.target.value) {
      const filtered = newData.filter((item) => {
        return (
          item.name_planting_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.commodity_name_var
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      });

      setFilterData(filtered);
    } else {
      setFilterData(originData);
    }
  };

  const columns = [
    {
      Header: () => <span className="p-4">No</span>,
      accessor: "no",
      Cell: ({ row }: any) => (
        <div className="text-left pl-4">{row.index + 1}.</div>
      ),
    },
    {
      Header: "Nama",
      accessor: "name_planting_var",
    },
    {
      Header: "Komoditas",
      accessor: "commodity_name_var",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status_int",
      Cell: ({ value }: any) =>
        parseInt(value) === 1 ? (
          <span className="bg-green-100 text-green-800 px-2 py-1 rounded-xl font-medium">
            Active
          </span>
        ) : (
          <span className="bg-red-100 text-red-800 px-2 py-1 rounded-xl font-medium">
            Inactive
          </span>
        ),
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }: any) => {
        const data = row.original;
        if (user.user_group_id_int === 5) {
          return (
            <Link to={"/dashboard/budget-management-plan/" + data.id_seq}>
              <i className="fa-solid fa-eye hover-anim cursor-pointer hover:text-blue-500 text-base"></i>
            </Link>
          );
        }
        return (
          <Dropdown
            onEdit={() => handleEditData(data)}
            onDelete={() => handleDeleteData(data)}
          />
        );
      },
    },
  ];

  return (
    <div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
      <div className="w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6">
        <div className="mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
          <h1 className="font-bold uppercase text-xl">Planting Method</h1>
          <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
            <SearchField
              onChange={handleSearch}
              placeholder="Cari budgetplan..."
            />
            {user?.user_group_id_int === 1 || user?.user_group_id_int === 2 ? (
              <ButtonAdd onClick={() => showModalForm(true)} />
            ) : null}
          </div>
        </div>
        <TableUser dataTable={filterData} columnTable={columns} />
      </div>

      {modalForm && (
        <ModalFormPlantingMethod
          data={selectedData!}
          onCancel={resetForm}
          onSubmit={handleReceiveDataForm}
        />
      )}
      {loader && <Loader />}
    </div>
  );
};

export default PlantingMethod;
