import { useEffect, useState } from "react";
import { dropdownChemicalName, IChemical } from "../api/chemical-api";

export default function useDropdownChemical(isRefreshing: boolean = false) {
  const [dataChemical, setDataChemical] = useState<IChemical[]>([]);
  const [loadingChemical, setLoading] = useState(false);
  const [errorChemical, setError] = useState(null);

  useEffect(() => {
    async function fetchDropdownChemical() {
      setLoading(true);
      let res = await dropdownChemicalName();

      console.log("DD Chemical :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataChemical(res.data.data);
        } else {
          setError(res.data.message);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
    }

    fetchDropdownChemical();
  }, [isRefreshing]);

  return { dataChemical, errorChemical, loadingChemical };
}
