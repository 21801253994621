import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { getAllCultivation } from "@api/cultivation-api";
import { ICultivatioLandProcess } from "@api/cultivationLandprocess-api";
import dayjs from "dayjs";
import Loader from "./Loader";
import Select from "react-select";
import useDropdownLand from "@hooks/useDropdownLand";
import useDropdownCultivation from "@hooks/useDropdownFarmerCultivation";
import useDropdownActivity from "@hooks/useDropdownTaskReference";
import { useDropdownParameterPengamatan } from "@hooks/useDropdownParameterPengamatan";
import { selectStylesForm } from "./TaskManagement/ModalTaskNew";
import { useDropdownPeriodPlant } from "@hooks/useDropdownRatioDevidend";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
	data: ICultivatioLandProcess;
	dataUser?: any;
	onCancel: (e: React.MouseEvent) => any;
	onSubmit: (data: ICultivatioLandProcess | any) => void;
};

const ModalFormCultivationLandProcess: React.FC<Props> = ({
	data,
	onCancel,
	onSubmit,
}) => {
	const [userID, setUserID] = useState(data ? data.user_id_int : 0);
	const [PeriodPlant, setPeriodPlant] = useState(data ? data.period_plant_txt : "",);
	const [dataTemplate, setdataTemplate] = useState(data ? data.file_template_txt : '');
	const [varietyId, setVarietyId] = useState(data ? data.variety_id : null);
	const [cultivationId, setcultivationId] = useState(data ? data.id_cultivation_int : 0);
	const userLoggedIn = JSON.parse(localStorage.getItem("pandawa-user"));
	const [isRefreshing, setIsRefreshing] = useState(false);
	const { dataFarmerCultivation, loadingFarmerCultivation } = useDropdownCultivation(PeriodPlant); // eslint-disable-line
	const { dataLand, loadingLand } = useDropdownLand(userID); // eslint-disable-line
	const { DataActivity, loadingActivity } = useDropdownActivity(isRefreshing, dataTemplate)
	const { dataParams, loadingParams, errorParams } = useDropdownParameterPengamatan();
	const [filteredCultivation, setfilteredCultivation] = useState<ICultivatioLandProcess[] | any[]>([]);
	const [selectedValues, setSelectedValues] = useState(data ? data.observation_value : []);
	const [Dataimages, setimages] = useState([]);
	const { DataPeriodPlant, loadingPeriodPlant } = useDropdownPeriodPlant(isRefreshing)
	const [selectedData, setSelectedData] = useState<any>(null);
	const [dataDoc, setDataDoc] = useState<any>(null);
	const [confirmDelete, showConfirmDelete] = useState(false);
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<any>({ criteriaMode: "all" });

	const setdefaultValue = useCallback(
		(obj) => {
			Object.keys(obj).map((key) => {
				if (key === "start_planting_dtm") {
					return setValue(key, dayjs(obj[key]).format("YYYY-MM-DD"));
				}
				else if (key === "period_plant_txt") {
					return setValue(key, obj[key]);
				}
				else {
					return setValue(key, obj[key], { shouldValidate: true });
				}
			});
		},
		[setValue],
	);

	useEffect(() => {
		if (data) {
			setUserID(data.user_id_int);
			setIsRefreshing(true)
			setdefaultValue(data);
		}
	}, [data, setdefaultValue]);

	const onSubmitForm: SubmitHandler<ICultivatioLandProcess> = ({
		user_id_int,
		land_code_var,
		activity_txt,
		date_performed_dtm,
		example_observation_txt,
		material_txt,
		dose_txt,
		images,
		period_plant_txt
	}) => {
		const formData = new FormData();

		formData.append('user_id_int', String(userID));
		formData.append('land_code_var', land_code_var);
		formData.append('activity_txt', activity_txt);
		formData.append('date_performed_dtm', date_performed_dtm);
		formData.append('period_plant_txt', period_plant_txt);
		formData.append('material_txt', material_txt);
		formData.append('dose_txt', dose_txt);
		formData.append('observation_value', JSON.stringify(selectedValues));
		formData.append('example_observation_txt', example_observation_txt);
		formData.append('id_cultivation_int', String(cultivationId));
		if (Dataimages.length > 0) {
			for (let i = 0; i < Dataimages.length; i++) {
				formData.append('images', Dataimages[i]);
			}
		}
		console.log('FormData: ', formData);
		if (onSubmit) {
			onSubmit(formData);
		}
	};

	const transformedOptions = dataParams
		?.filter((fc) => fc.variety_id === varietyId)
		?.map((item) => {
			const options = item?.value_parameter_pengamatan;
			return {
				id_seq: item?.id_seq,
				label: item?.parameter_pengamatan,
				options: options?.map((value) => ({
					value,
					label: value,
				})),
			};
		});

	const handleSelectUser = async (e) => {
		try {
			// await setUserID(Number(e.target.value));
			
			const res = await getAllCultivation();
			const resfilteredCultivation = res.data.data.filter(val => val.user_id_int === Number(e.user_id));

			if (resfilteredCultivation) {
				setfilteredCultivation(resfilteredCultivation)
			}

		} catch (error) {
			// Handle errors if any occur during the process
			console.error('Error:', error.message);
		}
	};

	const uploadImage = (e) => {
		const file = e.target.files;
		setimages(file)
	}

	const handleSelectLand = async (e) => {
		try {
			const filterCultivationbyLand = filteredCultivation.filter(val => val.land_code_var.includes(e.target.value));
			setdataTemplate(filterCultivationbyLand[0].file_template_txt)
			setVarietyId(filterCultivationbyLand[0].variety_id)
			setcultivationId(filterCultivationbyLand[0].id_seq)

		} catch (error) {
			// Handle errors if any occur during the process
			console.error('Error:', error.message);
		}
	};

	const handleSelectChange = (index, selectedOption) => {
		setSelectedValues((prevState) => {
			const updatedValues = [...prevState];
			updatedValues[index] = {
				parameter: selectedOption?.parameter,
				value: selectedOption?.value,
			};
			return updatedValues;
		});
	};

	const handleSelectPeriod = async (e) => {
		try {
			await setPeriodPlant(e.target.value);
		} catch (error) {
			// Handle errors if any occur during the process
			console.error('Error:', error.message);
		}
	};

	return (
		<>
			<div className="modal-form">
				<div className="modal-form-outside" onClick={onCancel}></div>

				<div className="modal-form-content" style={{ maxHeight: "90vh" }}>
					<div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
						<div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
							<i className="fa-brands fa-pagelines flex justify-center items-center"></i>
							<h3>{data ? "Ubah Pra Tanam" : "Tambah Pra Tanam"}</h3>
						</div>
						<button
							type="button"
							className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
							onClick={onCancel}>
							<svg
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"></path>
							</svg>
						</button>
					</div>

					<form
						onSubmit={handleSubmit(onSubmitForm)}
						className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
						<div className="space-y-5">
							{!loadingPeriodPlant && (
								<div className="w-full">
									<label htmlFor="period_plant_txt" className="label-form">
										Periode Tanam
									</label>
									<div className="flex item-center space-x-2">
										<select
											id="period_plant_txt"
											className="input-form"
											{...register("period_plant_txt", {
												required: "This field is required",
											})}
											onChange={(event) =>
												handleSelectPeriod(event)
											}>
											<option value="">- Pilih Periode Tanam -</option>
											{DataPeriodPlant?.map((item, key) => (
												<option key={key} value={item.period_plant_txt}>
													{item.period_plant_txt}
												</option>
											))}
										</select>
									</div>
									<ErrorField errors={errors} name="period_plant_txt" />
								</div>
							)}
							<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
								{!loadingFarmerCultivation && (
									<div className="w-full md:w-1/2">
										<label htmlFor="user_id_int" className="label-form">
											Petani
										</label>
										<Select
											id="user_id_int"
											styles={selectStylesForm}
											isClearable
											placeholder="- Pilih petani -"
											options={dataFarmerCultivation}
											isLoading={loadingFarmerCultivation}
											getOptionValue={(option: any) => option["user_id"]}
											getOptionLabel={(option: any) => option["fullname_var"]}
											onChange={(selectedOption) => {
												handleSelectUser(selectedOption);
												setUserID(selectedOption ? selectedOption.user_id : null);
											}}
										/>
										<ErrorField errors={errors} name="user_id_int" />
									</div>
								)}
								{!loadingLand && !loadingPeriodPlant && (
									<div className="w-full md:w-1/2">
										<label htmlFor="land_code_var" className="label-form">
											Lahan
										</label>
										<select
											id="land_code_var"
											className="input-form"
											{...register("land_code_var", {
												required: "Land code is required",
											})}
											onChange={(event) =>
												handleSelectLand(event)
											}>
											<option value="">- Pilih Lahan -</option>
											{userID != 0 && dataLand!.map((item, key) => (
												<option key={key} value={item.land_code_var}>
													[{item.land_code_var}] {item.land_name_var}
												</option>
											))}
										</select>
										<ErrorField errors={errors} name="land_code_var" />
									</div>
								)}
							</div>
							<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
								{!loadingActivity && (
									<div className="w-full">
										<label htmlFor="activity_txt" className="label-form">
											Aktivitas
										</label>
										<div className="flex item-center space-x-2">
											<select
												id="activity_txt"
												className="input-form"
												{...register("activity_txt", {
													required: "This field is required",
												})}
											>
												<option value="">- Pilih Aktivitas -</option>
												{DataActivity?.map((item, key) => (
													<option key={key} value={item.id_seq}>
														{item.activity_txt}
													</option>
												))}
											</select>
										</div>
										<ErrorField errors={errors} name="activity_txt" />
									</div>
								)}
								<div className="w-6/12">
									<label htmlFor="date_performed_dtm" className="label-form">
										Tanggal dilakukan
									</label>
									<input
										id="date_performed_dtm"
										type="date"
										className="input-form"
										{...register("date_performed_dtm", {
											required: "Start Planting is required.",
										})}
									/>
									<ErrorField errors={errors} name="date_performed_dtm" />
								</div>
							</div>
							<div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
								<div className="w-full">
									<label htmlFor="material_txt" className="label-form">
										Bahan
									</label>
									<input
										id="material_txt"
										name="material_txt"
										className="input-task"
										defaultValue={data?.material_txt || ""}
										placeholder="35ml;200L"
										{...register("material_txt", {
										})}
									/>
								</div>
								<div className="w-full">
									<label htmlFor="dose_txt" className="label-form">
										Dosis
									</label>
									<input
										id="dose_txt"
										name="dose_txt"
										className="input-task"
										defaultValue={data?.dose_txt || ""}
										placeholder="35ml;200L"
										{...register("dose_txt", {
										})}
									/>
								</div>
							</div>
							{!loadingParams && (
								<div className="grid grid-cols-2 gap-5">
									{transformedOptions?.map((param, index) => (
										<div key={`parameter-pengamatan-${index}`} className="w-full">
											<label
												htmlFor={`parameter-pengamatan-${index}`}
												className="label-form">
												{param?.label}
											</label>
											<Select
												id={`parameter-pengamatan-${index}`}
												onChange={(event) =>
													handleSelectChange(index, {
														parameter: param?.label,
														value: event?.value,
													})
												}
												styles={selectStylesForm}
												options={param?.options}
												isClearable
												defaultValue={param?.options?.find(
													(po) =>
														po.value?.toLowerCase() ===
														selectedValues?.find((sv) =>
															sv?.parameter ? sv.parameter === param.label : [])?.value?.toLowerCase(),
												)}
												placeholder="- Pilih -"
												getOptionValue={(option: any) => option["label"]}
												getOptionLabel={(option: any) => option["value"]}
											/>
										</div>
									))}
								</div>
							)}
							<h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">
								Dokumentasi Pengamatan
							</h2>
							<div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
								<div className="w-full">
									<label htmlFor="images" className="label-form">
										Unggah foto Pengamatan
									</label>
									{data ? (
										Object.keys(data).length !== 0 &&
										data?.document_txt?.map((url: string, key: number) => (
											<div className="relative w-full cursor-pointer hover:scale-110 hover:duration-500">
												<div className="absolute inset-0 w-full bg-black opacity-30 rounded-xl"
												/>
												<LazyLoadImage
													onClick={() =>
														window.open(url, "_blank")
													}
													effect="blur"
													alt={url}
													src={data?.document_txt[key]}
													className=" w-full rounded-lg object-cover"
													key={key}
												/>
												<div className="absolute top-0 right-0 space-x-2 m-2 text-gray-200">
													<i
														className="fa-solid fa-trash cursor-pointer hover:text-gray-500"
														onClick={() => {
															setDataDoc(data?.document_txt[key]);
															setSelectedData(data);
															showConfirmDelete(true);
														}}>
													</i>
												</div>
											</div>
										))

									) : (
										<h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">

										</h2>
									)
									}

									<input
										// id="images"
										// name="images"
										type="file"
										className="input-form"
										accept=".png, .jpg, .jpeg, .bmp"
										onChange={uploadImage}
										multiple
									/>
								</div>
							</div>

							<div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
								<div className="w-full">
									<label htmlFor="example_observation_txt" className="label-form">
										Catatan Pengamatan
									</label>
									<textarea
										name="example_observation_txt"
										id="example_observation_txt"
										className="input-task"
										defaultValue={data?.example_observation_txt}
										{...register("example_observation_txt", {
										})}
									/>
								</div>
							</div>
						</div>
						{/* <!-- Modal footer --> */}
						<div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
							<button type="submit" className="btn-primary">
								Simpan
							</button>
							<button
								type="reset"
								className="btn-secondary px-7"
								onClick={onCancel}>
								Batal
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ModalFormCultivationLandProcess;
