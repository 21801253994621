import { useEffect, useState } from "react";
import { dropdownCategoryVariable, ICategoryVariable } from "@api/categoryVariable-api";

export default function useDropdownCategoryVariable(isRefreshing = false) {
	const [dataCategoryVariable, setDataCategoryVariable] = useState<ICategoryVariable[]>([]);
	const [loadingCategoryVariable, setLoading] = useState(false);
	const [errorCategoryVariable, setError] = useState(null);

	useEffect(() => {
		async function fetchDropdownCategoryVariable() {
			setLoading(true);
			let res = await dropdownCategoryVariable();

			console.log("DD CategoryVariable :", res);
			if (res.data) {
				if (res.data.code === 0) {
					setDataCategoryVariable(res.data.data);
				} else {
					setError(res.data.message);
				}
			} else {
				setError(res.message);
			}
			setLoading(false);
		}

		fetchDropdownCategoryVariable();
	}, [isRefreshing]);

	return { dataCategoryVariable, errorCategoryVariable, loadingCategoryVariable };
}
