import { Transition } from "@tailwindui/react";
import ModalConfirm from "../modal/ConfirmModal";
import { FC, useEffect, useRef, useState } from 'react';

interface IDropdownUser {
    onEdit?: (e: React.MouseEvent) => any,
    onChangePassword?: (e: React.MouseEvent) => any,
    onDelete?: (e: React.MouseEvent) => any,
    onDetail?: (e: React.MouseEvent) => any,
    onInActive?: (e: React.MouseEvent) => any,
    onActive?: (e: React.MouseEvent) => any,
}

const DropdownTableUser: FC<IDropdownUser> = ({ onEdit, onChangePassword, onDelete, onDetail, onInActive, onActive }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [confirmDelete, showConfirmDelete] = useState(false)
    const [confirmInActive, showConfirmInActive] = useState(false)
    const [confirmActive, showConfirmActive] = useState(false)

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleDetail = (e: React.MouseEvent) => {
        setDropdownOpen(false)
        if (onDetail) onDetail(e)
    }

    const handleEdit = (e: React.MouseEvent) => {
        setDropdownOpen(false)
        if (onEdit) onEdit(e)
    }

    const handleDelete = (e: React.MouseEvent) => {
        showConfirmDelete(false)
        if (onDelete) onDelete(e)
    }

    const handleInActive = (e: React.MouseEvent) => {
        showConfirmInActive(false)
        if (onInActive) onInActive(e)
    }

    const handleActive = (e: React.MouseEvent) => {
        showConfirmActive(false)
        if (onActive) onActive(e)
    }

    return (
        <div className="relative inline-flex justify-center item-center flex-row-reverse">
            <button ref={trigger} onClick={() => setDropdownOpen(!dropdownOpen)} className="relative z-5 block rounded-md focus:outline-none cursor-pointer" aria-expanded={dropdownOpen} aria-haspopup="true">
                <span className='text-gray-500 text-lg hover:text-lightcayn'><i className="fa-solid fa-ellipsis"></i></span>
            </button>

            {/* {dropdownOpen && */}
            <Transition show={dropdownOpen}
                enter="transition ease-out duration-300"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                className="origin-top-right z-10 absolute top-full right-0 w-max bg-white border border-gray-200 py-1.5 px-1 rounded shadow-lg overflow-hidden mt-1"
            >
                <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)} >
                    <div className="flex flex-col w-40">
                        {onDetail &&
                            <button onClick={handleDetail} className="px-2 py-2 text-sm text-gray-700 hover:bg-lightcayn hover:text-white inline-flex items-center rounded-md">
                                <i className="fa-solid fa-solid fa-eye"></i>
                                <p className='ml-2.5'>Lihat Detail</p>
                            </button>
                        }
                        {onEdit &&
                            <button onClick={handleEdit} className="px-2 py-2 text-sm text-gray-700 hover:bg-lightcayn hover:text-white inline-flex items-center rounded-md">
                                <i className="fa-solid fa-pen-to-square"></i>
                                <p className='ml-2.5'>Ubah</p>
                            </button>
                        }
                        {onChangePassword &&
                            <button onClick={onChangePassword} className="px-2 py-2 text-sm text-gray-700 hover:bg-lightcayn hover:text-white inline-flex items-center rounded-md">
                                <i className="fa-solid fa-key"></i>
                                <p className='ml-2.5'>Ganti Password</p>
                            </button>
                        }
                        {onDelete &&
                            <button onClick={() => showConfirmDelete(true)} className="mt-1 px-2 py-2 text-sm text-gray-700 hover:bg-lightcayn hover:text-white inline-flex items-center rounded-md">
                                <i className="fa-solid fa-trash-can"></i>
                                <p className='ml-2.5'>Hapus</p>
                            </button>
                        }
                        {onInActive &&
                            <button onClick={() => showConfirmInActive(true)} className="mt-1 px-2 py-2 text-sm text-gray-700 hover:bg-lightcayn hover:text-white inline-flex items-center rounded-md">
                                <i className="fa-solid fa-trash-can"></i>
                                <p className='ml-2.5'>Non Aktif</p>
                            </button>
                        }
                        {onActive &&
                            <button onClick={() => showConfirmActive(true)} className="mt-1 px-2 py-2 text-sm text-gray-700 hover:bg-lightcayn hover:text-white inline-flex items-center rounded-md">
                                <i className="fa-solid fa-trash-can"></i>
                                <p className='ml-2.5'>Aktif</p>
                            </button>
                        }
                    </div>
                </div>
            </Transition>

            {confirmDelete && <ModalConfirm message='Hapus data ini?' onOK={handleDelete} onCancel={() => showConfirmDelete(false)} />}
            {confirmInActive && <ModalConfirm message='Ubah data menjadi non Active?' onOK={handleInActive} onCancel={() => showConfirmInActive(false)} />}
            {confirmActive && <ModalConfirm message='Ubah data menjadi Active?' onOK={handleActive} onCancel={() => showConfirmActive(false)} />}
        </div>
    )
}

export default DropdownTableUser