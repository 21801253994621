import { Routes, Route, Navigate } from "react-router-dom"
import { routeAuth } from "../routes"

const Auth = () => {
	const getRoutes = () => {
		return routeAuth.map((data, key) => {
			return (
				<Route path={data.path} element={data.component} key={key} />
			);

		});
	}

	return (
		<div className='min-h-screen bg-soft flex justify-center items-center font-poppins'>
			<Routes>
				{getRoutes()}
				<Route path='*' element={<Navigate replace to='/auth/sign-in' />} />
			</Routes>
		</div>
	)
}

export default Auth