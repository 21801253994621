import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getByQueryCulltivation } from "@api/cultivationLandprocess-api";
import { IParameterVariableResearch } from "@api/paramaterVariableResearch-api";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    data: IParameterVariableResearch;
    onCancel: (e: React.MouseEvent) => any;
    onClose: (e: React.MouseEvent<HTMLElement>) => void;
};

const ModalDetailMaterialResearch: React.FC<Props> = ({ data, onCancel, onClose }) => {
    // hooks
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<any>({ criteriaMode: "all" });

    // states
    const [dataMaterialResearch, setMaterialResearch] = useState<IParameterVariableResearch[]>([]);

    // functions
    const fetchMaterialReseach = async () => {
        const query = `id_material_research=${data?.id_seq}`;

        console.log('datas ', data);
        // const filtered = dataObservation.flatMap(arr => arr).filter(observation => observation.land_code_var === data?.land_code_var)
        // console.log('data observation filtered ', filtered[0]);

        const res = await getByQueryCulltivation(query);
        // const filter = dataObservation.filter(obs => obs.land_code_var === data.land_code_var)
        // console.log('cultivation res ', dataObservation);
        // if (dataObservation) {
        // setLogActivities(filtered);
        // }
    };

    // effects
    const setdefaultValue = useCallback(
        (obj) => {
            Object.keys(obj).map((key) => {
                return setValue(key, obj[key], { shouldValidate: true });
            });
        },
        [setValue],
    );
    useEffect(() => {
        if (data) {
            setdefaultValue(data);
            fetchMaterialReseach()
        }
    }, [data, setdefaultValue]);


    return (
        <>

            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                <div
                    className="modal-form-content bg-white p-5 w-[95%] md:w-6/12 2xl:w-5/12"
                    style={{ maxHeight: "90vh" }}>
                    <div className="flex justify-between items-start border-b px-8 pb-5 -mx-5">
                        <div className="text-base md:text-xl font-bold uppercase flex item-center space-x-3 lg:space-x-4">
                            <h3>Detail Variable Research</h3>
                        </div>
                        <button
                            type="button"
                            className="text-slate-400 bg-transparent rounded-lg text-base ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onClose}>
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>

                    {dataMaterialResearch?.map((item, key) => (
                        <div className="w-full border mt-5 bg-white rounded-2xl md:p-6">
                            <div className="flex flex-col mb-6 px-1 md:px-5 text-sm" key={key}>
                                <div className="flex flex-col mt-3 mb-5 px-1 md:px-1 text-sm">
                                    <div className="flex items-center justify-between">
                                        <h3 className="font-bold text-blue-500">Material</h3>
                                    </div>
                                    <table className="font-medium text-xs md:text-sm mt-3">
                                        <tbody>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

        </>
    );

};



export default ModalDetailMaterialResearch;
