import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table'


type Props = {
    columnTable: any[],
    dataTable: any[],
};


const TableMax = ({ columnTable, dataTable }: Props) => {
    // const data = useMemo(() => dataTable, [dataTable])

    const {
        getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canPreviousPage, canNextPage, pageOptions, state, gotoPage, pageCount, setPageSize, prepareRow,
    } = useTable(
        {
            columns: columnTable,
            data: dataTable,
            defaultColumn: { width: 10 },
            //   initialState: { pageSize: 5 }
        },
        useSortBy,
        usePagination,
        useRowSelect
    )

    const { pageIndex, pageSize } = state


    return (
        <>
            <div className="overflow-x-auto">
                <table className={`align-middle w-max min-w-full`} {...getTableProps()}>
                    <thead className='bg-soft text-left'>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className={`py-3 px-2 text-left cursor-pointer`} {...column.getHeaderProps({
                                        ...column.getSortByToggleProps(),
                                        style: { minWidth: column.minWidth, width: column.width }
                                    })}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted ?
                                                column.isSortedDesc ?
                                                    <i className="ml-1 fa-solid fa-arrow-down-a-z"></i>
                                                    :
                                                    <i className="ml-1 fa-solid fa-arrow-up-a-z"></i>
                                                : ''
                                            }
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr className='border-b-2 border-soft hover:bg-soft text-left' {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td className='py-3 px-2' {...cell.getCellProps({
                                            style: { minWidth: cell.column.minWidth, width: cell.column.width }
                                        })}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {dataTable.length ===0 &&
                    <div className='flex flex-col text-gray-400 items-center justify-center h-32'>
                        <i className="fa-solid fa-database text-4xl mb-2.5 text-gray-300"></i>
                         <p>No records to display</p>
                    </div>
                }
            </div>

            <div className='mt-5 mb-2 sm:mb-0 flex justify-between items-center'>
                <div className='hidden sm:flex items-center'>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </span>
                    <span className='mr-5'>
                        {' '} | Go to page :{' '}
                        <input
                            type='number'
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            className='w-20 outline-none border-1 border-gray-400 rounded-2xl px-3 py-1'
                        />
                    </span>{' '}
                </div>

                <div className='flex items-center justify-between md:justify-end w-full md:w-max'>
                    <div className='flex items-center md:mr-5'>
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className={`${canPreviousPage ? 'text-black hover:text-lightcayn' : 'text-gray-400 cursor-text'} font-bold mr-2`}>
                            {'<<'}
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className={`${canPreviousPage ? 'text-black hover:text-lightcayn' : 'text-gray-400 cursor-text'} mr-2`}>
                            Previous
                        </button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className={`${canNextPage ? 'text-black hover:text-lightcayn' : 'text-gray-400 cursor-text'} mr-2`}>
                            Next
                        </button>
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className={`${canNextPage ? 'text-black hover:text-lightcayn' : 'text-gray-400 cursor-text'} font-bold`}>
                            {'>>'}
                        </button>
                    </div>
                    <select className='outline-none px-2 py-1 border border-slate-200 rounded-2xl cursor-pointer' value={pageSize}
                        onChange={e => setPageSize(Number(e.target.value))}>
                        {[10, 20, 50, 100, 200, 500].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    )
}

export default TableMax