import { useEffect, useState } from "react"
import { BudgetDetailApi } from "../api/budgetplan-api"

export function useDropdownActivityBP(cartegrory_var: string) {
    const [activityList, setData] = useState<any[]>([])
    const [loadingActivity, setLoading] = useState(true)
    const [erroraActivity, setError] = useState(null)

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const res = await BudgetDetailApi.getDropdownActivity(cartegrory_var || '')

            console.log("DD ACTIVITY ==>", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setData(res.data.data)
                    setLoading(false)
                }else{
                    setError(res.data.message)
                    setData([])
                    setLoading(false)
                }
            }else{
                setError(res.message)
                setData([])
                setLoading(false)
            }
        }

        fetchData()
    }, [cartegrory_var])

    return {activityList, erroraActivity, loadingActivity}
}

export function useDropdownCategoryBP() {
    const [categoryList, setData] = useState<any[]>([])
    const [loadingCategory, setLoading] = useState(true)
    const [erroraCategory, setError] = useState(null)

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const res = await BudgetDetailApi.getDropdownCategory()

            console.log("DD CATEGORY ==>", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setData(res.data.data)
                    setLoading(false)
                }else{
                    setError(res.data.message)
                    setData([])
                    setLoading(false)
                }
            }else{
                setError(res.message)
                setData([])
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    return {categoryList, erroraCategory, loadingCategory}
}