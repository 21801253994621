import React from 'react';
import { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import { IOperatingParameter } from '../../../api/parameter-api';

type Props = {
    data: IOperatingParameter,
    onCancel: (e: React.MouseEvent) => any
    onSubmit: (data: IOperatingParameter) => void
};

const ModalFormOperatingParameter: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if (data) setdefaultValue(data)
    }, [data, setdefaultValue])

    const onSubmitForm: SubmitHandler<IOperatingParameter> = ({ operating_parameters_code_var, operating_parameters_description_var, operating_parameters_measure_var, operating_parameters_value_var }) => {

        const payload = {
            operating_parameters_code_var, operating_parameters_description_var, operating_parameters_measure_var, operating_parameters_value_var
        }

        onSubmit(payload)
    }

    return (
        <div className="modal-form">
            <div className="modal-form-outside" onClick={onCancel}></div>

            <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                    <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                        <i className="fa-solid fa-gears flex justify-center items-center"></i>
                        <h3>{data ? 'Ubah Operating Parameter' : 'Tambah Operating Parameter'}</h3>
                    </div>
                    <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                        onClick={onCancel}
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                    <div className='space-y-5'>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="code" className="label-form">Kode</label>
                                <input id='core' type='text' className='input-form' placeholder='C001'
                                    {...register('operating_parameters_code_var', {
                                        required: 'Code is required.',
                                    })}
                                />
                                <ErrorField errors={errors} name='operating_parameters_code_var' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="desc" className="label-form">Deskripsi</label>
                                <input id='desc' type='text' className='input-form' placeholder='Config 1'
                                    {...register('operating_parameters_description_var', {
                                        required: 'Description Name is required.'
                                    })}
                                />
                                <ErrorField errors={errors} name='operating_parameters_description_var' />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="value" className="label-form">Value</label>
                                <input id='value' type='text' className='input-form' placeholder='10' autoComplete='off'
                                    {...register('operating_parameters_value_var', {
                                        required: 'Value is required.'
                                    })}
                                />
                                <ErrorField errors={errors} name='operating_parameters_value_var' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="measure" className="label-form">Satuan</label>
                                <input id='measure' type='text' className='input-form' placeholder='meters'
                                    {...register('operating_parameters_measure_var', {
                                    })}
                                />
                                <ErrorField errors={errors} name='operating_parameters_measure_var' />
                            </div>
                        </div>

                    </div>

                    {/* <!-- Modal footer --> */}
                    <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                        <button type='submit' className="btn-primary">Simpan</button>
                        <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                    </div>

                </form>

            </div>

        </div>
    )
}

export default ModalFormOperatingParameter