import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import { useDropdownPeriodLandPrice, useDropdownPeriodPlant } from "@hooks/useDropdownRatioDevidend";

const ModalFormLandPrice = ({ data, onCancel, onSubmit }) => {

	const { DataPeriodPlant, loadingPeriodPlant } = useDropdownPeriodLandPrice()
	const [PeriodPlant, setPeriodPlant] = useState(
		data ? data.period_plant_txt : null,
	);

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm({ criteriaMode: "all" });

	const setdefaultValue = useCallback((obj) => {
		Object.keys(obj).map(key => {
			return setValue(key, obj[key], { shouldValidate: true })
		})
	}, [setValue])

	useEffect(() => {
		if (data) {
			setdefaultValue(data)
		}
	}, [data, setdefaultValue])


	const onValid = (dataForm) => {
		const { land_area_var, land_price_int, period_plant_txt, status_int } = dataForm

		const payload = { land_area_var, land_price_int, period_plant_txt, status_int }

		onSubmit(payload)
	}

	return (
		<div className="modal-form">
			<div className="modal-form-outside" onClick={onCancel}></div>

			<div className="modal-form-content" style={{ maxHeight: '90vh' }}>

				<div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
					<div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
						<i className="fa-solid fa-ranking-star flex justify-center items-center"></i>
						<h3>{data ? 'Ubah Harga PPAI' : 'Tambah Harga PPAI'}</h3>
					</div>
					<button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
						onClick={onCancel}
					>
						<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
					</button>
				</div>

				{/* Body */}
				<form onSubmit={handleSubmit(onValid)} className='px-8'>
					<div className='grid grid-cols-1 gap-x-8 gap-y-3'>

						<div className='w-full'>
							<label htmlFor="land_price_int" className="label-form">Luas Lahan</label>
							<input id="land_area_var" type='number' className='input-form' placeholder='1' step="0.01"
								{...register('land_area_var', { required: 'This field is required.' })}
							/>
							<ErrorField errors={errors} name='land_area_var' />
						</div>

						<div className='w-full'>
							<label htmlFor="land_price_int" className="label-form">Harga PPAI</label>
							<input id="land_price_int" type='number' className='input-form' placeholder='1000'
								{...register('land_price_int', { required: 'This field is required.' })}
							/>
							<ErrorField errors={errors} name='land_price_int' />
						</div>

						{!loadingPeriodPlant && (
							<div className="w-full">
								<label htmlFor="period_plant_txt" className="label-form">
									Periode Tanam
								</label>
								<div className="flex item-center space-x-2">
									<select
										id="period_plant_txt"
										className="input-form"
										{...register("period_plant_txt", {
											required: "This field is required",
										})}
										onChange={(e) => {
											setPeriodPlant(e.target.value);
										}}>
										<option value="">- Pilih Periode Tanam -</option>
										{DataPeriodPlant?.map((item, key) => (
											<option key={key} value={item.period_plant_txt}>
												{item.period_plant_txt}
											</option>
										))}
									</select>
								</div>
								<ErrorField errors={errors} name="period_plant_txt" />
							</div>
						)}
						<div className="w-full">
							<label htmlFor="status_int" className="label-form">
								Status
							</label>
							<select
								id="status_int"
								className="input-form"
								{...register("status_int", {
									required: "This field is required",
								})}>
								<option value="">- Pilih status -</option>
								<option key={`active`} value={1}>
									Active
								</option>
								<option key={`inactive`} value={0}>
									Inactive
								</option>
							</select>
							<ErrorField errors={errors} name="status_int" />
						</div>
					</div>
					{/* <!-- Modal footer --> */}
					<div className="flex items-center mt-6 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10 mb-5">
						<button type='submit' className="btn-primary">Simpan</button>
						<button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
					</div>
				</form>

				{/* End Body */}
			</div>
			{/* End Modal Content */}
		</div>
	)
}

export default ModalFormLandPrice