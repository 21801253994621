import { Transition } from '@tailwindui/react';
import dayjs from 'dayjs';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { IRecentActivity } from '../../context/ContextInterface';
import { GlobalContext } from '../../context/GlobalProvider';

function Notifications() {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const trigger = useRef<any>(null);
    const dropdown = useRef<any>(null);

    const { recentActivities, dispatchCalActivity } = useContext(GlobalContext)

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: any) => {
            if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: any) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleReadAllNotification = () => {
        dispatchCalActivity({ type: 'read_all' })
    }

    const handleClickNotification = (payload: IRecentActivity) => {
        setDropdownOpen(!dropdownOpen)
        dispatchCalActivity({ type: 'read', payload })
    }

    return (
        <div className="relative inline-flex ml-3">
            <button ref={trigger} aria-haspopup="true" onClick={() => setDropdownOpen(!dropdownOpen)} aria-expanded={dropdownOpen}
                className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ${dropdownOpen && 'bg-gray-200'}`}
            >
                <span className="sr-only">Notifications</span>
                <i className="fa-solid fa-bell text-gray-500 text-base"></i>
                {recentActivities.filter(item => !item.is_read).length > 0 &&
                    <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 border-2 border-white rounded-full"></div>
                }
            </button>

            <Transition show={dropdownOpen}
                enter="transition ease-out duration-300"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                className="origin-top-right z-10 absolute top-full right-0 -mr-[12.5rem] sm:mr-0 w-[24.5rem] bg-white border border-gray-200 rounded-2xl shadow-lg overflow-hidden mt-1"
            >
                <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
                    <div className='font-medium p-5 -mb-2 flex items-center justify-between'>
                        <div className="text-sm font-semibold">Notifications</div>
                        <p className='hover-anim' onClick={handleReadAllNotification}>Mark all read</p>
                    </div>
                    {recentActivities.length > 0 ?
                        <ul className='w-full'>
                            {recentActivities.sort((a, b) => b.id - a.id).map((item, key) =>
                                <li className={`border-b border-gray-200 last:border-0 hover:bg-gray-100 py-2 pl-5 pr-3 last:mb-4 ${item.is_read ? 'bg-white' : 'bg-slate-200'}`} key={key}>
                                    <Link onClick={() => handleClickNotification(item)} to={item.path}>
                                        <div className='flex items-center space-x-3'>
                                            <div className='bg-gradient-to-br from-lightcayn to-darkcayn w-12 h-12 text-white rounded-lg flex justify-center items-center'>
                                                <i className="fa-solid fa-bell text-base"></i>
                                            </div>
                                            <div className='flex flex-col w-[18.5rem]'>
                                                <span className='font-semibold truncate text-sm'>{item.title}</span>
                                                <div className='text-sms break-all'><span className='truncate break-all'>{item.activity.substring(0, 26)}... </span>{dayjs(item.id).format('MMM DD, YYYY')}</div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            )}
                            {/* <li className="border-b border-gray-200 last:border-0 hover:bg-gray-100 py-2 pl-5 pr-3 last:mb-4">
                            <Link onClick={() => setDropdownOpen(!dropdownOpen)} to='/'>
                                <div className='flex items-center space-x-3'>
                                    <div className='bg-gradient-to-br from-lightcayn to-darkcayn w-12 h-12 text-white rounded-lg flex justify-center items-center'>
                                        <i className="fa-solid fa-bell text-base"></i>
                                    </div>
                                    <div className='flex flex-col w-[18.5rem]'>
                                        <span className='font-semibold truncate text-sm'>Title of notification</span>
                                        <div className='text-sms break-all'><span className='truncate break-all'>{'ouhoh ouyoogkadsh dksahdsak khdaskdsdsf jdsf'.substring(0, 26)}... </span>Feb 12, 2021</div>
                                    </div>
                                </div>
                            </Link>
                        </li> */}
                        </ul>
                        :
                        <div className='h-20 flex justify-center items-center flex-col text-sm text-gray-500'>
                            <i className="fa-solid fa-bell text-base"></i>
                            <p>You don't have any notifications</p>
                        </div>
                    }
                </div>

            </Transition>
        </div>
    )
}

export default Notifications;