import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import Location from "../../helpers/location";
import ModalFormKordinat from "./landcoordinate/ModalFormKordinat";
import ModalOptionKoordinat from "./landcoordinate/ModalOptionLand";
import ModalDrawMaps from "./landcoordinate/ModalDrawMaps";
// import Loader from "./Loader";
import { getAllPetani } from "../../api/user-api";
import { toast } from "react-toastify";
import { selectStylesForm } from "./TaskManagement/ModalTaskNew";
import Select from "react-select"

const ModalFormLandResearchManagement = ({ data, onCancel, onSubmit, farmerGroupList }) => {
    const [modalKordinat, showModalKordinat] = useState(false)
    const [modalDraw, showModalDraw] = useState(false)
    const [modalOption, showModalOption] = useState(false)

    const [dataUser, setDataUser] = useState([])
    const [petaniID, setPetaniID] = useState('')


    const [province, setProvince] = useState([])
    const [provID, setProvID] = useState('')
    const [cities, setCities] = useState([])
    const [cityID, setCityID] = useState('')
    const [districts, setDistricts] = useState([])
    const [districtID, setDistrictID] = useState('')

    const [dataPendamping, setDataPendamping] = useState([])

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {
        Object.keys(obj).map(key => {
            if (key === 'prov_id') {
                setProvID(obj[key])
                return setValue('province_var', obj[key])
            } else if (key === 'city_id') {
                setCityID(obj[key])
                return setValue('city_var', obj[key])
            } else if (key === 'dis_id') {
                setDistrictID(obj[key])
                return setValue('district_var', obj[key])
            } else if (key === 'user_id_int') {
                setPetaniID(obj[key])
                return setValue('user_id_int', obj[key])
            } else {
                return setValue(key, obj[key], { shouldValidate: true })
            }
        })
    }, [setValue])

    useEffect(() => {
        if (data) setdefaultValue(data)
    }, [data, setdefaultValue])


    useEffect(() => {
        async function fetchPetani() {
            const res = await getAllPetani()

            console.log('Fetch Petani :', res)
            if (res.data) {
                if (res.data.code === 0) {
                    const sortData = res.data.data.sort((a, b) => a.fullname_var.localeCompare(b.fullname_var))
                    setDataUser(sortData)
                } else {
                    toast.error(res.data.message)
                }
            } else {
                toast.error(`${res.config?.url} ${res.message}`)
            }
        }

        fetchPetani()
    }, [])


    useEffect(() => {
        async function fetchLocation() {
            const prov = await Location.getProvinces()
            setProvince(prov)

            const city = await Location.allCity()
            setCities(city)

            const districts = await Location.allDistricts()
            setDistricts(districts)
        }
        fetchLocation()
    }, [])


    const onValid = (dataForm) => {

        const { user_id_int, land_name_var, province_var, city_var, district_var, address_land_txt,
            land_coordinates_txt, area_var, coordinates_area_var } = dataForm

        const payload = {
            user_id_int: petaniID, land_name_var, province_var, city_var, district_var, address_land_txt,
            land_coordinates_txt, area_var, coordinates_area_var
        }

        onSubmit(payload)
    }

    const getDefaultUser = () => {
        // Logic to determine default user
        if (data) {
            return {
                id_seq: data.user_id_int,
                fullname_var: data.fullname_var
            };
        }
    }

    const handleReceiveKordinat = (koor, areaLength) => {
        setValue('land_coordinates_txt', koor)
        if (areaLength) {
            setValue('coordinates_area_var', areaLength)
        }

        showModalKordinat(false)
        showModalOption(false)
        showModalDraw(false)
    }

    return (
        <>

            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: '90vh' }}>
                    <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                        <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                            <i className="fa-solid fa-layer-group flex justify-center items-center"></i>
                            <h3>{data ? 'Ubah Lahan' : 'Tambah Lahan Baru'}</h3>
                        </div>
                        <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onValid)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8 space-y-5">
                        <h2 className='font-bold text-sm  text-lightcayn -mb-2'>Data Pemilik Lahan</h2>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full md:w-1/2">
                                <label className="label-form">Pemilik Lahan</label>
                                <Select
                                    id="user_id_int"
                                    styles={selectStylesForm}
                                    isClearable
                                    placeholder="- Pilih Pemilik Lahan -"
                                    options={dataUser}
                                    value={getDefaultUser()}
                                    // isLoading={loadingFarmerCultivation}
                                    getOptionValue={(option: any) => option["id_seq"].toString()}
                                    getOptionLabel={(option: any) => option["fullname_var"]}
                                    onChange={(selectedOption) => {
                                        setPetaniID(selectedOption ? selectedOption.id_seq.toString() : null);
                                    }}
                                />
                                <ErrorField errors={errors} name='user_id_int' />
                            </div>
                        </div>
                        {/* <div className='flex flex-col sm:flex-row mb-7 md:mr-10 md:space-x-10 space-y-5 md:space-y-0'> */}

                        <h2 className='font-bold text-sm  text-lightcayn -mb-5 pt-5'>Data Lahan</h2>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full md:w-1/2">
                                <label className="label-form">Nama Lahan</label>
                                <input type='text' className="input-form" placeholder='Lahan_Nama Petani_lahan ke berapa'
                                    // defaultValue={petaniID? `lahan_${dataUser.filter(val => val.id_seq === parseInt(petaniID))[0].fullname_var}_${dataLand.length + 1}` : ''} 
                                    {...register("land_name_var", { required: "This field is required." })} />
                                <ErrorField errors={errors} name='land_name_var' />
                            </div>
                            <div className="w-full md:w-1/2">
                                <label className="label-form">Provinsi</label>
                                <select {...register("province_var", { required: "This field is required." })} value={provID} onChange={(e) => setProvID(e.target.value)} className="input-form">
                                    <option value=''>- Pilih Provinsi -</option>
                                    {province?.map((data, key) => (
                                        <option key={key} value={data.prov_id}>{data.prov_name}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='province_var' />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full md:w-1/2">
                                <label className="label-form">Kabupaten/Kota</label>
                                <select {...register("city_var", { required: "This field is required." })} value={cityID} onChange={(e) => setCityID(e.target.value)} className="input-form">
                                    <option value=''>- Pilih Kabupaten/Kota -</option>
                                    {cities?.filter(({ prov_id }) => parseInt(prov_id) === parseInt(provID))?.map((data, key) => (
                                        <option key={key} value={data.city_id}>{data.city_name}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='city_var' />
                            </div>
                            <div className="w-full md:w-1/2">
                                <label className="label-form">Kecamatan</label>
                                <select {...register("district_var", { required: "This field is required." })} value={districtID} onChange={(e) => setDistrictID(e.target.value)} className="input-form">
                                    <option value=''>- Pilih Kecamatan -</option>
                                    {districts?.filter(({ city_id }) => parseInt(city_id) === parseInt(cityID)).map((data, key) => (
                                        <option key={key} value={data.dis_id}>{data.dis_name}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='district_var' />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full md:w-1/2">
                                <label className="label-form">Alamat Lahan</label>
                                <textarea className="input-form" {...register("address_land_txt")} placeholder='jl...' />
                                <ErrorField errors={errors} name='address_land_txt' />
                            </div>

                            <div className="w-full md:w-1/2">
                                <label className="label-form">Luas Lahan (Ha)</label>
                                <input type='text' className="input-form" placeholder='20' {...register("area_var", { required: "Luas Lahan is required." })} />
                                <ErrorField errors={errors} name='area_var' />
                            </div>

                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full md:w-1/2">
                                <label className="label-form">Koordinat Lahan</label>
                                <textarea {...register("land_coordinates_txt")} placeholder='106.90906,-3.67875;102.6334,-6.4334' className="input-form" />
                                {/* <ErrorField errors={errors} name='land_coordinates_txt' /> */}
                                <button type='button' className='w-full font-medium border-1 border-gray-500 rounded-lg p-2 mt-1 md:mt-2 hover:text-white hover:bg-lightcayn hover:border-transparent' onClick={() => showModalOption(true)}>
                                    <span className='mr-1'><i className="fa-solid fa-circle-plus"></i></span>
                                    <span>{data ? 'Ubah' : 'Tambah'} Koordinat</span>
                                </button>
                            </div>

                            <div className="w-full md:w-1/2">
                                <label className="label-form">Luas Kordinat Lahan (Ha)</label>
                                <input type='text' className="input-form" placeholder='20' {...register("coordinates_area_var")} />
                                <ErrorField errors={errors} name='coordinates_area_var' />
                            </div>
                        </div>

                        <div className="flex items-center justify-end mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                            <button type='submit' className="btn-primary">Simpan</button>
                            <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                        </div>
                    </form>

                    {/* End Body */}
                </div>
                {/* End Modal Content */}
            </div>


            {modalOption && <ModalOptionKoordinat onCancel={() => showModalOption(false)} onClickManual={() => showModalKordinat(true)} onClickDraw={() => showModalDraw(true)} />}
            {modalKordinat && <ModalFormKordinat onCancel={() => showModalKordinat(false)} onSubmit={handleReceiveKordinat} data={getValues('land_coordinates_txt')} />}
            {modalDraw && <ModalDrawMaps onCancel={() => showModalDraw(false)} onSubmit={handleReceiveKordinat} data={getValues('land_coordinates_txt')} />}
        </>
    )
}

export default ModalFormLandResearchManagement