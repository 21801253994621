import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOneCultivation } from "@api/cultivation-api";
import Loader from "../../components/modal/Loader";
import SearchField from "../../components/SearchField";
import TableUser from "../../components/tables/Table";
import Helper from "../../helpers/helper";
import { ActionType } from "../../reduxs/action/actions";
import { ICultivation, getObservation } from "@api/cultivation-api";
// import { ITask } from "@components/DayInCalendar";


type Props = {};

const CultivationHarvestDetail: React.FC<Props> = () => {

	const [loader, showLoader] = useState(false);
	const [dataCultivation, setDataCultivation] = useState<ICultivation | null>(null,);
	// const [dataMaterialSupport, setDataMaterialSupport] = useState<IBudgetDetail | null>(null);
	const [originData, setOriginData] = useState<ICultivation[] | []>([]);
	// const [observationData, setObservationData] = useState<ICultivation[] | []>([]);
	const [observationData, setObservationData] = useState([]);

	const [filterData, setFilterData] = useState<ICultivation[] | []>([]);

	const { id }: any = useParams();
	const user = useSelector((state: any) => state.user);
	const dispatch = useDispatch();

	const fetchData = useCallback(async () => {
		showLoader(true);
		const res = await getOneCultivation(id)
		showLoader(false);
		console.log("RES ==>", res);
		if (res.data) {
			if (res.data.code === 0) {
				setDataCultivation(res.data.data);
				setOriginData(res.data.data[1]);
				setFilterData(res.data.data[1]);
			} else {
				toast.error(res.data.message);
			}
		} else {
			toast.error(res.status + " " + res.message || "Connection timeout");
		}


		// eslint-disable-next-line
	}, [id]);

	useEffect(() => {
		dispatch({ type: ActionType.SET_TITLE_BAR, value: "Penanaman" });
		fetchData();
		options();
	}, [fetchData, dispatch, id]);

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		const newData = [...originData];
		console.log('new ', originData);

		if (event.target.value) {
			// const filtered = newData.filter((item) => {
			// 	return (
			// 		item.activity_txt
			// 			?.toLowerCase()
			// 			.includes(event.target.value.toLowerCase()) ||
			// 		// item.params_values
			// 		// 	?.toLowerCase()
			// 		// 	.includes(event.target.value.toLowerCase()) ||
			// 		item.time_calender_dte
			// 			?.toLowerCase()
			// 			.includes(event.target.value.toLowerCase())
			// 	);
			// });
			const filtered = newData.filter((item) => {
				// Check if item.activity_txt is an array and if so, iterate over each element
				if (Array.isArray(item.activity_txt)) {
					for (let activity of item.activity_txt) {
						// Check if any activity contains the target value
						if (activity.toLowerCase().includes(event.target.value.toLowerCase())) {
							return true; // If found, include the item in the filtered array
						}
					}
				} else {
					// If activity_txt is not an array, handle it as before
					if (item.activity_txt?.toLowerCase().includes(event.target.value.toLowerCase())) {
						return true;
					}
				}

				// Check time_calender_dte as before
				if (item.time_calender_dte?.toLowerCase().includes(event.target.value.toLowerCase())) {
					return true;
				}

				// If neither condition is met, exclude the item from the filtered array
				return false;
			});


			setFilterData(filtered);
		} else {
			setFilterData(originData);
		}
	};


	const columns = [
		{
			Header: () => <span className="p-4">No</span>,
			accessor: "no",
			Cell: ({ row }: any) => (
				<div className="text-left pl-4">{row.index + 1}.</div>
			),
			width: 10,
		},
		{
			Header: "Tanggal Perlakuan (YYYY-MM-DD)",
			accessor: "time_calender_dte",
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<div className="pl-2 text-center">
						{data.time_calender_dte.split("T")[0]}
					</div>
				);
			},
		},
		{
			Header: "Aktifitas",
			accessor: "activity_txt",
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<div className="pl-2">
						{data.activity_txt.map((activity, index) => (
							<div key={index}> - {activity}</div>
						))}
					</div>
				);
			},
		},
		{
			Header: "Hasil Pengamatan",
			accessor: "params_values",
			Cell: ({ value, row }) => {
				const data = row.original;
				return (
					<div className="pl-2">
						{data.params_values[0].map((param, index) => (
							<div key={index}>
								<span className="font-weight-bold">{param?.parameter}: </span>
								<span>{param?.value}</span>
							</div>
						))}
					</div>
				);
			},
		},
	];

	const options = async () => {
		const res_observation = await getObservation(id)
		console.log('obs ', res_observation);
		
		// console.log('res_observation ', res_observation.data.data.result[0].params_values[1]);
		let data = []

		if (res_observation.data.data?.result) {
			if (res_observation.data.code === 0) {
				for (let i = 0; i < res_observation.data.data.result.length; i++) {
					for (let j = 0; j < res_observation.data.data.result[i].params_values.length; j++) {
						if (res_observation.data.data.result[i].params_values[j]?.parameter === 'Kondisi Daun') {
							// console.log('value ', res_observation.data.data.result[i].params_values[j].value);
							data.push({
								'time_txt': res_observation.data.data.result[i].time_txt,
								'leaf_condition': res_observation.data.data.result[i].params_values[j].value,
								'land_condition': '',
								'water_condition': '',
								'sapling_condition': '',
								'grain_condition': '',
								'plant_pests_condition': '',
							})
						}
						else if (res_observation.data.data.result[i].params_values[j]?.parameter === 'Kondisi Lahan') {
							const index = data.findIndex(obj => obj.time_txt === res_observation.data.data.result[i].time_txt);
							if (index >= 0) {
								data[index].land_condition = res_observation.data.data.result[i].params_values[j].value;
								// console.log('index ', data[index]);
							} else {
								data.push({
									'time_txt': res_observation.data.data.result[i].time_txt,
									'leaf_condition': '',
									'land_condition': res_observation.data.data.result[i].params_values[j].value,
									'water_condition': '',
									'sapling_condition': '',
									'grain_condition': '',
									'plant_pests_condition': '',
								})
							}

						}
						else if (res_observation.data.data.result[i].params_values[j]?.parameter === 'Kondisi Air') {
							const index = data.findIndex(obj => obj.time_txt === res_observation.data.data.result[i].time_txt);
							if (index >= 0) {
								data[index].water_condition = res_observation.data.data.result[i].params_values[j].value;
								// console.log('index ', data[index]);
							} else {
								data.push({
									'time_txt': res_observation.data.data.result[i].time_txt,
									'leaf_condition': '',
									'land_condition': '',
									'water_condition': res_observation.data.data.result[i].params_values[j].value,
									'sapling_condition': '',
									'grain_condition': '',
									'plant_pests_condition': '',
								})
							}
						}
						else if (res_observation.data.data.result[i].params_values[j]?.parameter === 'Kondisi Anakan') {
							const index = data.findIndex(obj => obj.time_txt === res_observation.data.data.result[i].time_txt);
							if (index >= 0) {
								data[index].sapling_condition = res_observation.data.data.result[i].params_values[j].value;
								// console.log('index ', data[index]);
							} else {
								data.push({
									'time_txt': res_observation.data.data.result[i].time_txt,
									'leaf_condition': '',
									'land_condition': '',
									'water_condition': '',
									'sapling_condition': res_observation.data.data.result[i].params_values[j].value,
									'grain_condition': '',
									'plant_pests_condition': '',
								})
							}
						}
						else if (res_observation.data.data.result[i].params_values[j]?.parameter === 'Kondisi Bulir') {
							const index = data.findIndex(obj => obj.time_txt === res_observation.data.data.result[i].time_txt);
							if (index >= 0) {
								data[index].grain_condition = res_observation.data.data.result[i].params_values[j].value;
							} else {
								data.push({
									'time_txt': res_observation.data.data.result[i].time_txt,
									'leaf_condition': '',
									'land_condition': '',
									'water_condition': '',
									'sapling_condition': '',
									'grain_condition': res_observation.data.data.result[i].params_values[j].value,
									'plant_pests_condition': '',
								})
							}
						}
						else if (res_observation.data.data.result[i].params_values[j]?.parameter === 'Hama Penyakit Tanaman') {
							const index = data.findIndex(obj => obj.time_txt === res_observation.data.data.result[i].time_txt);
							if (index >= 0) {
								data[index].plant_pests_condition = res_observation.data.data.result[i].params_values[j].value;
							} else {
								data.push({
									'time_txt': res_observation.data.data.result[i].time_txt,
									'leaf_condition': '',
									'land_condition': '',
									'water_condition': '',
									'sapling_condition': '',
									'grain_condition': '',
									'plant_pests_condition': res_observation.data.data.result[i].params_values[j].value,
								})
							}
						}
					}
				}
			} else {
				toast.error(res_observation.data.message);
			}
		} else {
			console.log('res ',res_observation);
			
			toast.error(res_observation.status + " " + res_observation.data.message || "Connection timeout");
		}
		console.log('datas ', data);

		// Initialize an empty object to store unique leaf conditions and assign numerical values
		const leafValueMap = {};
		let leafValueCounter = 0;

		const landValueMap = {};
		let landValueCounter = 0;
		
		const waterValueMap = {};
		let waterValueCounter = 0;

		const saplingValueMap = {};
		let saplingValueCounter = 0;

		const grainValueMap = {};
		let grainValueCounter = 0;

		const plantPestsValueMap = {};
		let plantPestsValueCounter = 0;

		// Iterate over the data to assign numerical values to leaf conditions and land conditions
		data.forEach(item => {
			const leafCondition = item.leaf_condition;
			if (!(leafCondition in leafValueMap)) {
				leafValueMap[leafCondition] = leafValueCounter;
				leafValueCounter++;
			}

			const landCondition = item.land_condition;
			if (!(landCondition in landValueMap)) {
				landValueMap[landCondition] = landValueCounter;
				landValueCounter++;
			}

			const waterCondition = item.water_condition;
			if (!(waterCondition in waterValueMap)) {
				waterValueMap[waterCondition] = waterValueCounter;
				waterValueCounter++;
			}

			const saplingCondition = item.sapling_condition;
			if (!(saplingCondition in saplingValueMap)) {
				saplingValueMap[saplingCondition] = saplingValueCounter;
				saplingValueCounter++;
			}

			const grainCondition = item.grain_condition;
			if (!(grainCondition in grainValueMap)) {
				grainValueMap[grainCondition] = grainValueCounter;
				grainValueCounter++;
			}

			const plantPestsCondition = item.plant_pests_condition;
			if (!(plantPestsCondition in plantPestsValueMap)) {
				plantPestsValueMap[plantPestsCondition] = plantPestsValueCounter;
				plantPestsValueCounter++;
			}
		});

		// Parse the data values using the valueMap
		const parsedLeafConditionValues = data.map(item => leafValueMap[item.leaf_condition]);
		const parsedLandConditionValues = data.map(item => landValueMap[item.land_condition]);
		const parsedWaterConditionValues = data.map(item => waterValueMap[item.water_condition]);
		const parsedSaplingConditionValues = data.map(item => saplingValueMap[item.sapling_condition]);
		const parsedGrainConditionValues = data.map(item => grainValueMap[item.grain_condition]);
		const parsedPlantPestConditionValues = data.map(item => plantPestsValueMap[item.plant_pests_condition]);

		// Extracting time data
		const categories = data.map(item => item.time_txt);

		// Creating the chart
		var options = {
			chart: {
				type: 'line',
				height: 350
			},
			series: [
				{
					name: 'Kondisi Daun',
					data: parsedLeafConditionValues
				},
				{
					name: 'Kondisi Lahan',
					data: parsedLandConditionValues
				},
				{
					name: 'Kondisi Air',
					data: parsedWaterConditionValues
				},
				{
					name: 'Kondisi Anakan',
					data: parsedSaplingConditionValues
				},
				{
					name: 'Kondisi Bulir',
					data: parsedGrainConditionValues
				},
				{
					name: 'Hama Penyakit Tanaman',
					data: parsedPlantPestConditionValues
				},
			],
			xaxis: {
				type: 'category',
				categories: categories,
				title: {
					text: 'Masa Tanam'
				}
			},
			yaxis: {
				// title: {
				// 	text: 'Condition'
				// },
				labels: {
					show: false,
					formatter: function (value, { seriesIndex}) {
						// console.log('seris ',grainValueMap);
						
						// Retrieve the leaf and land condition strings using the numerical value
						if (seriesIndex === 0) { // Leaf Condition series
							const leafCondition = Object.keys(leafValueMap).find(key => leafValueMap[key] === value);
							return leafCondition ? leafCondition : value;
						} else if (seriesIndex === 1) { // Land Condition series
							const landCondition = Object.keys(landValueMap).find(key => landValueMap[key] === value);
							return landCondition ? landCondition : value;
						} else if (seriesIndex === 2){
							const waterCondition = Object.keys(waterValueMap).find(key => waterValueMap[key] === value);
							return waterCondition ? waterCondition : value;
						} else if (seriesIndex === 3){
							const saplingCondition = Object.keys(saplingValueMap).find(key => saplingValueMap[key] === value);
							return saplingCondition ? saplingCondition : value;
						} else if (seriesIndex === 4){
							const grainCondition = Object.keys(grainValueMap).find(key => grainValueMap[key] === value);
							return grainCondition ? grainCondition : value;
						} else if (seriesIndex === 5){
							const plantPestsCondition = Object.keys(plantPestsValueMap).find(key => plantPestsValueMap[key] === value);
							return plantPestsCondition ? plantPestsCondition : value;
						} 
						else {
							return value; // Default case
						}
					}
				}
			},
			tooltip: {
				x: {
					show: true,
					format: 'HH:mm'
				},
			},
			legend: {
				show: true,
				showForSingleSeries: true,
				labels: {
					useSeriesColors: true
				}
			}
		};

		var chart = new ApexCharts(document.querySelector("#chart"), options);
		chart.render();
	}


	return (
		<>
			<div className="flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto">
				<div className="bg-white rounded-2xl shadow-2xl p-5 md:p-6 2xl:px-7">
					<h1 className="font-bold uppercase text-xl mt-2">
						Detail Penanaman Petani
					</h1>

					<nav
						className="flex my-5 py-4 px-2 md:px-5 text-gray-700 rounded-lg border bg-blue-50 border-blue-100"
						aria-label="Breadcrumb">
						<ol className="inline-flex items-center space-x-1 md:space-x-3">
							<li className="inline-flex items-center">
								<Link
									to="/dashboard/cultivation-management-cultivation-planting"
									className="inline-flex items-center text-sms md:text-sm font-semibold text-blue-500 hover:opacity-75">
									<svg
										className="mr-1 md:mr-2 w-4 h-4"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
									</svg>
									Penanaman
								</Link>
							</li>
							<li>
								<div className="flex items-center">
									<svg
										className="w-6 h-6 text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
											clipRule="evenodd"></path>
									</svg>
									<span className="ml-1 text-sms md:text-sm font-bold text-slate-800 hover:opacity-75 cursor-pointer">
										Detail Penanaman
									</span>
								</div>
							</li>
						</ol>
					</nav>

					<div className="flex flex-col md:flex-row justify-between items-center p-3 md:px-5 md:py-4 border rounded-2xl mb-5">
						<div className="flex items-center mb-3 md:mb-0">
							<span className="flex items-center justify-center w-18 h-18 2xl:w-20 2xl:h-20 bg-gray-200 text-gray-400 rounded-lg text-xl">
								<i className="fa-solid fa-user"></i>
							</span>
							<div className="flex flex-col ml-4">
								<h3 className="font-bold text-2xl">
									{dataCultivation?.[0][0].fullname_var}
								</h3>
								<span className="text-base">
									[{dataCultivation?.[0][0].land_code_var}]{" "}
									{dataCultivation?.[0][0].land_name_var}
								</span>
							</div>
						</div>
					</div>

					<div className="mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
						<h2 className="font-bold text-xl">Perlakuan Petani</h2>
						<div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0">
							<SearchField
								onChange={handleSearch}
								placeholder="Cari perlakuan..."
							/>
						</div>
					</div>
					{filterData && (
						<TableUser dataTable={filterData} columnTable={columns} />
					)}
				</div>
			</div>

			<div className="p-6">
				<div className="container mx-auto bg-white p-6 rounded-lg shadow-md">
					{/* <div> */}
					<h2 className="font-bold text-xl">Grafik Hasil Pengamatan</h2>
					{/* </div> */}
					<div id="chart"></div>
				</div>
			</div>
			{loader && <Loader />}
		</>
	);
};

export default CultivationHarvestDetail;


